import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Invite } from 'components/organisms/Invite/Invite';

interface InvitePeopleProps {
  assessment_id: number;
}

export const InvitePeople = ({ assessment_id, ...props }: InvitePeopleProps): ReactElement => {
  const intl = useIntl();
  const handleCompleteInvitation = () => {
    const showToastTimeMs = 200;
    setTimeout(() => {
      document.location.reload();
    }, showToastTimeMs);
  };

  return (
    <CardContainer {...props}>
      <Invite
        assessment_id={assessment_id}
        variant="grey"
        title={intl.formatMessage({
          defaultMessage: 'Invite people to provide feedback',
          description: 'Dashboard - Invite People - Title',
        })}
        paragraph={intl.formatMessage({
          defaultMessage:
            'We recommend inviting a minimum of 6 and a maximum of 9 people to give you feedback.',
          description: 'Dashboard - Invite People - Paragraph',
        })}
        cta={intl.formatMessage({
          defaultMessage: 'Send invitations',
          description: 'Dashboard - Invite People - Cta',
        })}
        onComplete={handleCompleteInvitation}
      />
    </CardContainer>
  );
};
