/* eslint no-param-reassign: ["error", { "props": false }] */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  AssessmentFullProps,
  AssessmentGetProps,
  AssessmentPostProps,
  AssessmentsInitialStateProps,
} from './assessmentsTypes';
import { ApiClient } from 'util/apiClient';
import { ApiPayloadArguments, ApiPayloadOptions, ApiPayloadReturn, ApiResponse } from 'redux/types';

const assessmentsApiHandler = createAsyncThunk<
  ApiPayloadReturn<AssessmentGetProps, AssessmentFullProps>,
  ApiPayloadArguments<AssessmentPostProps>,
  ApiPayloadOptions
>('assessments/assessmentsApiHandler', async (payloadArguments) => {
  const { data, message } = (await ApiClient[payloadArguments.method](
    `/assessments/${payloadArguments.id || ''}`,
    payloadArguments.body,
  )) as ApiResponse<AssessmentGetProps, AssessmentFullProps>;
  const returnObject = {
    data,
    message,
    method: payloadArguments.method,
  };
  return returnObject;
});

const initialState: AssessmentsInitialStateProps = {
  assessments: [],
  currentAssessment: null,
  loading: false,
  loadingCurrentAssesment: false,
  showAssessmentsError: null,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getLoadingKey(action: any) {
  return action.meta.arg.id ? 'loadingCurrentAssesment' : 'loading';
}

const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assessmentsApiHandler.pending, (state, action) => {
        state[getLoadingKey(action)] = true;
      })
      .addCase(assessmentsApiHandler.fulfilled, (state, action) => {
        const { data } = action.payload;
        if (action.payload.method === 'delete') {
          state.assessments = state.assessments.filter(
            (assessment, index) => assessment.id !== (data as Array<AssessmentGetProps>)[index].id,
          );
        }

        if (Array.isArray(data)) {
          state.assessments = data;
        } else {
          state.currentAssessment = data;
        }
        state[getLoadingKey(action)] = false;
      })

      .addCase(assessmentsApiHandler.rejected, (state, action) => {
        state.showAssessmentsError = action.payload?.message;
        const loadingKey = getLoadingKey(action);
        state[loadingKey] = false;
        if (loadingKey === 'loadingCurrentAssesment') {
          state.currentAssessment = null;
        }
      });
  },
});

export const assessmentsActions = {
  ...assessmentsSlice.actions,
  assessmentsApiHandler,
};

export default assessmentsSlice.reducer;
