/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
import {
  RegisterFormItemChild,
  FormItemContainerBasicProps,
  RegisteredFormItem,
} from 'globalTypes';
import { memo, ReactElement, useEffect, useState } from 'react';
import type { UseFormReturn } from 'react-hook-form';
import { ElementContent } from '../ElementContent/ElementContent';
import { ApiElementProps } from '../../EditAssessmentTypes';
import { AccordionHeading } from 'components/molecules/AccordionHeading/AccordionHeading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Separator } from 'components/atoms/Separator/Separator';

interface ElementProps
  extends FormItemContainerBasicProps,
    RegisterFormItemChild,
    RegisteredFormItem {
  id: number;
  name: string;
  title: string;
  onDelete: () => void;
  formMethods?: UseFormReturn;
  values?: ApiElementProps;
}

export const Element = memo(
  ({
    name,
    id,
    title,
    onDelete,
    formMethods,
    values,
    register,
    ...props
  }: ElementProps): ReactElement => {
    const [isopen, setIsOpen] = useState(true);

    useEffect(() => {
      formMethods?.setValue(`${name}.name`, title);

      return () => {
        formMethods?.unregister(name);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div>
        <AccordionHeading
          title={title}
          isOpen={isopen}
          handleToggleOpen={() => setIsOpen(!isopen)}
          handleDelete={onDelete}
          headingType={HeadingType.H6}
          color="black"
          confirmModalProps={{
            title: 'Delete element',
            subtitle: title,
            description: 'Are you sure you want to delete this element?',
          }}
        />
        <Separator margin="3rem 0 4rem" color="greyE" />

        {isopen && (
          <ElementContent
            {...props}
            name={name}
            formMethods={formMethods}
            values={values}
            register={register}
          />
        )}
      </div>
    );
  },
);
