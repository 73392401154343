import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledElementSectionRightItem = styled.div`
  position: relative;
  flex: 0 0 100%;
  min-width: 100%;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex: 0 0 75%;
    min-width: 75%;
  }
`;

export const StyledElementsContent = styled.div`
  margin-top: 2rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-top: 3.6rem;
    max-width: 35.4rem;
  }

  p:first-of-type {
    margin-bottom: 24px;
  }
`;
