/* eslint-disable unicorn/error-message */
import { Cohort } from '../../model/Cohort';

type CreateCohortDTO = {
  customer_id: number;
  description: string;
  name: string;
  organizational_sub_unit_id: number;
  organizational_unit_id: number;
  survey_id: number;
};

export const createCohortDTO = (
  unitId: number,
  subunitId: number,
  customerId: number,
  cohort: Cohort,
): CreateCohortDTO => {
  if (!cohort.name) throw new Error('Missing name field');
  if (!cohort.cohort_survey?.survey) throw new Error('Cohort must contain a survey');
  if (cohort.cohort_survey?.survey.id === undefined) throw new Error('Missing survey id');

  const createCohortDto: CreateCohortDTO = {
    name: cohort.name,
    description: 'TBD',
    customer_id: customerId,
    organizational_unit_id: unitId,
    organizational_sub_unit_id: subunitId,
    survey_id: cohort.cohort_survey.survey.id,
  };

  return createCohortDto;
};
