import { ReactElement } from 'react';
import * as S from './ClientCardList.styles';
import { ClientCard } from '../ClientCard/ClientCard';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Chevron } from 'components/atoms/Icon/Icon';
import { useSlider } from 'hooks/useSlider';
import { CustomerGetProps } from 'redux/ducks/customersDuck/customersTypes';

interface ClientCardListProps {
  customers: Array<CustomerGetProps>;
}

export const ClientCardList = ({ customers }: ClientCardListProps): ReactElement => {
  const { sliderRef, sliderData, actions } = useSlider(
    {
      align: 'start',
      slidesToScroll: 1,
      containScroll: 'trimSnaps',
    },
    [customers],
  );
  const COLLUMN_LENGTH = 5;
  const PAGE_COUNT = Math.ceil(customers.length / 10);
  const PAGE_CARD_COUNT = COLLUMN_LENGTH * 2;

  return (
    <Flex container gap="4rem" flexDirection="column" justifyContent="center" alignItems="center">
      <Flex container justifyContent="space-between" alignItems="center" gap="1rem" maxWidth="100%">
        {sliderData.isDraggable && (
          <S.CardPaginationButton
            disabled={!sliderData.isPreviousButtonEnabled}
            onClick={actions.goToPrevious}
            icon={Chevron}
          />
        )}
        <S.CardsSlider ref={sliderRef}>
          <S.ClientCardsList container gap="2rem" flexGrow="0">
            {[...new Array(PAGE_COUNT).keys()].map((pageIndex) => {
              return [...new Array(COLLUMN_LENGTH).keys()].map((columnIndex) => {
                const isFullPage =
                  pageIndex * PAGE_CARD_COUNT + PAGE_CARD_COUNT < customers.length ||
                  pageIndex === 0;

                const topIndex = pageIndex * (COLLUMN_LENGTH * 2) + columnIndex;

                const topCardIndex = isFullPage
                  ? topIndex
                  : topIndex + (columnIndex % COLLUMN_LENGTH);
                const bottomCardIndex = isFullPage
                  ? topCardIndex + COLLUMN_LENGTH
                  : topCardIndex + 1;

                if (customers[topCardIndex]) {
                  return (
                    <Flex
                      key={`card-group-${pageIndex}-${columnIndex}`}
                      container
                      flexDirection="column"
                      gap="2rem"
                    >
                      <ClientCard
                        {...customers[topCardIndex]}
                        date={customers[topCardIndex].created_at}
                        id={customers[topCardIndex].id}
                      />
                      {customers[bottomCardIndex] && (
                        <ClientCard
                          {...customers[bottomCardIndex]}
                          date={customers[bottomCardIndex].created_at}
                          id={customers[bottomCardIndex].id}
                        />
                      )}
                    </Flex>
                  );
                }

                return null;
              });
            })}
          </S.ClientCardsList>
        </S.CardsSlider>
        {sliderData.isDraggable && (
          <S.RightCardPaginationButton
            disabled={!sliderData.isNextButtonEnabled}
            onClick={actions.goToNext}
            icon={Chevron}
          />
        )}
      </Flex>
      {sliderData.isDraggable && (
        <Flex container alignItems="center" gap="1.6rem" justifyContent="center">
          {sliderData.scrollSnaps.map((page, index) => (
            <S.SliderIndicatorItem
              // eslint-disable-next-line react/no-array-index-key
              key={`indicator-${index}`}
              $isActive={index === sliderData.activeSlideIndex}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};
