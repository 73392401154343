import type { ReactElement, ReactNode } from 'react';
import { HideStyleProps } from './Hide.styles';
import * as S from './Hide.styles';

interface HideProps extends HideStyleProps {
  children: ReactNode;
}

export const Hide = ({ children, ...props }: HideProps): ReactElement => {
  return <S.StyledHide {...props}>{children}</S.StyledHide>;
};
