/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line import/no-unresolved
import { RegisteredFormItem, RegisterFormItemChild } from 'globalTypes';
import axios from 'axios';
import { ReactElement } from 'react';
import * as S from './CohortUsersForm.styles';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { Plus } from 'components/atoms/Icon/Icon';
import { Input } from 'components/atoms/Input/Input';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { setErrorEventAction, setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { useAppDispatch } from 'redux/store';
import { ParticipantService } from 'services/ParticipantService';
import { Participant } from 'model/Participant';
import { MediaQuery } from 'styles/mediaQuery';

interface CohortUsersFormProps extends RegisteredFormItem, RegisterFormItemChild {
  participants: Array<Participant>;
  setParticipants: (nextState: Array<Participant>) => void;
  isParticipantTable?: boolean;
  customerId?: number;
}

export const CohortUsersForm = ({
  register,
  formMethods,
  errors,
  participants,
  setParticipants,
  name,
  customerId,
  ...props
}: CohortUsersFormProps): ReactElement => {
  const dispatch = useAppDispatch();

  const handleAddUserClick = async () => {
    try {
      formMethods.trigger('currentParticipant.name');
      formMethods.trigger('currentParticipant.email');
      formMethods.trigger('currentParticipant.is_admin');
      const formParticipant = {
        name: formMethods.getValues('currentParticipant.name'),
        email: formMethods.getValues('currentParticipant.email'),
        is_admin: formMethods.getValues('currentParticipant.is_admin') || false,
      };

      if (!customerId) throw new Error('Customer id is missing');
      const participant: Participant = Object.assign(new Participant(), formParticipant);
      const newParticipant = await ParticipantService.addParticipant(customerId, participant);

      setParticipants([...participants, newParticipant]);

      formMethods.setValue('currentParticipant.name', '');
      formMethods.setValue('currentParticipant.email', '');
      formMethods.setValue('currentParticipant.is_admin', false);

      dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        formMethods.setError('currentParticipant.email', {
          type: 'validation',
          message: 'A user with this email already exists',
        });
      }

      dispatch(setErrorEventAction(Events.FORM_INVALID_ERROR));
    }
  };

  return (
    <S.StyledCohortUsersForm
      container
      gap="2rem"
      flexDirection={['row', [MediaQuery.MAX_767, 'column']]}
      {...props}
    >
      <RegisterFormItem
        register={register}
        formMethods={formMethods}
        errors={errors}
        name="currentParticipant.name"
        registerErrors="required"
        label="Full Name"
        color="blueA"
        alignError="left"
      >
        <Input hasRef placeholder="Name" />
      </RegisterFormItem>
      <RegisterFormItem
        register={register}
        formMethods={formMethods}
        errors={errors}
        name="currentParticipant.email"
        registerErrors={['required', { type: 'pattern', key: 'emailPattern' }]}
        label="Email"
        color="blueA"
        alignError="left"
      >
        <Input hasRef type="email" placeholder="Email" />
      </RegisterFormItem>
      <S.StyledRegisterFormItemCheckbox
        register={register}
        formMethods={formMethods}
        errors={errors}
        name="currentParticipant.is_admin"
        label="Client Admin"
        color="blueA"
      >
        <Checkbox checked={formMethods && formMethods.getValues('currentParticipant.is_admin')} />
      </S.StyledRegisterFormItemCheckbox>
      <PrimaryButton $outline $size="regular" type="button" onClick={handleAddUserClick}>
        Add user
        <Plus />
      </PrimaryButton>
    </S.StyledCohortUsersForm>
  );
};
