import styled from 'styled-components';
import { IconButton } from 'components/molecules/IconButton/IconButton';

export interface ChevronButtonProps {
  $open?: boolean;
}

export const StyledChevronButton = styled(IconButton)<ChevronButtonProps>`
  height: 4rem;
  width: 4rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.blueE};
  color: ${({ theme }) => theme.color.blueA};

  div {
    height: 0.7rem;
    display: flex;
    justify-content: center;

    svg {
      height: 100%;
      transform: rotate(${({ $open }) => ($open ? '90' : '270')}deg);
    }
  }
`;
