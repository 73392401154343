import { FunctionComponent, ReactElement, useEffect, useMemo, useState } from 'react';
import { Route, RouteProps, RouterProps, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserRole } from '../../util/getUserRole';
import { POST_LOGIN, SET_PROFILE_ID } from 'redux/ducks/authDuck/authTypes';
import { Path } from 'routes/Path';
import { Loading } from 'components/atoms/Loading/Loading';
import { LoadingTheme } from 'components/atoms/Loading/Loading.styles';
import { SessionService } from 'services/SessionService';
import { userRoles } from 'model/User';

interface ProtectedRouteProps extends RouteProps {
  component: FunctionComponent<RouterProps & { userRole?: string }>;
  // eslint-disable-next-line prettier/prettier
  role?: typeof userRoles[keyof typeof userRoles];
}

const ProtectedRoute = ({
  component: Component,
  role,
  ...props
}: ProtectedRouteProps): ReactElement => {
  const [userRole, setUserRole] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoggedIn = useMemo(() => !!userRole, [userRole]);

  useEffect(() => {
    getUserRole().then(async (data) => {
      const validUserRole = role ? data?.role === role : true;

      if (!data || !validUserRole) {
        if (data) await SessionService.logout();
        dispatch({ type: SET_PROFILE_ID, payload: null });
        history.push(Path.Login);
        return;
      }

      dispatch({
        type: POST_LOGIN,
        payload: data,
      });

      setUserRole(data.role);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      exact
      {...props}
      render={(routeProps) => {
        if (!isLoggedIn) {
          return <Loading variant={LoadingTheme.Light} />;
        }
        return <Component {...routeProps} userRole={userRole} />;
      }}
    />
  );
};

export default ProtectedRoute;
