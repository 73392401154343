import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';
import * as S from './EmptyDashboard.styles';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Heading } from 'components/atoms/Heading/Heading';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Path } from 'routes/Path';

interface EmptyDashboardProps {
  cohortSurveyId?: number;
  assessmentIsAvailable?: boolean;
}

export const EmptyDashboard = ({
  cohortSurveyId,
  assessmentIsAvailable,
  ...props
}: EmptyDashboardProps): ReactElement => {
  const { push } = useHistory();
  const handleStartAssessment = () => {
    if (!cohortSurveyId) return;
    push(`${Path.Assessment}/${cohortSurveyId}`);
  };

  return (
    <S.StyledCardContainer {...props}>
      {!assessmentIsAvailable ? (
        <Heading>
          You have not been assigned <br /> an assessment yet
        </Heading>
      ) : (
        <>
          <Heading type={HeadingType.H6}>
            <FormattedMessage
              description="User Dashboard - No Assessments - Heading"
              defaultMessage="You have not completed an assessment yet."
            />
          </Heading>

          <Paragraph margin="1.6rem 0 4rem">
            <FormattedMessage
              description="User Dashboard - No Assessments - Paragraph"
              defaultMessage="Take the first step towards growing your leadership"
            />
          </Paragraph>

          <PrimaryButton onClick={handleStartAssessment}>
            <FormattedMessage
              description="User Dashboard - No Assessments - Cta"
              defaultMessage="Start your Assessment"
            />
          </PrimaryButton>
        </>
      )}
    </S.StyledCardContainer>
  );
};
