/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-unresolved
import { FormItemContainerBasicProps, RegisterFormItemChild } from 'globalTypes';
import { ReactElement, useRef, useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { StyledNote } from '../../../CreateElement/CreateElement.styles';
import * as S from './ElementContent.styles';
import { ApiElementProps } from '../../EditAssessmentTypes';
import { ElementSingleQuestion } from '../ElementSingleQuestion/ElementSingleQuestion';
import { createQuestiontableData, tableData } from './ElementContent.data';
import { QuestionProps } from 'redux/ducks/questionsDuck/questionsTypes';
import {
  FormItemSizes,
  StyledLabel,
} from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import {
  getTemporaryAddQuestions,
  getTemporaryQuestions,
} from 'redux/ducks/temporaryQuestionsDuck/temporaryQuestionsActions';
import { questionsActions } from 'redux/ducks/questionsDuck/questionsReducer';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';
import { PillTabs } from 'components/atoms/PillTabs/PillTabs';
import { ManagementTable } from 'components/pages/Superadmin/components/ManagementTable/ManagementTable';
import { TableDataItems, TableOptions } from 'components/atoms/Table/Table.types';
import { editModalNameEmailProps } from 'util/editModalNameEmailProps';
import { FormModalContext } from 'components/organisms/FormModal/FormModal';
import { ConfirmModalContext } from 'components/organisms/ConfirmModal/ConfirmModal';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { RootState } from 'redux/store';
import {
  updateSelectedNewQuestions,
  updateSelectedQuestions,
} from 'redux/ducks/selectedChallengesOrQuestionsDuck/selectedChallengesOrQuestionsActions';
import { ActionMethod } from 'data/enum/ActionMethod';

interface ElementContentProps extends FormItemContainerBasicProps, RegisterFormItemChild {
  values?: ApiElementProps;
}

export const ElementContent = ({
  formMethods,
  register,
  errors,
  values,
  name,
}: ElementContentProps): ReactElement => {
  const elementValues = formMethods.getValues(name);
  const elementName = elementValues && elementValues.name;

  const dispatch = useDispatch();
  const intl = useIntl();
  const accordionRefs = useRef<Array<HTMLElement>>([]);

  const { useFormModal } = useContext(FormModalContext);
  const { useConfirmModal } = useContext(ConfirmModalContext);

  const [openIndex, setOpenIndex] = useState<boolean>(false);
  const [searchOrAdd, setSearchOrAdd] = useState(true);
  const [temporaryQuestionsArray, setTemporaryQuestionsArray] = useState<Array<QuestionProps>>([]);
  const [finalQuestions, setFinalQuestions] = useState<Array<QuestionProps>>([]);
  const [createdQuestions, setCreatedQuestions] = useState<Array<QuestionProps>>([]);
  const [newQuestions, setNewQuestions] = useState<TableOptions>();
  const [newCreatedQuestions, setNewCreatedQuestions] = useState<TableOptions>();

  const { questions } = useSelector((state: RootState) => {
    return state.questions;
  });

  const handleCallbackCheckbox = (value: boolean, id?: number) => {
    const newArray = finalQuestions.map((item) => {
      const isOpen = item.id === id ? value : item.set_as_open;

      return { ...item, set_as_open: isOpen };
    });

    setFinalQuestions(newArray);
  };

  const handleCallback = (childData: any) => {
    const {
      'elem.questions-isopen': set_as_open,
      'elem.questions.description': description,
      'elem.questions.name': name,
    } = childData;

    const newChildData = {
      set_as_open,
      description,
      name,
      question_id: 1,
      allow_no_answer: false,
      element_type_id: 2,
      order: 1,
    };
    setCreatedQuestions((previousState) => [...previousState, newChildData]);
  };

  const filterQuestionsById = (data: Array<QuestionProps>) => {
    if (data) {
      const filtered = Object.values(data).map((item) => {
        const isSelected =
          newCreatedQuestions &&
          newCreatedQuestions.rows.filter(
            (selecteditem) =>
              selecteditem.filter((subitem) => subitem.data && subitem.data.id === item.id)[0],
          )[0];

        return [
          { copy: item.id },
          { copy: item.name },
          { copy: item.description },
          { copy: 'Date Testing' },
          { hidden: true, control: isSelected ? 'Remove' : 'Add' },
        ];
      });
      const copyData = { ...tableData, rows: filtered } as TableOptions;
      setNewQuestions(copyData);
    }
  };

  const filterNewCreatedQuestions = (data: Array<QuestionProps>) => {
    if (data) {
      const filtered = data
        .filter((item) => item)
        .map(
          (item, index) =>
            item && [
              { copy: index + 1 },
              { copy: item?.name },
              { copy: item?.description },
              { copy: item?.created_at },
              {
                copy: (
                  <Checkbox
                    checked={item?.set_as_open}
                    refId={item?.id}
                    isOnTable
                    parentCallback={handleCallbackCheckbox}
                  />
                ),
              },
              { data: item, hidden: true },
            ],
        )
        .filter((item) => item);

      const copyData = { ...createQuestiontableData, rows: filtered } as TableOptions;

      setNewCreatedQuestions(copyData);
    }
  };

  const filterTemporaryQuestions = (data: Array<QuestionProps>) => {
    if (data) {
      const filtered = Object.values(data).map((item, index) => {
        return {
          id: item.id,
          question_id: item.element_id,
          name: item.name,
          description: item.description,
          allow_no_answer: item.allow_no_answer,
          set_as_open: item.set_as_open,
          element_type_id: 2,
          order: index + 1,
        };
      });
      setTemporaryQuestionsArray(filtered as Array<QuestionProps>);
    }
  };

  const RemoveOrAdd = async (rowIndex: number, tableRow?: TableDataItems, toggle?: boolean) => {
    let selectedQuestion = temporaryQuestionsArray[rowIndex];
    const selectedId = tableRow && tableRow[0].copy;
    if (tableRow && typeof selectedId === 'number') {
      [selectedQuestion] = temporaryQuestionsArray.filter(
        ({ question_id }) => question_id === selectedId,
      );
    }

    // check if the questions already is added
    const questionAdded = finalQuestions.filter((item) => item && item.id === rowIndex)[0];
    if (questionAdded && toggle) return;

    if (toggle && selectedQuestion) {
      setFinalQuestions((previousState) => [...previousState, selectedQuestion]);
    } else {
      setFinalQuestions((previousState) => {
        const newQuestions = previousState.filter((item) => item.id !== rowIndex);

        return [...newQuestions];
      });
    }
  };

  const Delete = async (rowIndex: number) => {
    const rowData =
      newCreatedQuestions &&
      newCreatedQuestions.rows.filter(
        (item) => (item[0].copy as unknown as number) === rowIndex,
      )[0];

    const questionData = rowData && rowData.filter((item) => item.data)[0];
    const questionId = questionData && questionData.data.id;

    if (!questionId) return;

    if (rowData) {
      const description = intl.formatMessage({
        defaultMessage: 'Are you sure you want to delete this question?',
        description: 'Confirm Delete Question Description',
      });

      const confirm = await useConfirmModal({
        title: 'Delete',
        subtitle: rowData[2].copy,
        description,
      });

      if (confirm) {
        setFinalQuestions((previousState) => {
          const newQuestions = previousState.filter((item) => item.id !== questionId);

          return [...newQuestions];
        });
      }
    }
  };

  const Edit = async (rowIndex: number) => {
    const rowData = newCreatedQuestions && newCreatedQuestions.rows[rowIndex - 1];

    if (rowData) {
      const name = rowData[1].copy as string;
      const email = rowData[2].copy as string;
      const formModalProps = editModalNameEmailProps(name, email, undefined, {
        registerFormItemProps: {
          label: 'Description',
          name: 'description',
        },
      });

      const response = (await useFormModal(formModalProps)) as {
        name: string;
        description: string;
      };

      if (response) {
        const newChildData = {
          description: response.description,
          name: response.name,
          set_as_open: createdQuestions[rowIndex].set_as_open,
        };

        const newArray = [...createdQuestions];
        newArray[rowIndex] = newChildData;
        setCreatedQuestions(newArray);
      }
    }
  };

  const InsideComponent = () => {
    return (
      <>
        {searchOrAdd ? (
          newQuestions && (
            <ManagementTable
              data={newQuestions as TableOptions}
              actions={{ RemoveOrAdd }}
              outsideComponent="search-questions"
            />
          )
        ) : (
          <div>
            <ElementSingleQuestion
              key={`${elementName}`}
              name="elem.questions"
              errors={errors}
              register={register}
              formMethods={formMethods}
              closeAll={() => setOpenIndex(false)}
              onOpen={() => setOpenIndex(!openIndex)}
              isOpen
              title={`Question ${elementName}`}
              accordionRef={(ref: HTMLElement) => {
                accordionRefs.current[1] = ref;
              }}
              parentCallback={handleCallback}
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    dispatch(questionsActions.questionsApiHandler({ method: ActionMethod.GET }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTemporaryAddQuestions(createdQuestions));
  }, [dispatch, createdQuestions]);

  useEffect(() => {
    dispatch(getTemporaryQuestions(finalQuestions));
  }, [finalQuestions, dispatch]);

  useEffect(() => {
    const existingQuestionsBody = {
      element_name: elementName,
      questions: finalQuestions,
    };
    dispatch(updateSelectedQuestions(existingQuestionsBody));
  }, [dispatch, finalQuestions, name]);

  useEffect(() => {
    const createdQuestionsBody = {
      element_name: elementName,
      questions: createdQuestions,
    };

    dispatch(updateSelectedNewQuestions(createdQuestionsBody));
  }, [dispatch, createdQuestions, name]);

  useEffect(() => {
    const questionsArray = createdQuestions.concat(finalQuestions);
    formMethods.setValue('created-questions', createdQuestions);
    formMethods.setValue(`${name}.questions`, finalQuestions);
    filterNewCreatedQuestions(questionsArray);
  }, [createdQuestions, finalQuestions, formMethods]);

  useEffect(() => {
    if (values && values.questions && temporaryQuestionsArray.length > 0) {
      values.questions.forEach((item) => {
        if (typeof item.element_id === 'number') {
          RemoveOrAdd(item.element_id, undefined, true);
        }
      });
    }
  }, [values, temporaryQuestionsArray]);

  useEffect(() => {
    filterQuestionsById(questions as Array<QuestionProps>);
    filterTemporaryQuestions(questions as Array<QuestionProps>);
  }, [questions]);

  useEffect(() => {
    filterQuestionsById(questions as Array<QuestionProps>);
  }, [newCreatedQuestions]);

  useEffect(() => {
    return () => {
      // setOpenIndex(false);
      // setSearchOrAdd(true);
      // setTemporaryQuestionsArray([]);
      // setFinalQuestions([]);
      // setCreatedQuestions([]);
      // setNewQuestions(undefined);
      // setNewCreatedQuestions(undefined);
    };
  }, []);

  return (
    <Flex container flexDirection="column" gap="3rem">
      {/* <ElementsViewSelect
        errors={errors}
        register={register}
        formMethods={formMethods}
        items={assessmentData.challenges}
        cta="Add Challenge"
        label="Select Challenges"
        note="You can set up to 3 challenges"
        name={`${name}`}
        values={values && values.challenges}
        // parentElementCallback={handleElementsViewCallback}
      /> */}
      <Flex container flexDirection="column" gap="3.5rem">
        <RegisterFormItem
          errors={errors}
          register={register}
          formMethods={formMethods}
          name={`${name}.questions`}
        >
          <Flex>
            <Flex container justifyContent="space-between" alignItems="center">
              <StyledLabel size={FormItemSizes.Medium} marginBottom="0rem">
                Set number of questions per element
              </StyledLabel>
            </Flex>
            <StyledNote type="label">You can set up to 10 questions</StyledNote>
          </Flex>
        </RegisterFormItem>
        <TabsContainer>
          <S.StyledPillTabsContainer onClick={() => setSearchOrAdd(!searchOrAdd)}>
            <PillTabs
              tabs={[
                intl.formatMessage({
                  defaultMessage: 'Search Questions',
                  description: 'User Dashboard - Assessment - Tab 1',
                }),
                intl.formatMessage({
                  defaultMessage: 'Create New One',
                  description: 'User Dashboard - Assessment - Tab 2',
                }),
              ]}
            />
          </S.StyledPillTabsContainer>
          {InsideComponent}
        </TabsContainer>

        {newCreatedQuestions && (
          <S.AddedQuestionsWrapper>
            <ManagementTable
              data={newCreatedQuestions as TableOptions}
              actions={{ Edit, Delete }}
              outsideComponent="add-questions"
              accordionRef={(ref: HTMLElement) => {
                accordionRefs.current[1] = ref;
              }}
            />
          </S.AddedQuestionsWrapper>
        )}
      </Flex>
    </Flex>
  );
};
