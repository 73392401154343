/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import { RegisteredFormItem } from 'globalTypes';
import { get } from 'lodash';
import { cloneElement, ReactElement, useEffect } from 'react';
import type { FieldValues, RegisterOptions, UseFormRegister, UseFormReturn } from 'react-hook-form';
import {
  FormItemContainer,
  FormItemContainerProps,
} from '../../atoms/FormItemContainer/FormItemContainer';
import { defaultErrors, DefaultErrorsKey } from './RegisterFormItem.data';

export type RegisterError =
  | DefaultErrorsKey
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | {
      type: keyof RegisterOptions;
      key?: DefaultErrorsKey;
      customValue?: any;
      value?: any;
    };

export type RegisterErrorProp = Array<RegisterError> | DefaultErrorsKey;

export interface RegisterFormItemProps extends FormItemContainerProps, RegisteredFormItem {
  children: ReactElement;
  name: string;
  registerErrors?: RegisterErrorProp;
  disableValidation?: boolean;
  hideMessage?: boolean;
  onWatch?: (name: string, value: string, formMethods: UseFormReturn) => void;
}

export const RegisterFormItem = ({
  name,
  register,
  formMethods,
  errors,
  registerErrors,
  children,
  disableValidation,
  hideMessage,
  onWatch,
  ...props
}: RegisterFormItemProps): ReactElement => {
  const getErrors = (registerErrors: Array<RegisterError> | DefaultErrorsKey) => {
    const customErrors = {} as FieldValues;

    if (typeof registerErrors === 'string') {
      customErrors[registerErrors] = defaultErrors[registerErrors];
    } else if (Array.isArray(registerErrors)) {
      registerErrors.forEach((error) => {
        if (typeof error === 'string') {
          customErrors[error] = defaultErrors[error];
        } else if (error.type === 'validate') {
          customErrors[error.type] = (value: string) => error.value(value, formMethods);
        } else if (error.type && error.value && !error.key) {
          customErrors[error.type] = error.value;
        } else if (error.type && error.key) {
          if (typeof defaultErrors[error.key] === 'function' && error.customValue) {
            customErrors[error.type] = (defaultErrors[error.key] as CallableFunction)(
              error.customValue,
            );
          } else {
            customErrors[error.type] = defaultErrors[error.key];
          }
        }
      });
    }

    return customErrors;
  };

  const { ref, ...inputProps } = (register as UseFormRegister<FieldValues>)(
    name,
    registerErrors && !disableValidation ? getErrors(registerErrors) : {},
  );

  const hasError = !!get(errors, name);

  useEffect(() => {
    if (disableValidation && !register) {
      formMethods.unregister(name);
    }
  }, [disableValidation]);

  useEffect(() => {
    const subscription = formMethods.watch((value: any, { name }: any) => {
      if (onWatch) {
        onWatch(name, value, formMethods);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [onWatch]);

  return (
    <FormItemContainer
      errorMessage={hasError && !hideMessage && get(errors, name).message}
      {...props}
    >
      {cloneElement(children, {
        ...children.props,
        ...inputProps,
        ...(children.props.hasRef ? { ref } : {}),
        input: inputProps,
        hasError,
        name,
        formMethods,
      })}
    </FormItemContainer>
  );
};
