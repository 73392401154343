import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SelectItem } from 'components/molecules/Select/Select';
import { GenderService } from 'services/GenderService';
/* eslint-disable no-param-reassign */

type Gender = { id: number; description: string };

const fetchGenders = createAsyncThunk('/genders/fetchGenders', async () =>
  GenderService.getGenders(),
);

const initialState: {
  genders: Array<Gender>;
  adaptedGenders: Array<SelectItem>;
  loading: boolean;
} = {
  genders: [],
  adaptedGenders: [],
  loading: false,
};

const gendersSlice = createSlice({
  name: 'nationalities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchGenders.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGenders.fulfilled, (state, action) => {
        const countries = action.payload;
        state.genders = countries;
        state.adaptedGenders = countries.map((gender) => ({
          title: gender.description,
          value: gender.id,
        }));
        state.loading = false;
      });
  },
});

export const gendersActions = {
  ...gendersSlice.actions,
  fetchGenders,
};

export default gendersSlice.reducer;
