import styled from 'styled-components';

export const ButtonWrapper = styled.div``;

export const StyledCopy = styled.p`
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.7rem;
`;
