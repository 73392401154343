/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line import/no-unresolved
import { LocationState } from 'globalTypes';
import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FieldValues } from 'react-hook-form';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import { TimeLength } from '../../components/TimeLength/TimeLength';
import * as S from './CreateChallenge.styles';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { MediaQuery } from 'styles/mediaQuery';
import { TextArea } from 'components/atoms/TextArea/TextArea';
import {
  RegisterErrorProp,
  RegisterFormItem,
} from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { StickyBar } from 'components/atoms/StickyBar/StickyBar';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Input } from 'components/atoms/Input/Input';
import { Text } from 'components/atoms/Text/Text';
import { Path } from 'routes/Path';
import { StorageItem } from 'data/enum/StorageItem';
import { RootState } from 'redux/store';
import { challengesActions } from 'redux/ducks/challengesDuck/challengesReducer';
import { ActionMethod } from 'data/enum/ActionMethod';
import { ChallengeBaseProps, ChallengeGetProps } from 'redux/ducks/challengesDuck/challengesTypes';
import { missionsActions } from 'redux/ducks/missionsDuck/missionsReducer';
import { Loader } from 'components/atoms/Loading/Loading.styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateChallengeProps {}

// eslint-disable-next-line no-empty-pattern
export const CreateChallenge = ({}: CreateChallengeProps): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory<LocationState>();

  const { push } = history;

  const registerErrors: Record<string, RegisterErrorProp> = {
    name: [
      'required',
      {
        type: 'maxLength',
        key: 'customMaxLength',
        customValue: 50,
      },
    ],
    description: [
      'required',
      {
        type: 'maxLength',
        key: 'customMaxLength',
        customValue: 400,
      },
    ],
    duration: 'required',
    missionAmount: 'required',
  };

  // "Edit Challenge" route receives an id from location state
  // If the route is "Create Challenge", id is undefined
  const challengeID = history.location.state && history.location.state.id;

  // Get (if available) the temporary challenge data saved in local storage
  const temporaryData = JSON.parse(localStorage.getItem(StorageItem.TEMPORARY_CHALLENGE) || '{}');

  const { challenge: temporaryChallenge }: { challenge: ChallengeGetProps } = temporaryData;

  // Get the current challenge if Editing a challenge
  const { currentChallenge, loading } = useSelector((state: RootState) => {
    return state.challenges;
  });

  // redirect the use to "Create Challenge" route if there is no id and they are on Edit Challenge page
  if (challengeID === undefined && history.location.pathname === Path.EditChallenge) {
    push(Path.CreateChallenge);
  }

  const handleSuccess = async (values: FieldValues) => {
    const { name, description, duration, mission_amount } = values;
    const challengeData: ChallengeBaseProps = {
      name,
      description,
      duration: parseInt(duration.value, 10),
      duration_type_id: parseInt(duration.type, 10),
      mission_amount: mission_amount.value,
      mission_duration_id: mission_amount.type,
    };

    // save the data to a temporary local storage for step 2
    const localStorageData = {
      ...temporaryData,
      challenge: challengeData,
      challengeID,
    };

    localStorage.setItem(StorageItem.TEMPORARY_CHALLENGE, JSON.stringify(localStorageData));

    push(Path.AddMissions, {
      id: challengeID,
    });
  };

  const handleCancel = () => {
    localStorage.removeItem(StorageItem.TEMPORARY_CHALLENGE);
    dispatch(challengesActions.resetCurrentChallenge());
    dispatch(missionsActions.resetCurrentMissions());
    push(Path.ChallengesManagement);
  };

  useEffect(() => {
    if (challengeID) {
      dispatch(
        challengesActions.challengesApiHandler({ method: ActionMethod.GET, id: challengeID }),
      );
    }
  }, [dispatch, challengeID]);

  return (
    <S.StyledCreateChallenge className="formLeftPaddingDifference">
      <BackgroundCircle
        lastBackground
        top={['40%', [MediaQuery.MIN_768, '34.6rem']]}
        left={['-40.5rem', [MediaQuery.MIN_768, '-58.5rem']]}
        size={['78.6rem', [MediaQuery.MIN_768, '113.8rem']]}
      />

      <AdminHeading
        title={challengeID === undefined ? 'Create a new challenge' : 'Edit challenge'}
        $color="blueA"
        $underline
        breadcrumbs={[
          { title: 'Challenges Management', href: Path.ChallengesManagement },
          { title: challengeID === undefined ? 'create challenge' : 'edit challenge' },
        ]}
      />

      {loading ? (
        <Loader />
      ) : (
        <S.StyledForm onSuccess={handleSuccess}>
          <RegisterFormItem
            register
            registerErrors={registerErrors.name}
            name="name"
            label="Challenge name"
            size={FormItemSizes.Medium}
            alignError="left"
          >
            <Input
              hasRef
              placeholder="Input a name for the challenge"
              defaultValue={
                (temporaryChallenge && temporaryChallenge.name) ||
                (currentChallenge && currentChallenge.name) ||
                undefined
              }
            />
          </RegisterFormItem>
          <div>
            <RegisterFormItem
              alignError="left"
              register
              registerErrors={registerErrors.description}
              name="description"
              label="Description"
              size={FormItemSizes.Medium}
            >
              <TextArea
                hasRef
                maxLength={400}
                defaultValue={
                  (temporaryChallenge && temporaryChallenge.description) ||
                  (currentChallenge && currentChallenge.description) ||
                  undefined
                }
              />
            </RegisterFormItem>
          </div>

          <div>
            <AdminHeading title="Set Challenge Times" $size="small" $underline />

            <Flex container flexDirection="column" gap="4rem">
              <RegisterFormItem
                alignError="left"
                register
                registerErrors={registerErrors.duration}
                name="duration"
                label="Challenge Length"
                description="Set the length in days, weeks, months or years"
                size={FormItemSizes.Medium}
              >
                <TimeLength
                  name="duration"
                  value={{
                    value:
                      (temporaryChallenge && temporaryChallenge.duration) ||
                      (currentChallenge && currentChallenge.duration) ||
                      undefined,
                    unit:
                      (temporaryChallenge && temporaryChallenge.duration_type_id) ||
                      (currentChallenge && currentChallenge.mission_duration_id) ||
                      undefined,
                  }}
                  max={50}
                  min={1}
                />
              </RegisterFormItem>
              <RegisterFormItem
                alignError="left"
                register
                registerErrors={registerErrors.missionAmount}
                name="mission_amount"
                label="Amount of Missions"
                description="Set the total number of missions and the frequency."
                size={FormItemSizes.Medium}
              >
                <TimeLength
                  name="mission_amount"
                  value={{
                    value:
                      (temporaryChallenge && temporaryChallenge.mission_amount) ||
                      (currentChallenge && currentChallenge.mission_amount) ||
                      undefined,
                    unit:
                      (temporaryChallenge && temporaryChallenge.mission_duration_id) ||
                      (currentChallenge && currentChallenge.mission_duration_id) ||
                      undefined,
                  }}
                  max={50}
                  min={1}
                />
              </RegisterFormItem>
            </Flex>
          </div>

          <StickyBar>
            <Flex container gap="3rem" alignItems="center" justifyContent="space-between">
              <Text type="uppercase" color="blueA">
                1/2
              </Text>
              <Flex container gap="3rem">
                <SecondaryButton onClick={handleCancel} type="button" color="greyA">
                  <span>Leave without saving</span>
                </SecondaryButton>
                <PrimaryButton type="submit" $size="regular" $backgroundColor="blueA">
                  Save & Next
                </PrimaryButton>
              </Flex>
            </Flex>
          </StickyBar>
        </S.StyledForm>
      )}
    </S.StyledCreateChallenge>
  );
};
