import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledIntro = styled.div`
  position: relative;
  margin: 2rem 0 4rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin: 2rem 0 11rem;
  }
`;
