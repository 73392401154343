/* eslint-disable import/no-unresolved */
import { RegisteredFormItem } from 'globalTypes';
import type { ReactElement } from 'react';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Input } from 'components/atoms/Input/Input';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { Text } from 'components/atoms/Text/Text';
import { MediaQuery } from 'styles/mediaQuery';
import { UploadImage } from 'components/organisms/UploadImage/UploadImage';
import { Upload } from 'components/atoms/Icon/Icon';

interface ClientNameProps extends RegisteredFormItem {
  defaultImage: { name: string | undefined; logo: string | undefined };
}

export const ClientName = ({
  formMethods,
  register,
  errors,
  defaultImage,
}: ClientNameProps): ReactElement => {
  return (
    <Flex container flexDirection="column" gap="3rem">
      <Flex
        container
        gap="1rem 3rem"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex
          container
          flexDirection="column"
          gap="3rem"
          flexGrow="1"
          flexBasis={['50%', [MediaQuery.MIN_1024, '70%']]}
        >
          <RegisterFormItem
            errors={errors}
            register={register}
            formMethods={formMethods}
            registerErrors="required"
            name="customer.name"
            label="Client Name"
            size={FormItemSizes.Medium}
            alignError="left"
          >
            <Input hasRef placeholder="Enter client's name" defaultValue={defaultImage?.name} />
          </RegisterFormItem>
        </Flex>
        <Flex
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-end"
          gap="2.7rem"
        >
          <RegisterFormItem
            errors={errors}
            register={register}
            formMethods={formMethods}
            registerErrors="required"
            name="customer.logo"
            alignError="left"
          >
            <UploadImage defaultImage={defaultImage}>
              <PrimaryButton type="button" $size="regular">
                Upload Logo
                <Upload />
              </PrimaryButton>
            </UploadImage>
          </RegisterFormItem>
          <Text type="label">Allowed extensions: JPEG, PNG</Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
