import styled from 'styled-components';
import { FontWeight } from '../../../styles/variables';
import { ColorKey, theme } from 'styles/theme/default';
import { Responsive, responsive } from 'styles/helpers/responsive';

export interface ParagraphStyleProps {
  fontWeight?: FontWeight;
  color?: ColorKey | 'currentColor';
  margin?: Responsive;
}

export const StyledParagraph = styled.p<ParagraphStyleProps>`
  font-size: 16px;
  line-height: 22px;
  color: ${({ color }) => (color === 'currentColor' ? color : theme.color[color || 'greyA'])};
  ${({ fontWeight }) => fontWeight && `font-weight:  ${fontWeight}`};
  ${({ margin }) => margin && responsive('margin', margin)};
`;
