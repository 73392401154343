/* eslint-disable no-restricted-properties */
import { useEffect, useRef, ReactElement } from 'react';
import { LargeArrow } from '../Icon/Icon';
import * as S from './ScrollDownArrow.styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ScrollDownArrowProps {}

const getTop = (element: HTMLElement) => {
  return element.getBoundingClientRect().top;
};

export const ScrollDownArrow = ({ ...props }: ScrollDownArrowProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handlePosition = () => {
      if (!ref.current) return;
      const footer = document.querySelector('footer');
      if (!footer) return;

      if (getTop(footer) - window.innerHeight < 0) {
        ref.current.classList.add('absolute');
      } else {
        ref.current.classList.remove('absolute');
      }
    };
    handlePosition();

    document.addEventListener('scroll', handlePosition);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('scroll', handlePosition);
    };
  }, []);

  return (
    <S.StyledScrollDownArrow ref={ref} {...props}>
      <LargeArrow />
    </S.StyledScrollDownArrow>
  );
};
