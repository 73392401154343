import styled, { css } from 'styled-components';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledCardContainer = styled(CardContainer)`
  cursor: pointer;
  transition: background 200ms ease;
  height: 100%;
  min-height: 31.5rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    min-height: 29rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    width: 24.2rem;
    height: 24.2rem;
    border-radius: 50%;
    z-index: 0;
    transition: background 200ms ease;
  }

  ${({ variant, theme }) =>
    variant === 'blue' &&
    css`
      &::after {
        background: ${theme.color.blueH};
      }
    `}

  p {
    position: relative;
    z-index: 1;
    transition: color 200ms ease;
  }
`;
