import styled from 'styled-components';
import { Heading } from '../../atoms/Heading/Heading';
import { Paragraph } from '../../atoms/Paragraph/Paragraph';

export const StyledError = styled.div``;

export const StyledHeading = styled(Heading)``;

export const StyledMessage = styled(Paragraph)``;

export const StyledButtonWrapper = styled.div``;
