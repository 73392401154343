import { TableDataItems, TableOptions } from 'components/atoms/Table/Table.types';
import { Participant } from 'model/Participant';

const data: TableOptions = {
  controls: [{ type: 'Delete', action: 'Delete' }],
  columnOptions: {
    0: { width: '6rem', mobileRow: true },
    1: { width: '15rem', mobileRow: true },
    2: { width: '20rem', mobileRow: true },
    3: { width: '40rem', align: 'left', checkboxLabel: 'Client Admin', mobileRow: true },
    4: { width: '4rem', mobileRow: true },
    5: { width: '4rem', mobileRow: true },
  },
  // eslint-disable-next-line unicorn/no-unused-properties
  rows: [],
};

export const getCohortUserDetails = (
  tableData: TableOptions,
  rowIndex: number,
): { id: number; name: string; email: string; checked: boolean } => {
  const rowData = tableData.rows[rowIndex];
  const name = rowData[1].copy as string;
  const email = rowData[2].copy as string;
  const checked = !!rowData[3].checkboxDefaultChecked;
  const id = rowData[4].data as number;

  return { id, name, email, checked };
};

const getParticipantRow = (participant: Participant, index: number): TableDataItems => {
  return [
    { copy: `${index + 1}` },
    { copy: participant.name },
    { copy: participant.email },
    {
      type: 'Checkbox',
      checkboxDefaultChecked: participant.is_admin,
      areActionsDisabled: true,
    },
    { data: participant.id },
  ];
};

export const getFormatedCohortUserTableData = (
  participants: Array<Participant>,
  allowEdit?: boolean,
): TableOptions => {
  let { controls } = data;
  if (allowEdit) controls = [{ type: 'Edit', action: 'Edit' }, ...controls];

  return {
    head: data.head,
    columnOptions: data.columnOptions,
    controls,
    rows: participants.map((participant, index) => getParticipantRow(participant, index)),
  };
};

export const filterOptions = {
  ALL_PARTICIPANTS: 'All participants',
  ASSESSMENT_COMPLETED: 'Assessment completed',
  ASSESSMENT_NOT_STARTED: 'Assessment not started',
};
