/* eslint-disable import/no-unresolved */
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RegisteredFormItem } from 'globalTypes';
import { ClientSelect } from '../ClientSelect/ClientSelect';
import { getSelectItems } from '../../../../../../../util/getSelectItems';
import { RootState } from 'redux/store';
import { ActionMethod } from 'data/enum/ActionMethod';
import { functionsActions } from 'redux/ducks/functionsDuck/functionsReducers';
import { SelectItem } from 'components/molecules/Select/Select';

export const ClientFunction = ({
  formMethods,
  register,
  errors,
  defaultValue,
}: RegisteredFormItem): ReactElement => {
  const dispatch = useDispatch();
  const { functions } = useSelector((state: RootState) => state.functions);
  const items = getSelectItems(functions);
  const fieldName = 'customer.function_id';

  useEffect(() => {
    dispatch(functionsActions.functionsApiHandler({ method: ActionMethod.GET }));
  }, [dispatch]);

  const onSelect = ({ value }: SelectItem) => {
    formMethods.setValue(fieldName, value);
  };
  return (
    <ClientSelect
      formMethods={formMethods}
      register={register}
      errors={errors}
      items={items}
      name={fieldName}
      copy="Function"
      onSelect={onSelect}
      defaultValue={defaultValue}
    />
  );
};
