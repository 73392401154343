/* eslint no-param-reassign: ["error", { "props": false }] */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  OrganizationalTypesBaseProps,
  OrganizationalTypesGetProps,
  OrganizationalTypesInitialStateProps,
} from './organizationalTypesTypes';
import { ApiPayloadArguments, ApiPayloadOptions, ApiPayloadReturn, ApiResponse } from 'redux/types';
import { ApiClient } from 'util/apiClient';

const organizationalTypesApiHandler = createAsyncThunk<
  ApiPayloadReturn<OrganizationalTypesGetProps, OrganizationalTypesGetProps>,
  ApiPayloadArguments<OrganizationalTypesBaseProps>,
  ApiPayloadOptions
>('organizationalTypes/organizationalTypesApiHandler', async (payloadArguments) => {
  const { data, message } = (await ApiClient[payloadArguments.method](
    '/organizational-types',
    payloadArguments.body,
  )) as ApiResponse<OrganizationalTypesGetProps, OrganizationalTypesGetProps>;

  return { data, message, method: payloadArguments.method };
});

const initialState: OrganizationalTypesInitialStateProps = {
  organizationalTypes: [],
  loading: false,
  showOrganizationalTypesError: null,
};

const organizationalTypesSlice = createSlice({
  name: 'organizationalTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(organizationalTypesApiHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(organizationalTypesApiHandler.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.organizationalTypes = data as Array<OrganizationalTypesGetProps>;
        state.loading = false;
      })
      .addCase(organizationalTypesApiHandler.rejected, (state, action) => {
        const message = action.payload?.message;
        state.showOrganizationalTypesError = message || null;
        state.loading = false;
      });
  },
});

export const organizationalTypesActions = {
  ...organizationalTypesSlice.actions,
  organizationalTypesApiHandler,
};

export default organizationalTypesSlice.reducer;
