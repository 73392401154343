import styled from 'styled-components';

export const StyledPillTabsContainer = styled.div`
  position: absolute;
  right: 110px;
  margin-top: 5px;
`;

export const AddedQuestionsWrapper = styled.div`
  margin-inline: -5%;
  border-top: 1px solid rgba(217, 217, 217, 0.5);
`;
