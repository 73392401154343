import { ReactElement, ReactNode } from 'react';
import * as S from './DownloadButton.styles';
import { Download } from 'components/atoms/Icon/Icon';

interface DownloadButtonProps {
  children: ReactNode;
  onClick?: () => void;
}
export const DownloadButton = ({ children, onClick }: DownloadButtonProps): ReactElement => (
  <S.StyledSecondaryButton color="blueA" onClick={onClick}>
    <span>{children}</span>
    <Download />
  </S.StyledSecondaryButton>
);
