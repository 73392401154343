/* eslint no-param-reassign: ["error", { "props": false }] */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { MissionBaseProps, MissionGetProps, MissionsInitialStateProps } from './missionsTypes';
import { ApiPayloadArguments, ApiPayloadOptions, ApiPayloadReturn, ApiResponse } from 'redux/types';
import { ApiClient } from 'util/apiClient';

const missionsApiHandler = createAsyncThunk<
  ApiPayloadReturn<MissionGetProps, MissionGetProps>,
  ApiPayloadArguments<MissionBaseProps>,
  ApiPayloadOptions
>('missions/missionsApiHandler', async (payloadArugments) => {
  const { data, message } = (await ApiClient[payloadArugments.method](
    `/missions/${payloadArugments.id || ''}`,
    payloadArugments.body,
  )) as ApiResponse<MissionGetProps, MissionGetProps>;
  return { data, message, method: payloadArugments.method };
});

// constants
const initialState: MissionsInitialStateProps = {
  missions: [],
  currentMission: null,
  loading: false,
  loadingMissionsElement: false,
  showMissionsError: null,
};

// getMissionsWithChallengeId
// reducers
const missionsSlice = createSlice({
  name: 'missions',
  initialState,
  reducers: {
    resetCurrentMissions(state) {
      state.currentMission = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(missionsApiHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(missionsApiHandler.fulfilled, (state, action) => {
        const { data } = action.payload;
        if (Array.isArray(data)) {
          state.missions = data;
        } else {
          state.currentMission = data;
        }
        state.loading = false;
      })
      .addCase(missionsApiHandler.rejected, (state, action) => {
        const message = action.payload ? action.payload.message : null;
        state.showMissionsError = message;
        state.loading = false;
      });
  },
});

export const missionsActions = {
  ...missionsSlice.actions,
  missionsApiHandler,
};

export default missionsSlice.reducer;
