import type { ReactElement } from 'react';
import { IconButton } from '../IconButton/IconButton';
import { Cross } from 'components/atoms/Icon/Icon';
import { AbstractButtonProps } from 'components/atoms/AbstractButton/AbstractButton';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CloseButtonProps extends AbstractButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
}

export const CloseButton = ({ ...props }: CloseButtonProps): ReactElement => {
  return <IconButton size="small" $addClickRange $alignToRight icon={Cross} {...props} />;
};
