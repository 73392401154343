import type { ReactElement } from 'react';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { StickyBar, StickyBarProps } from 'components/atoms/StickyBar/StickyBar';

interface FormStickyProps extends StickyBarProps {
  noCancelButton?: boolean;
  validateButton?: boolean;
  cancelCopy?: string;
  confirmCopy?: string;
  validateCopy?: string;
  handleCancel?: () => void;
  handleConfirm?: () => void;
  handleValidate?: () => void;
  inModal?: boolean;
}

export const FormSticky = ({
  noCancelButton,
  validateButton,
  handleCancel,
  handleConfirm,
  handleValidate,
  cancelCopy,
  confirmCopy,
  validateCopy,
  ...props
}: FormStickyProps): ReactElement => {
  return (
    <StickyBar inModal={props.inModal} sidePadding="3.5rem" {...props}>
      <Flex container justifyContent="flex-end" gap="3rem" flexWrap="wrap">
        {!noCancelButton && (
          <SecondaryButton type="button" color="greyA" onClick={handleCancel}>
            <span>{cancelCopy || 'Cancel'}</span>
          </SecondaryButton>
        )}
        {validateButton && (
          <SecondaryButton type="button" color="blueA" onClick={handleValidate}>
            <span>{validateCopy || 'Validate'}</span>
          </SecondaryButton>
        )}
        <PrimaryButton $size="regular" type="submit" onClick={handleConfirm}>
          {confirmCopy || 'Confirm'}
        </PrimaryButton>
      </Flex>
    </StickyBar>
  );
};
