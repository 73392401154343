import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import * as S from './AssessmentFeedback.styles';
import { Intro } from './sections/Intro/Intro';
import { Invite } from '../../organisms/Invite/Invite';
import { Path } from 'routes/Path';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AssessmentFeedbackProps {}

export const AssessmentFeedback = ({ ...props }: AssessmentFeedbackProps): ReactElement => {
  const intl = useIntl();
  const { location, push } = useHistory<{ assessment_id: number }>();
  const { state } = location;
  const handleCompleteFeedback = () => push(Path.Dashboard);

  return (
    <S.StyledLayout padding="internal" {...props}>
      <Intro />
      <Invite
        assessment_id={state?.assessment_id}
        title={intl.formatMessage({
          defaultMessage: 'Invite people to provide feedback',
          description: 'Assessment Feedback - Invite People - Title',
        })}
        paragraph={intl.formatMessage({
          description: 'Assessment Feedback - Invite People - Paragraph',
          defaultMessage: 'We recommend inviting a maximum of 9 people.',
        })}
        cta={intl.formatMessage({
          defaultMessage: 'Send & Finish',
          description: 'Assessment Feedback - Invite People - Cta',
        })}
        onComplete={handleCompleteFeedback}
      />
    </S.StyledLayout>
  );
};
