import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { Flex } from 'components/atoms/Flex/FlexContainer';

export const StatsHeader = styled.div`
  display: flex;
  padding: 5.5rem 0;
  align-items: center;
  justify-content: space-between;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 3rem 0;
    gap: 3rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledDashboardContent = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledFeedbackFlex = styled(Flex)`
  max-width: 100%;
  height: 100%;
`;

export const StyledAnswersContainer = styled.div`
  gap: 6rem;
  display: flex;
  overflow: hidden;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex-direction: row;
  }
`;
