import styled, { css } from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { responsive, Responsive } from 'styles/helpers/responsive';
import { MediaQuery } from 'styles/mediaQuery';

export interface LayoutStyleProps {
  padding?: 'normal' | 'form' | 'internal' | 'small';
  overflowHidden?: boolean;
  background?: Responsive;
}

const formLeftPaddingDifference = '21rem';
const internalLeftPadding = '20.5rem';

export const StyledLayout = styled.div<LayoutStyleProps>`
  min-height: 100vh;
  ${({ background }) => background && responsive('background', background)}

  --header-height: 10.2rem;

  position: relative;

  ${({ overflowHidden }) => overflowHidden && 'overflow: hidden;'}

  main {
    position: relative;
  }

  ${({ padding, theme }) =>
    padding === 'normal' &&
    css`
      main {
        padding: var(--header-height) ${theme.sitePaddings.mobile};

        @media ${respondTo(MediaQuery.MIN_1024)} {
          padding: var(--header-height) ${theme.sitePaddings.desktop};
        }
      }
    `}

  ${({ padding, theme }) =>
    padding === 'small' &&
    css`
      main {
        padding: 2rem ${theme.sitePaddings.mobile};

        @media ${respondTo(MediaQuery.MIN_1024)} {
          padding: 4rem ${theme.sitePaddings.desktop};
        }
      }
    `}

  ${({ padding, theme }) =>
    (padding === 'form' || padding === 'internal') &&
    css`
      main {
        padding: var(--header-height) ${theme.sitePaddings.mobile} 0;
        ${padding === 'form' &&
        `
          overflow: hidden;
          position: relative;
        `}

        @media ${respondTo(MediaQuery.MIN_1024)} {
          padding: 13.5rem ${theme.sitePaddings.desktop} 0;
        }

        @media ${respondTo(MediaQuery.MIN_1280)} {
          padding: 13.5rem 10rem 0
            ${padding === 'form'
              ? `calc(${theme.sitePaddings.desktop} + ${formLeftPaddingDifference})`
              : internalLeftPadding};
        }
      }
    `}

  .formLeftPaddingDifference {
    @media ${respondTo(MediaQuery.MIN_1280)} {
      padding-left: ${formLeftPaddingDifference};
    }
  }

  .full-bleed {
    margin-left: -${({ theme }) => theme.sitePaddings.mobile};
    margin-right: -${({ theme }) => theme.sitePaddings.mobile};
    padding-left: ${({ theme }) => theme.sitePaddings.mobile};
    padding-right: ${({ theme }) => theme.sitePaddings.mobile};

    @media ${respondTo(MediaQuery.MIN_1024)} {
      margin-left: -${({ theme }) => theme.sitePaddings.desktop};
      margin-right: -${({ theme }) => theme.sitePaddings.desktop};
      padding-left: ${({ theme }) => theme.sitePaddings.desktop};
      padding-right: ${({ theme }) => theme.sitePaddings.desktop};
    }

    ${({ padding, theme }) => {
      if (padding === 'form' || padding === 'internal') {
        const currentPadding =
          padding === 'form'
            ? `calc(${theme.sitePaddings.desktop} + ${formLeftPaddingDifference})`
            : internalLeftPadding;

        return css`
          @media ${respondTo(MediaQuery.MIN_1280)} {
            margin-left: -${currentPadding};
            margin-right: -10rem;
            padding-left: ${currentPadding};
            padding-right: ${currentPadding};
          }
        `;
      }
      return '';
    }}
  }
`;
