import styled from 'styled-components';
import { motion } from 'framer-motion';
import type { InviteVariantType } from '../Invite';
import { typeStyle } from 'styles/typeStyle';

export const StyledItemCircle = styled.div`
  font-size: 1.4rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
  border-radius: 50%;
  margin: 0 1.6rem 1.6rem 0;
  text-align: center;
`;

export const StyledItemContainer = styled(motion.li)`
  display: flex;
  align-items: flex-end;

  &:nth-of-type(odd) ${StyledItemCircle} {
    background: ${({ theme }) => theme.color.blueC};
  }

  &:nth-of-type(even) ${StyledItemCircle} {
    background: ${({ theme }) => theme.color.redG};
  }
`;

export const StyledItemInner = styled.div<{ variant?: InviteVariantType }>`
  ${typeStyle.label}
  width: 100%;
  padding: 1.6rem 0;
  display: flex;
  justify-content: space-between;
  color: ${({ theme, variant }) => (variant === 'blue' ? theme.color.cream : theme.color.blueA)};
  border-bottom: 1px solid ${({ theme }) => theme.color.greyE};

  svg {
    fill: currentColor;
  }
`;
