import { AxiosInstance } from 'axios';
import { ObjectiveInfoType, ObjectiveType } from './types/ObjectiveTypes';
import { ApiClient } from '../util/apiClient';

enum ObjectiveEndpoints {
  ParticipantObjectives = '/participant-objectives',
  Objectives = '/objectives',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unsuscribeFromObjective = async (objectiveId: number): Promise<any> => {
  const { data } = await ApiClient.delete(
    `${ObjectiveEndpoints.ParticipantObjectives}/${objectiveId}`,
  );

  return data;
};

const createObjective = async (body: ObjectiveType): Promise<AxiosInstance> => {
  return ApiClient.post(ObjectiveEndpoints.Objectives, body);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getObjectives = async (): Promise<Array<ObjectiveInfoType>> => {
  const { data } = await ApiClient.get(ObjectiveEndpoints.Objectives);

  return data;
};

export const ObjectiveService = { unsuscribeFromObjective, createObjective, getObjectives };
