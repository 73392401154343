import styled from 'styled-components';

export const MapModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  z-index: 9;
  padding: 0 30px;
  background-color: rgba(255, 255, 255, 0.7);
`;

export const ButtonWrapper = styled.div`
  position: relative;
  top: -5rem;
`;
export const Map = styled.div``;
