import type { ReactElement } from 'react';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Separator } from 'components/atoms/Separator/Separator';

interface ModalHeadingProps {
  title: string;
}

export const ModalHeading = ({ title }: ModalHeadingProps): ReactElement => {
  return (
    <>
      <Heading type={HeadingType.H6} color="blueA">
        {title}
      </Heading>
      <Separator margin="1.7rem 0 3rem" />
    </>
  );
};
