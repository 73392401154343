import styled from 'styled-components';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { StyledInput } from 'components/atoms/Input/Input.styles';

export const StyledTimeLength = styled(Flex)``;

export const StyledInputTimeLength = styled(StyledInput)<{ hasMargin?: boolean }>`
  border-color: ${({ theme }) => theme.color.blueA};
  color: ${({ theme }) => theme.color.black};
  width: 5.5rem;
  text-align: center;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const StyledSeparator = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color.blackD};
  padding: 1rem;
`;

export const InputContainer = styled.div<{ flexStart?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2rem;
  justify-content: ${({ flexStart }) => (flexStart ? 'flex-start' : 'center')};
`;

export const PillContainer = styled.div`
  display: flex;
  column-gap: 2rem;
`;
