import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { FontWeight } from 'styles/variables';

export const StyledElementTitleItem = styled.div`
  color: ${({ theme }) => theme.color.redA};
`;

export const StyledElementTitle = styled.div`
  font-size: 2rem;
  margin-bottom: 3rem;
  font-weight: ${FontWeight.Medium};

  @media ${respondTo(MediaQuery.MIN_1024)} {
    font-size: 2.4rem;
  }
`;

export const StyledElementLine = styled.div`
  width: 100%;
  height: 0.1rem;
  /* background: ${({ theme }) => theme.color.redD}; */

  &::before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    transform: translateY(-50%);
    display: block;
    background: currentColor;
    border-radius: 50%;
  }
`;
