/* eslint-disable no-console */
import { createContext, memo, ReactNode, useCallback, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useMessages } from 'hooks/useMessages';
import { Locale } from 'data/enum/Locale';

interface IntlProviderWrapperProps {
  children: ReactNode;
}

interface IntlProviderContextProps {
  locale: Locale;
  toggleLocale: () => void;
}
interface Error {
  name: string;
  message: string;
  stack?: string;
  code?: string;
}

const IntlProviderContext = createContext({} as IntlProviderContextProps);

export const IntlProviderWrapper = memo(({ children }: IntlProviderWrapperProps) => {
  const [locale, setLocale] = useState(Locale.EnUs);
  const messages = useMessages(locale);

  const toggleLocale = useCallback(() => {
    setLocale((previousState) => (previousState === Locale.EnUs ? Locale.EsUs : Locale.EnUs));
  }, [setLocale]);

  const customError = (error: Error) => {
    console.log(`@formatjs/intl Error:${error.code}`, { error });
  };

  return (
    <IntlProvider
      locale={locale}
      messages={messages}
      onError={customError}
      wrapRichTextChunksInFragment
    >
      <IntlProviderContext.Provider value={{ locale, toggleLocale }}>
        {children}
      </IntlProviderContext.Provider>
    </IntlProvider>
  );
});
