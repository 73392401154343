import { rgba } from 'polished';
import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  keyframes,
  ThemeProps,
} from 'styled-components';
import { Ease } from '../../../styles/easing';
import { respondTo } from '../../../styles/helpers/respondTo';
import { MediaQuery } from '../../../styles/mediaQuery';
import { FontWeight } from '../../../styles/variables';
import { zIndex } from '../../../styles/zIndex';
import { Heading } from '../Heading/Heading';
import { Paragraph } from '../Paragraph/Paragraph';

// eslint-disable-next-line no-shadow
export enum LoadingTheme {
  Light = 1,
  Dark,
}

export const loader = keyframes`
	0% {
    transform: scaleX(.5) translateX(-100%);
	}

	50% {
    transform: scaleX(1) translateX(0);
	}

	100% {
    transform: scaleX(1) translateX(120%);
	}
`;

export const Loader = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 0.2rem;
  background: ${(props) => rgba(props.theme.color.black, 0.2)};

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 0.2rem;
    width: 100%;
    background: ${(props) => props.theme.color.black};
    animation: ${loader} 3s ${Ease.EaseOutCubic} infinite;
    transform-origin: top left;
  }
`;

const variants: Record<LoadingTheme, FlattenInterpolation<ThemeProps<DefaultTheme>>> = {
  [LoadingTheme.Light]: css`
    color: ${(props) => props.theme.color.black};
    background: ${(props) => props.theme.color.white};

    ${Loader} {
      background: ${(props) => rgba(props.theme.color.black, 0.2)};

      &::after {
        background: ${(props) => props.theme.color.black};
      }
    }
  `,
  [LoadingTheme.Dark]: css`
    color: ${(props) => props.theme.color.white};
    background: ${(props) => props.theme.color.black};

    ${Loader} {
      background: ${(props) => rgba(props.theme.color.white, 0.2)};

      &::after {
        background: ${(props) => props.theme.color.white};
      }
    }
  `,
};

export const StyledLoading = styled.div<{ $variant: LoadingTheme }>`
  ${(props) => variants[props.$variant]}
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex('navigation')};
  padding-top: 2rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    display: flex;
    position: fixed;
    padding-top: 3rem;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding-top: 3.8rem;
  }
`;

export const LoadingContent = styled.div`
  position: relative;
  flex: 1 1 auto;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 45rem;
  right: 15vw;
  left: 15vw;
`;

export const StyledHeading = styled(Heading)`
  font-weight: ${FontWeight.Normal};
  line-height: 0.8;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: 60vw;
  }

  strong {
    display: block;
    font-weight: ${FontWeight.SemiBold};
  }
`;

export const StyledParagraph = styled(Paragraph)`
  margin-bottom: 2rem;
`;
