/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild } from 'globalTypes';
import { ForwardedRef, forwardRef, ReactElement, useMemo, useEffect, useState } from 'react';
import { useSelect, UseSelectStateChange } from 'downshift';
import { debounce } from 'lodash';
import * as S from './Select.styles';
import { Expand } from 'components/atoms/Icon/Icon';

export type SelectItem = {
  title: string;
  value: string | number;
  secondaryValue?: string | number;
};
export interface SelectProps extends S.SelectStyleProps, RegisterFormItemChild {
  items: Array<SelectItem>;
  defaultValue?: string | number | SelectItem;
  onSelect?: (value: SelectItem) => void;
  staticSelectedText?: string;
  placeholder?: string;
  isElementComponent?: boolean;
  noItemsText?: string;
  reset?: number;
  disabled?: boolean;
  shouldStartEmpty?: boolean;
  label?: string;
}

const initialValue: SelectItem = { title: '', value: '' };

export const Select = forwardRef(
  (
    {
      items,
      defaultValue,
      variant,
      hasError,
      name,
      formMethods,
      onSelect,
      width,
      placeholder,
      staticSelectedText,
      isElementComponent,
      noItemsText,
      reset,
      shouldStartEmpty,
      disabled,
      label,
      ...props
    }: SelectProps,
    ref,
  ): ReactElement => {
    const [selectedItem, setSelectedItem] = useState<SelectItem | null>(() => initialValue);
    const [finalItems, setFinalItems] = useState(items);
    const formatedDefaultValue = useMemo(() => {
      if (typeof defaultValue === 'number') {
        if (formMethods) formMethods.setValue(name, defaultValue);
        const defaultItem = items.find((item) => item.value === defaultValue);
        return defaultItem ? defaultItem.title : defaultValue;
      }

      return typeof defaultValue === 'string' ? defaultValue : defaultValue?.title;
    }, [defaultValue, formMethods, items, name]);

    const onSelectedItemChange = (item: UseSelectStateChange<SelectItem>) => {
      const { selectedItem } = item;
      if (!selectedItem) return;
      setSelectedItem(selectedItem);

      if (formMethods) formMethods.setValue(name, selectedItem);
      if (onSelect) onSelect(selectedItem);
    };

    const useSelectProps = {
      items: finalItems,
      selectedItem,
      onSelectedItemChange,
    };

    const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } = useSelect(useSelectProps);

    const handleSearchInputChange = debounce((event: { target: { value: string } }) => {
      const {
        target: { value },
      } = event;
      const matchedItems = items.filter((item) => {
        const stringToReplace = item.title || '';
        stringToReplace.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
        return stringToReplace.match(new RegExp(value, 'i'));
      });
      setFinalItems(matchedItems);
    }, 66);

    const menuProps = {
      ...getMenuProps(),
      onBlur: undefined,
      onKeyDown: undefined,
    };

    useEffect(() => {
      setFinalItems(items);

      if (selectedItem?.title) return;
      if (shouldStartEmpty) return;

      const defaultItem = items.find((item) => item.value === defaultValue);
      if (!defaultItem) return;
      setSelectedItem(defaultItem);

      if (formMethods) {
        formMethods.setValue(name, defaultItem.value);
      }
    }, [defaultValue, items, shouldStartEmpty, name, formMethods, selectedItem]);

    return (
      <S.StyledSelect {...props}>
        {label && <S.StyledLabel>{label}</S.StyledLabel>}

        <S.StyledSelectedItem
          variant={variant}
          hasError={hasError}
          isOpen={isOpen}
          type="button"
          selectDefaultValue={formatedDefaultValue}
          ref={ref as ForwardedRef<HTMLDivElement>}
          {...getToggleButtonProps({ width })}
          disabled={disabled}
        >
          <S.StyledSelectedItemText>
            {staticSelectedText && `${staticSelectedText} `}
            {selectedItem?.title || formatedDefaultValue || (
              <S.StyledPlaceholder>{placeholder}</S.StyledPlaceholder>
            )}
          </S.StyledSelectedItemText>
          <Expand />
        </S.StyledSelectedItem>
        <S.StyledSelectList isOpen={isOpen} aria-hidden={!isOpen} variant={variant} {...menuProps}>
          {isElementComponent && (
            <S.StyledSearchInput
              handleInputChange={handleSearchInputChange}
              placeholder="Search"
              name="search"
              icon="Search"
              isSelect
            />
          )}
          {finalItems.length === 0 && noItemsText && (
            <S.StyledNoItemsText>{noItemsText}</S.StyledNoItemsText>
          )}
          {isOpen &&
            finalItems.map((item, index) => {
              return (
                <S.StyledSelectListItem
                  key={`element-${item.title}`}
                  {...getItemProps({ item, index })}
                >
                  {item.title}
                </S.StyledSelectListItem>
              );
            })}
        </S.StyledSelectList>
      </S.StyledSelect>
    );
  },
);
