/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild, FormItemContainerBasicProps } from 'globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { ElementsSelect } from '../ElementsSelect/ElementsSelect';
import { Element } from '../Element/Element';
import { ApiElementProps } from '../../EditAssessmentTypes';
import { AdminHeading } from 'components/pages/Superadmin/components/AdminHeading/AdminHeading';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { RootState } from 'redux/store';
import { getElements } from 'redux/ducks/elementsDuck/elementsActions';

interface ElementProps {
  element_id?: number;
  order?: number;
  questions: Array<any>;
  challenges: Array<any>;
  name: string;
}

interface ElementFormItemProps extends FormItemContainerBasicProps, RegisterFormItemChild {
  apiElements?: Array<ApiElementProps>;
}

interface RecievedElements {
  allow_no_answer: boolean;
  created_at: string;
  deleted_at: string;
  description: string;
  element_type_id: number;
  id: number;
  name: string;
  set_as_open: boolean;
  updated_at: string;
}

// eslint-disable-next-line no-empty-pattern
export const ElementFormItem = ({
  register,
  formMethods,
  errors,
  apiElements,
}: ElementFormItemProps): ReactElement => {
  const [elements, setElements] = useState<Array<ElementProps>>([]);
  const [elementNames, setElementNames] = useState<Array<string>>([]);
  const [finalRecievedElements, setRecievedElements] = useState<Array<RecievedElements>>([]);
  const formatedElements = useMemo(
    () => elementNames.map((element) => ({ title: element, value: element })),
    [elementNames],
  );

  const dispatch = useDispatch();

  const elementsDropdownField = 'elements';

  const { elements: recievedElements } = useSelector((state: RootState) => {
    return state.elements;
  });

  const handleDeleteElement = (index: number) => {
    const newElements = [...elements].filter((element, elementIndex) => elementIndex !== index);
    setElements(newElements);

    newElements.forEach((item, index) => {
      formMethods.setValue(`${elementsDropdownField}[${index}]`, item);
    });
  };

  const handleAddElement = (name: string, values?: any) => {
    // check if element is already added;
    const elementExists = elements.filter((item) => item.name === name)[0];
    if (elementExists) {
      return;
    }

    const filteredElement = recievedElements.filter(
      (element: RecievedElements) => element.name === name,
    ) as unknown as Array<RecievedElements>;

    const newElement: ElementProps = {
      element_id: filteredElement[0].id,
      order: 0,
      challenges: (values && values.challenges) || [],
      questions: (values && values.questions) || [],
      name,
    };

    const updateElements = [...elements, newElement];
    setElements(updateElements);

    const elementIndex = updateElements.findIndex((item) => item.name === name);

    const elementValues = formMethods.getValues(`${elementsDropdownField}[${elementIndex}]`);

    formMethods.setValue(`${elementsDropdownField}[${elementIndex}]`, {
      ...elementValues,
      name,
      element_id: filteredElement[0].id,
      order: elementIndex,
    });
  };

  useEffect(() => {
    dispatch(getElements());
  }, [dispatch]);

  useEffect(() => {
    setRecievedElements(recievedElements);
  }, [recievedElements, finalRecievedElements]);

  useEffect(() => {
    const elementNames = finalRecievedElements && finalRecievedElements.map((item) => item.name);
    setElementNames(elementNames);
  }, [finalRecievedElements]);

  useEffect(() => {
    if (apiElements && recievedElements.length > 0 && elements.length === 0) {
      apiElements.forEach((item) => {
        handleAddElement(item.name, {
          challenges: item.challenges,
          questions: item.questions,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiElements, recievedElements]);

  return (
    <>
      <div>
        <AdminHeading title="Select Elements" $size="small" $underline />

        <RegisterFormItem
          register={register}
          formMethods={formMethods}
          errors={errors}
          name={elementsDropdownField}
          registerErrors="required"
          label="Select Elements"
          size={FormItemSizes.Medium}
          alignError="right"
        >
          <ElementsSelect
            selectedItems={elements}
            items={formatedElements}
            cta="Add Element"
            note="You can set up to 10 elements"
            onAddClick={handleAddElement}
          />
        </RegisterFormItem>
      </div>
      {elements.map((element, index) => (
        <Element
          key={element.element_id}
          id={index}
          name={`${elementsDropdownField}[${index}]`}
          onDelete={() => handleDeleteElement(index)}
          title={element.name}
          register={register}
          formMethods={formMethods}
          values={element as ApiElementProps}
          errors={errors}
        />
      ))}
    </>
  );
};
