import { isUint } from 'isntnt';
import { ReactElement, ComponentProps, ForwardedRef, forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

export type AbstractButtonProps = ComponentProps<'button'> & ComponentProps<'a'>;

export const AbstractButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  AbstractButtonProps
>(({ href, type, ...props }: AbstractButtonProps, ref): ReactElement => {
  const location = useLocation();
  if (href) {
    const customTimeParam = JSON.parse(String(new URLSearchParams(location.search).get('t')));
    const customTime = isUint(customTimeParam) ? customTimeParam : null;

    const to = { pathname: href, state: { customTime } };

    return href.startsWith('/') ? (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <Link ref={ref as any} {...props} to={to} />
    ) : (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <a ref={ref as any} target="_blank" {...props} href={href} rel="noopener noreferrer" />
    );
  }

  return (
    <button
      ref={ref as ForwardedRef<HTMLButtonElement>}
      // eslint-disable-next-line react/button-has-type
      type={type}
      {...props}
    />
  );
});
