/* eslint-disable import/no-extraneous-dependencies */
import { MessageDescriptor } from '@formatjs/intl';
import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import * as S from './Objective.styles';

interface ObjectiveProps {
  index: number;
  title: MessageDescriptor;
  content: MessageDescriptor;
}

export const Objective = ({ index, title, content, ...props }: ObjectiveProps): ReactElement => {
  const intl = useIntl();
  return (
    <S.StyledObjective {...props}>
      <S.StyledObjectiveTitle>
        0{index} {intl.formatMessage(title)}
      </S.StyledObjectiveTitle>
      <S.StyledObjectiveContent>
        {intl.formatMessage(content, {
          p: (string) => <p>{string}</p>,
          h6: (string) => <h6>{string}</h6>,
          ul: (string) => <ul>{string}</ul>,
          li: (string) => <li>{string}</li>,
        })}
      </S.StyledObjectiveContent>
    </S.StyledObjective>
  );
};
