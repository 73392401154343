import { ApiClient } from '../util/apiClient';
import { addParticipantDTO } from './dto/AddParticipantDTO';
import { Participant } from 'model/Participant';

enum ParticipantEndpoints {
  AddParticipant = '/customers/add_participant',
  Participant = '/participants',
}

const addParticipant = async (
  customerId: number,
  participant: Participant,
): Promise<Participant> => {
  const { data } = await ApiClient.post(
    ParticipantEndpoints.AddParticipant,
    addParticipantDTO(customerId, participant),
  );

  const newParticipant = participant;
  newParticipant.id = data.id;
  return newParticipant;
};

const deleteParticipant = async (participantId: number | undefined): Promise<Participant> => {
  const { data } = await ApiClient.delete(`${ParticipantEndpoints.Participant}/${participantId}`);

  return data;
};

export const ParticipantService = { addParticipant, deleteParticipant };
