import { ReactElement, useState, useEffect, useMemo } from 'react';
import { mean as average } from 'lodash';
import { useEmblaCarousel } from 'embla-carousel/react';
import { Question } from 'services/types/DashboardTypes';
import { WrittenScore } from 'components/organisms/WrittenScore/WrittenScore';
import { NumericScore } from 'components/organisms/NumericScore/NumericScore';
import { CardQuestions } from 'components/molecules/CardQuestions/CardQuestions';
import * as S from 'components/organisms/ActiveFeedbackContent/ActiveFeedbackContent.styles';
import { usePagination } from 'hooks/usePagination';
import { PaginationButtons } from 'components/molecules/PaginationButtons/PaginationButtons';
import { useDeviceState } from 'hooks/useDeviceState';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Element } from 'model/Element';

interface ActiveFeedbackContentProps {
  questions: Array<Question>;
  selfNumericTitle: string;
  assessorsNumericTitle: string;
  activeElement?: Element;
}

const startQuestionIndex = 0;

export const ActiveFeedbackContent = ({
  questions,
  selfNumericTitle,
  assessorsNumericTitle,
  activeElement,
}: ActiveFeedbackContentProps): ReactElement => {
  const [activequestion, setActiveQuestion] = useState(startQuestionIndex);
  const { isMobile } = useDeviceState();

  const [carouselRef, embla] = useEmblaCarousel({
    align: 'start',
    startIndex: startQuestionIndex,
    draggable: false,
  });

  const {
    paginationButtonProps,
    paginationMotionProps,
    setActiveIndex,
    activeItem: currentAssessorComment,
  } = usePagination({
    items: questions[activequestion]?.assessor_answers || [''],
  });

  useEffect(() => {
    setActiveIndex(startQuestionIndex);
    setActiveQuestion(startQuestionIndex);
    if (!embla) return;

    embla?.reInit();
    embla.scrollTo(startQuestionIndex);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  useEffect(() => {
    if (!embla) return undefined;

    embla.on('select', () => {
      setActiveQuestion(embla.selectedScrollSnap());
    });

    return () => embla.destroy();
  }, [embla]);

  const assessorsAverageActiveQuestion = useMemo(
    () =>
      Math.round(
        average(questions[activequestion]?.assessor_answers.filter((answer) => answer !== -1)),
      ),
    [activequestion, questions],
  );

  const assessorsAverageElement = useMemo(
    () =>
      Math.round(
        average(
          questions
            .map((question) => {
              return question?.assessor_answers;
            })
            ?.flat(1)
            ?.filter((answer) => answer !== -1),
        ),
      ),
    [questions],
  );

  const selfAverageElement = useMemo(
    () =>
      Math.round(
        average(
          questions
            .map((question) => {
              return question?.self_answer;
            })
            ?.filter((answer) => answer !== -1),
        ),
      ),
    [questions],
  );

  return (
    <>
      <Heading type={HeadingType.H3}>{activeElement?.name}</Heading>
      <S.StyledElementAnswersContainer>
        {questions[activequestion]?.no_answer ? (
          <NumericScore
            selfLabel={selfNumericTitle}
            assessorsLabel={assessorsNumericTitle}
            assessorsAverage={assessorsAverageElement}
            selfAverageElement={selfAverageElement}
          />
        ) : (
          <WrittenScore
            paginationButtonProps={paginationButtonProps}
            paginationMotionProps={paginationMotionProps}
            questionContent={currentAssessorComment}
            amountOfQuestions={questions[activequestion]?.assessor_answers?.length || 0}
          />
        )}
      </S.StyledElementAnswersContainer>
      <S.StyledAnswersContainer>
        <CardQuestions
          paginationMotionProps={paginationMotionProps}
          active={activequestion}
          questions={questions}
          carouselRef={carouselRef}
        />

        {questions[activequestion]?.no_answer ? (
          <NumericScore
            selfLabel={selfNumericTitle}
            assessorsLabel={assessorsNumericTitle}
            assessorsAverage={assessorsAverageActiveQuestion}
            selfAnswer={questions[activequestion]?.self_answer}
          />
        ) : (
          <WrittenScore
            paginationButtonProps={paginationButtonProps}
            paginationMotionProps={paginationMotionProps}
            questionContent={currentAssessorComment}
            amountOfQuestions={questions[activequestion]?.assessor_answers?.length || 0}
          />
        )}
      </S.StyledAnswersContainer>
      <PaginationButtons
        onLeftClick={() => embla?.scrollPrev()}
        onRightClick={() => embla?.scrollNext()}
        isLeftDisabled={activequestion - 1 < 0}
        isRightDisabled={activequestion + 1 >= questions.length}
        size={isMobile ? 'medium' : 'small'}
      />
    </>
  );
};
