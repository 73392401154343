import { ReactElement, useState } from 'react';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import * as S from './Login.styles';
import { LoginForm } from './forms/LoginForm/LoginForm';
import { ResetForm } from './forms/ResetForm/ResetForm';
import { NewPasswordForm } from './forms/NewPasswordForm/NewPasswordForm';
import { LoginFormType } from './Login.types';
import { slideFade } from 'util/motionTransitions';

// TODO: Login forms backend integrations

interface LoginProps {
  form: LoginFormType;
}

export const Login = ({ form }: LoginProps): ReactElement => {
  const [activeForm, setActiveForm] = useState<LoginFormType>(form || LoginFormType.LoginForm);

  return (
    <S.LoginPage activeForm={activeForm}>
      <AnimateSharedLayout>
        <S.ContentWrapper layout>
          <S.HeadingWrapper layout>
            <S.StyledLogo></S.StyledLogo>
            <S.Heading layout>
              {activeForm === LoginFormType.LoginForm ? (
                <FormattedMessage
                  description="Login - Heading"
                  defaultMessage="Log in to the THNK 360º Mirror"
                />
              ) : (
                <FormattedMessage
                  description="Login - Heading"
                  defaultMessage="Welcome to the THNK Mirror."
                />
              )}
            </S.Heading>
          </S.HeadingWrapper>

          <S.FormWrapper layout>
            <AnimatePresence exitBeforeEnter initial={false}>
              <motion.div layout {...slideFade()} key={activeForm} style={{ width: '100%' }}>
                {activeForm === LoginFormType.LoginForm && (
                  <LoginForm setActiveForm={setActiveForm} />
                )}
                {activeForm === LoginFormType.ResetForm && (
                  <ResetForm setActiveForm={setActiveForm} />
                )}
                {activeForm === LoginFormType.NewPasswordForm && (
                  <NewPasswordForm setActiveForm={setActiveForm} form={form} />
                )}
                {activeForm === LoginFormType.CreatePasswordForm && (
                  <NewPasswordForm setActiveForm={setActiveForm} form={form} />
                )}
              </motion.div>
            </AnimatePresence>
          </S.FormWrapper>
        </S.ContentWrapper>
      </AnimateSharedLayout>
    </S.LoginPage>
  );
};
