import styled, { css } from 'styled-components';
import { Ease } from 'styles/easing';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { TransitionDuration } from 'styles/variables';
import { zIndex } from 'styles/zIndex';

export enum HeaderVariants {
  Active = 'Active',
  Hidden = 'Hidden',
  Logo = 'Logo',
}

export enum HeaderColors {
  Black = 'Black',
  White = 'White',
}

export enum HeaderTypes {
  Logo = 'Logo',
  Navigation = 'Navigation',
  NoBackground = 'NoBackground',
}

interface HeaderStyleProps {
  variant: HeaderVariants;
  type?: HeaderTypes;
}

export const StyledHeader = styled.header<HeaderStyleProps>`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: transform ${TransitionDuration.Slow} ${Ease.EaseOutQuad};
  z-index: ${zIndex('overlay')};
  ${({ theme, type }) =>
    type !== HeaderTypes.Logo &&
    type !== HeaderTypes.NoBackground &&
    `background: ${theme.color.cream}`};

  ${({ variant }) =>
    variant === HeaderVariants.Active &&
    css`
      transform: translateY(0);
    `}
`;

export const StyledHeaderInner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.9rem ${({ theme }) => theme.sitePaddings.mobile};

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding: 2.9rem ${({ theme }) => theme.sitePaddings.desktop};
  }
`;

export const StyledHeaderNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const StlyedHeaderNavigationSection = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.color.blueA};
  margin-right: 3rem;

  &:first-of-type {
    & > * {
      @media ${respondTo(MediaQuery.MAX_767)} {
        margin-right: 3rem;
      }
      @media ${respondTo(MediaQuery.MIN_768)} {
        &:not(:last-child) {
          margin-right: 4.2rem;
        }
      }
    }
  }
`;

export const StlyedHeaderLastSection = styled.div`
  display: flex;
  align-items: center;

  @media ${respondTo(MediaQuery.MAX_767)} {
    position: absolute;
    top: 100%;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 25px;
    background: ${({ theme }) => theme.color.white};
    z-index: ${zIndex('overlay')};

    & > *:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  @media ${respondTo(MediaQuery.MIN_768)} {
    & > * {
      &:not(:last-child) {
        margin-right: 2.5rem;
      }
    }
  }
`;

export const StyledHeaderDivider = styled.div`
  height: 1rem;
  width: 1px;
  background: ${({ theme }) => theme.color.blueC};
  margin: 0 3rem;
`;

export const LogoWrapper = styled.div<{ $color?: HeaderColors }>`
  ${({ $color }) => $color === HeaderColors.White && 'filter: brightness(0) invert(1)'};
`;

export const StyledMobileButton = styled.a`
  width: 33px;
  height: auto;
`;

export const StyledLogo = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  height: 36px;
  cursor: pointer;

  svg {
    height: 100%;
    width: auto;
  }

  .reveal {
    position: absolute;
    left: calc(100% + 8px);
    width: 0;
    height: 100%;
    transition: 0.5s;
    overflow: hidden;
    opacity: 0;

    @media ${respondTo(MediaQuery.MAX_1280)} {
      display: none;
    }
  }

  &:hover .reveal {
    width: 137px;
    opacity: 1;
  }
`;
