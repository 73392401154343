import styled from 'styled-components';
import { FontWeight } from '../../../styles/variables';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledMultiSelect = styled.div``;

export const StyledLabel = styled.label`
  font-weight: ${FontWeight.Normal};
  display: block;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  margin-bottom: 0.9rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    font-size: 2.4rem;
    margin-bottom: 2.4rem;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    max-width: 40rem;
  }

  @media ${respondTo(MediaQuery.MIN_1280)} {
    max-width: none;
  }
`;
