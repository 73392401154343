import styled from 'styled-components';
import { Radio } from '../Radio/Radio';
import { typeStyle } from 'styles/typeStyle';

export const PillRadio = styled(Radio)`
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  color: ${({ theme }) => theme.color.blueA};

  label {
    ${typeStyle.uppercase};
    color: ${({ theme }) => theme.color.blueA};
    padding-right: 1rem;
    margin: 0;
    cursor: pointer;
    user-select: none;
  }
`;
