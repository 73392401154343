import { LegacyRef, ReactElement, useContext, useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-unresolved
import { TabsProps } from 'globalTypes';
import * as S from './StickyTabs.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { PillTabs } from 'components/atoms/PillTabs/PillTabs';
import { LayoutContext } from 'components/organisms/NormalLayout/NormalLayout';

export const StickyTabs = ({ ...props }: TabsProps): ReactElement => {
  const tabsElementTrigger = useRef<HTMLElement>(null);
  const [stickybar, setStickyBar] = useState(false);
  const { headerVariant } = useContext(LayoutContext);

  useEffect(() => {
    const observerCallback = (entries: Array<IntersectionObserverEntry>) =>
      setStickyBar(entries[0].intersectionRatio < 1);

    const observer = new IntersectionObserver(observerCallback, { threshold: 1 });

    observer.observe(tabsElementTrigger.current as HTMLElement);

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <S.StyledStickyBar
        alignToTop
        isStickyBarActive={stickybar}
        isHeaderActive={headerVariant === 'Active'}
      >
        <PillTabs {...props} />
      </S.StyledStickyBar>
      <Flex flexBasis="100%" ref={tabsElementTrigger as LegacyRef<HTMLDivElement>} />
    </>
  );
};
