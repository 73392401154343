/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SelectItem } from 'components/molecules/Select/Select';
import { CustomerService } from 'services/CustomerService';

type Industry = { id: number; description: string };

const fetchIndustries = createAsyncThunk('/industries/fetchIndustries', async () =>
  CustomerService.getIndustries(),
);

const initialState: {
  industries: Array<Industry>;
  adaptedIndustries: Array<SelectItem>;
  loading: boolean;
} = {
  industries: [],
  adaptedIndustries: [],
  loading: false,
};

const industriesSlice = createSlice({
  name: 'industries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIndustries.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchIndustries.fulfilled, (state, action) => {
        const industries = action.payload;
        state.industries = industries;
        state.adaptedIndustries = industries.map((industry) => ({
          title: industry.description,
          value: industry.id,
        }));
        state.loading = false;
      });
  },
});

export const industriesActions = {
  ...industriesSlice.actions,
  fetchIndustries,
};

export default industriesSlice.reducer;
