import * as types from './authTypes';
import { ReduxReducer } from 'redux/types';

type InitialAuthState = {
  userInfo: { role: string } | null;
  profileId: null | number;
};

// constants
const initialAuthState: InitialAuthState = {
  userInfo: null,
  profileId: null,
};

// reducers
export const authReducer: ReduxReducer<typeof initialAuthState> = (
  state = initialAuthState,
  action,
) => {
  switch (action.type) {
    case types.POST_LOGIN:
      return { ...state, userInfo: action.payload };
    case types.SET_PROFILE_ID:
      return { ...state, profileId: action.payload };
    default:
      return state;
  }
};
