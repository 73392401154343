/* eslint-disable unicorn/error-message */
/* eslint-disable @typescript-eslint/naming-convention */
import { OrganizationSubunit } from '../../model/OrganizationSubunit';

type CreateSubunitDTO = {
  description: string;
  organizational_unit_id: number;
};

export const createSubunitDTO = (
  unitId: number,
  subunit: OrganizationSubunit,
): CreateSubunitDTO => {
  if (!subunit.description) throw new Error('Missing description field');

  const createSubUnitDto: CreateSubunitDTO = {
    description: subunit.description,
    organizational_unit_id: unitId,
  };

  return createSubUnitDto;
};
