import { TableOptions } from 'components/atoms/Table/Table.types';

export const data: TableOptions = {
  head: ['No.', 'Name', 'Assignation date', 'Creation date', ' '],
  controls: [{ type: 'Duplicate' }],
  columnOptions: {
    0: { width: '17%', mobileRow: true },
    1: { width: '25%', defaultValue: '-', mobileRow: true },
    2: { width: '25%', mobileRow: true },
    4: { width: '25%', mobileRow: true },
    5: { width: '3.5rem', mobileRow: true },
  },
  rows: [
    [
      { copy: '1.' },
      { copy: 'Joan of Arch' },
      { copy: 'mm/dd/yy' },
      { copy: 'mm/dd/yy' },
      { copy: '' },
    ],
    [
      { copy: '2.' },
      { copy: 'Henry Caville' },
      { copy: 'mm/dd/yy' },
      { copy: 'mm/dd/yy' },
      { copy: '' },
    ],
    [{ copy: '3.' }, { copy: '' }, { copy: 'mm/dd/yy' }, { copy: 'mm/dd/yy' }, { copy: '' }],
    [{ copy: '4.' }, { copy: '' }, { copy: 'mm/dd/yy' }, { copy: 'mm/dd/yy' }, { copy: '' }],
    [
      { copy: '5.' },
      { copy: 'Virginia Wolf' },
      { copy: 'mm/dd/yy' },
      { copy: 'mm/dd/yy' },
      { copy: '' },
    ],
  ],
};
