import styled from 'styled-components';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { ColorKey } from 'styles/theme/default';

export interface AccordionHeadingStyleProps {
  isOpen?: boolean;
  color?: ColorKey;
}

export const StyledIconsFlex = styled(Flex)<AccordionHeadingStyleProps>`
  color: ${({ theme, color }) => theme.color[color || 'blueA']};
  margin-left: auto;

  button:first-of-type {
    ${({ isOpen }) => (isOpen ? 'transform: rotate(90deg);' : 'transform: rotate(270deg);')}
  }
`;
