import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Image } from 'components/atoms/Image/Image';
import { fluidType } from 'styles/helpers/fluidType';
import { respondTo } from 'styles/helpers/respondTo';
import { size } from 'styles/helpers/size';
import { MediaQuery } from 'styles/mediaQuery';
import { Heading } from 'components/atoms/Heading/Heading';
import { typeStyle } from 'styles/typeStyle';

export const OnboardingIntro = styled.section`
  position: relative;
  padding-top: 11rem;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 12rem ${({ theme }) => theme.sitePaddings.mobile} 5.5rem;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex-direction: row;
  }
`;

export const StyledHeading = styled(Heading)`
  margin: 1rem 0 2rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin: 0 0 3.6rem;
  }
`;

export const TextWrapper = styled(motion.div)`
  color: ${({ theme }) => theme.color.black};
  width: 100%;
  margin-bottom: 3.5rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding-left: 10%;
    width: 47%;
    margin-bottom: 0;
  }
  @media ${respondTo(MediaQuery.MIN_1280)} {
    width: 38%;
  }
`;

export const StyledSubtitle = styled.h4`
  ${typeStyle.label};
  color: ${({ theme }) => theme.color.blueA};
`;

export const TitleHighlight = styled(motion.span)`
  display: block;
  color: ${({ theme }) => theme.color.blueA};
`;

export const StyledParagraph = styled(motion.p)`
  ${typeStyle.body};
  color: ${({ theme }) => theme.color.blueA};

  &:first-of-type {
    margin-bottom: 2rem;
  }
`;

export const Bold = styled.span`
  font-weight: 600;
`;

export const ImageWrapper = styled.div`
  height: 0;
  width: 100%;
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  left: 0;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    max-width: 50rem;
    padding-top: min(50rem, 100%);
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: 47%;
    padding-top: 47%;

    &:before {
      ${size('50%')};
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: ${({ theme }) => theme.color.blueA};
    }
  }
`;

export const StyledImage = styled(Image)`
  ${size('100%')}
  position: absolute;
  top: 0;
  overflow: hidden;
  border-radius: 0 100rem 100rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    z-index: 1;
  }
`;

export const PillShape = styled.span`
  ${fluidType(10, 255)}
  display: block;
  width: 80%;
  height: 11rem;
  background-color: ${({ theme }) => theme.color.blueA};
  border-radius: 0 20rem 20rem 0;
  margin-top: -5.5rem;
  position: absolute;
  left: 0;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    mix-blend-mode: multiply;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: 50%;
    height: 1.4em;
    margin-top: -0.4em;
    position: relative;
  }
`;

export const PillCircle = styled.span`
  ${size('11rem')};
  background-color: ${({ theme }) => theme.color.redA};
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  border-radius: 100%;
  transform: translateX(50%);

  @media ${respondTo(MediaQuery.MIN_1024)} {
    ${size('1.4em')};
  }

  &:after {
    ${size('100%')};
    content: '';
    position: absolute;
    border-radius: 100%;
    top: 0;
    left: -50%;
    background-color: ${({ theme }) => theme.color.black};
  }
`;
