/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { ReactElement } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { isEmpty } from 'lodash';
import * as S from './AddMissionForm.styles';
import { TextArea } from 'components/atoms/TextArea/TextArea';
import {
  RegisterErrorProp,
  RegisterFormItem,
} from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Input } from 'components/atoms/Input/Input';
import { Text } from 'components/atoms/Text/Text';
import { Upload } from 'components/atoms/Icon/Icon';
import { UploadImage } from 'components/organisms/UploadImage/UploadImage';
import { MissionGetProps } from 'redux/ducks/missionsDuck/missionsTypes';

interface AddMissionFormProps {
  mission: MissionGetProps;
  index: number;
  onChange: (index: number, value: string, name: string) => void;
  formMethods: UseFormReturn<{
    missions: Array<MissionGetProps>;
  }>;
}

export const AddMissionForm = ({
  index,
  mission,
  onChange,
  formMethods,
  ...props
}: AddMissionFormProps): ReactElement => {
  const registerErrors: Record<string, RegisterErrorProp> = {
    name: [
      'required',
      {
        type: 'maxLength',
        key: 'customMaxLength',
        customValue: 50,
      },
    ],
    description: [
      'required',
      {
        type: 'maxLength',
        key: 'customMaxLength',
        customValue: 400,
      },
    ],
  };

  const handleChange = (value: string, title: string) => {
    onChange(index, value, title);
  };

  const getImageName = () => {
    if (isEmpty(formMethods.getValues().missions)) return undefined;
    return {
      name: formMethods.getValues().missions[index].imgName || undefined,
      logo: formMethods.getValues().missions[index].img || undefined,
    };
  };

  return (
    <S.StyledForm
      customMethods={formMethods as UseFormReturn<any>}
      showSuccessMessage={false}
      {...props}
    >
      <RegisterFormItem
        register
        registerErrors={registerErrors.name}
        name={`missions[${index}].name`}
        label="Mission name"
        size={FormItemSizes.Medium}
        alignError="left"
      >
        <Input
          hasRef
          placeholder={localStorage.getItem(`name-${index + 1}`) || 'Input a name for the mission'}
          handleInputChange={(event) =>
            handleChange(event.target.value as unknown as string, 'name')
          }
          defaultValue={mission.name}
        />
      </RegisterFormItem>

      <div>
        <RegisterFormItem
          register
          registerErrors={registerErrors.description}
          alignError="left"
          name={`missions[${index}].description`}
          label="Mission Description"
          size={FormItemSizes.Medium}
        >
          <TextArea
            hasRef
            handleInputChange={(event) =>
              handleChange(event.target.value as unknown as string, 'description')
            }
            placeholder={localStorage.getItem(`description-${index + 1}`) || ''}
            maxLength={400}
            defaultValue={mission.description}
          />
        </RegisterFormItem>
      </div>

      <Flex container flexDirection="column" gap="2rem" alignItems="flex-start">
        <RegisterFormItem
          register
          name={`missions[${index}].img`}
          alignError="left"
          formMethods={formMethods}
        >
          <UploadImage defaultImage={getImageName()} withFilename>
            <PrimaryButton type="button" $outline $size="regular">
              Upload image
              <Upload />
            </PrimaryButton>
          </UploadImage>
        </RegisterFormItem>
        <Text type="label">Allowed extensions: JPEG, PNG</Text>
      </Flex>
    </S.StyledForm>
  );
};
