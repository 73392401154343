/* eslint-disable react-hooks/rules-of-hooks */
import { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectCurrentCohort } from 'redux/ducks/dashboardDuck/dashboardReducer';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Separator } from 'components/atoms/Separator/Separator';
import { Table } from 'components/atoms/Table/Table';
import { slideFade } from 'util/motionTransitions';
import { getFormatedTableData } from 'components/pages/ClientAdminDashboard/content/AdminDashboardTable.data';
import { RootState } from 'redux/store';

export const AdminDashboardTable = (): ReactElement => {
  const intl = useIntl();
  const cohort = useSelector((state: RootState) => selectCurrentCohort(state.dashboard));

  const tableData = useMemo(
    () => getFormatedTableData(cohort?.cohort_survey.participants || [], intl),
    [cohort, intl],
  );

  return (
    <CardContainer {...slideFade()}>
      <Flex container justifyContent="space-between" alignItems="center" flexWrap="wrap" gap="1rem">
        <Heading type={HeadingType.H6}>Cohort participants</Heading>
      </Flex>
      <Separator color="greyE" margin="2rem 0 2.4rem" />
      <Table data={tableData} />
    </CardContainer>
  );
};
