import styled, { css } from 'styled-components';
import { AbstractButton } from 'components/atoms/AbstractButton/AbstractButton';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { ColorKey } from 'styles/theme/default';

export interface IconButtonStyleProps {
  size?: 'small' | 'logo' | 'nav-icon';
  $color?: ColorKey;
  $alignToRight?: boolean;
  $addClickRange?: boolean;
  $center?: boolean;
}

export const StyledIconButton = styled(AbstractButton)<IconButtonStyleProps>`
  display: block;
  transition: opacity 0.3s ease;
  color: ${({ $color, theme }) => ($color ? theme.color[$color] : 'inherit')};

  &:hover {
    opacity: 0.8;
  }

  ${({ $alignToRight }) =>
    $alignToRight &&
    css`
      margin-left: auto;
    `}

  ${({ size }) =>
    size === 'small' &&
    css`
      svg {
        width: 12px;
        height: 12px;
      }
    `}

  ${({ size }) =>
    size === 'logo' &&
    css`
      svg {
        width: 146px;
        height: 36px;
      }
    `}

  ${({ size }) =>
    size === 'nav-icon' &&
    css`
      svg {
        width: 2.3rem;
        height: 2.3rem;

        @media ${respondTo(MediaQuery.MIN_768)} {
          width: auto;
          height: 2rem;
        }
      }
    `}

  ${({ $addClickRange }) =>
    $addClickRange &&
    css`
      div {
        padding: 50%;
        margin: -50%;
      }
    `}

  ${({ $center }) =>
    $center &&
    css`
      position: relative;

      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;

export const StyledIcon = styled.div``;
