/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
import { RegisteredFormItem } from 'globalTypes';
import { ReactElement, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import type { SubunitType } from '../Subunit/Subunit.types';
import { Subunit } from '../Subunit/Subunit';
import * as S from './OrganizationUnit.styles';
import { Separator } from 'components/atoms/Separator/Separator';
import { AccordionHeading } from 'components/molecules/AccordionHeading/AccordionHeading';
import { Participant } from 'model/Participant';
import { OrganizationUnit as OrganizationUnitType } from 'model/OrganizationUnit';
import { Survey } from 'model/Survey';

export interface UnitData {
  id?: string | number;
  name?: string;
  fieldName?: string;
  subunits?: Array<SubunitType>;
  organizationName?: string;
  description?: string;
  organizational_sub_units?: Array<SubunitType>;
}

export interface OrganizationUnitProps extends RegisteredFormItem, UnitData {
  surveys: Array<{ id: number; name: string }>;
  unitIndex: number;
  participants: Array<Participant>;
  unit: OrganizationUnitType;
  isEditMode?: boolean;
  initialEditCohorts: Array<number>;
  handleAddSubunit: (description: string, unitId?: number) => void;
  handleDeleteUnit: (unitId: number | undefined) => void;
  handleAddCohort: (unitId?: number, subunitId?: number) => void;
  handleDeleteCohort: (unitId: number, subunitId: number, cohortId: number) => void;
  handleCohortChange: (unitId: number, subunitId: number, cohortId: number, name: string) => void;
  handleSurveyChange: (unitId: number, subunitId: number, cohortId: number, survey: Survey) => void;
  handleDeleteSubunit: (
    unitIndex: number,
    subunitIndex: number,
    formMethods: UseFormReturn,
  ) => void;
  handleParticipantChange: (
    unitId: number,
    subunitId: number,
    cohortId: number,
    newParticipantsList: Array<Participant>,
  ) => void;
}

export const OrganizationUnit = ({
  unit,
  unitIndex,
  fieldName,
  register,
  formMethods,
  errors,
  isEditMode,
  initialEditCohorts,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleAddSubunit,
  handleDeleteUnit,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleDeleteSubunit,
  handleParticipantChange,
  handleAddCohort,
  handleDeleteCohort,
  handleCohortChange,
  handleSurveyChange,
  participants,
  surveys,
}: OrganizationUnitProps): ReactElement => {
  const [isopen, setIsOpen] = useState(true);

  return (
    <S.StyledOrganizationUnit>
      {unit && (
        <>
          <AccordionHeading
            isOpen={isopen}
            title={unit.description !== 'Non-Applicable' ? unit.description : undefined}
            handleToggleOpen={() => setIsOpen(!isopen)}
            handleDelete={() => handleDeleteUnit(unit.id)}
            confirmModalProps={{
              title: 'Delete unit',
              subtitle: unit.description,
              description: 'Are you sure you want to delete this unit?',
            }}
          />
          <Separator margin="3rem 0 2rem" color="greyE" />

          {/* TODO: Keeping it commented bc we suspect this will be required after MVP
           {isopen && (
            <AddUnit
              register={register}
              formMethods={formMethods}
              errors={errors}
              name={`currentSubUnitDescription[${unitIndex}]`}
              handleAddUnit={(description) => handleAddSubunit(description, unit.id)}
              ctaCopy="Add subunit"
              registerFormItemProps={{ label: 'Subunit name' }}
              inputProps={{ placeholder: 'Enter subunit name' }}
              defaultValue="Subunit"
            />
          )} */}
        </>
      )}
      {isopen && unit.organizational_sub_units && unit.organizational_sub_units.length > 0 && (
        <S.StyledSubunitsWrapper container flexDirection="column" gap="4rem">
          {unit.organizational_sub_units.map((subunit, index) => (
            <Subunit
              isEditMode={isEditMode}
              key={subunit.id}
              name={`${fieldName}.subunit[${index}]`}
              register={register}
              formMethods={formMethods}
              errors={errors}
              unit={unit}
              subunit={subunit}
              unitIndex={unitIndex}
              subunitIndex={index}
              cohorts={subunit.cohorts}
              handleAddCohort={handleAddCohort}
              handleDeleteCohort={handleDeleteCohort}
              handleCohortChange={handleCohortChange}
              participants={participants}
              handleParticipantChange={handleParticipantChange}
              surveys={surveys}
              handleSurveyChange={handleSurveyChange}
              initialEditCohorts={initialEditCohorts}
            />
          ))}
        </S.StyledSubunitsWrapper>
      )}
    </S.StyledOrganizationUnit>
  );
};
