/* eslint-disable import/no-unresolved */
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RegisteredFormItem } from 'globalTypes';
import { ClientSelect } from '../ClientSelect/ClientSelect';
import { getSelectItems } from '../../../../../../../util/getSelectItems';
import { RootState } from 'redux/store';
import { ActionMethod } from 'data/enum/ActionMethod';
import { organizationalTypesActions } from 'redux/ducks/organizationalTypes/organizationalTypesReducer';
import { SelectItem } from 'components/molecules/Select/Select';

export const ClientOrganizationalType = ({
  formMethods,
  register,
  errors,
  defaultValue,
}: RegisteredFormItem): ReactElement => {
  const dispatch = useDispatch();
  const { organizationalTypes } = useSelector((state: RootState) => {
    return state.organizationalTypes;
  });
  const items = getSelectItems(organizationalTypes);
  const fieldName = 'customer.organizational_type_id';

  useEffect(() => {
    dispatch(
      organizationalTypesActions.organizationalTypesApiHandler({ method: ActionMethod.GET }),
    );
  }, [dispatch]);

  const onSelect = ({ value }: SelectItem) => {
    formMethods.setValue(fieldName, value);
  };

  return (
    <ClientSelect
      formMethods={formMethods}
      register={register}
      errors={errors}
      items={items}
      name={fieldName}
      copy="Organizational Type"
      onSelect={onSelect}
      defaultValue={defaultValue}
    />
  );
};
