import { createContext, Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { Layout, LayoutProps } from '../Layout/Layout';
import * as S from './NormalLayout.styles';
import Header from 'components/molecules/Header/Header';
import {
  HeaderColors,
  HeaderTypes,
  HeaderVariants,
} from 'components/molecules/Header/Header.styles';
import { Path } from 'routes/Path';
import { Footer } from 'components/molecules/Footer/Footer';

interface NormalLayoutProps extends LayoutProps {
  headerColorVariant?: HeaderColors;
  headerType?: HeaderTypes;
}

export interface LayoutContextProps {
  headerVariant: HeaderVariants;
  setHeaderVariant: Dispatch<SetStateAction<HeaderVariants>>;
}

export const LayoutContext = createContext({} as LayoutContextProps);
// eslint-disable-next-line no-empty-pattern
export const NormalLayout = ({
  children,
  headerType,
  headerColorVariant,
  ...props
}: NormalLayoutProps): ReactElement => {
  const [headerVariant, setHeaderVariant] = useState<HeaderVariants>(HeaderVariants.Active);

  return (
    <Layout {...props}>
      <Header
        headerColorVariant={headerColorVariant}
        headerType={headerType}
        headerVariant={headerVariant}
        setHeaderVariant={setHeaderVariant}
        headerRedirectPath={Path.Dashboard}
      />
      <LayoutContext.Provider value={{ setHeaderVariant, headerVariant }}>
        <S.StyledMain>{children}</S.StyledMain>
      </LayoutContext.Provider>
      <Footer footerRedirectPath={Path.Dashboard} />
    </Layout>
  );
};
