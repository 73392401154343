/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import { ReactElement } from 'react';
import * as S from './Table.styles';
import { TableRow } from './TableRow/TableRow';
import {
  ActionsType,
  Challenges,
  ParentCallback,
  TableDataItems,
  TableOptions,
} from './Table.types';
import { useDeviceState } from 'hooks/useDeviceState';
import { ColorKey } from 'styles/theme/default';

export interface TableProps {
  data: TableOptions;
  isRemove?: boolean;
  actions?: ActionsType;
  color?: ColorKey;
  thColor?: ColorKey;
  maxHeight?: string;
  challenges?: Challenges;
  parentCallback?: ParentCallback;
  isAddQuestions?: boolean;
  shouldDisableItem?: (tableDataItem: TableDataItems) => boolean;
}

export const Table = ({
  data,
  actions,
  color,
  thColor,
  maxHeight,
  shouldDisableItem,
}: TableProps): ReactElement => {
  const { deviceState } = useDeviceState();
  const isDesktop = deviceState >= 3;

  return (
    <S.TableWrapper maxHeight={maxHeight}>
      <S.StyledTable $color={color} $thColor={thColor}>
        {data.head && data.rows && (
          <S.THead>
            <tr>
              {data.head.map((th, index) => {
                if (th) {
                  if (!th.trim()) return <th key={`th-${index}`}></th>;
                  const options = data.columnOptions[index] || {};
                  return (
                    <S.TableTH key={`th-${index}`} {...options}>
                      {th}
                    </S.TableTH>
                  );
                }
                return null;
              })}
            </tr>
          </S.THead>
        )}
        <S.TableBody>
          {data.rows &&
            data.rows.map((tr, index) => {
              return (
                <TableRow
                  key={`row-${index}`}
                  rowIndex={index}
                  tr={tr}
                  data={data}
                  isDesktop={isDesktop}
                  actions={actions}
                  areActionsDisabled={shouldDisableItem && shouldDisableItem(tr)}
                />
              );
            })}
        </S.TableBody>
      </S.StyledTable>
    </S.TableWrapper>
  );
};
