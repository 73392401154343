import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledTableRow = styled.tr`
  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 1rem 0;
  }
`;

export const StyledDropdownTd = styled.td`
  padding: 2rem 0 1.8rem 2.8rem;
`;
