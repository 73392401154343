import styled from 'styled-components';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledCohortUsersForm = styled(Flex)`
  @media ${respondTo(MediaQuery.MIN_768)} {
    align-items: center;
  }
`;

export const StyledRegisterFormItemCheckbox = styled(RegisterFormItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: unset;
  margin: 0 6rem 0 4rem;

  input {
    margin: 1rem auto;
  }
`;
