import styled from 'styled-components';

export const StyledTitle = styled.label`
  font-weight: 400;
  font-size: 3.4rem;
`;

export const StyledParagraph = styled.p`
  margin: 1.6rem 0;
  color: ${({ theme }) => theme.color.blackB};
`;

export const StyledLink = styled.a`
  display: inline;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.blueA};

  &:hover {
    color: ${({ theme }) => theme.color.blueB};
  }
`;

export const StyledOrderedList = styled.ol`
  list-style-type: decimal;
  list-style-position: inside;
`;
export const StyledUnorderedList = styled.ul`
  list-style-type: disc;
  list-style-position: inside;
  padding-left: 1.4rem;
`;

export const StyledTitleItem = styled.li`
  margin: 3.2rem 0 1.6rem;
  font-weight: 400;
  font-size: 2.4rem;
`;

export const StyledParagraphItem = styled.li`
  margin: 1.6rem 0;
  color: ${({ theme }) => theme.color.blackB};
`;
