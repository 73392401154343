/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return  */
/* eslint-disable consistent-return  */
import { ReactElement } from 'react';
import * as S from './TableRow.styles';
import type { TableOptions, TableDataItems, ActionsType } from '../Table.types';
import { TableData } from '../TableData/TableData';
import { TableControlType } from '../Table.types';
import { TableControl } from '../TableControl/TableControl';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';

interface TableRowProps {
  tr: TableDataItems;
  data: TableOptions;
  isDesktop: boolean;
  rowIndex: number;
  actions?: ActionsType;
  isAddQuestions?: boolean;
  areActionsDisabled?: boolean;
}

export const TableRow = ({
  tr,
  data,
  isDesktop,
  rowIndex,
  actions,
  areActionsDisabled,
  ...props
}: TableRowProps): ReactElement => {
  const hasCustomControl = tr.filter((item) => item.control);

  const controls = data.controls.map((item, index) => ({
    ...item,
    type: ((hasCustomControl[index] && hasCustomControl[index].control) ||
      item.type) as TableControlType,
  }));

  const rows = [...tr, ...controls];

  return (
    <>
      <S.StyledTableRow {...props}>
        {rows.map((td, index) => {
          const options = td.options || data.columnOptions[index] || {};
          return (
            !td.hidden && (
              <TableData
                {...td}
                {...options}
                key={`data-${index}`}
                rowIndex={rowIndex}
                actions={actions}
                areActionsDisabled={areActionsDisabled || td.areActionsDisabled}
                tableRow={tr}
              />
            )
          );
        })}
      </S.StyledTableRow>
      <tr>
        <S.StyledDropdownTd>
          {tr.map((td, index) => {
            const options = data.columnOptions[index] || {};
            return (
              !td.hidden &&
              options.mobileDropdown && (
                <Paragraph key={td.copy} margin="0 0 1.6rem">
                  {td.copy}
                </Paragraph>
              )
            );
          })}
          <Flex container gap="1.6rem" flexWrap="wrap">
            {data.controls.map((control, index) => {
              return (
                (control.options?.mobileDropdown || control.options?.mobileDropdownOnly) && (
                  <TableControl
                    key={`control-${index}`}
                    type={
                      (hasCustomControl[index] &&
                        (hasCustomControl[index].control as TableControlType)) ||
                      (control.type as TableControlType)
                    }
                    rowIndex={rowIndex}
                    actions={actions}
                    areActionsDisabled={areActionsDisabled}
                    {...control}
                    tableRow={tr}
                  />
                )
              );
            })}
          </Flex>
        </S.StyledDropdownTd>
      </tr>
    </>
  );
};
