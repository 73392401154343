/* eslint-disable import/no-unresolved */
import { RegisteredFormItem } from 'globalTypes';
import type { ReactElement } from 'react';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { RadioGroup } from 'components/molecules/RadioGroup/RadioGroup';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { BusinessType } from 'model/Customer';

export const ClientType = ({
  formMethods,
  register,
  errors,
  defaultValue,
}: RegisteredFormItem): ReactElement => {
  return (
    <RegisterFormItem
      errors={errors}
      register={register}
      formMethods={formMethods}
      registerErrors="required"
      name="customer.B2B_B2C"
      size={FormItemSizes.Medium}
      alignError="left"
    >
      <RadioGroup
        items={[
          { title: BusinessType.B2B, value: 0 },
          { title: BusinessType.B2C, value: 1 },
        ]}
        gap="2rem"
        radioType="pill"
        defaultValue={defaultValue}
      />
    </RegisterFormItem>
  );
};
