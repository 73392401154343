import styled, { css } from 'styled-components';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { FontWeight } from '../../../styles/variables';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import type { ColorKey } from 'styles/theme/default';

// eslint-disable-next-line no-shadow
export enum FormItemSizes {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}

const marginSizes = {
  Large: '1.6rem',
  Medium: '.9rem',
  Small: '.9rem',
};

const fontSizes = {
  Large: '2rem',
  Medium: '1.6rem',
  Small: '1.2rem',
};

export type AlignErrorOptions = 'left' | 'right';

export const StyledFormItemContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
`;

export const TransparentLabel = styled.label`
  color: transparent;
  display: block;
  margin-bottom: 0.8rem;
  font-size: 2.4rem;
  margin-bottom: 2.4rem;
  height: 28px;
`;

type LabelProps = {
  size?: FormItemSizes;
  color?: ColorKey;
  marginBottom?: string;
};

export const StyledLabel = styled.label<LabelProps>`
  ${({ theme, color }) => color && `color: ${theme.color[color]};`};
  font-weight: ${FontWeight.Normal};
  display: block;
  margin-bottom: 0.8rem;
  font-size: ${({ size }) => fontSizes[size || 'Medium']};
  margin-bottom: ${({ size, marginBottom }) => marginBottom || marginSizes[size || 'Medium']};
  font-weight: 600;

  ${(props) =>
    (props.size === FormItemSizes.Medium || props.size === FormItemSizes.Large) &&
    css`
      @media ${respondTo(MediaQuery.MIN_768)} {
        font-size: 2.4rem;
        margin-bottom: ${props.marginBottom || '0.8rem'};
      }

      @media ${respondTo(MediaQuery.MIN_1024)} {
        max-width: 40rem;
      }

      @media ${respondTo(MediaQuery.MIN_1280)} {
        max-width: none;
      }
    `};
`;

type ErrorMessageProps = {
  color?: ColorKey;
  alignError?: AlignErrorOptions;
};

export const StyledErrorMessage = styled(ErrorMessage)<ErrorMessageProps>`
  color: ${({ theme, color }) => (color === 'cream' ? theme.color[color] : theme.color.redA)};
  position: absolute;
  ${({ alignError }) => (alignError === 'left' ? 'left: 0;' : 'right: 0;')};
  top: 100%;
  font-size: 12px;
`;

export const StyledLabelDescription = styled.span`
  display: block;
  margin-top: 1rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.color.blackD};
`;
