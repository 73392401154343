// eslint-disable-next-line no-shadow
export enum Path {
  Home = '/',
  Index = '/index',
  Login = '/login',
  Onboarding = '/onboarding',
  NewPassword = '/login/reset-password',
  Profile = '/profile',
  Dashboard = '/dashboard',
  CreateObjective = '/create/objective',
  Assessment = '/assessment',
  AssessmentFeedback = '/assessment/feedback',
  AssessmentCompleted = '/assessment/completed',
  NotFound = '*',
  SuperAdmin = '/admin/',
  SuperAdminManagement = '/admin/superadmin-management',
  ClientOverview = '/admin/client-overview',
  AdminDashboard = '/admin/dashboard',
  ManagementTool = '/admin/management-tool',
  ElementsManagement = '/admin/elements-management',
  ChallengesManagement = '/admin/challenges-management',
  QuestionsManagement = '/admin/questions-management',
  AssessmentManagement = '/admin/assessment-management',
  CreateChallenge = '/admin/create-challenge',
  EditChallenge = '/admin/edit-challenge',
  CreateElement = '/admin/create-element',
  AddMissions = '/admin/add-missions',
  AddClient = '/admin/add-client',
  EditClient = '/admin/edit-client',
  CreateAssessment = '/admin/create-assessment',
  EditAssessment = '/admin/edit-assessment',
  AssessmentHistory = '/admin/assessment-history',
  PasswordPage = '/login/create-password',
  TermsAndConditions = '/terms-and-conditions',
  PrivacyPolicy = '/privacy-policy',
}
