import { editModalNameEmailProps } from './editModalNameEmailProps';
import type { ModalData } from 'components/organisms/FormModal/FormModal';

export const editModalNameEmailCheckboxProps = (
  name: string,
  email: string,
  checked: boolean,
): ModalData => {
  const formModalProps = editModalNameEmailProps(name, email);

  return {
    ...formModalProps,
    items: [
      ...formModalProps.items,
      {
        type: 'checkbox',
        registerFormItemProps: {
          label: 'Client admin',
          name: 'is_admin',
          color: 'greyA',
          registerErrors: undefined,
        },
        itemProps: {
          checked,
        },
      },
    ],
  };
};
