import styled from 'styled-components';

export const StyledTableData = styled.td<{ align?: string; $width?: string }>`
  font-weight: 400;
  color: inherit;
  font-size: 1.6rem;
  line-height: 1.667;
  height: 4rem;
  padding-right: 2rem;

  ${({ align }) => align && `text-align: ${align};`};
`;
