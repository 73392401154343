/* eslint-disable no-console */
// eslint-disable-next-line import/no-unresolved
import { FormInputBasicProps } from 'globalTypes';
import type { ReactElement, ReactNode } from 'react';
import { UseFormReturn } from 'react-hook-form';
import * as S from './CheckboxGroup.styles';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';

interface CheckboxGroupProps extends FormInputBasicProps {
  children: ReactNode;
  name: string;
  formMethods?: UseFormReturn;
}

export const CheckboxGroup = ({
  children,
  name,
  errors,
  errorMessage,
  isRequired,
  register,
  formMethods,
  ...props
}: CheckboxGroupProps): ReactElement => {
  const input = register(name, {
    required: isRequired && (errorMessage ?? 'This field is required.'),
  });

  const hasError = !!errors[input.name];

  return (
    <S.StyledFormItemContainer errorMessage={hasError && errors[name].message} {...props}>
      <Checkbox hasError={hasError} name={name} formMethods={formMethods} />
      {children}
    </S.StyledFormItemContainer>
  );
};
