import styled, { css } from 'styled-components';
import { typeStyle } from '../../../styles/typeStyle';
import { HeadingType } from './Heading.data';
import { ColorKey } from 'styles/theme/default';
import { FontWeight } from 'styles/variables';
import { Responsive, responsive } from 'styles/helpers/responsive';

/**
 * These types are available for the heading component. Keep in mind that by default the type also
 * defines the tag that is used to render out the component. So if you choose to use the type `h2`
 * it will also render out a `<h2>` tag.
 */
export interface StyledHeadingProps {
  $type?: HeadingType;
  color?: ColorKey | 'currentColor';
  margin?: Responsive;
  maxWidth?: Responsive;
  fontWeight?: FontWeight;
}

export const StyledHeading = styled.h1<StyledHeadingProps>`
  ${({ $type }) => $type && typeStyle[$type]};
  ${(props) => css`
    ${props.color &&
    `color: ${props.color === 'currentColor' ? props.color : props.theme.color[props.color]};`}
    ${props.margin && responsive('margin', props.margin)}
    ${props.maxWidth && responsive('max-width', props.maxWidth)}
    ${props.fontWeight && `font-weight: ${props.fontWeight};`}
  `}
`;
