import styled, { css } from 'styled-components';
import { styledScrollbar } from 'styles/styledScrollbar';
import { typeStyle } from 'styles/typeStyle';
import { zIndex } from 'styles/zIndex';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Chevron } from 'components/atoms/Icon/Icon';
import { styledInput } from 'styles/styledInput';
import { ColorKey } from 'styles/theme/default';

export interface SearchSelectStyleProps {
  variant?: 'input';
  $width?: string;
  $color?: ColorKey;
}

export const StyledSearchSelect = styled.div<SearchSelectStyleProps>`
  position: relative;
  color: ${({ $color, theme }) => ($color ? theme.color[$color] : 'inherit')};
`;

export const StyledSelectedFlex = styled(Flex)`
  cursor: pointer;
  width: fit-content;
`;

export const StyledSelectedItem = styled.div<SearchSelectStyleProps>`
  ${({ variant, theme }) =>
    variant !== 'input'
      ? `
      border-bottom: 0.1rem solid currentColor;
`
      : css`
          ${styledInput(theme)};
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 50rem;
        `}
`;

export const StyledSelectedItemInput = styled.input<SearchSelectStyleProps>`
  appearance: none;
  outline: none;
  color: inherit;
  background: none;
  overflow: hidden;
  will-change: width;

  ${({ variant }) =>
    variant === 'input'
      ? 'width: 100%;'
      : css`
          ${typeStyle.h6};
          width: 30rem;
        `}

  &::placeholder {
    color: ${({ theme }) => theme.color.blueC};
  }
`;

export const StyledChevron = styled(Chevron)`
  margin-left: 1.4rem;
  transform: rotate(-90deg);
`;

export const StyledList = styled.ul`
  ${styledScrollbar};
  z-index: ${zIndex('overlay')};
  position: absolute;
  left: 0;
  top: 100%;
  background: ${({ theme }) => theme.color.cream};
  box-shadow: ${({ theme }) => theme.boxShadow.A};
  width: 35rem;
  max-height: 20rem;
  overflow-y: scroll;
`;

export const StyledListItem = styled.li<{ variant?: 'last-group-item'; isHighlighted?: boolean }>`
  padding: 1.2rem;
  cursor: pointer;
  transition: color 300ms ease, background 300ms ease;
  color: ${({ theme }) => theme.color.blueA};
  ${({ variant, theme }) =>
    variant === 'last-group-item' && `border-bottom: 2px solid ${theme.color.greyD};`}

  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `
    background: ${theme.color.blueA};
    color: ${theme.color.cream};
  `}
`;
