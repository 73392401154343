export const assessmentData = {
  elements: ['choose an element to add', 'Architecture', 'Exploring', 'Consulting'],
  scores: [
    { title: '1-5', value: 0 },
    { title: '1-7', value: 1 },
    { title: '1-10', value: 2 },
  ],
  challenges: [
    'Select a challenge',
    '21 Day Challenge',
    '3 Months Challenge',
    '6 Months Challenge',
  ],
};
