import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './ParticipantDashboardWrapper.styles';
import { ParticipantFeedback } from 'components/organisms/ParticipantDashboardWrapper/ParticipantFeedback/ParticipantFeedback';
import { InvitePeople } from 'components/organisms/InvitePeople/InvitePeople';
import { PeersTable } from 'components/organisms/PeersTable/PeersTable';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';
import { StatsHeader } from 'components/organisms/StatsHeader/StatsHeader';
import { shouldDisableItem } from 'util/dashboardAssessorsTable';
import { RootState } from 'redux/store';
import {
  assessmentIsSelfAnswered,
  selectCurrentParticipant,
} from 'redux/ducks/dashboardDuck/dashboardReducer';
import { useCurrentUserIndex } from 'hooks/useCurrentUserIndex';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ParticipantDashboardWrapperProps {}

export const ParticipantDashboardWrapper = ({
  ...props
}: ParticipantDashboardWrapperProps): ReactElement => {
  const isSelfAnswered = useSelector((state: RootState) =>
    assessmentIsSelfAnswered(state.dashboard),
  );
  const participant = useSelector((state: RootState) => selectCurrentParticipant(state.dashboard));
  const { currentCohortIndex } = useSelector((state: RootState) => state.dashboard);
  const [currentAssessmentTab, setCurrentAssessmentTab] = useState(0);
  const assessment = useMemo(() => participant?.assessment, [participant]);
  const assessors = useMemo(() => assessment?.assessors || [], [assessment]);
  const hasAssessors = useMemo(() => assessors.length > 0, [assessors]);
  const intl = useIntl();
  const dispatch = useDispatch();
  useCurrentUserIndex(true);

  const tabs = useMemo(
    () =>
      isSelfAnswered
        ? [
            intl.formatMessage({
              defaultMessage: 'My dashboard',
              description: 'User Dashboard - Assessment - Tab 1',
            }),
            intl.formatMessage({
              defaultMessage: 'My Assessors',
              description: 'User Dashboard - Assessment - Tab 2',
            }),
          ]
        : [],
    [intl, isSelfAnswered],
  );

  useEffect(() => {
    setCurrentAssessmentTab(0);
  }, [dispatch, currentCohortIndex]);

  return (
    <S.StyledDashboardContent {...props}>
      <TabsContainer activeTab={currentAssessmentTab} setActiveTab={setCurrentAssessmentTab}>
        <S.StatsHeader>
          <StatsHeader tabs={tabs} isUserDashboard />
        </S.StatsHeader>
        <>
          <ParticipantFeedback />
          {hasAssessors ? (
            <PeersTable
              title={intl.formatMessage({
                defaultMessage: 'Assessors',
                description: 'User Dashboard - Assessors Table - Title',
              })}
              ctaCopy={intl.formatMessage({
                description: 'User Dashboard - Assessors Table - Cta',
                defaultMessage: 'Invite Assessor',
              })}
              invitelist={assessors}
              assessment_id={assessment?.id || 0}
              shouldDisableItem={shouldDisableItem}
            />
          ) : (
            <InvitePeople assessment_id={assessment?.id || 0} />
          )}
        </>
      </TabsContainer>
    </S.StyledDashboardContent>
  );
};
