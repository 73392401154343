import { ApiClient } from './apiClient';

export const getUserRole = async (): Promise<{ role: string } | null> => {
  try {
    const { data } = await ApiClient.get('/user-role');
    return data;
  } catch (error) {
    return null;
    // TODO: handle error redirect
  }
};
