import styled, { css } from 'styled-components';
import { responsive, Responsive } from 'styles/helpers/responsive';
import { size } from 'styles/helpers/size';
import { ColorKey } from 'styles/theme/default';

interface BackgroundCircleStyleProps {
  position?: 'fixed';
  top?: Responsive;
  left?: Responsive;
  bottom?: Responsive;
  right?: Responsive;
  size?: Responsive;
  color?: ColorKey;
  lastBackground?: boolean;
}

export const BackgroundCircle = styled.div<BackgroundCircleStyleProps>`
  background: ${({ theme, color }) => (color ? theme.color[color] : theme.color.blueE)};
  border-radius: 50%;

  ${(props) => css`
    position: ${props.position || 'absolute'};
    ${props.lastBackground && 'z-index: -1;'}
    ${props.left && responsive('left', props.left)}
    ${props.top && responsive('top', props.top)}
    ${props.bottom && responsive('bottom', props.bottom)}
    ${props.right && responsive('right', props.right)}
    ${
      // eslint-disable-next-line unicorn/explicit-length-check
      props.size && responsive(size, props.size)
    }
  `}
`;
