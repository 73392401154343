import { ReactElement } from 'react';
import { CloseButton } from '../../CloseButton/CloseButton';
import * as S from './MapModal.styles';

interface MapModalProps {
  close: () => void;
}

export const MapModal = ({ close }: MapModalProps): ReactElement => {
  return (
    <S.MapModal>
      <S.Map>
        <S.ButtonWrapper>
          <CloseButton aria-label="Close button" onClick={close} />
        </S.ButtonWrapper>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2436.2706938345004!2d4.912709716498268!3d52.36551147978535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6099f50d4ec4b%3A0xe27408d4984a2edb!2sPlantage%20Middenlaan%2045%2C%201018%20DC%20Amsterdam%2C%20Netherlands!5e0!3m2!1sen!2sar!4v1658173146866!5m2!1sen!2sar"
          width="800"
          height="600"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </S.Map>
    </S.MapModal>
  );
};
