import { ApiClient } from 'util/apiClient';

enum GenderEndpoints {
  Genders = '/genders',
}

const getGenders = async (): Promise<Array<{ id: number; description: string }>> => {
  const { data } = await ApiClient.get(GenderEndpoints.Genders);
  return data;
};

export const GenderService = { getGenders };
