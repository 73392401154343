import { DOMAttributes, ReactElement, ReactNode } from 'react';
import { ParagraphStyleProps } from './Paragraph.styles';
import * as S from './Paragraph.styles';

export interface ParagraphProps extends ParagraphStyleProps, DOMAttributes<HTMLParagraphElement> {
  children?: ReactNode;
}

export const Paragraph = ({ children, ...props }: ParagraphProps): ReactElement => {
  return <S.StyledParagraph {...props}>{children}</S.StyledParagraph>;
};
