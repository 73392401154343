import styled from 'styled-components';
import { Ease } from 'styles/easing';
import { FontWeight } from 'styles/variables';

export const StyledCustomMouse = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  transition: transform 500ms ${Ease.EaseOutQuint};
`;

export const StyledCustomMouseInner = styled.div`
  position: relative;
  transition: transform 250ms ease;
  transform: scale(0);
`;

export const StyledTip = styled.span`
  position: absolute;
  top: calc(100% + 1rem);
  left: 50%;
  transform: translateX(-50%);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: ${FontWeight.Medium};
  color: ${({ theme }) => theme.color.blueA};
`;
