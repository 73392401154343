/* eslint-disable max-lines */
import { ReactElement } from 'react';
import * as S from './PrivacyPolicy.styles';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';

export const PrivacyPolicy = (): ReactElement => {
  return (
    <NormalLayout padding="normal">
      <S.StyledTitle>Privacy Policy</S.StyledTitle>
      <S.StyledSubTitle>360 Mirror</S.StyledSubTitle>

      <S.StyledParagraph>
        This Privacy Policy applies to the processing of personal data from all our users regarding
        the products and/or services of THNK Innovation BV, THNK Amsterdam BV, THNK Social
        Enterprise BV also trading under the name THNK School of Creative Leadership, (hereinafter
        THNK). In this Privacy Policy we explain how the personal data is processed and for which
        purposes the data are being processed. By using products and/or services of THNK and
        accepting this Privacy Policy, the following terms shall apply.
      </S.StyledParagraph>

      <S.StyledHighlight>About THNK</S.StyledHighlight>

      <S.StyledParagraph>
        THNK Innovation BV, THNK Amsterdam BV, THNK Social Enterprise BV are responsible for
        processing your personal data (the &#39;controller&#39;) within the meaning of the General
        Data Protection Regulation (‘GDPR’).
      </S.StyledParagraph>

      <S.StyledParagraph>
        THNK is located at Plantage Middenlaan 45, 1018 DC Amsterdam, The Netheralnds. Any questions
        you might have can be emailed to{' '}
        <S.StyledLink href="mailto:amsterdam@thnk.org">amsterdam@thnk.org</S.StyledLink>.
      </S.StyledParagraph>

      <S.StyledParagraph>
        Under the GDPR, THNK does not have the obligation to appoint a data protection officer
        (‘DPO’).
      </S.StyledParagraph>

      <S.StyledHighlight>What information do we collect about you?</S.StyledHighlight>

      <S.StyledParagraph>
        By using THNK’s products and/or services, it processes the personal data of its users. THNK
        collects information about you when you complete your registration and when you voluntarily
        complete assessments, surveys, evaluations and provide feedback. Moreover, website usage
        information is collected using cookies. This data is collected for certain purposes on a
        legitimate legal basis.
      </S.StyledParagraph>

      <S.StyledParagraph>
        The following Data is collected from prospective participants when completing a registration
        form for the order of a product and/or service of THNK:
      </S.StyledParagraph>

      <S.StyledUnorderedList>
        <S.StyledParagraphItem>email address</S.StyledParagraphItem>
        <S.StyledParagraphItem>first and last name</S.StyledParagraphItem>
        <S.StyledParagraphItem>gender</S.StyledParagraphItem>
        <S.StyledParagraphItem>country of residence</S.StyledParagraphItem>
        <S.StyledParagraphItem>nationality</S.StyledParagraphItem>
        <S.StyledParagraphItem>Date of birth</S.StyledParagraphItem>
        <S.StyledParagraphItem>
          organization name (organization unit and subunit)
        </S.StyledParagraphItem>
        <S.StyledParagraphItem>organization size</S.StyledParagraphItem>
        <S.StyledParagraphItem>industry</S.StyledParagraphItem>
        <S.StyledParagraphItem>job function</S.StyledParagraphItem>
        <S.StyledParagraphItem>type of organisation</S.StyledParagraphItem>
        <S.StyledParagraphItem>
          other information related to the motivation, needs and expectations of the products THNK
          offers
        </S.StyledParagraphItem>
        <S.StyledParagraphItem>
          individual evaluation data – self-assessment and input from external party at the User’s
          choice and discretion
        </S.StyledParagraphItem>
        <S.StyledParagraphItem>
          personal reflections and input from peers related to learning goals
        </S.StyledParagraphItem>
      </S.StyledUnorderedList>

      <S.StyledParagraph>
        In the case of surveys or feedback, in addition to the above, we process additional personal
        data. The nature and scope of the personal data can vary in these cases; further information
        on this will accompany the survey or feedback concerned.
      </S.StyledParagraph>

      <S.StyledParagraph>
        The following Data is collected from users using products and/or services of THNK:
      </S.StyledParagraph>

      <S.StyledUnorderedList>
        <S.StyledParagraphItem>
          Marketing and Sales Data - personal data geared at marketing programs, recruiting and
          selecting participants
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>
          Operational Data – Information geared at facilitating operational procedures, e.g.
          onborading and ensuring best experience for the participants
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>
          Individual evaluation data - data provided by the participants through evaluation surveys
          and self-assessment questionnaires
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>
          Marketing Evaluation Data –evaluation data provided by participants during program
          onboarding to understand decision making for the program and improve our marketing
        </S.StyledParagraphItem>
      </S.StyledUnorderedList>

      <S.StyledParagraph>
        The following Data is collected from visitors to the THNK website:
      </S.StyledParagraph>

      <S.StyledUnorderedList>
        <S.StyledParagraphItem>website visit and use;</S.StyledParagraphItem>
        <S.StyledParagraphItem>IP address;</S.StyledParagraphItem>
        <S.StyledParagraphItem>duration and time of visit to the website;</S.StyledParagraphItem>
        <S.StyledParagraphItem>use of social media.</S.StyledParagraphItem>
      </S.StyledUnorderedList>

      <S.StyledHighlight>How do we collect data from you?</S.StyledHighlight>

      <S.StyledParagraph>
        You directly provide THNK with most of the data we collect. We collect data and process data
        when you:
      </S.StyledParagraph>

      <S.StyledUnorderedList>
        <S.StyledParagraphItem>
          register online via submitting an online form and place an order for any of our services
        </S.StyledParagraphItem>
        <S.StyledParagraphItem>
          voluntarily complete an assessment, survey or provide feedback
        </S.StyledParagraphItem>
        <S.StyledParagraphItem>
          use of view our website via your browser&#39;s cookies
        </S.StyledParagraphItem>
      </S.StyledUnorderedList>

      <S.StyledHighlight>For what purposes do we process personal data?</S.StyledHighlight>

      <S.StyledParagraph>
        We process personal data for the purposes of providing our services, within the framework of
        training programs, assessment and education, and in order to comply with statutory
        obligations.
      </S.StyledParagraph>

      <S.StyledParagraph>
        Personal data supplied by users and participants (or prospective participants) is processed
        for the following purposes:
      </S.StyledParagraph>

      <S.StyledUnorderedList>
        <S.StyledParagraphItem>
          registration for the purposes of learning, assessment and training program;
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>
          to provide self-awareness, evaluation and education on leadership development (including
          online education and assessment);
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>
          to complete self-assessment and track personal learning progress related to the 360 Mirror
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>
          administrative purposes, to enable services to be provided and the payments for these to
          be collected
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>
          to process requests for information about training programs;
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>to provide course choice support;</S.StyledParagraphItem>

        <S.StyledParagraphItem>to monitor study progress;</S.StyledParagraphItem>

        <S.StyledParagraphItem>to provide study counselling;</S.StyledParagraphItem>

        <S.StyledParagraphItem>information purposes;</S.StyledParagraphItem>

        <S.StyledParagraphItem>
          to provide (more detailed) updates on current developments, events, webinars, etc.;
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>
          to email newsletters, invitations to participate in surveys, information on your
          participant account, your participant status and other (necessary) information from THNK
          School of Creative Leadership;
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>
          the continuous improvement of the quality and accessibility of facilitating and social
          impact by, among other things, conducting research and carrying out and processing surveys
          and their results.
        </S.StyledParagraphItem>
      </S.StyledUnorderedList>

      <S.StyledParagraph>
        The personal data supplied by visitors to the 360 Mirror websites is processed for the
        following purposes:
      </S.StyledParagraph>

      <S.StyledUnorderedList>
        <S.StyledParagraphItem>to compile user statistics;</S.StyledParagraphItem>

        <S.StyledParagraphItem>
          to promote security and improvement of our website; and
        </S.StyledParagraphItem>

        <S.StyledParagraphItem>to improve our services.</S.StyledParagraphItem>
      </S.StyledUnorderedList>

      <S.StyledParagraph>
        In the case of surveys or feedback, personal data is processed for the purposes of the
        survey or feedback. Further information on the way in which personal data is processed will
        accompany the survey or feedback concerned.
      </S.StyledParagraph>

      <S.StyledHighlight>On what legal grounds do we process your data?</S.StyledHighlight>

      <S.StyledParagraph>
        The processing of personal data of users is essential for the execution/performance of the
        agreement by THNK. All personal data of participants, for example an e-mail address, used by
        THNK for promotional use and the ability to send a newsletter is on the basis of consent.
      </S.StyledParagraph>

      <S.StyledParagraph>
        The processing of personal data of the 360 Mirror website users is on the basis of consent
        of the user of the website.
      </S.StyledParagraph>

      <S.StyledParagraph>
        THNK has a legitimate interest in the processing of personal data of users, participants
        and/or prospective participants and/or website users for managing, analysing, maintaining,
        optimising and securing our products and/or services and/or for preventing abuse and/or
        fraud of our products and/or services and/or determining strategic analyses and reports on
        our products and/or services.
      </S.StyledParagraph>

      <S.StyledHighlight>How will we use the information about you?</S.StyledHighlight>

      <S.StyledParagraph>
        THNK is a service provider offering leadership programs to individual participants and
        corporate clients as well as assessment of their leadership. The 360 Mirror tool is a
        critical part of the leadership development journey of the user and/or participant. All the
        personal registration data collected by the 360 Mirror tool is used entirely and solely for
        the purpose it was collected for – leadership assessment. No data is shared with external
        parties or used for any external purposes.
      </S.StyledParagraph>

      <S.StyledParagraph>
        Personal registration data is needed in order to create customized accounts for users,
        whereby they can complete self-assessments and track their personal learning progress
        related to the 360 Mirror assessment. This registration data is also collected by THNK in
        order to better understand the types of leaders that are using the 360 Mirror tool
        (demographic data). Such data is always anonymized.
      </S.StyledParagraph>

      <S.StyledParagraph>
        Individual Evaluation data is collected from and for the individual users (if they choose to
        participate in the assessments). The self-assessment questionnaires are individual reports
        completed by the users and used by them as an aid to reflect on their leadership
        development. The individual evaluation data is used by THNK in an anonymized format in order
        to better understand the state of leadership in the world today.
      </S.StyledParagraph>

      <S.StyledParagraph>
        We collect information about you in order to answer your queries, to assess if you could be
        a candidate for our programming, and, if you agree, to email you about products and services
        we think may be of interest to you. We use your information collected from the website to
        personalise your repeat visits to our website. THNK does not share your information for
        marketing purposes with other companies.
      </S.StyledParagraph>

      <S.StyledParagraph>
        THNK shares personal data with third parties to support the performance of its services, on
        the basis of a statutory provision or in case of an emergency if this is, in the reasonable
        opinion of THNK , in the interest of it users. This includes legitimate requests of
        authorities, summons or court orders, acts to investigate or prevent damages or fraud, or
        acts to guarantee the safety of our network and our Products and/or Services.
      </S.StyledParagraph>

      <S.StyledParagraph>
        You can think of a software supplier or other service providers who need personal data to
        deliver their services. We formalize the processing and security of personal data through
        agreements with these parties.
      </S.StyledParagraph>

      <S.StyledHighlight>How do we store your data?</S.StyledHighlight>

      <S.StyledParagraph>
        THNK securely stores your data online, through verified and trusted, GDPR-compliant software
        providers like Google, Hubspot, Box, Salesforce, MediaMonks among others. Our data is stored
        in reputable databases that specialise in this type of data collection, and therefore have
        robust built-in systems to protect such data. THNK may use the services of third parties to
        process your data in accordance with this privacy statement. These parties can be located
        outside the European Union or they can use servers located outside the European Union,
        including in the United States. The level of the right to protection of personal data may be
        lower in these countries than in the European Union.
      </S.StyledParagraph>

      <S.StyledHighlight>Data collected automatically</S.StyledHighlight>

      <S.StyledParagraph>
        In case a participant or other user visits our website, our services may store automatic
        information, such as URLS, IP addresses, browser types. THNK has a legitimate interest in
        processing the personal data in question.
      </S.StyledParagraph>

      <S.StyledHighlight>Third party data</S.StyledHighlight>

      <S.StyledParagraph>
        THNK processes personal data of third parties by using for example Hubspot to capture where
        the marketing leads come from - from ads on Facebook, ads on LinkedIn and so on. THNK has a
        legitimate interest in processing the personal data from third parties in question.
      </S.StyledParagraph>

      <S.StyledHighlight>Sensitive personal data</S.StyledHighlight>

      <S.StyledParagraph>THNK does not process sensitive personal data.</S.StyledParagraph>

      <S.StyledHighlight>Users’ rights</S.StyledHighlight>

      <S.StyledParagraph>
        All (prospective) participants and users of our website have certain rights under the GDPR
        to manage their own personal data.
      </S.StyledParagraph>

      <S.StyledUnorderedList>
        <S.StyledParagraphItem>The right to access;</S.StyledParagraphItem>
        <S.StyledParagraphItem>The right to rectification;</S.StyledParagraphItem>
        <S.StyledParagraphItem>The right to erasure;</S.StyledParagraphItem>
        <S.StyledParagraphItem>The right to object;</S.StyledParagraphItem>
        <S.StyledParagraphItem>The right to data portability;</S.StyledParagraphItem>
        <S.StyledParagraphItem>The right to restriction of processing;</S.StyledParagraphItem>
      </S.StyledUnorderedList>

      <S.StyledParagraph>
        A user may also withdraw the consent he/she has granted for the processing of his/her data
        at any time.
      </S.StyledParagraph>

      <S.StyledParagraph>
        In case the user wishes to invoke on or more of these rights, he/she may contact THNK
        through <S.StyledLink href="mailto:amsterdam@thnk.org">amsterdam@thnk.org</S.StyledLink>.
      </S.StyledParagraph>

      <S.StyledHighlight>How long do we keep your personal data?</S.StyledHighlight>

      <S.StyledParagraph>
        We will not keep your personal data for longer than is necessary for the purposes for which
        we use it. We are required by law to keep some data for a certain period of time.
      </S.StyledParagraph>

      <S.StyledParagraph>
        For example, it could be that after you have completed a course we have to keep certain
        personal data for administrative purposes or due to a legal obligation. Wherever possible,
        we will pseudonymise or anonymise your personal data.
      </S.StyledParagraph>

      <S.StyledParagraph>
        The data collected from the 360 Mirror users is not shared outside the organization and is
        kept for a limited amount of time. The processing is directly linked to the personal use of
        the tool by the users and by THNK to better understand the nature of its user base.
      </S.StyledParagraph>

      <S.StyledParagraph>
        All data collected will remain on the 360 Mirror platform. No data will be shared via
        additional platforms, spreadsheets or emails.
      </S.StyledParagraph>

      <S.StyledHighlight>Marketing</S.StyledHighlight>

      <S.StyledParagraph>
        We would like to send you information about products and services of ours and possibly other
        companies which may be of interest to you. If you have consented to receive marketing, you
        may opt out at a later date. You have a right at any time to stop us from contacting you for
        marketing purposes. If you no longer wish to be contacted for marketing purposes, please
        unsubscribe from our mailing list using the Unsubscribe link at the bottom of our marketing
        emails or send us an email to{' '}
        <S.StyledLink href="mailto:amsterdam@thnk.org">amsterdam@thnk.org</S.StyledLink>.
      </S.StyledParagraph>

      <S.StyledHighlight>Access to your information and correction</S.StyledHighlight>

      <S.StyledParagraph>
        You have the right to request a copy of the information that we hold about you. If you would
        like a copy of some or all of your personal information, please email{' '}
        <S.StyledLink href="mailto:amsterdam@thnk.org">amsterdam@thnk.org</S.StyledLink> or write to
        us at the following address: Plantage Middenlaan 45, 1018 DC, Amsterdam, Netherlands. We
        want to make sure that your personal information is accurate and up to date. You may ask us
        to correct or remove information you think is inaccurate. If in doubt about your identity,
        we are entitled to ask you to provide proof of your identity first.
      </S.StyledParagraph>

      <S.StyledHighlight>Security</S.StyledHighlight>

      <S.StyledParagraph>
        THNK has taken adequate technical and organizational measures to protect personal data of
        participants and other users against loss of unlawful processing, for example, by using
        passwords to protect a database.
      </S.StyledParagraph>

      <S.StyledParagraph>
        In the unlikely event that you discover a data breach, it of utmost importance that we are
        informed immediately. You can report data leaks directly through:{' '}
        <S.StyledLink href="mailto:amsterdam@thnk.org">amsterdam@thnk.org</S.StyledLink>. Your
        report and data will be treated confidentially at all times.
      </S.StyledParagraph>

      <S.StyledHighlight>Cookies</S.StyledHighlight>

      <S.StyledParagraph>
        Cookies are text files placed on your computer to collect standard internet log information
        and visitor behaviour information. This information is used to track visitor use of the
        website and to compile statistical reports on website activity. For further information
        visit www.aboutcookies.org or www.allaboutcookies.org. You can set your browser not to
        accept cookies and the above websites tell you how to remove cookies from your browser.
        However in a few cases some of our website features may not function as a result.
      </S.StyledParagraph>

      {/* TODO: Revisit once we have that link

      <S.StyledParagraph>
        We ask all our website visitors for a consent to use cookies which help us understand how
        visitors use our webiste. That consent can be revoked at any time through the following
        link:
      </S.StyledParagraph> */}

      <S.StyledHighlight>What types of cookies do we use?</S.StyledHighlight>

      <S.StyledParagraph>
        There are a number of different types of cookies, however, our website uses:
      </S.StyledParagraph>

      <S.StyledUnorderedList>
        <S.StyledParagraphItem>
          functionality - THNK uses these cookies so that we recognize you on our website and
          remember your previously selected preferences. A mix of first-party and third-party
          cookies are used.
        </S.StyledParagraphItem>
        <S.StyledParagraphItem>
          advertising - THNK uses these cookies to collect information about your visit to our
          website, the content you viewed, the links you followed and information about your
          browser, device and your IP address
        </S.StyledParagraphItem>
        <S.StyledParagraphItem>
          other - for example, LinkedIn and Facebook. These cookies are used just to connect these
          services to our website so that we can understand where the leads are coming from (e.g.
          from Facebook or Linkedin ads pointing to www.thnk.org)
        </S.StyledParagraphItem>
      </S.StyledUnorderedList>

      <S.StyledHighlight>Other websites</S.StyledHighlight>

      <S.StyledParagraph>
        The 360 Mirror website may contain links to other websites. This privacy policy only applies
        to this website so when you link to other websites you should read their own privacy
        policies.
      </S.StyledParagraph>

      <S.StyledHighlight>Changes to our privacy policy</S.StyledHighlight>

      <S.StyledParagraph>
        We keep our privacy policy under regular review and we will place any updates on this web
        page. This privacy policy was last updated on 6 September, 2022.
      </S.StyledParagraph>

      <S.StyledHighlight>How to contact us </S.StyledHighlight>

      <S.StyledParagraph>
        Please contact us if you have any questions about our privacy policy or information we hold
        about you by email:{' '}
        <S.StyledLink href="mailto:amsterdam@thnk.org">amsterdam@thnk.org</S.StyledLink>.
      </S.StyledParagraph>
    </NormalLayout>
  );
};
