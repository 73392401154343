import styled from 'styled-components';
import { Form } from 'components/molecules/Form/Form';
import { Text } from 'components/atoms/Text/Text';

export const StyledCreateElement = styled.div`
  margin-bottom: 15rem;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
`;

export const StyledNote = styled(Text)`
  margin-top: 1.6rem;
`;
