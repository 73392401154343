/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */

import styled, { css } from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { Responsive, responsive } from 'styles/helpers/responsive';
import { MediaQuery } from 'styles/mediaQuery';

export interface FlexContainerProps {
  container?: boolean;
  center?: boolean;
  gap?: Responsive;
  minHeight?: Responsive;
  maxWidth?: Responsive;
  width?: Responsive;
  flexBasis?: Responsive;
  justifyContent?: Responsive<'space-between' | 'flex-end' | 'flex-start' | 'center'>;
  flexDirection?: Responsive<'column' | 'row' | 'row-reverse' | 'column-reverse'>;
  alignItems?: Responsive<'center' | 'flex-end' | 'flex-start' | 'stretch'>;
  flexWrap?: Responsive<'wrap' | 'wrap-reverse'>;
  screen?: MediaQuery;
  flexGrow?: Responsive;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
}

export const Flex = styled.div<FlexContainerProps>`
  ${({ screen }) => screen && `@media ${respondTo(screen)} {`}

  ${(props) => css`
    ${props.justifyContent && responsive('justify-content', props.justifyContent)}
    ${props.flexDirection && responsive('flex-direction', props.flexDirection)}
    ${props.flexWrap && responsive('flex-wrap', props.flexWrap)}
    ${props.flexGrow && responsive('flex-grow', props.flexGrow)}
    ${props.alignItems && responsive('align-items', props.alignItems)}
    ${props.minHeight && responsive('min-height', props.minHeight)}
    ${props.maxWidth && responsive('max-width', props.maxWidth)}
    ${props.width && responsive('width', props.width)}
    ${props.flexBasis &&
    responsive((value: string) => `flex-basis: ${value}; min-width: ${value};`, props.flexBasis)}
    ${props.container && 'display: flex;'}
    ${props.center && 'justify-content: center; align-items: center;'}
    ${props.gap && responsive((value: string) => `gap: ${value}; `, props.gap)}
  `}

  ${({ screen }) => screen && `}`}
`;
