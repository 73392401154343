import type { FunctionComponent, ReactElement, SVGProps } from 'react';
import * as S from './IntroItem.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { Separator } from 'components/atoms/Separator/Separator';
import { MediaQuery } from 'styles/mediaQuery';

interface IntroItemProps {
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  title?: string;
  paragraphs?: Array<string>;
  list?: { title: string; items: Array<string> };
  isLast?: boolean;
}

export const IntroItem = ({
  icon: Icon,
  title,
  paragraphs,
  list,
  isLast,
  ...props
}: IntroItemProps): ReactElement => {
  const hasSeparator = !isLast && Icon;

  return (
    <S.StyledIntroItem {...props}>
      <Flex container justifyContent={[[MediaQuery.MIN_1024, 'center']]} flexBasis="7rem">
        {Icon && <Icon />}
      </Flex>
      <S.StyledItemContent>
        {title && (
          <Heading type={HeadingType.H4} margin="0 0 1.6rem">
            {title}
          </Heading>
        )}
        {paragraphs &&
          paragraphs.map((paragraph) => <Paragraph key={paragraph}>{paragraph}</Paragraph>)}

        {hasSeparator && <Separator margin={['3rem 0 0', [MediaQuery.MIN_1024, '2rem 0 0']]} />}
      </S.StyledItemContent>
    </S.StyledIntroItem>
  );
};
