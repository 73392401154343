import styled from 'styled-components';
import { Form } from 'components/molecules/Form/Form';

export const StyledCreateChallenge = styled.div``;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  padding-bottom: 7rem;
`;
