import { ReactElement, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserDashboard } from 'components/pages/UserDashboard/UserDashboard';
import { ClientAdminDashboard } from 'components/pages/ClientAdminDashboard/ClientAdminDashboard';
import { Loader } from 'components/atoms/Loading/Loading.styles';
import { DashboardService } from 'services/DashboardService';
import { dashboardActions } from 'redux/ducks/dashboardDuck/dashboardReducer';
import { ProfileService } from 'services/ProfileService';
import { SET_PROFILE_ID } from 'redux/ducks/authDuck/authTypes';
import { RootState } from 'redux/store';

interface DashboardProps {
  userRole?: string;
}

export const Dashboard = ({ userRole }: DashboardProps): ReactElement => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const isAdmin = useMemo(() => userRole === 'client-admin', [userRole]);
  const { profileId } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const cohorts = await DashboardService.getDashboard();
      dispatch(dashboardActions.setCohorts(cohorts));

      if (profileId === null) {
        const profile = await ProfileService.getProfile();
        dispatch({
          type: SET_PROFILE_ID,
          payload: profile.participant_id,
        });
      }

      setLoading(false);
    };

    dispatch(dashboardActions.setCurrentCohortIndex(0));
    dispatch(dashboardActions.setCurrentParticipantIndex(0));

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  if (loading) return <Loader />;

  return isAdmin ? <ClientAdminDashboard /> : <UserDashboard />;
};
