import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom';

Sentry.init({
  dsn: 'https://f5c6112e0e3ac5c21fd693f8a31f68a1@o4506512536633344.ingest.us.sentry.io/4507345878253568',
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0, // eslint-disable-line unicorn/no-zero-fractions

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'https://mirror.thnk.org/'],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0, // eslint-disable-line unicorn/no-zero-fractions
});
