import styled from 'styled-components';
import { motion } from 'framer-motion';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { Flex } from 'components/atoms/Flex/FlexContainer';

export const StatsHeader = styled.div`
  display: flex;
  padding: 5.5rem 0;
  align-items: center;
  justify-content: space-between;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 3rem 0;
    gap: 3rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledDashboardContent = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledDashboardCards = styled(motion.div)<{ $gap: string }>`
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.$gap};

  @media ${respondTo(MediaQuery.MAX_1023)} {
    flex-directio: column;
  }
`;

export const StyledFeedbackFlex = styled(Flex)`
  max-width: 100%;
`;
