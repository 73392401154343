import styled from 'styled-components';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { IconButton } from 'components/molecules/IconButton/IconButton';
import { size } from 'styles/helpers/size';
import { Flex } from 'components/atoms/Flex/FlexContainer';

export const ContentWrapper = styled(Flex)`
  padding-top: 3.5rem;
`;

export const StyledIconButton = styled(IconButton)`
  ${size('3rem')};
  transition: transform 0.3s ease-out;
`;

export const StyledQuestionContent = styled.div`
  position: relative;
  overflow: hidden;
  height: 0;
`;

export const StyledCheckboxContainer = styled.div`
  display: flex,
  align-items: center;
`;

export const StyledElementQuestion = styled(CardContainer)<{
  $isOpen: boolean;
  $hasError?: boolean;
}>`
  ${({ $hasError, theme }) =>
    $hasError &&
    `
  border: 1px solid ${theme.color.redA};
`}

  ${StyledIconButton} {
    transform: ${({ $isOpen }) => ($isOpen ? 'rotate(90deg)' : 'rotate(270deg)')};
  }
`;
