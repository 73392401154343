/* eslint-disable no-console */
import debugLib from 'debug';
import { APP_NAME, IS_PRODUCTION } from '../environment';

const base = debugLib(APP_NAME);

export const log = base.extend('log');
log.log = console.log;

export const info = base.extend('info');
info.log = console.info;

export const error = base.extend('error');
error.log = console.error;

// Always enable error log
error.enabled = true;

export const warn = base.extend('warn');
warn.log = console.warn;

// Enable debug when not on production
if (!IS_PRODUCTION && localStorage.getItem('debug') == null) {
  localStorage.setItem('debug', `${base.namespace}:*`);
}
