import type { TableDataItems, TableOptions } from 'components/atoms/Table/Table.types';

export const getQuestionsDetails = (
  rowIndex: number,
  finalQuestions?: TableOptions,
): Array<TableDataItems> =>
  finalQuestions ? finalQuestions.rows.filter((row) => Number(row[0].copy) === rowIndex) : [];

export const tableData: TableOptions = {
  head: ['No.', 'Name', 'Description', 'Creation Date', 'Open', ' '],
  controls: [
    { type: 'Edit', action: 'Edit' },
    { type: 'Delete', action: 'Delete' },
  ],
  columnOptions: {
    0: { width: '10%' },
    1: { width: '20%' },
    2: { width: '20%' },
    3: { width: '27%' },
    4: { width: '10%' },
  },
  rows: [],
};
