import { ReactElement, ReactNode, useState } from 'react';
import * as S from './Message.styles';
import { Cross } from '../Icon/Icon';
import { Noop } from '../Noop/Noop';
import { IconButton } from 'components/molecules/IconButton/IconButton';

interface MessageProps {
  children: ReactNode;
}

export const Message = ({ children, ...props }: MessageProps): ReactElement => {
  const [active, setActive] = useState(true);

  if (!active) return <Noop />;

  return (
    <S.StyledMessage {...props}>
      <S.StyledFlex container alignItems="center" gap=".9rem">
        {children}
        <IconButton icon={Cross} onClick={() => setActive(false)} />
        <S.StyledTriangle />
      </S.StyledFlex>
    </S.StyledMessage>
  );
};
