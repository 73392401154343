import styled from 'styled-components';

export const StyledProfileMenu = styled.div`
  position: relative;
`;

export const StyledProfileButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledDropdown = styled.ul`
  ${({ theme }) => `background-color: ${theme.color.greyB}`};
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  position: absolute;
  top: calc(100% + 8px);
  right: -100%;
`;

export const StyledItem = styled.li`
  cursor: pointer;
  white-space: nowrap;
  padding: 10px 16px;
  font-weight: 600;

  &:hover {
    ${({ theme }) => `background-color: ${theme.color.greyE}`};
  }
`;
