/* eslint-disable no-restricted-properties */
import { ReactElement, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { zIndex } from 'styles/zIndex';

export interface StickyBarProps {
  alignToTop?: boolean;
  sidePadding?: string;
  children?: ReactElement;
  inModal?: boolean;
}

const getTop = (element: HTMLElement) => {
  return element.getBoundingClientRect().top;
};

const StyledStickyBar = styled.div<StickyBarProps>`
  width: 100%;
  position: fixed;
  left: 0;
  ${({ alignToTop }) => (alignToTop ? 'top: 0;' : 'bottom: 0;')}
  z-index: ${zIndex('overlay')};
  box-shadow: 0px 4px 23px 4px ${({ theme }) => theme.color.blackC};
  padding: 1.8rem ${({ theme }) => theme.sitePaddings.mobile};
  background: ${({ theme }) => theme.color.cream};

  &.absolute {
    position: absolute;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding: 3rem ${({ theme, sidePadding }) => sidePadding || theme.sitePaddings.desktop};
  }
`;

export const StickyBar = ({ children, ...props }: StickyBarProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (props.alignToTop || props.inModal) return;

    const handlePosition = () => {
      if (!ref.current) return;
      const footer = document.querySelector('footer');
      if (!footer) return;

      if (getTop(footer) - window.innerHeight < 0) {
        ref.current.classList.add('absolute');
      } else {
        ref.current.classList.remove('absolute');
      }
    };
    handlePosition();

    document.addEventListener('scroll', handlePosition);

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('scroll', handlePosition);
    };
  }, [props.alignToTop, props.inModal]);

  return (
    <StyledStickyBar ref={ref} {...props}>
      {children}
    </StyledStickyBar>
  );
};
