import { ChangeEventHandler, MouseEventHandler, ReactElement } from 'react';
import * as S from './Radio.styles';
import { Text } from '../Text/Text';

export interface RadioProps extends S.RadioStyleProps {
  label: string;
  name?: string;
  id?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
}

export const Radio = ({
  name,
  label,
  id,
  checked,
  defaultChecked,
  mobileType,
  onChange,
  onClick,
  ...props
}: RadioProps): ReactElement => {
  return (
    <S.StyledRadioContainer mobileType={mobileType} {...props}>
      <Text as="label" htmlFor={id || label} type="bodyRegular" color="inherit">
        {label}
      </Text>
      <S.StyledContainer>
        <S.StyledRadio
          mobileType={mobileType}
          type="radio"
          name={name}
          id={id || label}
          checked={checked}
          defaultChecked={defaultChecked}
          onClick={onClick}
          onChange={onChange}
        />
        <S.StyledCheck />
      </S.StyledContainer>
    </S.StyledRadioContainer>
  );
};
