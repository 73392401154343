import { useMemo, useState } from 'react';
import { slideFade } from 'util/motionTransitions';
import { PaginationButtonsProps } from 'components/molecules/PaginationButtons/PaginationButtons';

interface UsePaginationProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  items: Array<any>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePagination = ({ items }: UsePaginationProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState<1 | -1 | undefined>(-1);

  const handleButtonClick = (direction: 1 | -1) => {
    setActiveIndex(activeIndex + direction);
    setDirection(direction);
  };

  const paginationButtonProps = useMemo(
    () => ({
      onLeftClick: () => handleButtonClick(-1),
      onRightClick: () => handleButtonClick(1),
      isLeftDisabled: activeIndex - 1 < 0,
      isRightDisabled: activeIndex + 1 >= items.length,
      count: `${activeIndex + 1}/${items.length}`,
      size: 'small',
      direction: 'column',
      color: 'blueA',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeIndex, items],
  ) as PaginationButtonsProps;

  const paginationMotionProps = useMemo(
    () => ({
      ...slideFade('y', 50, 0.2, direction, false, true),
    }),
    [direction],
  );

  return {
    activeIndex,
    activeItem: items[activeIndex],
    paginationButtonProps,
    paginationMotionProps,
    setActiveIndex,
  };
};
