import { ReactElement, useCallback, useContext } from 'react';
import { getCohortUserDetails, getFormatedCohortUserTableData } from './CohortUsersTable.data';
import { Table } from 'components/atoms/Table/Table';
import { ConfirmModalContext } from 'components/organisms/ConfirmModal/ConfirmModal';
import { FormModalContext } from 'components/organisms/FormModal/FormModal';
import { editModalNameEmailCheckboxProps } from 'util/editModalNameEmailCheckboxProps';
import { TableDataItems } from 'components/atoms/Table/Table.types';
import { Participant } from 'model/Participant';

interface CohortUsersTableProps {
  participants: Array<Participant>;
  deleteUser: (index: number) => void;
  editUser: (user: Participant, index: number) => void;
  setParticipants: (nextState: Array<Participant>) => void;
  shouldDisableItem?: (tableDataItem: TableDataItems) => boolean;
  allowEdit?: boolean;
}

export const CohortUsersTable = ({
  participants,
  deleteUser,
  editUser,
  setParticipants,
  shouldDisableItem,
  allowEdit,
}: CohortUsersTableProps): ReactElement => {
  const { useConfirmModal } = useContext(ConfirmModalContext);
  const { useFormModal } = useContext(FormModalContext);
  const getTableData = useCallback(
    () => getFormatedCohortUserTableData(participants, allowEdit),
    [participants, allowEdit],
  );

  const DeleteUser = async (rowIndex: number) => {
    const { id, email } = getCohortUserDetails(getTableData(), rowIndex);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const confirmed = await useConfirmModal({
      title: 'Delete',
      subtitle: email,
      description: 'Are you sure you want to delete this user?',
    });

    if (confirmed) {
      deleteUser(id);
      setParticipants(participants.filter((participant) => participant.email !== email));
    }
  };

  const EditUser = async (rowIndex: number) => {
    const { name, email, checked } = getCohortUserDetails(getTableData(), rowIndex);
    const formModalProps = editModalNameEmailCheckboxProps(name, email, checked);

    const data = await useFormModal(formModalProps);

    if (data) editUser(data as Participant, rowIndex);
  };

  return (
    <Table
      color="blueA"
      actions={{ Delete: DeleteUser, ...(allowEdit && { Edit: EditUser }) }}
      shouldDisableItem={shouldDisableItem}
      data={getTableData()}
    />
  );
};
