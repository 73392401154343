import { ReactElement } from 'react';
import * as S from './AdminDashboardWrapper.styles';
import { AdminFeedback } from 'components/organisms/AdminDashboardWrapper/AdminFeedback/AdminFeedback';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';
import { StatsHeader } from 'components/organisms/StatsHeader/StatsHeader';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AdminDashboardWrapperProps {}

export const AdminDashboardWrapper = ({ ...props }: AdminDashboardWrapperProps): ReactElement => {
  return (
    <S.StyledDashboardContent {...props}>
      <TabsContainer>
        <S.StatsHeader>
          <StatsHeader />
        </S.StatsHeader>
        <AdminFeedback additionalCopy="You can always send them a friendly reminder." />
      </TabsContainer>
    </S.StyledDashboardContent>
  );
};
