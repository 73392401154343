import styled, { css } from 'styled-components';
import { Ease } from 'styles/easing';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { ColorKey } from 'styles/theme/default';

export const StyledPillTabs = styled.div`
  width: fit-content;
  max-width: 100%;
  position: relative;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-sizing: initial;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTabsContainer = styled.div<{ color: ColorKey }>`
  display: inline-flex;
  border: 1px solid ${({ theme, color }) => theme.color[color]};
  border-radius: 2px;
  position: relative;
  color: ${({ theme, color }) => theme.color[color]};
`;

export const StyledTabIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 2px;
  z-index: -1;
  background: currentColor;
  box-shadow: 1px 0px 0px currentColor;
  transition: width 0.4s ${Ease.EaseInOutQuart}, transform 0.4s ${Ease.EaseInOutQuart};
`;

export enum TabsVariants {
  Active = 'Active',
}

interface TabsStyleProps {
  variant?: TabsVariants | boolean;
}

export const StyledTab = styled.div<TabsStyleProps>`
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 300ms ease;
  color: currentColor;

  @media ${respondTo(MediaQuery.MIN_768)} {
    padding: 1.2rem 4.4rem;
  }

  ${({ variant }) =>
    variant === TabsVariants.Active &&
    css`
      color: ${({ theme }) => theme.color.cream};
    `}
`;
