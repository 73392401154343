import styled from 'styled-components';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { size } from 'styles/helpers/size';
import { Heading } from 'components/atoms/Heading/Heading';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const HeroWrapper = styled.div`
  border-bottom: solid 1px ${({ theme }) => theme.utils.hexToRgba(theme.color.greyD, 0.4)};
  margin-bottom: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 3rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    margin-bottom: 5rem;
  }
`;

export const HeadingWrapper = styled.div``;

export const StyledHeading = styled(Heading)`
  max-width: 80%;
  margin: 1.5rem 0 1rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    margin: 3rem 0 1.6rem;
  }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  @media ${respondTo(MediaQuery.MAX_767)} {
    margin-top: 2rem;
  }
  @media ${respondTo(MediaQuery.MIN_768)} {
    margin-left: 2rem;
  }
`;

export const ProfilePicture = styled.div`
  ${size('19.2rem')};
  position: relative;

  @media ${respondTo(MediaQuery.MAX_767)} {
    display: none;
  }
`;

export const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
`;
