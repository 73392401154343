/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { ReactElement, useMemo, useRef } from 'react';
import { useLockBodyScroll } from 'react-use';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as S from './MobileMenu.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Separator } from 'components/atoms/Separator/Separator';
import { AbstractButton } from 'components/atoms/AbstractButton/AbstractButton';
import { Path } from 'routes/Path';
import { SessionService } from 'services/SessionService';
import { SET_PROFILE_ID } from 'redux/ducks/authDuck/authTypes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface MobileMenuProps {}

export const MobileMenu = ({ ...props }: MobileMenuProps): ReactElement => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isDashboardPath = useMemo(() => pathname.includes(Path.Dashboard), [pathname]);
  const body = useRef(document.body);
  const { push } = useHistory();

  useLockBodyScroll(true, body);

  const handleSignOut = async () => {
    try {
      await SessionService.logout();
      dispatch({
        type: SET_PROFILE_ID,
        payload: null,
      });
    } catch (error) {
      // Do nothing
    } finally {
      localStorage.removeItem('token');
      push(Path.Login);
    }
  };

  return (
    <S.StyledMobileMenu {...props}>
      <Flex container minHeight="100%" flexDirection="column" justifyContent="space-between">
        <div>
          {/* <AbstractButton href="/profile">
            <Flex container alignItems="center">
              <ProfileIcon />
              <Heading margin="0 0 0 1rem" type={HeadingType.H6} color="blueA">
                <FormattedMessage description="Mobile Menu - Profile" defaultMessage="Profile" />
              </Heading>
            </Flex>
          </AbstractButton> */}

          {!isDashboardPath && (
            <AbstractButton href="/dashboard">
              <Flex container alignItems="center">
                <Heading margin="0" type={HeadingType.H6} color="blueA">
                  <FormattedMessage
                    description="Mobile Menu - My Dashboard"
                    defaultMessage="My Dashboard"
                  />
                </Heading>
              </Flex>
            </AbstractButton>
          )}

          <Separator color="greyE" margin="3rem 0" />

          <AbstractButton onClick={handleSignOut}>
            <Flex container alignItems="center">
              <Heading margin="0" type={HeadingType.H6} color="blueA">
                Sign Out
              </Heading>
            </Flex>
          </AbstractButton>
        </div>
        <div>
          {/* //* Removed for MVP
            <LanguageSelect />
          */}
        </div>
      </Flex>
    </S.StyledMobileMenu>
  );
};
