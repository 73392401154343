/* eslint-disable react-hooks/rules-of-hooks */
import { ReactElement, useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as S from './PeersTable.style';
import { getFormatedTableData, getUserDetails, personModal } from './PeersTable.data';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Table } from 'components/atoms/Table/Table';
import { slideFade } from 'util/motionTransitions';
import { FormModalContext } from 'components/organisms/FormModal/FormModal';
import { ShareModalContext } from 'components/organisms/ShareModal/ShareModal';
import { Separator } from 'components/atoms/Separator/Separator';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Plus } from 'components/atoms/Icon/Icon';
import { ConfirmModalContext } from 'components/organisms/ConfirmModal/ConfirmModal';
import { setErrorEvent, setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { TableDataItems } from 'components/atoms/Table/Table.types';
import { AssessmentService } from 'services/AssessmentService';
import { Path } from 'routes/Path';
import { Assessor } from 'services/types/DashboardTypes';
import { DashboardService } from 'services/DashboardService';
import { dashboardActions } from 'redux/ducks/dashboardDuck/dashboardReducer';

interface PeersTableProps {
  assessment_id: number;
  invitelist: Array<Assessor>;
  shouldDisableItem?: (tableDataItem: TableDataItems) => boolean;
  title: string;
  ctaCopy: string;
}

export const PeersTable = ({
  assessment_id,
  invitelist,
  shouldDisableItem,
  title,
  ctaCopy,
}: PeersTableProps): ReactElement => {
  const { useFormModal } = useContext(FormModalContext);
  const { useShareModal } = useContext(ShareModalContext);
  const { useConfirmModal } = useContext(ConfirmModalContext);
  const intl = useIntl();
  const dispatch = useDispatch();
  const { replace } = useHistory();

  const tableData = useMemo(() => getFormatedTableData(invitelist, intl), [invitelist, intl]);
  const refreshDashboard = async () => {
    const cohorts = await DashboardService.getDashboard();
    dispatch(dashboardActions.setCohorts(cohorts));
  };

  const handleAddPerson = async () => {
    try {
      const user = (await useFormModal(personModal({ assessors: invitelist }))) as Assessor;

      if (!user) return;

      const { name, email } = user;

      await AssessmentService.inviteAssessor({ name, email, assessment_id });
      await refreshDashboard();

      dispatch(setSuccessEventAction(Events.INVITATIONS_SENT));

      const showToastTimeMs = 100;
      setTimeout(() => replace(Path.Dashboard), showToastTimeMs);
    } catch (error) {
      dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
    }
  };

  const handleCopyPerson = async (rowIndex: number) => {
    const { reminder_link } = getUserDetails(tableData, rowIndex);

    const shouldCopy = await useShareModal({
      title: 'Copy share details',
      content: reminder_link,
    });

    if (!shouldCopy) return;

    await navigator.clipboard.writeText(reminder_link);
    dispatch(setSuccessEventAction(Events.COPY_CLIPBOARD_SUCCESS));
  };

  const handleDeletePerson = async (rowIndex: number) => {
    try {
      const { email, id } = getUserDetails(tableData, rowIndex);

      const shouldDelete = await useConfirmModal({
        title: 'Delete',
        subtitle: email,
        description: 'Are you sure you want to delete this user?',
      });

      if (!shouldDelete) return;

      await AssessmentService.removeAssessor(id);
      await refreshDashboard();

      dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
    } catch (error) {
      dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
    }
  };

  return (
    <S.StyledPeersTable>
      <CardContainer {...slideFade()}>
        <Flex
          container
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap="1rem"
        >
          <Heading type={HeadingType.H6}>{title}</Heading>
          <PrimaryButton $size="regular" $outline onClick={handleAddPerson}>
            {ctaCopy}
            <Plus />
          </PrimaryButton>
        </Flex>
        <Separator color="greyE" margin="2rem 0 2.4rem" />
        <Table
          data={tableData}
          actions={{ Copy: handleCopyPerson, Delete: handleDeletePerson }}
          shouldDisableItem={shouldDisableItem}
        />
      </CardContainer>
    </S.StyledPeersTable>
  );
};
