import { Dispatch, ReactElement, SetStateAction, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as S from './ProfileHero.styles';
import { EditProfileModal } from '../EditProfileModal/EditProfileModal';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { Spinner } from 'components/atoms/Spinner/Spinner';
import { ArrowExternal } from 'components/atoms/Icon/Icon';
import { Hide } from 'components/atoms/Hide/Hide';
import { MediaQuery } from 'styles/mediaQuery';
import { setErrorEvent, setSuccessEvent } from 'redux/ducks/eventsDuck/eventsActions';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Profile } from 'services/types/ProfileTypes';
import { Image } from 'components/atoms/Image/Image';
import placeholderImage from 'assets/img/onboarding/placeholder-profile.png';
import { ProfileService } from 'services/ProfileService';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { UploadProfilePicture } from 'components/atoms/UploadProfilePicture/UploadProfilePicture';

interface ProfileHeroProps {
  profile: Profile;
  setProfile: Dispatch<SetStateAction<Profile>>;
}

export const ProfileHero = ({ profile, setProfile, ...props }: ProfileHeroProps): ReactElement => {
  const dispatch = useDispatch();
  const [editProfile, setEditProfile] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);

  const handleImageUpload = async (imageBase64?: string | undefined) => {
    try {
      if (!imageBase64) throw new Error('Image could not be parsed as base64');

      setImageLoading(true);

      const imageUrl = await ProfileService.updateProfilePicture(imageBase64);
      setProfile({ ...profile, img: imageUrl || '' });

      dispatch(setSuccessEvent(Events.FORM_SUCCESS));
    } catch (error) {
      dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
    } finally {
      setImageLoading(false);
    }
  };

  return (
    <>
      <S.HeroWrapper {...props}>
        <S.HeadingWrapper>
          <Hide screen={MediaQuery.MAX_767}>
            <Heading color="redA" type={HeadingType.H5}>
              <FormattedMessage
                description="Profile - Hero - Eyebrow"
                defaultMessage="> My Profile"
              />
            </Heading>
          </Hide>
          <S.StyledHeading type={HeadingType.H2}>{profile.name}</S.StyledHeading>
          <Flex container screen={MediaQuery.MIN_768}>
            <Paragraph>{profile.company}</Paragraph>
            <S.StyledSecondaryButton color="redA" onClick={() => setEditProfile(true)}>
              <span>
                <FormattedMessage
                  description="Profile - Hero - Edit Profile Cta"
                  defaultMessage="Edit profile"
                />
              </span>
              <ArrowExternal />
            </S.StyledSecondaryButton>
          </Flex>
        </S.HeadingWrapper>
        <S.ProfilePicture>
          <S.ImageWrapper>
            {(!profile || imageLoading) && <Spinner />}
            <Image
              src={profile.img || placeholderImage}
              alt="Profile Image"
              fit="cover"
              borderRadius="50%"
            />
          </S.ImageWrapper>
          <UploadProfilePicture
            allowedExtensions={['jpeg', 'jpg', 'png']}
            onImageUpload={handleImageUpload}
            disabled={imageLoading}
          />
        </S.ProfilePicture>
      </S.HeroWrapper>
      {editProfile && (
        <EditProfileModal
          profile={profile}
          setProfile={setProfile}
          onClose={() => setEditProfile(false)}
        />
      )}
    </>
  );
};
