import { respondTo } from './respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { IS_DEVELOPMENT } from 'util/environment';

export type Responsive<T = string> = T | Array<Array<MediaQuery | T> | T>;

export const responsive = (
  property: string | ((value: string) => string),
  item: Responsive<string>,
): string => {
  const getPropertyValue = (value: string) =>
    typeof property === 'function' ? property(value) : `${property}: ${value};`;

  if (typeof item === 'string') return getPropertyValue(item);

  let css = '';

  for (const responsiveItem of item) {
    if (typeof responsiveItem === 'string') {
      css += getPropertyValue(responsiveItem);

      if (IS_DEVELOPMENT && Object.values(MediaQuery).includes(responsiveItem as MediaQuery)) {
        throw new Error(
          "Property value can't be a media query. Make sure the media query is in an array.",
        );
      }
    } else {
      const screen = responsiveItem[0] as MediaQuery;
      const value = responsiveItem[1];

      css += `@media ${respondTo(screen)} {${getPropertyValue(value)}};`;
    }
  }

  return css;
};
