import styled from 'styled-components';
import { motion } from 'framer-motion';
import { FontWeight } from 'styles/variables';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { typeStyle } from 'styles/typeStyle';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';

export const StyledWrittenScore = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledTitle = styled.h5`
  font-weight: ${FontWeight.Medium};
  font-size: 2rem;
  margin-bottom: 1.6rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    font-size: 2.4rem;
  }
`;

export const StyledSubTitle = styled.h6`
  font-weight: ${FontWeight.Medium};
  font-size: 1.6rem;
  margin-bottom: 0.8rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    font-size: 1.8rem;
  }
`;

export const StyledLabel = styled.label`
  ${typeStyle.label};
  margin-bottom: 0.8rem;
  color: ${({ theme }) => theme.color.blueC};
`;

export const StyledCommentsContainer = styled.div`
  margin-bottom: 2.8rem;
`;

export const StyledBody = styled.div`
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
`;

export const StyledTextWrapper = styled(motion.div)`
  overflow-y: auto;
  padding-right: 4px;
  word-wrap: break-word;

  ::-webkit-scrollbar {
    width: 8px;
    &-track {
      background-color: transparent;
    }
    &-thumb {
      background-color: ${({ theme }) => theme.color.cream};
      border-radius: 100px;
    }
  }
`;

export const StyledParagraph = styled(Paragraph)`
  margin: 0 0 2rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin: auto 0;
  }
`;
