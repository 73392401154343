import { IntlShape } from 'react-intl';
import moment from 'moment';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import type { TableOptions, TableDataItems } from 'components/atoms/Table/Table.types';
import type { ModalData } from 'components/organisms/FormModal/FormModal';
import { Assessor } from 'services/types/DashboardTypes';

export const getUserDetails = (
  tableData: TableOptions,
  rowIndex: number,
): { name: string; email: string; reminder_link: string; id: string } => {
  const rowData = tableData.rows[rowIndex];
  const name = rowData[1].copy as string;
  const email = rowData[2].copy as string;
  const reminder_link = rowData[5].data as string;
  const id = rowData[6].data as string;

  return { name, email, reminder_link, id };
};

interface PersonModalParams {
  defaultName?: string;
  defaultEmail?: string;
  assessors?: Array<{ name: string; email: string }>;
}

export const personModal = ({
  assessors,
  defaultName,
  defaultEmail,
}: PersonModalParams): ModalData => ({
  title: 'Add assessor',
  confirmCopy: 'Add',
  items: [
    {
      type: 'input',
      registerFormItemProps: {
        registerErrors: 'required',
        name: 'name',
        label: 'Name',
        color: 'greyA',
      },
      itemProps: {
        defaultValue: defaultName,
        hasRef: true,
      },
    },
    {
      type: 'input',
      registerFormItemProps: {
        registerErrors: ['required', { type: 'pattern', key: 'emailPattern' }],
        name: 'email',
        label: 'Email',
        color: 'greyA',
      },
      itemProps: {
        defaultValue: defaultEmail,
        hasRef: true,
      },
    },
    {
      type: 'input',
      registerFormItemProps: {
        name: 'confirm-email',
        label: 'Confirm email',
        color: 'greyA',
        registerErrors: [
          {
            type: 'validate',
            value: (confirmEmailValue: string, methods: UseFormReturn<FieldValues>) => {
              const emailValue = methods.getValues('email');

              if (emailValue !== confirmEmailValue) {
                return 'The Emails doesn’t match.';
              }

              if (assessors && assessors.some((assessor) => assessor.email === confirmEmailValue)) {
                return 'Email already in list.';
              }

              return true;
            },
          },
        ],
      },
      itemProps: {
        hasRef: true,
      },
    },
  ],
});

const getTableHead = (intl: IntlShape) => [
  intl.formatMessage({
    description: 'Dashboard - Tables - Number Title',
    defaultMessage: '#.',
  }),
  intl.formatMessage({
    description: 'Dashboard - Tables - Name Title',
    defaultMessage: 'Name',
  }),
  intl.formatMessage({
    description: 'Dashboard - Tables - Email Title',
    defaultMessage: 'Email',
  }),
  intl.formatMessage({
    description: 'Dashboard - Tables - Assessment Status Title',
    defaultMessage: 'Status',
  }),
  intl.formatMessage({
    description: 'Dashboard - Tables - Last Remember Title',
    defaultMessage: 'Date of last reminder',
  }),
];

const getAssessorRow = (assessor: Assessor, index: number): TableDataItems => {
  return [
    {
      copy: `${index + 1}. `,
    },
    { copy: assessor.name },
    { copy: assessor.email },
    {
      status: assessor.is_completed
        ? { type: 'finished', copy: 'Finished' }
        : { type: 'not_started', copy: 'Not Started yet' },
    },
    { copy: moment(assessor.date_send).format('MM-DD-YY') },
    { data: assessor.reminder_link },
    { data: assessor.id },
    { copy: '' },
  ];
};

export const getFormatedTableData = (assessors: Array<Assessor>, intl: IntlShape): TableOptions => {
  return {
    head: getTableHead(intl),
    columnOptions: [],
    controls: [
      { type: 'Notification', action: 'Copy' },
      { type: 'Delete', action: 'Delete' },
    ],
    rows: assessors.map((assessor, index) => getAssessorRow(assessor, index)),
  };
};
