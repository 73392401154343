import { ReactElement } from 'react';
import * as S from './Circles.styles';

interface CirclesProps {
  percentage: number;
}

export const Circles = ({ percentage, ...props }: CirclesProps): ReactElement => {
  return (
    <S.StyledCircles {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 466 466"
        fill="none"
        className="dotted-circle"
      >
        <path d="M407.079 81.1531C447.351 127.322 467.634 187.598 463.464 248.721C459.295 309.844 431.016 366.807 384.847 407.079C338.678 447.351 278.402 467.634 217.279 463.464C156.157 459.295 99.1932 431.016 58.9209 384.847C18.6486 338.678 -1.63379 278.402 2.53554 217.279C6.70488 156.157 34.9845 99.1932 81.1531 58.9209C127.322 18.6486 187.598 -1.63379 248.72 2.53554C309.843 6.70488 366.807 34.9844 407.079 81.1531L407.079 81.1531Z" />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 466 466"
        fill="none"
        className="complete-circle"
      >
        <circle
          style={{
            strokeDasharray: `${(1450 * percentage) / 100}, 20000`,
          }}
          cx="-7%"
          cy="50%"
          r="calc(50% - 2px)"
          transform="rotate(-90, 100, 100)"
        />
      </svg>
    </S.StyledCircles>
  );
};
