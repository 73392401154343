/* eslint-disable no-console */
import { AxiosInstance } from 'axios';
import { ApiClient } from 'util/apiClient';
import { UserResponse } from 'services/types/UserTypes';

enum SessionEndpoints {
  Login = '/login',
  Logout = '/logout',
  ChangePassword = '/profiles/update-password',
}

const logout = (): Promise<AxiosInstance> => ApiClient.post(SessionEndpoints.Logout);

const changePassword = (body: {
  old_password: string;
  password: string;
  password_confirmation: string;
}): Promise<AxiosInstance> => ApiClient.put(SessionEndpoints.ChangePassword, body);

const login = async (body: { email: string; password: string }): Promise<UserResponse> => {
  const { data } = await ApiClient.post(SessionEndpoints.Login, body);
  console.log({ data });
  return data;
};

export const SessionService = { login, logout, changePassword };
