import { useState, useCallback, useEffect, useRef } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { EmblaOptionsType } from 'embla-carousel';
import { flatten } from 'lodash';
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSlider = (options?: EmblaOptionsType, dependencies: Array<unknown> = []) => {
  const [isPreviousButtonEnabled, setIsPreviousButtonEnabled] = useState<boolean>(false);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(true);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<Array<number>>([]);

  const [sliderRef, emblaApi] = useEmblaCarousel({
    ...options,
  });

  const flattenDependenciesCountRef = useRef<number>(flatten(dependencies).length);

  const goToPrevious = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);
  const goToNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);
  const goTo = useCallback((index) => emblaApi?.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;

    setActiveSlideIndex(emblaApi.selectedScrollSnap());
    setIsPreviousButtonEnabled(emblaApi.canScrollPrev());
    setIsNextButtonEnabled(emblaApi.canScrollNext());
  }, [emblaApi]);

  const checkDraggable = useCallback(() => {
    if (!emblaApi) return;
    let draggable = false;
    if (options?.draggable !== false) {
      const maxWidth = emblaApi.containerNode().offsetWidth;
      const [lastSlide] = emblaApi.slideNodes().slice(-1);
      const itemsWidth = lastSlide.offsetLeft + lastSlide.offsetWidth;
      draggable = maxWidth < itemsWidth;
    }
    setScrollSnaps(emblaApi.scrollSnapList());
    setIsDraggable(draggable);
  }, [emblaApi, options?.draggable]);

  useEffect(() => {
    const flattenDependenciesCount = flatten(dependencies).length;

    if (flattenDependenciesCount !== flattenDependenciesCountRef.current) {
      flattenDependenciesCountRef.current = flattenDependenciesCount;
      setTimeout(checkDraggable, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependencies]);

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi?.reInit({
      ...options,
      draggable: isDraggable,
    });
    goTo(0);
    setScrollSnaps(emblaApi.scrollSnapList());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDraggable, emblaApi, ...dependencies]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();

    emblaApi.on('select', onSelect);
    emblaApi.on('resize', checkDraggable);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((document as any).fonts) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (document as any).fonts.ready.then(() => {
        checkDraggable();
      });
    } else {
      checkDraggable();
    }

    // eslint-disable-next-line consistent-return
    return () => {
      emblaApi.off('select', onSelect);
      emblaApi.off('resize', checkDraggable);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emblaApi]);

  return {
    emblaApi,
    sliderRef,
    sliderData: {
      isPreviousButtonEnabled,
      isNextButtonEnabled,
      activeSlideIndex,
      scrollSnaps,
      isDraggable,
    },
    actions: {
      goToPrevious,
      goToNext,
      goTo,
    },
  };
};
