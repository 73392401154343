/* eslint-disable import/no-unresolved */
import { RegisteredFormItem } from 'globalTypes';
import type { ReactElement } from 'react';
import { ClientSelect } from '../ClientSelect/ClientSelect';
import { Size } from 'model/Customer';
import { SelectItem } from 'components/molecules/Select/Select';
import { useClientSizeTransformer } from 'hooks/useClientSizeTransformer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ClientSizeProps extends RegisteredFormItem {}

export const ClientSize = ({
  formMethods,
  errors,
  register,
  defaultValue,
}: ClientSizeProps): ReactElement => {
  const { adaptedClientSizes } = useClientSizeTransformer();
  const fieldName = 'customer.organization_size';

  const onSelect = ({ value }: SelectItem) => {
    formMethods.setValue(fieldName, value as Size);
  };

  return (
    <ClientSelect
      formMethods={formMethods}
      register={register}
      errors={errors}
      items={adaptedClientSizes}
      name={fieldName}
      copy="Size"
      onSelect={onSelect}
      defaultValue={typeof defaultValue === 'number' && adaptedClientSizes[defaultValue].value}
    />
  );
};
