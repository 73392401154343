/* eslint-disable react-hooks/rules-of-hooks */
import { ReactElement, useContext } from 'react';
import * as S from './AccordionHeading.styles';
import { IconButton } from '../IconButton/IconButton';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Chevron, Cross } from 'components/atoms/Icon/Icon';
import { ConfirmModalContext, ModalData } from 'components/organisms/ConfirmModal/ConfirmModal';

interface AccordionHeadingProps extends S.AccordionHeadingStyleProps {
  title?: string;
  headingType?: HeadingType;
  isOpen?: boolean;
  handleToggleOpen?: () => void;
  handleDelete?: () => void;
  confirmModalProps?: ModalData;
}

export const AccordionHeading = ({
  title,
  isOpen,
  handleToggleOpen,
  handleDelete,
  confirmModalProps,
  headingType,
  color,
  ...props
}: AccordionHeadingProps): ReactElement => {
  const { useConfirmModal } = useContext(ConfirmModalContext);

  const handleDeleteClick = async () => {
    const confirmed = await useConfirmModal(confirmModalProps || { title: 'Delete Unit' });
    if (confirmed && handleDelete) handleDelete();
  };

  return (
    <Flex container justifyContent="space-between" alignItems="center" {...props}>
      {title && (
        <Heading type={headingType} color={color || 'blueA'}>
          {title}
        </Heading>
      )}
      <S.StyledIconsFlex isOpen={isOpen} color={color} container gap="2rem">
        <IconButton icon={Chevron} onClick={handleToggleOpen} />
        {handleDelete && <IconButton icon={Cross} onClick={handleDeleteClick} />}
      </S.StyledIconsFlex>
    </Flex>
  );
};
