export const getScrollPosition = (element: HTMLElement): number => {
  let top = element.offsetTop;
  let currentElement = element;

  while (currentElement.offsetParent) {
    currentElement = currentElement.offsetParent as HTMLElement;
    top += currentElement.offsetTop;
  }

  return top;
};
