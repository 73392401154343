import { IntlShape } from 'react-intl';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { Participant } from 'services/types/DashboardTypes';
import type { TableOptions, TableDataItems } from 'components/atoms/Table/Table.types';
import type { ModalData } from 'components/organisms/FormModal/FormModal';

export const getUserDetails = (
  tableData: TableOptions,
  rowIndex: number,
): { name: string; email: string; reminder_link: string; id: string } => {
  const rowData = tableData.rows[rowIndex];
  const name = rowData[1].copy as string;
  const email = rowData[2].copy as string;
  const reminder_link = rowData[5].data as string;
  const id = rowData[5].data as string;

  return { name, email, reminder_link, id };
};

interface PersonModalParams {
  defaultName?: string;
  defaultEmail?: string;
  assessors?: Array<{ name: string; email: string }>;
}

export const personModal = ({
  assessors,
  defaultName,
  defaultEmail,
}: PersonModalParams): ModalData => ({
  title: 'Add assessor',
  confirmCopy: 'Add',
  items: [
    {
      type: 'input',
      registerFormItemProps: {
        registerErrors: 'required',
        name: 'name',
        label: 'Name',
        color: 'greyA',
      },
      itemProps: {
        defaultValue: defaultName,
        hasRef: true,
      },
    },
    {
      type: 'input',
      registerFormItemProps: {
        registerErrors: ['required', { type: 'pattern', key: 'emailPattern' }],
        name: 'email',
        label: 'Email',
        color: 'greyA',
      },
      itemProps: {
        defaultValue: defaultEmail,
        hasRef: true,
      },
    },
    {
      type: 'input',
      registerFormItemProps: {
        name: 'confirm-email',
        label: 'Confirm email',
        color: 'greyA',
        registerErrors: [
          {
            type: 'validate',
            value: (confirmEmailValue: string, methods: UseFormReturn<FieldValues>) => {
              const emailValue = methods.getValues('email');

              if (emailValue !== confirmEmailValue) {
                return 'The Emails doesn’t match.';
              }

              if (assessors && assessors.some((assessor) => assessor.email === confirmEmailValue)) {
                return 'Email already in list.';
              }

              return true;
            },
          },
        ],
      },
      itemProps: {
        hasRef: true,
      },
    },
  ],
});

const getTableHead = (intl: IntlShape) => [
  intl.formatMessage({
    description: 'Dashboard - Tables - Number Title',
    defaultMessage: '#.',
  }),
  intl.formatMessage({
    description: 'Dashboard - Tables - Name Title',
    defaultMessage: 'Name',
  }),
  intl.formatMessage({
    description: 'Dashboard - Tables - Email Title',
    defaultMessage: 'Email',
  }),
  intl.formatMessage({
    description: 'Dashboard - Tables - Assessment Status Title',
    defaultMessage: 'Status',
  }),
];

const participantCompletedSelfAssessment = (participant: Participant) =>
  participant?.assessment &&
  !isEmpty(participant.assessment) &&
  participant.assessment.elements.every((element) =>
    element.questions.every((question) => !!question.self_answer || question.self_answer === 0),
  );

const getParticipantRow = (participant: Participant, index: number): TableDataItems => {
  return [
    {
      copy: `${index + 1}. `,
    },
    { copy: participant.name },
    { copy: participant.email },
    {
      status: participantCompletedSelfAssessment(participant)
        ? { type: 'finished', copy: 'Finished' }
        : { type: 'not_started', copy: 'Not Started yet' },
    },
    { data: participant.email },
    { data: participant.id },
  ];
};

export const getFormatedTableData = (
  participants: Array<Participant>,
  intl: IntlShape,
): TableOptions => {
  return {
    head: getTableHead(intl),
    columnOptions: [],
    controls: [],
    rows: participants.map((participant, index) => getParticipantRow(participant, index)),
  };
};
