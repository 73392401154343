import styled from 'styled-components';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Text } from 'components/atoms/Text/Text';
import { Chevron } from 'components/atoms/Icon/Icon';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Heading } from 'components/atoms/Heading/Heading';
import bgImage from 'assets/img/assessment/complete-bg.svg';
import bgImageMobile from 'assets/img/assessment/complete-bg-mobile.svg';

export const StyledLayout = styled(NormalLayout)`
  height: 100%;
`;

export const StyledCompleted = styled.div`
  height: 100%;
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background-size: cover;
  background-image: url(${bgImageMobile});
  background-position: bottom;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    background-image: url(${bgImage});
    background-position: right;
  }
`;

export const StyledFlex = styled(Flex)`
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0 ${({ theme }) => theme.sitePaddings.mobile};
`;

export const StyledHeading = styled(Heading)`
  @media ${respondTo(MediaQuery.MAX_1023)} {
    font-size: 6.4rem;
  }
`;

export const ContentWrapper = styled.div`
  @media ${respondTo(MediaQuery.MIN_1024)} {
    max-width: 59.6rem;
    margin-left: -15%;
  }
`;

export const TextWrapper = styled.div`
  width: 70%;
  margin-bottom: 4.6rem;
`;

export const BoldText = styled(Text)`
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;

export const StyledChevron = styled(Chevron)`
  transform: scaleX(-1);
  height: 0.9rem;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  @media ${respondTo(MediaQuery.MAX_1023)} {
    width: 100%;
  }
`;
