import type { ReactElement } from 'react';
// eslint-disable-next-line import/no-unresolved
import { TabsProps } from 'globalTypes';
import * as S from './CardTabs.styles';
import { Flex, FlexContainerProps } from 'components/atoms/Flex/FlexContainer';
import { useDraggableElement } from 'hooks/useDragglableElement';

interface CardTabsProps extends TabsProps, FlexContainerProps, S.TabStyleProps {}

export const CardTabs = ({
  tabs,
  active = 0,
  variant,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActive = () => {},
  ...props
}: CardTabsProps): ReactElement => {
  const { draggableRef } = useDraggableElement({ watch: [tabs] });

  return (
    <S.SliderWrapper>
      <S.StyledTabsContainer ref={draggableRef}>
        <Flex container {...props}>
          {tabs.map((tab, index) => (
            <S.StyledTab
              key={tab}
              onClick={() => setActive(index)}
              isActive={active === index}
              variant={variant}
            >
              {tab}
            </S.StyledTab>
          ))}
        </Flex>
      </S.StyledTabsContainer>
    </S.SliderWrapper>
  );
};
