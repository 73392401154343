import { AnimationControls, useAnimation } from 'framer-motion';
import React, { useCallback } from 'react';

export const useMotionTrigger = <T extends HTMLElement>(
  threshold: number | Array<number> = 0.5,
): { controls: AnimationControls; rootRef: React.RefCallback<T> } => {
  const controls = useAnimation();

  const transitionAnimation = useCallback(
    (entries: Array<IntersectionObserverEntry>) => {
      if (entries[0].isIntersecting) controls.start('animate');
    },
    [controls],
  );

  const rootRef = useCallback(
    (node) => {
      if (!node) return;
      const observer = new IntersectionObserver(transitionAnimation, {
        root: null,
        threshold,
      });

      observer.observe(node);
    },
    [threshold, transitionAnimation],
  );

  return {
    controls,
    rootRef,
  };
};
