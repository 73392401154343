export const userRoles = {
  superAdmin: 'super-admin',
  clientAdmin: 'client-admin',
  participant: 'participant',
  assessor: 'assessor',
} as const;

export class User {
  public id?: string;
  public name?: string;
  public email?: string;
  public roles?: Array<{ name: typeof userRoles }>;
}
