import { ChangeEvent, useRef, ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import * as S from './UploadProfilePicture.styles';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { setErrorEvent } from 'redux/ducks/eventsDuck/eventsActions';
import { EditLarge } from 'components/atoms/Icon/Icon';

interface UploadProfilePictureProps {
  allowedExtensions: Array<string>;
  onImageUpload: (imageBase64?: string) => void;
  disabled?: boolean;
}

export const UploadProfilePicture = ({
  allowedExtensions,
  onImageUpload,
  disabled,
}: UploadProfilePictureProps): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const validateImageExtension = (extension: string) => {
    if (!allowedExtensions.includes(extension.toLowerCase())) {
      throw Events.PICTURE_INVALID_EXTENSION;
    }
  };

  const onImageLoad = async (reader: FileReader) => {
    const imageBase64 = reader.result;
    onImageUpload(imageBase64?.toString());
  };

  const onFileUpload = ({ target }: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!target.files) throw Events.FORM_REQUEST_ERROR;
      const file = target.files[0];

      const splittedName = file.name.split('.').filter(Boolean);
      const extension = splittedName[splittedName.length - 1];
      validateImageExtension(extension);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => onImageLoad(reader);
    } catch (error) {
      const eventError = error as Events;

      if (Object.values(Events).includes(eventError)) {
        dispatch(setErrorEvent(eventError));
      } else {
        dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
      }
    }
  };

  return (
    <>
      <S.EditInput
        ref={inputRef}
        onChange={onFileUpload}
        type="file"
        accept={allowedExtensions.map((extension) => `.${extension}`).join(',')}
        disabled={disabled}
      />
      <S.EditButton aria-label="Edit Profile Image" onClick={handleUploadClick}>
        <EditLarge />
      </S.EditButton>
    </>
  );
};
