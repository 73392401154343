/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Element, Cohort, Participant } from 'services/types/DashboardTypes';

interface DashboardProps {
  cohorts: Array<Cohort>;
  currentCohortIndex: number;
  currentParticipantIndex: number;
}

const initialState: DashboardProps = {
  cohorts: [],
  currentCohortIndex: 0,
  currentParticipantIndex: 0,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setCohorts: (state, action) => {
      state.cohorts = action.payload;
    },
    setCurrentCohortIndex: (state, action) => {
      state.currentCohortIndex = action.payload;
    },
    setCurrentParticipantIndex: (state, action) => {
      state.currentParticipantIndex = action.payload;
    },
    setParticipant: (state, action) => {
      const { cohorts, currentCohortIndex } = state;
      const dashboard = cohorts[currentCohortIndex];
      const newParticipantIndex = dashboard.cohort_survey.participants.findIndex(
        (participant) => participant.id === action.payload,
      );

      if (!newParticipantIndex && newParticipantIndex !== 0) return;

      state.currentParticipantIndex = newParticipantIndex;
    },
    completeMission: (state, action) => {
      const { cohorts } = state;
      if (!cohorts) return;

      const newCohorts = cohorts.map(({ cohort_survey, ...cohort }) => {
        const { survey } = cohort_survey;
        const { objective } = survey;

        if (!objective) return { cohort_survey, ...cohort };

        const newObjective = {
          ...objective,
          missions: objective?.missions.map((mission) =>
            mission.id === action.payload
              ? { ...mission, is_completed: !mission.is_completed }
              : mission,
          ),
        };

        return {
          cohort_survey: { ...cohort_survey, survey: { ...survey, objective: newObjective } },
          ...cohort,
        };
      });

      state.cohorts = newCohorts;
    },
  },
});

export const selectCurrentCohort = ({
  cohorts,
  currentCohortIndex,
}: DashboardProps): Cohort | undefined => {
  return cohorts[currentCohortIndex];
};

export const selectCurrentParticipant = (state: DashboardProps): Participant | undefined =>
  selectCurrentCohort(state)?.cohort_survey.participants
    ? selectCurrentCohort(state)?.cohort_survey.participants[state.currentParticipantIndex]
    : undefined;

export const selectAreEnoughAssessors = (state: DashboardProps): boolean => {
  const participant = selectCurrentParticipant(state);
  if (!participant) return false;

  return (
    !!participant.assessment &&
    participant.assessment.assessors &&
    participant.assessment.assessors.filter((assessor) => assessor.is_completed).length > 2
  );
};

export const selectCurrentElements = (state: DashboardProps): Array<Element> | undefined => {
  const participant = selectCurrentParticipant(state);

  return participant?.assessment?.elements;
};

export const assessmentIsSelfAnswered = (state: DashboardProps): boolean =>
  !!selectCurrentElements(state)?.some((element) => element.questions);

export const dashboardActions = {
  ...dashboardSlice.actions,
};

export default dashboardSlice.reducer;
