import './util/instrument';
import { AnimatePresence } from 'framer-motion';
import { ReactElement, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Loading } from './components/atoms/Loading/Loading';
import { Error } from './components/organisms/Error/Error';
import { ErrorBoundary } from './components/util/ErrorBoundary/ErrorBoundary';
import { Tracking } from './components/util/Tracking/Tracking';
import { Routes } from './routes/Routes';
import { Toast } from './components/atoms/Toast/Toast';

const App = (): ReactElement => {
  return (
    <>
      <Toast />
      <Tracking />
      <Router>
        <AnimatePresence exitBeforeEnter>
          <ErrorBoundary handler={Error}>
            <Suspense fallback={<Loading />}>
              <Routes />
            </Suspense>
          </ErrorBoundary>
        </AnimatePresence>
      </Router>
    </>
  );
};

export default App;
