/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SelectItem } from 'components/molecules/Select/Select';
import { CountryService } from 'services/CountryService';

type Nationality = { id: number; description: string };

const fetchNationalities = createAsyncThunk('/nationalities/fetchNationalities', async () =>
  CountryService.getNationalities(),
);

const initialState: {
  nationalities: Array<Nationality>;
  adaptedNationalities: Array<SelectItem>;
  loading: boolean;
} = {
  nationalities: [],
  adaptedNationalities: [],
  loading: false,
};

const nationalitiesSlice = createSlice({
  name: 'nationalities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNationalities.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNationalities.fulfilled, (state, action) => {
        const nationalities = action.payload;
        state.nationalities = nationalities;
        state.adaptedNationalities = nationalities.map((nationality) => ({
          title: nationality.description,
          value: nationality.id,
        }));
        state.loading = false;
      });
  },
});

export const nationalitiesActions = {
  ...nationalitiesSlice.actions,
  fetchNationalities,
};

export default nationalitiesSlice.reducer;
