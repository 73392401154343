import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledScrollDownArrow = styled.div`
  position: fixed;
  bottom: 18rem;
  left: 0;
  color: ${({ theme }) => theme.color.blueA};
  display: none;

  &.absolute {
    position: absolute;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    display: block;
  }

  @media ${respondTo(MediaQuery.MIN_1280)} {
    left: 6.7rem;
  }

  svg {
    height: 3rem;
    transform: rotate(90deg);
  }
`;
