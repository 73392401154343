/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild, FormItemContainerBasicProps } from 'globalTypes';
import { memo, ReactElement, useEffect, useState } from 'react';
import type { FieldValues, UseFormRegister, UseFormReturn } from 'react-hook-form';
import { ElementContent } from '../ElementContent/ElementContent';
import { AccordionHeading } from 'components/molecules/AccordionHeading/AccordionHeading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Separator } from 'components/atoms/Separator/Separator';

interface ElementProps extends FormItemContainerBasicProps, RegisterFormItemChild {
  id: number;
  name: string;
  title: string;
  onDelete: () => void;
  register: boolean | UseFormRegister<FieldValues>;
  formMethods?: UseFormReturn;
}

// eslint-disable-next-line no-empty-pattern
export const Element = memo(
  ({ name, id, title, onDelete, formMethods, ...props }: ElementProps): ReactElement => {
    const [isopen, setIsOpen] = useState(true);

    useEffect(() => {
      formMethods?.setValue(`${name}.element`, title);

      return () => {
        formMethods?.unregister(name);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <div>
        <AccordionHeading
          title={title}
          isOpen={isopen}
          handleToggleOpen={() => setIsOpen(!isopen)}
          handleDelete={onDelete}
          headingType={HeadingType.H6}
          color="black"
          confirmModalProps={{
            title: 'Delete element',
            subtitle: title,
            description: 'Are you sure you want to delete this element?',
          }}
        />
        <Separator margin="3rem 0 4rem" color="greyE" />

        <div style={{ display: isopen ? 'block' : 'none' }}>
          <ElementContent {...props} name={name} formMethods={formMethods} />
        </div>
      </div>
    );
  },
);
