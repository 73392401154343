import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { CloseButton } from 'components/molecules/CloseButton/CloseButton';
import { size } from 'styles/helpers/size';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export interface ModalStyleProps {
  variant?: 'fullscreen';
}

export const StyledModal = styled(motion.div)<ModalStyleProps>`
  background: ${({ theme }) => theme.color.cream};
  max-height: calc(100% - 20rem);
  border-radius: 1.6rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;

  ${({ variant, theme }) =>
    variant === 'fullscreen' &&
    css`
      width: calc(100vw - (${theme.sitePaddings.mobile} * 2));
      height: calc(100vh - ${theme.sitePaddings.mobile} * 2);
      max-height: unset;

      @media ${respondTo(MediaQuery.MIN_1024)} {
        width: calc(100vw - (${theme.sitePaddings.desktop} * 2));
        height: 85vh;
      }
    `}
`;

export const StyledContent = styled.div<{ $noscroll?: boolean | undefined; $size?: 'small' }>`
  max-height: 100%;
  height: 100%;
  position: relative;
  overflow-y: ${({ $noscroll }) => ($noscroll ? 'hidden' : 'auto')};
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.color.greyA} ${({ theme }) => theme.color.cream};

  ${({ $size }) =>
    $size === 'small'
      ? `
      height: 60vh;
      max-height: 70.5rem;
      min-width: 90vw;
      padding: 7rem 2rem;

      @media ${respondTo(MediaQuery.MIN_768)} {
        min-width: 61rem;
        height: 70vh;
        padding: 7rem 5.5rem;
      }
      `
      : `
      padding: 2.3rem;

      @media ${respondTo(MediaQuery.MIN_768)} {
        padding: 5.5rem;
      }

      @media ${respondTo(MediaQuery.MIN_1024)} {
        padding: 10.5rem;
      }
  `}

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.color.greyD};
    margin: 2.5rem 0;

    @media ${respondTo(MediaQuery.MIN_768)} {
      margin: 5rem 0;
    }
    @media ${respondTo(MediaQuery.MIN_1024)} {
      margin: 8rem 0;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.color.greyA};
  }
`;

export const StyledCloseButton = styled(CloseButton)`
  ${size('3.2rem')};
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border-radius: 50%;
  right: 2rem;
  top: 2rem;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.cream};
`;
