import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Chevron } from 'components/atoms/Icon/Icon';

export const StyledCreateObjectiveForm = styled.div`
  padding: 3rem 0 8rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    padding: 6rem 0 15.5rem;
  }

  & > *:not(:last-child) {
    margin-bottom: 4rem;

    @media ${respondTo(MediaQuery.MIN_768)} {
      margin-bottom: 8rem;
    }
  }
`;

export const StyledInputsFlex = styled(Flex)`
  & > * {
    flex-basis: 50%;

    @media ${respondTo(MediaQuery.MAX_767)} {
      margin-bottom: 3rem;
    }
  }

  @media ${respondTo(MediaQuery.MIN_1280)} {
    & > * {
      flex-basis: 40%;
    }
  }
`;

export const StyledChevron = styled(Chevron)`
  transform: rotate(180deg);
  height: 1rem;
`;
