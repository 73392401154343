import type { TableOptions } from 'components/atoms/Table/Table.types';

export const tableData: TableOptions = {
  head: ['No.', 'Name', 'Description', 'Creation Date', ' '],
  controls: [{ type: 'RemoveOrAdd', action: 'RemoveOrAdd' }],
  columnOptions: {
    0: { width: '6%' },
    1: { width: '14%' },
    2: { width: '30%' },
    3: { width: '42%' },
    4: { width: '4rem' },
    5: { width: '4rem' },
  },
  rows: [],
};

export const createQuestiontableData: TableOptions = {
  head: ['No.', 'Name', 'Description', 'Creation Date', 'Open Question', 'Add/Remove'],
  controls: [
    { type: 'Edit', action: 'Edit' },
    { type: 'Delete', action: 'Delete' },
  ],
  columnOptions: {
    0: { width: '6%' },
    1: { width: '14%' },
    2: { width: '30%' },
    3: { width: '21%' },
    4: { width: '21%' },
    5: { width: '4rem' },
    6: { width: '4rem' },
  },
  rows: [],
};
