/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { Dispatch } from 'redux';
import * as types from './elementsTypes';
import { ReduxActionCreator, Action } from 'redux/types';
import { ApiClient } from 'util/apiClient';
import { ActionResponse } from 'data/enum/ActionResponse';

type UpdateElementBody = {
  id?: number | undefined;
  name: string;
  description: string;
  allow_no_answer?: boolean;
  set_as_open?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  element_type_id?: number;
};

// actions
export const getElements: ReduxActionCreator = () => async (dispatch, getState) => {
  try {
    const data = await ApiClient.get('/elements?element_type_id=1');
    dispatch({
      type: types.GET_ELEMENTS_SUCCESS,
      payload: data.data,
    });
    return data;
  } catch (error) {
    return null;
  }
};

export const postElements =
  (body: UpdateElementBody, onPostSuccess: () => void) => async (dispatch: Dispatch<Action>) => {
    try {
      const { data } = await ApiClient.post(`/elements`, body);
      dispatch({
        type: types.ADD_ELEMENTS_SUCCESS,
        payload: data,
      });

      onPostSuccess();
      return ActionResponse.SUCCESS;
    } catch (error) {
      return ActionResponse.ERROR;
    }
  };

export const getElementsWithId = (id: number) => async (dispatch: Dispatch) => {
  try {
    const { data } = await ApiClient.get(`/elements/${id}`);
    dispatch({
      type: types.GET_ELEMENT_SUCCESS,
      payload: data,
    });
    return ActionResponse.SUCCESS;
  } catch (error) {
    return ActionResponse.ERROR;
  }
};

export const updateElements =
  (id: number, body: UpdateElementBody) => async (dispatch: Dispatch) => {
    try {
      const { data } = await ApiClient.put(`/elements/${id}`, body);
      dispatch({
        type: types.UPDATE_ELEMENT_SUCCESS,
        payload: [data],
      });
      return ActionResponse.SUCCESS;
    } catch (error) {
      return ActionResponse.ERROR;
    }
  };

export const deleteElementWithId = (id: number) => async (dispatch: Dispatch) => {
  try {
    const { data } = await ApiClient.delete(`/elements/${id}`);
    dispatch({
      type: types.DELETE_ELEMENT_SUCCESS,
      payload: data,
    });

    // refresh page
    window.location.reload();

    return ActionResponse.SUCCESS;
  } catch (error) {
    return ActionResponse.ERROR;
  }
};
