type BehaviorType = 'smooth' | 'auto';

const OFFSET = 100;

export const scrollToElement = (
  element: HTMLElement | null,
  behavior: BehaviorType = 'smooth',
): void => {
  if (element == null) {
    throw new ReferenceError('element is empty');
  }

  const scrollTop = window.pageYOffset || element.scrollTop;
  const rectTop = element.getBoundingClientRect().top;

  const top = rectTop + scrollTop - OFFSET;

  window.scrollTo({ top, behavior });
};
