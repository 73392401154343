import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  createPasswordButton: {
    description: 'Login - Form - Create Password Button Label',
    defaultMessage: 'Create password',
  },
  createPasswordDescription: {
    description: 'Login - Form - Create Password Description',
    defaultMessage:
      'After creating your password, you’ll be redirected to the log in screen to log in.',
  },
  enterEmail: {
    description: 'Login - Form - Email Placeholder',
    defaultMessage: 'Enter your email address',
  },
  emailLabel: {
    description: 'Login - Form - Email Label',
    defaultMessage: 'Email',
  },
  enterEmailAdress: {
    description: 'Login - Form - EmailAdress',
    defaultMessage: 'Email',
  },
  enterEmailAdressPlaceholder: {
    description: 'Login - Form - EmailAdress Placeholder',
    defaultMessage: 'Enter your email address',
  },
  enterPassword: {
    description: 'Login - Form - Enter Password Placeholder',
    defaultMessage: 'Enter your password',
  },
  enterNewPassword: {
    description: 'Login - Form - Enter New Password Placeholder',
    defaultMessage: 'Enter your new password',
  },
  confirmNewPassword: {
    description: 'Login - Form - Confirm New Password Placeholder',
    defaultMessage: 'Confirm your new password',
  },
  passwordLabel: {
    description: 'Login - Form - Password Label',
    defaultMessage: 'Password',
  },
  newPasswordLabel: {
    description: 'Login - Form - New Password Label',
    defaultMessage: 'New Password',
  },
  confirmPasswordLabel: {
    description: 'Login - Form - Confirm Password Label',
    defaultMessage: 'Confirm Password',
  },
  termsConditions: {
    description: 'Login - Form - Terms Conditions Blurb',
    defaultMessage:
      "THNK is committed to protecting and respecting your privacy. We don't share your data without your consent. I agree to the",
  },
  termsConditionsTermsLink: {
    description: 'Login - Form - Terms Conditions link',
    defaultMessage: 'Terms & Conditions',
  },
  termsConditionsSeparator: {
    description: 'Login - Form - Terms Conditions separator',
    defaultMessage: 'and',
  },
  termsConditionsPrivacyLink: {
    description: 'Login - Form - Terms Conditions - Privacy link',
    defaultMessage: 'Privacy Policy',
  },
  termsConditionsModalTitle: {
    description: 'Login - Terms Conditions Modal Title',
    defaultMessage: 'Terms and conditions',
  },
  termsConditionsModalContent: {
    description: 'Login - Terms Conditions Modal Content',
    defaultMessage:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus velit vel enim malesuada auctor. Curabitur vestibulum aliquam lorem. Suspendisse tristique massa et dolor gravida, hendrerit elementum diam fermentum. Vestibulum nec turpis at',
  },
  privacyPolicysModalTitle: {
    description: 'Login - Privacy Policy Modal Title',
    defaultMessage: 'Privacy Policy',
  },
  privacyPolicyModalContent: {
    description: 'Login - Privacy Policy Modal Content',
    defaultMessage:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In tempus velit vel enim malesuada auctor. Curabitur vestibulum aliquam lorem. Suspendisse tristique massa et dolor gravida, hendrerit elementum diam fermentum. Vestibulum nec turpis at',
  },
  requiredInputError: {
    description: 'Login - Form - Required Input Error',
    defaultMessage: 'This field is required.',
  },
  maxLengthInputError: {
    description: 'Login - Form - Max Length Input Error',
    defaultMessage: 'Please enter less than {maxLength} characters.',
  },
  invalidEmailInputError: {
    description: 'Login - Form - Invalid Email Input Error',
    defaultMessage: 'Enter a valid e-mail address.',
  },
  sendLinkButton: {
    description: 'Login - Form - Send Link Button Label',
    defaultMessage: 'Send Link',
  },
  reSendLinkButton: {
    description: 'Login - Form - Resend Link Button Label',
    defaultMessage: 'Resend Link',
  },
  cancelButton: {
    description: 'Login - Form - Cancel Button Label',
    defaultMessage: 'Cancel',
  },
  resetPasswordButton: {
    description: 'Login - Form - Reset Password Button Label',
    defaultMessage: 'Reset Password',
  },
  backToLoginButton: {
    description: 'Login - Form - Back to Login Button Label',
    defaultMessage: 'Back to Login',
  },
  resetPasswordConfirmation: {
    description: 'Login - Form - Reset Password Confirmation Message',
    defaultMessage:
      '<p>A link has been sent to your email to reset your password</p><p>If you have not received the link in {seconds} secs, try resending it</p>',
  },
  resetPasswordDescription: {
    description: 'Login - Form - Reset Password Description',
    defaultMessage:
      'After reseting your password, a verification email will be sent to your inbox.',
  },
});
