import { Cohort } from './Cohort';

export class OrganizationSubunit {
  public id: number = 0;
  public description?: string;
  public cohorts: Array<Cohort> = [];

  public addCohort(cohort: Cohort): void {
    if (cohort.id !== undefined) {
      this.cohorts.push(cohort);
    } else {
      const newCohort = cohort;
      newCohort.id = this.cohorts.length;
      this.cohorts.push(newCohort);
    }
  }

  public removeCohortById(cohortId: number): void {
    this.cohorts = this.cohorts.filter((cohort) => cohort.id !== cohortId);
  }

  public getCohortById(cohortId: number): Cohort {
    const cohort = this.cohorts.find((cohort) => cohort.id === cohortId);
    if (!cohort) throw new Error('Cohort given id not found');

    return cohort;
  }
}
