import * as types from './elementsTypes';
import { ReduxReducer } from 'redux/types';

export type ElementProps = {
  id: number;
  name: string;
  description: string;
  allow_no_answer: boolean;
  set_as_open: true;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  element_type_id: number;
};

type ElementInitialStateProps = {
  elements: Array<ElementProps>;
  currentElement: ElementProps | null;
  loading: boolean;
  loadingCurrentElement: boolean;
  showElementsError: string | null;
};

// constants
const initialState: ElementInitialStateProps = {
  elements: [],
  currentElement: null,
  loading: false,
  loadingCurrentElement: false,
  showElementsError: null,
};

// reducers
export const elementsReducer: ReduxReducer<typeof initialState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.GET_ELEMENTS_REQUEST:
      return { ...state, loading: true };
    case types.GET_ELEMENTS_SUCCESS:
      return { ...state, elements: action.payload, loading: false };
    case types.GET_ELEMENTS_FAILURE:
      return { ...state, loading: false, showElementsError: action.payload };

    case types.GET_ELEMENT_REQUEST:
      return { ...state, loading: true };
    case types.GET_ELEMENT_SUCCESS:
      return { ...state, currentElement: action.payload };
    case types.GET_ELEMENT_FAILURE:
      return { ...state, loading: false, showElementsError: action.payload };

    case types.ADD_ELEMENTS_REQUEST:
      return { ...state, loading: true };
    case types.ADD_ELEMENTS_SUCCESS:
      return { ...state, currentElement: action.payload };
    case types.ADD_ELEMENTS_FAILURE:
      return { ...state, loading: false, showElementsError: action.payload };

    case types.UPDATE_ELEMENT_REQUEST:
      return { ...state, loading: true };
    case types.UPDATE_ELEMENT_SUCCESS:
      return { ...state, elements: action.payload, loading: false };
    case types.UPDATE_ELEMENT_FAILURE:
      return { ...state, loading: false, showElementsError: action.payload };

    case types.DELETE_ELEMENT_REQUEST:
      return { ...state, loading: true };
    case types.DELETE_ELEMENT_SUCCESS:
      return { ...state, elements: state.elements, loading: false };
    case types.DELETE_ELEMENT_FAILURE:
      return { ...state, loading: false, showElementsError: action.payload };

    default:
      return state;
  }
};
