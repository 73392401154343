import type { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { LoadingTheme } from './Loading.styles';
import * as S from './Loading.styles';
import { HeadingType } from '../Heading/Heading.data';

interface LoadingProps {
  variant?: LoadingTheme;
  message?: ReactNode;
}

export const Loading = ({
  message,
  variant = LoadingTheme.Dark,
  ...props
}: LoadingProps): ReactElement => {
  return (
    <S.StyledLoading {...props} $variant={variant}>
      <S.LoadingContent>
        {message && <S.StyledHeading type={HeadingType.H2}>{message}</S.StyledHeading>}

        <S.LoaderWrapper>
          <S.StyledParagraph>
            <FormattedMessage description="Loading" defaultMessage="Loading" />
          </S.StyledParagraph>

          <S.Loader />
        </S.LoaderWrapper>
      </S.LoadingContent>
    </S.StyledLoading>
  );
};
