import { ApiClient } from '../util/apiClient';
import { SurveyElementsResponse, SurveyResponse } from 'services/types/SurveyTypes';
import { SurveyDataProps } from 'redux/ducks/surveysDuck/surveysDuckTypes';

enum SurveyEndpoints {
  Surveys = 'surveys',
  CohortSurvey = 'cohorts-surveys',
  SurveysElements = 'surveys-elements',
  SurveyElementChallenges = 'element-challenges',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createSurvey = (body: SurveyDataProps): Promise<any> => {
  return ApiClient.post(`/${SurveyEndpoints.Surveys}`, body);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getSurvey = async (cohortSurveyId: string): Promise<any> => {
  const { data } = await ApiClient.get(`/${SurveyEndpoints.CohortSurvey}/${cohortSurveyId}`);
  return data;
};

const getSurveys = async (): Promise<Array<SurveyResponse>> => {
  const { data } = await ApiClient.get(`/${SurveyEndpoints.Surveys}`);
  return data;
};

const getSurveyElements = async (surveyId: string): Promise<SurveyElementsResponse> => {
  const { data } = await ApiClient.get(`/${SurveyEndpoints.SurveysElements}/${surveyId}`);
  return data;
};

export const SurveyService = {
  createSurvey,
  getSurvey,
  getSurveys,
  getSurveyElements,
  SurveyEndpoints,
};
