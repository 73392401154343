import type { ReactElement } from 'react';
import * as S from './NoResults.styles';

interface NoResultsProps {
  title: string;
  subtitle?: string;
  hasMinHeight?: boolean;
}

export const NoResults = ({ title, subtitle, hasMinHeight }: NoResultsProps): ReactElement => {
  return (
    <S.NoResults $hasMinHeight={hasMinHeight}>
      <S.StyledTitleNoResult>
        {title}
        <S.StyledSubtitleNoResult>{subtitle}</S.StyledSubtitleNoResult>
      </S.StyledTitleNoResult>
    </S.NoResults>
  );
};
