import { ReactElement, useMemo } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import * as S from './ChangePassword.styles';
import { Input } from 'components/atoms/Input/Input';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { changePasswordFormSchema } from 'components/molecules/Form/FormSchemas';
import { setErrorEvent, setSuccessEvent } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { SessionService } from 'services/SessionService';

interface ChangePasswordProps {
  onHideChangePassword: () => void;
}

export const ChangePassword = ({ onHideChangePassword }: ChangePasswordProps): ReactElement => {
  const formMethods = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(changePasswordFormSchema),
    defaultValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    } as FieldValues,
  });

  const dispatch = useDispatch();

  const fieldsAreFilled = useMemo(
    () => Object.keys(formMethods.formState.dirtyFields).length < 3,
    [formMethods.formState],
  );

  const handleFormSuccess = async (formData: FieldValues) => {
    try {
      const { old_password, new_password, confirm_password } = formData;
      await SessionService.changePassword({
        old_password,
        password: new_password,
        password_confirmation: confirm_password,
      });

      onHideChangePassword();
      dispatch(setSuccessEvent(Events.FORM_SUCCESS));
    } catch (error) {
      dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
    }
  };

  return (
    <S.StyledForm customMethods={formMethods} onSuccess={handleFormSuccess}>
      <RegisterFormItem
        register
        registerErrors="required"
        name="old_password"
        color="greyA"
        label="Your current password"
      >
        <Input type="password" hasRef />
      </RegisterFormItem>

      <RegisterFormItem
        register
        registerErrors="required"
        name="new_password"
        color="greyA"
        label="New password"
      >
        <Input type="password" hasRef />
      </RegisterFormItem>

      <RegisterFormItem
        register
        registerErrors="required"
        name="confirm_password"
        color="greyA"
        label="Repeat new password"
      >
        <Input type="password" hasRef />
      </RegisterFormItem>

      <S.StyledButton
        type="submit"
        $size="regular"
        $outline
        disabled={fieldsAreFilled}
        $disabledBackgroundColor="greyA"
        $disabledColor="greyA"
      >
        Confirm New Password
      </S.StyledButton>
    </S.StyledForm>
  );
};
