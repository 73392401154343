/* eslint-disable unicorn/error-message */
import { Customer } from '../../model/Customer';
// import { BusinessTypeAdapter } from 'services/adapters/BusinessTypeAdapter';

type CreateCustomerDTO = {
  name: string;
  logo: string;
  B2B_B2C: number;
  organization_size: number;
  organizational_type_id: number;
  industry_id: number;
  function_id: number;
};

export const createCustomerDTO = (customer: Customer): CreateCustomerDTO => {
  // TODO: Validate on customer model
  if (!customer.name) throw new Error('Missing name field');
  if (!customer.logo) throw new Error('Missing logo field');
  if (customer.B2B_B2C === undefined) throw new Error('Missing business type field');
  if (customer.organization_size === undefined) throw new Error('Missing size field');
  if (!customer.organizational_type_id) throw new Error('Missing organizational type field');
  if (!customer.industry_id) throw new Error('Missing client industry field');
  if (!customer.function_id) throw new Error('Missing client function field');

  const createCustomerDto: CreateCustomerDTO = {
    name: customer.name,
    logo: customer.logo,
    B2B_B2C: customer.B2B_B2C,
    organization_size: customer.organization_size,
    organizational_type_id: customer.organizational_type_id,
    industry_id: customer.industry_id,
    function_id: customer.function_id,
  };

  return createCustomerDto;
};
