import styled from 'styled-components';
import { PaginationButtons } from 'components/molecules/PaginationButtons/PaginationButtons';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { Heading } from 'components/atoms/Heading/Heading';

export const StyledRightSide = styled.div`
  padding: 0 ${({ theme }) => theme.sitePaddings.mobile} 0;
  margin-bottom: 2.6rem;
  position: relative;
  max-width: 100%;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding: 0;
    margin: 0;
  }
`;

export const StyledHeading = styled(Heading)`
  margin: 1.5rem 0 2.8rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin: 0 0 7.7rem;
  }
`;

export const StyledTitleHighlight = styled.span`
  display: block;
  color: ${({ theme }) => theme.color.redA};
`;

export const StyledPaginationButtons = styled(PaginationButtons)`
  position: absolute;
  top: 15.5rem;
  right: ${({ theme }) => theme.sitePaddings.mobile};
  z-index: 1;
  color: ${({ theme }) => theme.color.redA};
  background-color: ${({ theme }) => theme.color.cream};

  @media ${respondTo(MediaQuery.MIN_1024)} {
    display: none;
  }
`;

export const StyledCarouselViewport = styled.div`
  overflow: hidden;
`;

export const StyledCarousel = styled.div`
  display: flex;
`;

export const StyledLine = styled.div`
  width: 100%;
  height: 0.1rem;
  background: ${({ theme }) => theme.color.redD};
  position: absolute;
  top: 21.35rem;
  left: ${({ theme }) => theme.sitePaddings.mobile};

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: 100%;
    top: 28.78rem;
    left: 2px;
  }
`;
