import styled, { css } from 'styled-components';
import { IconButton } from '../IconButton/IconButton';
import { ColorKey } from 'styles/theme/default';

export interface PaginationButtonsSyleProps {
  color?: ColorKey;
  direction?: 'column';
  size?: 'small' | 'medium';
}

export const StyledPaginationButtons = styled.div<PaginationButtonsSyleProps>`
  display: flex;
  color: ${({ theme, color }) => color && theme.color[color || 'blueA']};

  button {
    width: 4.5rem;
    height: 4.5rem;
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      button {
        width: 3.2rem;
        height: 3.2rem;

        svg {
          height: 0.8rem;
          width: 0.6rem;
        }
      }
    `}

  ${({ size }) =>
    size === 'medium' &&
    css`
      button {
        width: 4rem;
        height: 4rem;
      }
    `}

  ${({ direction, size }) =>
    direction === 'column'
      ? css`
          flex-direction: column;

          & > button:first-of-type {
            svg {
              transform: rotate(90deg);
            }
          }

          & > button:last-of-type {
            margin-top: ${size === 'small' ? '0.8rem' : '1rem'};

            svg {
              transform: rotate(270deg);
            }
          }
        `
      : css`
          & > button:last-of-type {
            margin-left: ${size === 'small' ? '0.8rem' : '1rem'};

            svg {
              transform: rotate(180deg);
            }
          }
        `}
`;

export const StyledCount = styled.div`
  font-size: 1.4rem;
  text-align: center;
  margin-top: 1.2rem;
`;

export const StyledPaginationButton = styled(IconButton)<{ disabled?: boolean }>`
  border-radius: 50%;
  border: 1px solid currentColor;
  color: ${({ theme, disabled }) => (disabled ? theme.color.greyA : 'currentColor')};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  opacity: ${({ disabled }) => disabled && '0.6'};
  transition: opacity 0.4s ease, color 0.4s ease, border-color 0.4s ease, transform 0.3s ease;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:active {
    transform: scale(0.8);
  }
`;
