export const createQuestionModalOptions = {
  title: 'Create new question',
  cancelCopy: 'Leave without saving',
  confirmCopy: 'create question',
  items: [
    {
      type: 'input',
      registerFormItemProps: {
        name: 'name',
        label: 'Question Name',
        color: 'greyA',
        registerErrors: [
          'required',
          {
            type: 'maxLength',
            key: 'customMaxLength',
            customValue: 50,
          },
        ],
      },
      itemProps: {
        placeholder: 'Add question name',
        hasRef: true,
      },
    },
    {
      type: 'textarea',
      registerFormItemProps: {
        name: 'description',
        label: 'Question Description',
        color: 'greyA',
        registerErrors: [
          'required',
          {
            type: 'maxLength',
            key: 'customMaxLength',
            customValue: 500,
          },
        ],
      },
      itemProps: {
        placeholder: 'Input question’s description',
        hasRef: true,
      },
    },
    {
      type: 'checkbox',
      registerFormItemProps: {
        name: 'set_as_open',
        label: 'Open',
        color: 'greyA',
        disableValidation: true,
      },
      itemProps: {
        parentCallback: (): null => {
          return null;
        },
      },
    },
  ],
};
