/* eslint-disable import/no-unresolved */
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RegisteredFormItem } from 'globalTypes';
import { ClientSelect } from '../ClientSelect/ClientSelect';
import { RootState } from 'redux/store';
import { industriesActions } from 'redux/ducks/industriesDuck/industriesReducer';
import { SelectItem } from 'components/molecules/Select/Select';

// eslint-disable-next-line @typescript-eslint/no-empty-interface

export const ClientIndustry = ({
  formMethods,
  register,
  errors,
  defaultValue,
}: RegisteredFormItem): ReactElement => {
  const dispatch = useDispatch();
  const { adaptedIndustries } = useSelector((state: RootState) => state.industries);
  const fieldName = 'customer.industry_id';

  const onSelect = ({ value }: SelectItem) => {
    formMethods.setValue(fieldName, value);
  };

  useEffect(() => {
    dispatch(industriesActions.fetchIndustries());
  }, [dispatch]);

  return (
    <ClientSelect
      formMethods={formMethods}
      register={register}
      errors={errors}
      items={adaptedIndustries}
      copy="Industry"
      name={fieldName}
      onSelect={onSelect}
      defaultValue={defaultValue}
    />
  );
};
