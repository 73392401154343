// Types

// Get all the elements
export const GET_ELEMENTS_REQUEST = 'GET_ELEMENTS_REQUEST';
export const GET_ELEMENTS_SUCCESS = 'GET_ELEMENTS_SUCCESS';
export const GET_ELEMENTS_FAILURE = 'GET_ELEMENTS_FAILURE';

// Store a newly created element in storage
export const ADD_ELEMENTS_REQUEST = 'POST_ELEMENTS_REQUEST';
export const ADD_ELEMENTS_SUCCESS = 'POST_ELEMENTS_SUCCESS';
export const ADD_ELEMENTS_FAILURE = 'POST_ELEMENTS_FAILURE';

// Get only one the element
export const GET_ELEMENT_REQUEST = 'GET_ELEMENT_REQUEST';
export const GET_ELEMENT_SUCCESS = 'GET_ELEMENT_SUCCESS';
export const GET_ELEMENT_FAILURE = 'GET_ELEMENT_FAILURE';

// Update the specified element in storage
export const UPDATE_ELEMENT_REQUEST = 'UPDATE_ELEMENT_REQUEST';
export const UPDATE_ELEMENT_SUCCESS = 'UPDATE_ELEMENT_SUCCESS';
export const UPDATE_ELEMENT_FAILURE = 'UPDATE_ELEMENT_FAILURE';

// Delete one element
export const DELETE_ELEMENT_REQUEST = 'DELETE_ELEMENT_REQUEST';
export const DELETE_ELEMENT_SUCCESS = 'DELETE_ELEMENT_SUCCESS';
export const DELETE_ELEMENT_FAILURE = 'DELETE_ELEMENT_FAILURE';
