import { ReactElement } from 'react';
// eslint-disable-next-line import/no-unresolved
import { TabsProps } from 'globalTypes';
import { TabsVariants } from './PillTabs.styles';
import * as S from './PillTabs.styles';
import { useTabsIndicator } from 'hooks/useTabsIndicator';

export const PillTabs = ({
  tabs,
  active = 0,
  color = 'blueA',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActive = () => {},
  ...props
}: TabsProps): ReactElement => {
  const { tabsContainerRef, tabsIndicatorRef } = useTabsIndicator({ active });

  return (
    <S.StyledPillTabs className="full-bleed" {...props}>
      <S.StyledTabsContainer color={color} ref={tabsContainerRef}>
        {tabs.map((tab, index) => (
          <S.StyledTab
            key={`tab-${tab}`}
            onClick={() => setActive(index)}
            variant={index === active && TabsVariants.Active}
          >
            {tab}
          </S.StyledTab>
        ))}
        <S.StyledTabIndicator ref={tabsIndicatorRef} />
      </S.StyledTabsContainer>
    </S.StyledPillTabs>
  );
};
