import { ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { escapeRegExp, values } from 'lodash';
import { ManagementTable } from '../../components/ManagementTable/ManagementTable';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import { tableData } from './ChallengesManagement.data';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Plus } from 'components/atoms/Icon/Icon';
import { Path } from 'routes/Path';
import { ConfirmModalContext } from 'components/organisms/ConfirmModal/ConfirmModal';
import { RootState } from 'redux/store';
import { TableOptions } from 'components/atoms/Table/Table.types';
import { setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { StorageItem } from 'data/enum/StorageItem';
import { challengesActions } from 'redux/ducks/challengesDuck/challengesReducer';
import { ActionMethod } from 'data/enum/ActionMethod';
import { ChallengeGetProps } from 'redux/ducks/challengesDuck/challengesTypes';
import { missionsActions } from 'redux/ducks/missionsDuck/missionsReducer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChallengesManagementProps {}

// eslint-disable-next-line no-empty-pattern
export const ChallengesManagement = ({}: ChallengesManagementProps): ReactElement => {
  const { useConfirmModal } = useContext(ConfirmModalContext);
  const { push } = useHistory();
  const { challenges } = useSelector((state: RootState) => state.challenges);
  const dispatch = useDispatch();

  const [filterQuery, setFilterQuery] = useState<string>();
  const [formattedTableData, setFormattedTableData] = useState<TableOptions>();

  /**
   * Create and update the data used to populate the table.
   */
  const createFormattedTableData = (data: Array<ChallengeGetProps>) => {
    const rows = data.map((item, index) => {
      const date = item.created_at && item.created_at.substring(0, 10);

      return [
        { copy: index + 1 },
        { copy: item.name },
        { copy: item.description },
        { copy: date },
        { data: item.id },
      ];
    });

    setFormattedTableData({ ...tableData, rows } as TableOptions);
  };

  /**
   * Filter and update table data elements by their name and description based on a filter string.
   * */
  const filterTableData = (data: Array<ChallengeGetProps>) => {
    const regex = new RegExp(`(${escapeRegExp(filterQuery?.toLowerCase())})`);

    const filtered = values(data).filter(
      (item) => regex.test(item.name.toLowerCase()) || regex.test(item.description.toLowerCase()),
    );

    createFormattedTableData(filtered);
  };

  const clearTemporaryChallenge = () => {
    localStorage.removeItem(StorageItem.TEMPORARY_CHALLENGE);
    dispatch(challengesActions.resetCurrentChallenge());
    dispatch(missionsActions.resetCurrentMissions());
  };

  const handleCreateChallengeClick = () => {
    clearTemporaryChallenge();
    push(Path.CreateChallenge);
  };

  const Delete = async (rowIndex: number) => {
    const rowData = formattedTableData && formattedTableData.rows[rowIndex];

    if (rowData) {
      const id = Number(rowData[rowData.length - 1].data);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const confirm = await useConfirmModal({
        title: 'Delete',
        subtitle: rowData[2].copy,
        description: 'Are you sure you want to delete this challenge?',
      });

      if (confirm) {
        dispatch(challengesActions.challengesApiHandler({ method: ActionMethod.DELETE, id }));
        dispatch(challengesActions.challengesApiHandler({ method: ActionMethod.GET }));

        dispatch(setSuccessEventAction(Events.FORM_DELETE_SUCCESS));
      }
    }
  };

  const Edit = async (rowIndex: number) => {
    clearTemporaryChallenge();
    const rowData = formattedTableData && formattedTableData.rows[rowIndex];

    if (rowData) {
      const id = Number(rowData[rowData.length - 1].data);
      push(Path.EditChallenge, {
        id,
      });
    }
  };

  useEffect(() => {
    dispatch(challengesActions.challengesApiHandler({ method: ActionMethod.GET }));
  }, [dispatch]);

  useEffect(() => {
    if (filterQuery === undefined) {
      createFormattedTableData(challenges || []);
    } else {
      filterTableData(challenges || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [challenges, filterQuery]);

  return (
    <>
      <AdminHeading
        title="Challenges Management"
        subtitle="Create and manage challenges"
        backButton={{
          title: 'Back to Management Tool',
          href: Path.ManagementTool,
        }}
      >
        <PrimaryButton $size="regular" onClick={handleCreateChallengeClick}>
          Create New Challenge <Plus />
        </PrimaryButton>
      </AdminHeading>

      {formattedTableData && (
        <ManagementTable
          data={formattedTableData}
          actions={{ Delete, Edit }}
          setFilterQuery={setFilterQuery}
          /*
          This will disable action buttons temporarily while some
          survey issues related to this actions are being fixed.
          */
          // @NOTE(gb) excuse me?
          // shouldDisableItem={() => true}
        />
      )}
    </>
  );
};
