import { ApiClient } from 'util/apiClient';

enum CountriesEndpoints {
  Countries = '/countries',
  Nationalities = '/nationalities',
}

const getCountries = async (): Promise<Array<{ id: number; description: string }>> => {
  const { data } = await ApiClient.get(CountriesEndpoints.Countries);
  return data;
};

const getNationalities = async (): Promise<Array<{ id: number; description: string }>> => {
  const { data } = await ApiClient.get(CountriesEndpoints.Nationalities);
  return data;
};

export const CountryService = { getCountries, getNationalities };
