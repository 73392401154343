import { ReactElement, useEffect, useState } from 'react';
import { AdminTable } from '../Dashboard/sections/AdminTable/AdminTable';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import { SuperadminService } from 'services/SuperadminService';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Loader } from 'components/atoms/Loading/Loading.styles';
import { Superadmin } from 'services/types/SuperadminTypes';
import { Path } from 'routes/Path';

export const SuperAdminManagement = (): ReactElement => {
  const [adminUsers, setAdminUsers] = useState<Array<Superadmin>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const admins = await SuperadminService.getSuperadmins();
      setAdminUsers(admins);
      setLoading(false);
    };

    fetchData();
  }, []);

  const deleteAdminUserByIndex = async (searchedIndex: number) => {
    const newAdminUsers = adminUsers.filter((_, index) => index !== searchedIndex);
    setAdminUsers(newAdminUsers);
  };

  const editAdminUser = (newUser: Superadmin) => {
    const newAdminUsers = adminUsers.map((user) => (user.id === newUser.id ? newUser : user));
    setAdminUsers(newAdminUsers);
  };

  const addAdminUser = (newUser: Superadmin) => setAdminUsers([...adminUsers, newUser]);

  return loading ? (
    <Loader />
  ) : (
    <>
      <AdminHeading
        title="SuperAdmin Management"
        backButton={{
          title: 'Back to Management Tool',
          href: Path.ManagementTool,
        }}
      />

      <CardContainer>
        <AdminTable
          adminUsers={adminUsers}
          addAdminUser={addAdminUser}
          editAdminUser={editAdminUser}
          deleteAdminUserByIndex={deleteAdminUserByIndex}
        />
      </CardContainer>
    </>
  );
};
