import { ReactElement } from 'react';
import { error } from '../../../util/debug/console';
import { GTM_ID, IS_DEVELOPMENT } from '../../../util/environment';
import { GoogleTagManager } from './GoogleTagManager/GoogleTagManager';

const VEP_GTM_LAYER_NAME = 'vepDL';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window[VEP_GTM_LAYER_NAME] = window[VEP_GTM_LAYER_NAME] || [];

export const Tracking = (): ReactElement | null => {
  if (GTM_ID == null && !IS_DEVELOPMENT) {
    error('REACT_APP_GTM_ID environment variable is empty');
  }

  return GTM_ID ? <GoogleTagManager id={GTM_ID} layerName={VEP_GTM_LAYER_NAME} /> : null;
};
