import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const SubHeadingBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  gap: 1.5rem;
  padding-bottom: 2rem;
  border-bottom: solid 1px ${({ theme }) => theme.color.greyE};
  margin-bottom: 5.6rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    align-items: center;
    flex-direction: row;
    gap: 0;
  }
`;

export const InputWrapper = styled.div`
  width: 25rem;
  color: ${({ theme }) => theme.color.blueA};
`;
