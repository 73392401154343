import type { MouseEventHandler, ReactElement } from 'react';
import * as S from './PaginationButtons.styles';
import { Chevron } from 'components/atoms/Icon/Icon';
import { ColorKey } from 'styles/theme/default';

export interface PaginationButtonsProps extends S.PaginationButtonsSyleProps {
  color?: ColorKey;
  isLeftDisabled?: boolean;
  isRightDisabled?: boolean;
  count?: string | number;
  onLeftClick?: MouseEventHandler<HTMLButtonElement> & MouseEventHandler<HTMLAnchorElement>;
  onRightClick?: MouseEventHandler<HTMLButtonElement> & MouseEventHandler<HTMLAnchorElement>;
}

export const PaginationButtons = ({
  onLeftClick,
  onRightClick,
  isLeftDisabled,
  isRightDisabled,
  count,
  ...props
}: PaginationButtonsProps): ReactElement => {
  return (
    <S.StyledPaginationButtons {...props}>
      <S.StyledPaginationButton disabled={isLeftDisabled} onClick={onLeftClick} icon={Chevron} />
      <S.StyledPaginationButton disabled={isRightDisabled} onClick={onRightClick} icon={Chevron} />
      {count && <S.StyledCount>{count}</S.StyledCount>}
    </S.StyledPaginationButtons>
  );
};
