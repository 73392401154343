import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import * as S from './EmptyFeedbackCard.styles';
import { slideFade } from 'util/motionTransitions';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { Text } from 'components/atoms/Text/Text';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface EmptyFeedbackCardProps {
  cohortSurveyId?: number;
}

export const EmptyFeedbackCard = ({
  cohortSurveyId,
  ...props
}: EmptyFeedbackCardProps): ReactElement => {
  return (
    <S.StyledEmptyFeedbackCard {...props} {...slideFade()} variant="blue">
      <S.StyledContent>
        <Heading type={HeadingType.H6}>
          <FormattedMessage
            description="Dashboard - Empty Feedback Card - Heading"
            defaultMessage="No Results Yet"
          />
        </Heading>
        <Paragraph color="currentColor" margin="1rem 0 3rem">
          <FormattedMessage
            description="Dashboard - Empty Feedback Card - Paragraph"
            defaultMessage='Some assessors haven&apos;t completed the assessment. You can send them a reminder my clicking on "My Assessors" in your dashboard.'
          />
        </Paragraph>
        <Text type="bold" color="inherit">
          <FormattedMessage
            description="Dashboard - Empty Feedback Card - Note"
            defaultMessage="At least 3 assessors must finish the assessment in order for you to receive your results."
          />
        </Text>
      </S.StyledContent>
    </S.StyledEmptyFeedbackCard>
  );
};
