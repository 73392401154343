import { FormEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { debounce } from 'lodash';
import { ClientCardList } from '../../components/ClientCardList/ClientCardList';
import * as S from './ClientOverview.styles';
import { StyledSearchInput } from '../../components/ManagementTable/ManagementTable.styles';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Text } from 'components/atoms/Text/Text';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Plus } from 'components/atoms/Icon/Icon';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Path } from 'routes/Path';
import { customersActions } from 'redux/ducks/customersDuck/customersReducer';
import { ActionMethod } from 'data/enum/ActionMethod';
import { RootState } from 'redux/store';
import { Loading } from 'components/atoms/Loading/Loading';
import { LoadingTheme } from 'components/atoms/Loading/Loading.styles';
import { CustomerGetProps } from 'redux/ducks/customersDuck/customersTypes';
import { MediaQuery } from 'styles/mediaQuery';
import { NoResults } from 'components/molecules/NoResults/NoResults';

export const ClientOverview = (): ReactElement => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const searchQueryRef = useRef<string>('');

  const [searchQuery, setSearchQuery] = useState<string>();
  const { loading, customers } = useSelector((state: RootState) => state.customers);
  const [filteredCustomers, setFilteredCustomers] = useState<Array<CustomerGetProps>>([]);

  const filterCustomerData = useCallback(
    (values: Array<CustomerGetProps>) => {
      const filteredData = values.filter((item) =>
        searchQuery ? item.name.toLowerCase().includes(searchQuery) : true,
      );
      return filteredData;
    },
    [searchQuery],
  );

  const updateSearchQuery = debounce(() => setSearchQuery(searchQueryRef.current), 600);

  const handleReset = () => {
    dispatch(customersActions.resetCurrentCustomer());
    push(Path.AddClient);
  };

  const handleSearchUpdate = (event: FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;

    searchQueryRef.current = target.value.toLowerCase();
    updateSearchQuery();
  };

  useEffect(() => {
    dispatch(
      customersActions.customersApiHandler({
        method: ActionMethod.GET,
        entity: 'customerList',
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (customers) setFilteredCustomers(filterCustomerData(customers));
  }, [customers, filterCustomerData]);

  return (
    <>
      <Heading margin="2rem 0" type={HeadingType.H2}>
        Client Overview
      </Heading>
      <S.SubHeadingBar>
        <Text>Manage existing clients and create new ones</Text>
        <Flex container gap="1.5rem" flexDirection={['column', [MediaQuery.MIN_768, 'row']]}>
          <S.InputWrapper>
            <StyledSearchInput
              placeholder="SEARCH CLIENT BY NAME"
              name="search"
              icon="Search"
              handleInputChange={handleSearchUpdate}
            />
          </S.InputWrapper>

          <PrimaryButton $outline $size="regular" onClick={handleReset}>
            Add new client
            <Plus />
          </PrimaryButton>
        </Flex>
      </S.SubHeadingBar>

      {loading && <Loading variant={LoadingTheme.Light} />}

      {!loading && (
        <>
          {filteredCustomers.length > 0 ? (
            <ClientCardList customers={filteredCustomers} />
          ) : (
            <NoResults
              title="No Results"
              subtitle="Start your way to self improvement"
              hasMinHeight
            />
          )}
        </>
      )}
    </>
  );
};
