import { useEffect, useState } from 'react';
import { Locale } from '../data/enum/Locale';

const messagesMap: Record<Locale, () => Promise<Record<string, string>>> = {
  [Locale.EnUs]: async () => (await import('../assets/messages/en-US.json')).default,
  [Locale.EsUs]: async () => (await import('../assets/messages/default.json')).default,
};

export const useMessages = (locale: Locale): Record<string, string> | undefined => {
  const [message, setMessages] = useState<Record<string, string>>();

  useEffect(() => {
    (async () => {
      setMessages(await messagesMap[locale]());
    })();
  }, [locale]);

  return message;
};
