/* eslint-disable import/no-unresolved */
import { ReactElement, useEffect, useState } from 'react';
import { useCombobox, UseComboboxStateChange } from 'downshift';
import { RegisterFormItemChild } from 'globalTypes';
import * as S from './SearchSelect.styles';

export interface DropdownItemProps {
  id?: number;
  type: 'group' | 'other' | 'placeholder';
  copy: string;
}

export type SearchSelectItemsType = Array<DropdownItemProps>;

interface SearchSelectProps extends S.SearchSelectStyleProps, RegisterFormItemChild {
  items: SearchSelectItemsType;
  placeholder?: string;
  readOnly?: boolean;
  defaultValue?: DropdownItemProps;
  onChange?: (item: DropdownItemProps) => void;
}

export const SearchSelect = ({
  items,
  placeholder,
  readOnly,
  variant,
  defaultValue,
  formMethods,
  onChange,
  name,
  ...props
}: SearchSelectProps): ReactElement => {
  const filterByName = (data: Array<DropdownItemProps>, inputValue: string) => {
    return data.filter((item) => {
      return item.copy.toLowerCase().includes(inputValue.toLowerCase());
    });
  };

  const [filtereditems, setFilteredItems] = useState(items);
  const [selectedItem, setSelectedItem] = useState<DropdownItemProps | null>(defaultValue || null);

  const onSelectedItemChange = ({ selectedItem }: UseComboboxStateChange<DropdownItemProps>) => {
    if (selectedItem) {
      setSelectedItem(selectedItem);
      if (onChange) onChange(selectedItem);
    }

    if (formMethods) {
      formMethods.setValue(name, selectedItem);
      formMethods.clearErrors(name);
    }
  };

  const {
    isOpen,
    highlightedIndex,
    getToggleButtonProps,
    getComboboxProps,
    getMenuProps,
    getItemProps,
    getInputProps,
  } = useCombobox<DropdownItemProps>({
    items: filtereditems,
    selectedItem,
    onSelectedItemChange,
    itemToString: (item) => (item ? item.copy : ''),
    onInputValueChange: ({ inputValue, type }) => {
      setFilteredItems(
        filterByName(items, type === '__input_change__' && inputValue ? inputValue : ''),
      );
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: state.selectedItem ? state.selectedItem.copy : '',
          };
        default:
          return changes;
      }
    },
  });

  useEffect(() => {
    setFilteredItems(items);

    if (defaultValue) {
      setSelectedItem(defaultValue);
      if (onChange) onChange(defaultValue);
    }

    if (formMethods) {
      formMethods.setValue(name, defaultValue);
      formMethods.clearErrors(name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  return (
    <S.StyledSearchSelect {...props}>
      <S.StyledSelectedFlex container alignItems="center" {...getToggleButtonProps()}>
        <S.StyledSelectedItem variant={variant} {...getComboboxProps()}>
          <S.StyledSelectedItemInput
            {...getInputProps()}
            placeholder={placeholder}
            variant={variant}
            readOnly={readOnly}
          />
          {variant === 'input' && <S.StyledChevron />}
        </S.StyledSelectedItem>
        {variant !== 'input' && <S.StyledChevron />}
      </S.StyledSelectedFlex>
      <S.StyledList {...getMenuProps()}>
        {isOpen &&
          filtereditems.map((item, index) => (
            <S.StyledListItem
              key={item.copy}
              variant={
                filtereditems[index + 1] &&
                filtereditems[index + 1].type !== 'group' &&
                item.type === 'group'
                  ? 'last-group-item'
                  : undefined
              }
              isHighlighted={highlightedIndex === index}
              {...getItemProps({ item, index })}
            >
              {item.copy}
            </S.StyledListItem>
          ))}
      </S.StyledList>
    </S.StyledSearchSelect>
  );
};
