import { Provider } from 'react-redux';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { IntlProviderWrapper } from './contexts/IntlProviderWrapper/IntlProviderWrapper';
import { store } from './redux/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { theme } from './styles/theme/default';
import { GlobalStyle } from './styles/GlobalStyle';
import './util/lazysizes';
import 'simplebar/dist/simplebar.min.css';

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <IntlProviderWrapper>
          <GlobalStyle />
          <App />
        </IntlProviderWrapper>
      </Provider>
    </ThemeProvider>
  </StrictMode>,
  // eslint-disable-next-line unicorn/prefer-query-selector
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
