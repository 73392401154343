import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CheckboxGroup } from 'components/molecules/CheckboxGroup/CheckboxGroup';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { MediaQuery } from 'styles/mediaQuery';
import { typeStyle } from 'styles/typeStyle';
import { respondTo } from 'styles/helpers/respondTo';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-top: 3.9rem;
  margin-bottom: 3.4rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    padding-top: 0;
    flex-wrap: wrap;
  }
`;

export const StyledRegisterFormItem = styled(RegisterFormItem)`
  margin-bottom: 2.4rem;
`;

export const StyledCheckBoxInput = styled(CheckboxGroup)`
  justify-content: flex-start;

  input {
    margin-left: 0;
    margin-right: 1rem;
  }
`;

export const StyledTermsCheckBoxInput = styled(StyledCheckBoxInput)`
  margin-top: 1rem;
  width: 100%;
  margin-bottom: 4.9rem;
  flex: none;
`;

export const TextButton = styled(Link)`
  ${typeStyle.label};
  color: ${({ theme }) => theme.color.greyA};
  text-decoration: underline;
  display: inline;
  transition: color 0.3s ease;

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.color.blueA};
    }
  }
`;

export const ForgotPasswordbutton = styled.button`
  ${typeStyle.label};
  color: ${({ theme }) => theme.color.blueB};
  position: absolute;
  left: 0;
  top: 0;
  transition: color 0.2s ease;

  @media ${respondTo(MediaQuery.MIN_768)} {
    position: static;
  }

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.color.blueA};
    }
  }
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  width: 100%;
`;

export const Links = styled.span``;
export const PrivacyPolicy = styled.button`
  margin-left: 0.5rem;
  ${typeStyle.label};
  color: ${({ theme }) => theme.color.greyA};
  text-decoration: underline;
  display: inline;
  transition: color 0.3s ease;

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.color.blueA};
    }
  }
`;
