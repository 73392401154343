/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

let memoryState = {} as { [key: string]: any };

export const useMemoryState = <T>(
  key: string,
  initialState: any,
): [state: T, onChange: (nextState: T) => void] => {
  const [state, setState] = useState<T>(() => {
    const hasMemoryValue = Object.prototype.hasOwnProperty.call(memoryState, key);
    if (hasMemoryValue) {
      return memoryState[key];
    }
    return typeof initialState === 'function' ? initialState() : initialState;
  });

  const onChange = (nextState: T) => {
    memoryState[key] = nextState;
    setState(nextState);
  };

  return [state, onChange];
};

export const cleanMemoryState = (): void => {
  memoryState = {};
};
