import { memo } from 'react';
import styled from 'styled-components';
import type { InviteVariantType } from './Invite';
import { Heading } from 'components/atoms/Heading/Heading';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledInvite = memo(styled.div<{ variant?: InviteVariantType }>`
  padding: 4rem 0 10rem;
  background: ${({ theme, variant }) =>
    variant === 'blue' ? `${theme.color.blueA}; mix-blend-mode: darken;` : theme.color.greyB};
  position: relative;
  overflow: hidden;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding: 9rem 0 10rem;
  }
`);

export const StyledHeading = memo(styled(Heading)`
  max-width: 80%;
  margin-bottom: 4rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    max-width: 52.5rem;
    margin-bottom: 7.2rem;
  }
`);

export const StyledInviteFormWrapper = memo(styled.div`
  margin: 0 auto;
`);

export const StyledPeopleListContainer = styled.div`
  @media ${respondTo(MediaQuery.MAX_1023)} {
    & > p {
      max-width: 65%;
    }
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    min-height: 11rem;
    margin: 5.6rem 0 4rem;
  }
`;

export const StyledSendButtonContainer = styled.div`
  display: flex;
  gap: 2rem;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    flex-direction: column;
    margin-top: 8rem;

    button {
      width: 100%;
    }
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: fit-content;
    margin-left: auto;
  }
`;
