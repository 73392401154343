import styled from 'styled-components';
import { Heading } from 'components/atoms/Heading/Heading';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Text } from 'components/atoms/Text/Text';

export const StyledManagementOverview = styled.section``;

export const StyledHeading = styled(Heading)`
  border-bottom: solid 1px ${({ theme }) => theme.color.greyE};
  padding-bottom: 3rem;
`;

export const CardsWrapper = styled(Flex)`
  flex: 1;
  padding: 10rem 0;
`;

export const CardTitle = styled(Text)`
  font-weight: 500;
  color: ${({ theme }) => theme.color.blueA};
  transition: color 0.3s ease;
`;

export const ManagementCard = styled(Flex)`
  max-width: 25.2rem;
  border-radius: 2px;
  text-align: center;
  padding: 6.5rem 4.5rem;
  background-color: ${({ theme }) => theme.color.blueE};
  transition: background-color 0.3s ease;

  p {
    transition: color 0.3s ease;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.blueB};

      p,
      ${CardTitle} {
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
`;
