/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild, FormItemContainerBasicProps } from 'globalTypes';
import { ReactElement, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Element } from '../Element/Element';
import { ElementsSelect } from '../ElementsSelect/ElementsSelect';
import { AdminHeading } from 'components/pages/Superadmin/components/AdminHeading/AdminHeading';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { getElements } from 'redux/ducks/elementsDuck/elementsActions';
import { RootState } from 'redux/store';
import { SelectItem } from 'components/molecules/Select/Select';
import { setErrorEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import constants from 'data/constants';
import { removeSelectedChallenge } from 'redux/ducks/selectedChallengesOrQuestionsDuck/selectedChallengesOrQuestionsActions';

interface ElementFormItemProps extends FormItemContainerBasicProps, RegisterFormItemChild {}

interface RecievedElements {
  allow_no_answer: boolean;
  created_at: string;
  deleted_at: string;
  description: string;
  element_type_id: number;
  id: number;
  name: string;
  set_as_open: boolean;
  updated_at: string;
}

interface ElementProps {
  element_id: number;
  order: number;
  questions: Array<any>;
  challenges: Array<any>;
  name: string;
}

// eslint-disable-next-line no-empty-pattern
export const ElementFormItem = ({
  register,
  formMethods,
  errors,
}: ElementFormItemProps): ReactElement => {
  const [elements, setElements] = useState<Array<ElementProps>>([]);
  const [disableAddElementsButton, setDisableAddElementsButton] = useState<boolean>(false);
  const [finalRecievedElements, setRecievedElements] = useState<Array<RecievedElements>>([]);

  const [elementNames, setElementNames] = useState<Array<SelectItem>>([]);

  const dispatch = useDispatch();

  const { elements: recievedElements } = useSelector((state: RootState) => {
    return state.elements;
  });

  const formFieldName = 'selected-elements';

  const handleDeleteElement = (index: number) => {
    const newElements = [...elements].filter((_element, elementIndex) => elementIndex !== index);
    setElements(newElements);
    formMethods.setValue(formFieldName, newElements);
    dispatch(removeSelectedChallenge(index));
  };

  useEffect(() => {
    dispatch(getElements());
  }, [dispatch]);

  useEffect(() => {
    setRecievedElements(recievedElements);
  }, [recievedElements, finalRecievedElements]);

  useEffect(() => {
    const elementNames =
      finalRecievedElements &&
      finalRecievedElements.map((item) => ({ title: item.name, value: item.name }));
    setElementNames(elementNames);
  }, [finalRecievedElements]);

  // TODO: use element ID instead of name
  const handleAddElement = (item: SelectItem) => {
    if (elements.length >= constants.CREATE_ASSESSMENT_MAX_ELEMENTS) {
      dispatch(setErrorEventAction(Events.MAXIMUM_NUMBER_OF_ELEMENTS_EXCEEDED));
      return;
    }

    const filteredElement = recievedElements.filter(
      (element: RecievedElements) => element.name === item.value,
    );

    const newElement: ElementProps = {
      element_id: filteredElement[0].id,
      order: 0,
      challenges: [],
      questions: [],
      name: item.value as string,
    };
    setElements([...elements, newElement]);
    formMethods.setValue(formFieldName, item.value);
    formMethods.clearErrors(formFieldName);
  };

  useEffect(() => {
    const shouldDisableAddElementsButton =
      elements.length >= constants.CREATE_ASSESSMENT_MAX_ELEMENTS;
    if (shouldDisableAddElementsButton !== disableAddElementsButton)
      setDisableAddElementsButton(shouldDisableAddElementsButton);
  }, [elements, setDisableAddElementsButton, disableAddElementsButton]);

  return (
    <>
      <div>
        <AdminHeading title="Select Elements" $size="small" $underline />

        <RegisterFormItem
          register={register}
          formMethods={formMethods}
          errors={errors}
          name={formFieldName}
          registerErrors="required"
          label="Select Elements"
          size={FormItemSizes.Medium}
          alignError="left"
        >
          <ElementsSelect
            selectedItems={elements}
            items={elementNames}
            cta="Add Element"
            note="You can set up to 10 elements"
            onAddClick={handleAddElement}
            disableAddButton={disableAddElementsButton}
          />
        </RegisterFormItem>
      </div>

      {elements.map((element, index) => (
        <Element
          key={element.element_id}
          id={index}
          name={element.name}
          onDelete={() => handleDeleteElement(index)}
          title={element.name}
          register={register}
          formMethods={formMethods}
          errors={errors}
        />
      ))}
    </>
  );
};
