/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line import/no-unresolved
import { FormItemContainerBasicProps, RegisterFormItemChild } from 'globalTypes';
import { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { StyledNote } from '../../../CreateElement/CreateElement.styles';
import { ElementSingleQuestion } from '../ElementSingleQuestion/ElementSingleQuestion';
import { createQuestiontableData, questionsTableData } from './ElementContent.data';
import * as S from './ElementContent.styles';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import {
  FormItemSizes,
  StyledLabel,
} from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { PillTabs } from 'components/atoms/PillTabs/PillTabs';
import { TableDataItems, TableOptions } from 'components/atoms/Table/Table.types';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';
import { ConfirmModalContext } from 'components/organisms/ConfirmModal/ConfirmModal';
import { ManagementTable } from 'components/pages/Superadmin/components/ManagementTable/ManagementTable';
import { ActionMethod } from 'data/enum/ActionMethod';
import { questionsActions } from 'redux/ducks/questionsDuck/questionsReducer';
import { QuestionProps } from 'redux/ducks/questionsDuck/questionsTypes';
import {
  updateSelectedChallenges,
  updateSelectedNewQuestions,
  updateSelectedQuestions,
} from 'redux/ducks/selectedChallengesOrQuestionsDuck/selectedChallengesOrQuestionsActions';
import {
  getTemporaryAddQuestions,
  getTemporaryQuestions,
} from 'redux/ducks/temporaryQuestionsDuck/temporaryQuestionsActions';
import { RootState } from 'redux/store';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { setErrorEventAction } from 'redux/ducks/eventsDuck/eventsActions';

interface ElementContentProps extends FormItemContainerBasicProps, RegisterFormItemChild {}

const DISABLE_QUESTION_CREATION = true;

export const ElementContent = ({
  formMethods,
  register,
  errors,
  name,
}: ElementContentProps): ReactElement => {
  const names = [`${name}.questions`, `${name}`];
  const dispatch = useDispatch();
  const intl = useIntl();
  const accordionRefs = useRef<Array<HTMLElement>>([]);

  const { useConfirmModal } = useContext(ConfirmModalContext);

  const [openIndex, setOpenIndex] = useState<boolean>(false);
  const [searchOrAdd, setSearchOrAdd] = useState(true);

  const [temporaryQuestionsArray, setTemporaryQuestionsArray] = useState<Array<QuestionProps>>([]);
  const [finalQuestions, setFinalQuestions] = useState<Array<QuestionProps>>([]);
  const [createdQuestions, setCreatedQuestions] = useState<Array<QuestionProps>>([]);

  const [newQuestions, setNewQuestions] = useState<TableOptions>();
  const [newCreatedQuestions, setNewCreatedQuestions] = useState<TableOptions>();

  const [filterQuery, setFilterQuery] = useState<string>();

  const { questions } = useSelector((state: RootState) => {
    return state.questions;
  });

  const handleCallback = (childData: any) => {
    const { isOpenAnswer: set_as_open, description, name } = childData;

    const newChildData = {
      set_as_open,
      description,
      name,
      question_id: 1,
      allow_no_answer: false,
      element_type_id: 2,
      order: 1,
    };
    setCreatedQuestions((previousState) => [...previousState, newChildData]);
  };

  const filterTemporaryQuestions = (data: Array<QuestionProps>) => {
    if (data) {
      const filtered = Object.values(data).map((item, index) => {
        return {
          question_id: item.id,
          name: item.name,
          description: item.description,
          allow_no_answer: item.allow_no_answer,
          set_as_open: item.set_as_open,
          element_type_id: 2,
          order: index + 1,
        };
      });
      setTemporaryQuestionsArray(filtered);
    }
  };

  const filterQuestionsByName = (data: Array<QuestionProps>, query: string) => {
    return data.filter((item) =>
      query !== undefined
        ? new RegExp(`(${query?.toLowerCase()})`).test(item.name.toLowerCase())
        : true,
    );
  };

  const updateTableData = (data: Array<QuestionProps>) => {
    if (data) {
      const rows = data.map((item) => {
        const isSelected =
          newCreatedQuestions &&
          newCreatedQuestions.rows.some((selectedItem) =>
            selectedItem.some((subitem) => subitem.data && subitem.data.question_id === item.id),
          );

        return [
          { copy: item.name },
          { copy: item.description },
          { copy: item.created_at },
          {
            copy: (
              <Checkbox
                isDisabled
                checked={item?.set_as_open}
                refId={item?.question_id}
                isOnTable
              />
            ),
          },
          { hidden: true, control: isSelected ? 'Remove' : 'Add' },
        ];
      });

      filterTemporaryQuestions(data);
      setNewQuestions({ ...questionsTableData, rows } as TableOptions);
    }
  };

  const handleCallbackCheckbox = (value: boolean, id?: number) => {
    const newArray = finalQuestions.map((item) => {
      const isOpen = item.question_id === id ? value : item.set_as_open;

      return { ...item, set_as_open: isOpen };
    });

    setFinalQuestions(newArray);
  };

  const filterNewCreatedQuestions = (data: Array<QuestionProps>) => {
    if (data) {
      const filtered = data
        .filter((item) => item)
        .map((item, index) => {
          return [
            { copy: index + 1 },
            { copy: item?.name },
            { copy: item?.description },
            { copy: item?.created_at },
            {
              copy: (
                <Checkbox
                  isDisabled
                  checked={item?.set_as_open}
                  refId={item?.question_id}
                  isOnTable
                  parentCallback={handleCallbackCheckbox}
                />
              ),
            },
            { data: item, hidden: true },
          ];
        });

      const copyData = { ...createQuestiontableData, rows: filtered } as TableOptions;

      setNewCreatedQuestions(copyData);
    }
  };

  const RemoveOrAdd = async (rowIndex: number, tableRow?: TableDataItems, add?: boolean) => {
    let selectedQuestion = temporaryQuestionsArray[rowIndex];
    const selectedId = tableRow && tableRow[0].copy;
    if (tableRow && typeof selectedId === 'number') {
      [selectedQuestion] = temporaryQuestionsArray.filter(
        ({ question_id }) => question_id === selectedId,
      );
    }

    if (add) {
      if (!selectedQuestion) return;
      if (finalQuestions.length >= 10) {
        dispatch(setErrorEventAction(Events.MAXIMUM_NUMBER_OF_QUESTIONS_EXCEEDED));
        return;
      }
      const newFinalQuestions = [...finalQuestions, selectedQuestion];
      setFinalQuestions(newFinalQuestions);
    } else {
      const newFinalQuestions = finalQuestions.filter(
        (item) => item.question_id !== selectedQuestion.question_id,
      );
      setFinalQuestions(newFinalQuestions);
    }
  };

  const Delete = async (rowIndex: number) => {
    if (!newCreatedQuestions) return;

    const selectedRow = newCreatedQuestions.rows[rowIndex];

    const rowData = (selectedRow.filter((item) => item.data)[0] || {}).data;

    if (!rowData) return;

    const description = intl.formatMessage({
      defaultMessage: 'Are you sure you want to delete this question?',
      description: 'Confirm Delete Question Description',
    });

    const confirm = await useConfirmModal({
      title: 'Delete',
      subtitle: selectedRow[2].copy,
      description,
    });

    if (!confirm) return;
    const newQuestions = finalQuestions.filter((item) => item.question_id !== rowData.question_id);
    setFinalQuestions(newQuestions);
  };

  const InsideComponent = () => {
    return (
      <>
        {searchOrAdd ? (
          newQuestions && (
            <ManagementTable
              data={newQuestions as TableOptions}
              actions={{ RemoveOrAdd }}
              outsideComponent="search-questions"
              setFilterQuery={setFilterQuery}
            />
          )
        ) : (
          <div>
            <ElementSingleQuestion
              key={`${name}`}
              name="elem.questions"
              errors={errors}
              register={register}
              formMethods={formMethods}
              closeAll={() => setOpenIndex(false)}
              onOpen={() => setOpenIndex(!openIndex)}
              isOpen
              title={`Question ${name}`}
              accordionRef={(ref: HTMLElement) => {
                accordionRefs.current[1] = ref;
              }}
              parentCallback={handleCallback}
            />
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    dispatch(questionsActions.questionsApiHandler({ method: ActionMethod.GET }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTemporaryAddQuestions(createdQuestions));
  }, [dispatch, createdQuestions]);

  useEffect(() => {
    dispatch(getTemporaryQuestions(finalQuestions));
  }, [finalQuestions, dispatch]);

  useEffect(() => {
    const existingQuestionsBody = {
      element_name: name,
      questions: finalQuestions,
    };
    dispatch(updateSelectedQuestions(existingQuestionsBody));
  }, [dispatch, finalQuestions, name]);

  useEffect(() => {
    const createdQuestionsBody = {
      element_name: name,
      questions: createdQuestions,
    };
    dispatch(updateSelectedNewQuestions(createdQuestionsBody));
  }, [dispatch, createdQuestions, name]);

  useEffect(() => {
    const questionsArray = createdQuestions.concat(finalQuestions);
    formMethods.setValue('created-questions', createdQuestions);
    formMethods.setValue('questions', finalQuestions);
    filterNewCreatedQuestions(questionsArray);
  }, [createdQuestions, finalQuestions, formMethods]);

  useEffect(() => {
    if (filterQuery !== undefined) {
      const filteredQuestions = filterQuestionsByName(
        questions as Array<QuestionProps>,
        filterQuery,
      );
      updateTableData(filteredQuestions);
    }
  }, [filterQuery]);

  useEffect(() => {
    updateTableData(questions as Array<QuestionProps>);
    filterTemporaryQuestions(questions as Array<QuestionProps>);
  }, [questions]);

  useEffect(() => {
    updateTableData(questions as Array<QuestionProps>);
  }, [newCreatedQuestions]);

  useEffect(() => {
    dispatch(
      updateSelectedChallenges({
        element_name: names[1],
        challenges: [],
      } as any),
    );
  }, [dispatch]);

  return (
    <Flex container flexDirection="column" gap="3rem">
      {/* <ElementsViewSelect
        errors={errors}
        register={register}
        formMethods={formMethods}
        items={assessmentData.challenges}
        cta="Add Challenge"
        label="Select Challenges"
        note="You can set up to 3 challenges"
        name={names[1]}
      /> */}
      <Flex container flexDirection="column" gap="3.5rem">
        <RegisterFormItem
          errors={errors}
          register={register}
          formMethods={formMethods}
          name={names[0]}
        >
          <Flex>
            <Flex container justifyContent="space-between" alignItems="center">
              <StyledLabel size={FormItemSizes.Medium} marginBottom="0rem">
                Set number of questions per element
              </StyledLabel>
            </Flex>
            <StyledNote type="label">You can set up to 10 questions</StyledNote>
          </Flex>
        </RegisterFormItem>
        {DISABLE_QUESTION_CREATION ? (
          newQuestions && (
            <ManagementTable
              data={newQuestions as TableOptions}
              actions={{ RemoveOrAdd }}
              outsideComponent="search-questions"
              setFilterQuery={setFilterQuery}
            />
          )
        ) : (
          <TabsContainer>
            <S.StyledPillTabsContainer onClick={() => setSearchOrAdd(!searchOrAdd)}>
              <PillTabs
                tabs={[
                  intl.formatMessage({
                    defaultMessage: 'Search Questions',
                    description: 'User Dashboard - Assessment - Tab 1',
                  }),
                  intl.formatMessage({
                    defaultMessage: 'Create New One',
                    description: 'User Dashboard - Assessment - Tab 2',
                  }),
                ]}
              />
            </S.StyledPillTabsContainer>
            {InsideComponent}
          </TabsContainer>
        )}

        {newCreatedQuestions && (
          <S.AddedQuestionsWrapper>
            <ManagementTable
              data={newCreatedQuestions}
              actions={{ Delete }}
              outsideComponent="add-questions"
              accordionRef={(ref: HTMLElement) => {
                accordionRefs.current[1] = ref;
              }}
            />
          </S.AddedQuestionsWrapper>
        )}
      </Flex>
    </Flex>
  );
};
