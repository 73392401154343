import { ReactElement, ForwardedRef, forwardRef, useState, useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild } from 'globalTypes';
import { Select, SelectItem } from 'components/molecules/Select/Select';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Plus } from 'components/atoms/Icon/Icon';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Text } from 'components/atoms/Text/Text';

export type Element = {
  id: number;
  title: string;
  challenges: Array<string>;
  questions: Array<string>;
};

interface ElementsSelectProps extends RegisterFormItemChild {
  items: Array<SelectItem>;
  cta: string;
  note?: string;
  onAddClick: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedItems: Array<any>;
}

export const ElementsSelect = forwardRef(
  (
    {
      items,
      cta,
      note,
      selectedItems,
      name,
      formMethods,
      onAddClick,
      ...props
    }: ElementsSelectProps,
    ref,
  ): ReactElement => {
    const [currentItem, setCurrentItem] = useState<SelectItem>();

    const handleSelectItem = (item: SelectItem) => {
      if (item.value !== items[0].value) {
        setCurrentItem(item);
      }
    };

    const handleAddElement = () => {
      if (currentItem) {
        onAddClick(currentItem.value.toString());
        // formMethods.setValue(`${name}.${selectedItems.length}`, currentItem);
        formMethods.clearErrors(name);
      }

      formMethods.trigger(name);
    };

    useEffect(() => {
      setCurrentItem(undefined);
    }, [items.length]);

    return (
      <>
        <Flex container gap="5rem">
          <Flex flexGrow="1">
            <Select
              {...props}
              ref={ref as ForwardedRef<HTMLDivElement>}
              items={items}
              variant="input"
              onSelect={handleSelectItem}
              placeholder="Select an option"
              isElementComponent
            />
          </Flex>
          <PrimaryButton
            type="button"
            onClick={handleAddElement}
            $size="regular"
            $outline
            $marginBlock
          >
            {cta} <Plus />
          </PrimaryButton>
        </Flex>
        {note && (
          <Text margin="1.5rem 0 0" type="label">
            {note}
          </Text>
        )}
      </>
    );
  },
);
