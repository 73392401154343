import { motion, Variants } from 'framer-motion';
import React, { ReactElement, ReactNode } from 'react';
import { useMotionTrigger } from 'hooks/useMotionTrigger';
import { fade, slideFade, staggerChildren, Transitions } from 'util/motionTransitions';

interface AnimationGroupProps {
  children: ReactNode;
}

export const AnimationGroup = ({ children }: AnimationGroupProps): ReactElement => {
  const { controls, rootRef } = useMotionTrigger(0.3);

  const variants: { [key in Transitions]?: Variants } = {
    fade: fade(1).variants,
    slideFade: slideFade('y', 40, 1.5).variants,
  };

  const recursiveMap = (children: ReactNode): ReactNode => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        if (child.props.animate) {
          return React.cloneElement(child, {
            ...{
              ...child.props,
              variants: Object.values(Transitions).includes(child.props.animate)
                ? variants[child.props.animate as Transitions]
                : variants.slideFade,
              animate: false,
              key: child.props.name,
            },
          });
        }

        let elementChild: React.ReactElement = child;
        if (child.props.children) {
          elementChild = React.cloneElement(elementChild, {
            children: recursiveMap(elementChild.props.children),
          });
        }
        return elementChild;
      }
      return child;
    });
  };

  return (
    <motion.div style={{ display: 'contents' }} {...staggerChildren()} animate={controls}>
      <div
        ref={rootRef}
        style={{
          position: 'absolute',
          width: '1px',
          top: 0,
          left: 0,
          height: '100%',
          pointerEvents: 'none',
          zIndex: -1,
        }}
      ></div>
      {recursiveMap(children)}
    </motion.div>
  );
};
