import { ReactElement, useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { RegisteredFormItem } from 'globalTypes';
import * as S from '../../ClientForm.styles';
import { ClientType } from '../ClientType/ClientType';
import { ClientName } from '../ClientName/ClientName';
import { ClientSize } from '../ClientSize/ClientSize';
import { ClientIndustry } from '../ClientIndustry/ClientIndustry';
import { ClientFunction } from '../ClientFunction/ClientFunction';
import { ClientOrganizationalType } from '../ClientOrganizationalType/ClientOrganizationalType';
import { Customer } from 'model/Customer';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Form } from 'components/molecules/Form/Form';
import { MediaQuery } from 'styles/mediaQuery';

interface CreateClientFormProps extends RegisteredFormItem {
  currentCustomer: Customer;
  disabled: boolean;
  isEditMode: boolean;
  handleClientFormSuccess: (formData: FieldValues) => Promise<void>;
}

export const CreateClientForm = ({
  currentCustomer,
  isEditMode = false,
  disabled,
  handleClientFormSuccess,
}: CreateClientFormProps): ReactElement => {
  const defaultImage = useMemo(
    () =>
      isEditMode
        ? { name: currentCustomer.name, logo: currentCustomer.logo }
        : { name: undefined, logo: undefined },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEditMode],
  );

  return (
    <Form onSuccess={handleClientFormSuccess}>
      <Flex container flexDirection="column" gap="4rem">
        <ClientType register defaultValue={currentCustomer.B2B_B2C && currentCustomer.B2B_B2C} />
        <ClientName register defaultImage={defaultImage} />
        <Flex container gap="4rem" flexDirection={['row', [MediaQuery.MAX_1280, 'column']]}>
          <ClientSize register defaultValue={isEditMode && currentCustomer.organization_size} />
          <ClientIndustry register defaultValue={isEditMode && currentCustomer.industry_id} />
          <ClientFunction register defaultValue={isEditMode && currentCustomer.function_id} />
          <ClientOrganizationalType
            register
            defaultValue={isEditMode && currentCustomer.organizational_type_id}
          />
        </Flex>
      </Flex>
      <Flex container justifyContent="flex-end">
        <S.StyledPrimaryButton $size="regular" type="submit" disabled={disabled}>
          Save Client
        </S.StyledPrimaryButton>
      </Flex>
    </Form>
  );
};
