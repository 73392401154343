import type { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import type { ButtonElement } from '../../molecules/Button/Button';
import * as S from './Error.styles';

interface ErrorProps {
  error?: Error;
  button?: ButtonElement;
  message?: ReactNode;
}

export const Error = ({ message, error, button }: ErrorProps): ReactElement => {
  return (
    <S.StyledError>
      <S.StyledHeading>
        <FormattedMessage
          description="Error page heading"
          defaultMessage="Oops, something went wrong"
        />
      </S.StyledHeading>

      <S.StyledMessage>{error?.message || message}</S.StyledMessage>

      {button && <S.StyledButtonWrapper>{button}</S.StyledButtonWrapper>}
    </S.StyledError>
  );
};
