import { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// eslint-disable-next-line import/no-unresolved
import { RegisteredFormItem } from 'globalTypes';
import { useHistory } from 'react-router';
import * as S from './CreateObjectiveForm.styles';
import { Package } from '../Package/Package';
import { Time } from '../Time/Time';
import { MediaQuery } from 'styles/mediaQuery';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { StickyBar } from 'components/atoms/StickyBar/StickyBar';
import { TextArea } from 'components/atoms/TextArea/TextArea';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { Select, SelectItem } from 'components/molecules/Select/Select';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Input } from 'components/atoms/Input/Input';
import { SurveyService } from 'services/SurveyService';
import { SurveyElementsResponse } from 'services/types/SurveyTypes';
import useApiClient from 'hooks/useApiClient';
import { Path } from 'routes/Path';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateObjectiveFormProps extends RegisteredFormItem {}

const formatElementItems = (elements: SurveyElementsResponse): Array<SelectItem> => {
  return elements.map((element) => {
    return {
      value: element.element_id,
      title: element.name,
      secondaryValue: element.survey_element_id,
    };
  });
};

export const CreateObjectiveForm = ({
  register,
  formMethods,
  errors,
}: CreateObjectiveFormProps): ReactElement => {
  const [elementItems, setElementItems] = useState<Array<SelectItem>>([]);
  const [selectedElementEndpoint, setSelectedElementEndpoint] = useState<string | null>(null);
  const objectives = useApiClient(selectedElementEndpoint);
  const history = useHistory<{ survey_id: string }>();
  const intl = useIntl();
  const BASE_NAME = 'objective';

  // TODO : add dynamic {participantId} and {cohort_survey_id}
  const { state } = history.location;

  useEffect(() => {
    if (!state) return;

    SurveyService.getSurveyElements(state.survey_id).then((response) => {
      setElementItems(formatElementItems(response));
    });
  }, [state]);

  const handleExitWithoutSaving = () => {
    history.push(Path.Dashboard);
  };

  const setCurrentElement = (item: SelectItem) => {
    if (!item.secondaryValue) return;
    const endpoint = `/${SurveyService.SurveyEndpoints.SurveyElementChallenges}/${item.secondaryValue}`;
    setSelectedElementEndpoint(endpoint);
  };

  // TODO : add dynamic {participantId} and {cohort_survey_id}
  if (!history.location.state) return <>{null}</>;

  return (
    <S.StyledCreateObjectiveForm>
      <S.StyledInputsFlex container gap="2rem" screen={MediaQuery.MIN_768}>
        <RegisterFormItem
          register={register}
          formMethods={formMethods}
          errors={errors}
          registerErrors="required"
          name={`${BASE_NAME}.title`}
          label={intl.formatMessage({
            description: 'Create Objective - Form - Name Input - Label',
            defaultMessage: 'Name of Objective',
          })}
          size={FormItemSizes.Large}
        >
          <Input
            hasRef
            placeholder={intl.formatMessage({
              description: 'Create Objective - Form - Name Input - Placeholder',
              defaultMessage: 'What would you like to call this objective?',
            })}
          />
        </RegisterFormItem>
        <RegisterFormItem
          register={register}
          formMethods={formMethods}
          errors={errors}
          registerErrors="required"
          name={`${BASE_NAME}.elements`}
          label={intl.formatMessage({
            description: 'Create Objective - Form - Grow Input - Label',
            defaultMessage: 'Select a growth area',
          })}
          size={FormItemSizes.Large}
        >
          <Select
            hasRef
            items={elementItems}
            onSelect={setCurrentElement}
            variant="input"
            placeholder="Select an option"
          />
        </RegisterFormItem>
      </S.StyledInputsFlex>
      <RegisterFormItem
        register={register}
        formMethods={formMethods}
        errors={errors}
        registerErrors="required"
        name={`${BASE_NAME}.package`}
        label={intl.formatMessage({
          description: 'Create Objective - Form - Package Input - Label',
          defaultMessage: 'Choose a challenge',
        })}
        size={FormItemSizes.Large}
      >
        <Package surveyElementChallenges={objectives.data} />
      </RegisterFormItem>
      <RegisterFormItem
        register={register}
        formMethods={formMethods}
        errors={errors}
        registerErrors="required"
        name={`${BASE_NAME}.startTime`}
        label={intl.formatMessage({
          description: 'Create Objective - Form - Time Input - Label',
          defaultMessage: 'When do you want to start?',
        })}
        size={FormItemSizes.Large}
      >
        <Time />
      </RegisterFormItem>
      <RegisterFormItem
        register={register}
        formMethods={formMethods}
        errors={errors}
        registerErrors="required"
        name={`${BASE_NAME}.description`}
        label={intl.formatMessage({
          description: 'Create Objective - Form - Description Input - Label',
          defaultMessage: 'Describe your objective',
        })}
        size={FormItemSizes.Large}
      >
        <TextArea
          hasRef
          maxLength={255}
          placeholder={intl.formatMessage({
            description: 'Create Objective - Form - Description Input - Placeholder',
            defaultMessage: 'Write a description of what you hope to achieve with this Objective.',
          })}
        />
      </RegisterFormItem>
      <StickyBar>
        <Flex
          container
          gap="3rem"
          alignItems="center"
          justifyContent={['center', [MediaQuery.MIN_768, 'flex-end']]}
        >
          <SecondaryButton type="button" onClick={handleExitWithoutSaving} color="greyA">
            <span>
              <FormattedMessage
                description="Create Objective - Form - Exit Without Save"
                defaultMessage="Exit without saving"
              />
            </span>
          </SecondaryButton>
          <PrimaryButton type="submit" $size="regular">
            <FormattedMessage
              description="Create Objective - Form - Save Objective"
              defaultMessage="save objective"
            />
            <S.StyledChevron />
          </PrimaryButton>
        </Flex>
      </StickyBar>
    </S.StyledCreateObjectiveForm>
  );
};
