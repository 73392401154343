import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router';
import * as S from './StartAssessment.styles';
import bgImageDesktop from 'assets/img/onboarding/outro-bg-desktop.svg';
import bgImageMobile from 'assets/img/onboarding/outro-bg-mobile.svg';
import { useDeviceState } from 'hooks/useDeviceState';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { AnimationGroup } from 'components/util/AnimationGroup/AnimationGroup';
import { MediaQuery } from 'styles/mediaQuery';
import { Path } from 'routes/Path';

export const StartAssessment = (): ReactElement => {
  const { isMobile } = useDeviceState();
  const { push } = useHistory();

  const handleStartAssessment = () => push(Path.Dashboard);
  // const handleProfileClick = () => push(Path.Profile);

  return (
    <S.OnboardingOutro>
      <S.Background
        src={bgImageMobile}
        source={[{ media: MediaQuery.MIN_1024, srcset: bgImageDesktop }]}
        fit="cover"
      />
      <S.ContentWrapper>
        <AnimationGroup>
          <S.Headline>
            <S.HeadlineSpan animate>
              <FormattedMessage
                description="Onboarding - Outro - Heading"
                defaultMessage="Let’s start <arrow></arrow> <graphic></graphic> the <underline>assessment</underline>"
                values={{
                  arrow: () => (
                    <>
                      <S.StyledInineArrow>
                        <S.StyledLargeArrow />
                      </S.StyledInineArrow>
                      {!isMobile && <br />}
                    </>
                  ),
                  graphic: () => <S.QuarterCircles />,
                  underline: (chunk: string) => <S.Underline>{chunk}</S.Underline>,
                }}
              />
            </S.HeadlineSpan>
          </S.Headline>
          <S.ButtonWrapper>
            <motion.div animate>
              <PrimaryButton onClick={handleStartAssessment}>
                <FormattedMessage
                  description="Onboarding - Outro - Cta"
                  defaultMessage="Start assessment"
                />
                <S.StyledChevron />
              </PrimaryButton>
            </motion.div>
            {/* <motion.div style={{ display: 'inline-grid' }} animate>
              <S.StyledSecondaryButton onClick={handleProfileClick}>
                <span>
                  <FormattedMessage
                    description="Onboarding - Outro - Secondary Cta"
                    defaultMessage="Go to profile"
                  />
                </span>
                <ArrowExternal />
              </S.StyledSecondaryButton>
            </motion.div> */}
          </S.ButtonWrapper>
        </AnimationGroup>
      </S.ContentWrapper>
    </S.OnboardingOutro>
  );
};
