import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SelectItem } from 'components/molecules/Select/Select';
import { CountryService } from 'services/CountryService';
/* eslint-disable no-param-reassign */

type Country = { id: number; description: string };

const fetchCountries = createAsyncThunk('/countries/fetchCountries', async () =>
  CountryService.getCountries(),
);

const initialState: {
  countries: Array<Country>;
  adaptedCountries: Array<SelectItem>;
  loading: boolean;
} = {
  countries: [],
  adaptedCountries: [],
  loading: false,
};

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCountries.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        const countries = action.payload;
        state.countries = countries;
        state.adaptedCountries = countries.map((country) => ({
          title: country.description,
          value: country.id,
        }));
        state.loading = false;
      });
  },
});

export const countriesActions = {
  ...countriesSlice.actions,
  fetchCountries,
};

export default countriesSlice.reducer;
