import type { CSSProperties, ReactElement, ReactNode } from 'react';
import * as S from './ElementTitleItem.styles';

interface ElementTitleItemProps {
  style: CSSProperties;
  children: ReactNode;
}

export const ElementTitleItem = ({ children, ...props }: ElementTitleItemProps): ReactElement => {
  return (
    <S.StyledElementTitleItem {...props}>
      <S.StyledElementTitle>{children}</S.StyledElementTitle>
      <S.StyledElementLine></S.StyledElementLine>
    </S.StyledElementTitleItem>
  );
};
