import { ReactElement, MouseEvent, useCallback, useRef, ReactNode } from 'react';
import { useLockBodyScroll } from 'react-use';
import ReactDOM from 'react-dom';
import { StyledBackdrop } from './Backdrop.styles';

interface BackdropProps {
  children: ReactNode;
  onBackdropClick: (event: MouseEvent) => void;
}

export const Backdrop = ({ children, onBackdropClick }: BackdropProps): ReactElement => {
  const backdrop = useRef<HTMLDivElement>(null);
  const body = useRef(document.body);

  useLockBodyScroll(true, body);

  const backdropClickHandler = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();

      if (event.target !== backdrop.current) {
        return;
      }

      onBackdropClick(event);
    },
    [onBackdropClick, backdrop],
  );

  const content = (
    <StyledBackdrop
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6, ease: [0.19, 1, 0.22, 1] }}
      ref={backdrop}
      onClick={backdropClickHandler}
    >
      {children}
    </StyledBackdrop>
  );

  // eslint-disable-next-line unicorn/prefer-query-selector
  return ReactDOM.createPortal(content, document.getElementById('modal') as HTMLDivElement);
};
