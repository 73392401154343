import { ReactElement } from 'react';
// eslint-disable-next-line import/no-unresolved
import { RegisteredFormItem } from 'globalTypes';
import { CreateObjectiveForm } from '../CreateObjectiveForm/CreateObjectiveForm';
import { Tabs } from 'components/atoms/Tabs/Tabs';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';

type ObjectiveFormPaginationProps = RegisteredFormItem;

export const ObjectiveFormPagination = ({
  register,
  formMethods,
  errors,
}: ObjectiveFormPaginationProps): ReactElement => {
  return (
    <TabsContainer>
      <Tabs tabs={['Objective']} />
      <div>
        <CreateObjectiveForm register={register} formMethods={formMethods} errors={errors} />
      </div>
    </TabsContainer>
  );
};
