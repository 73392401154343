import {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconButton } from '../IconButton/IconButton';
import { HeaderVariants } from './Header.styles';
import * as S from './Header.styles';
// import { Notifications } from '../../organisms/Notifications/Notifications';
import { MobileMenu } from '../MobileMenu/MobileMenu';
import { Cross, Hamburguer, Symbol, THNK } from 'components/atoms/Icon/Icon';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { useDeviceState } from 'hooks/useDeviceState';
import { Path } from 'routes/Path';
import { ProfileMenu } from 'components/organisms/ProfileMenu/ProfileMenu';
import { RootState } from 'redux/store';

interface HeaderProps {
  headerVariant: S.HeaderVariants;
  setHeaderVariant: Dispatch<SetStateAction<S.HeaderVariants>>;
  headerColorVariant?: S.HeaderColors;
  headerType?: S.HeaderTypes;
  headerRedirectPath: Path;
  children?: ReactNode;
}

export default function Header({
  headerVariant,
  headerType,
  headerColorVariant,
  setHeaderVariant,
  headerRedirectPath,
  children,
  ...props
}: HeaderProps): ReactElement {
  const { deviceState } = useDeviceState();
  const [mobilemenu, setMobileMenu] = useState(false);
  const [desktopview, setDesktopView] = useState(deviceState >= 2);
  const { pathname } = useLocation();
  const { push } = useHistory();
  const isDashboardPath = useMemo(() => pathname.includes(Path.Dashboard), [pathname]);
  const { userInfo } = useSelector((state: RootState) => state.auth);

  const handleHeaderClick = () => {
    push(headerRedirectPath);
  };

  useEffect(() => {
    const useDesktopHeader = deviceState >= 2;
    setDesktopView(useDesktopHeader);
  }, [deviceState]);

  useEffect(() => {
    let previousScrollPosition = window.pageYOffset;

    const handleScroll = debounce(() => {
      const currentScrollPosition = window.pageYOffset;

      const variant =
        previousScrollPosition > currentScrollPosition || currentScrollPosition < 100
          ? HeaderVariants.Active
          : HeaderVariants.Hidden;

      if (variant !== headerVariant) setHeaderVariant(variant);

      previousScrollPosition = currentScrollPosition;
    }, 66);

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerVariant]);

  return (
    <S.StyledHeader {...props} type={headerType} variant={headerVariant}>
      <S.StyledHeaderInner>
        <S.LogoWrapper $color={headerColorVariant}>
          <S.StyledLogo onClick={handleHeaderClick}>
            <Symbol />
            <div className="reveal">
              <THNK />
            </div>
          </S.StyledLogo>
        </S.LogoWrapper>

        {children}

        {headerType !== S.HeaderTypes.Logo && !children && userInfo?.role !== undefined && (
          <S.StyledHeaderNavigation>
            <S.StlyedHeaderNavigationSection>
              {desktopview && <ProfileMenu />}
              {/* Temporarily removed for MVP <Notifications /> */}
            </S.StlyedHeaderNavigationSection>

            {!desktopview && (
              <>
                {mobilemenu ? (
                  <IconButton
                    $color="blueA"
                    size="nav-icon"
                    onClick={() => setMobileMenu(false)}
                    icon={Cross}
                  />
                ) : (
                  <IconButton onClick={() => setMobileMenu(!mobilemenu)} icon={Hamburguer} />
                )}
              </>
            )}

            {desktopview && (
              <S.StlyedHeaderLastSection>
                {/* //* Removed for MVP
                 <LanguageSelect />
                 */}
                {!isDashboardPath && (
                  <PrimaryButton $size="regular" href="/dashboard">
                    <span>
                      <FormattedMessage
                        description="Header - Start Cta"
                        defaultMessage="My Dashboard"
                      />
                    </span>
                  </PrimaryButton>
                )}
              </S.StlyedHeaderLastSection>
            )}
            {mobilemenu && !desktopview && <MobileMenu />}
          </S.StyledHeaderNavigation>
        )}
      </S.StyledHeaderInner>
    </S.StyledHeader>
  );
}
