// Types

// Get selected challenges
export const GET_SELECTED_CHALLENGES = 'GET_SELECTED_CHALLENGES';

// Get selected questions
export const GET_SELECTED_QUESTIONS = 'GET_SELECTED_QUESTIONS';

// Get selected questions
export const GET_SELECTED_NEW_QUESTIONS = 'GET_SELECTED_NEW_QUESTIONS';

// Get selected name
export const GET_SELECTED_NAME = 'GET_SELECTED_NAME';

// Clear selected challenges
export const CLEAR_SELECTED_CHALLENGES = 'CLEAR_SELECTED_CHALLENGES';

// Remove selected challenge
export const REMOVE_SELECTED_CHALLENGE = 'REMOVE_SELECTED_CHALLENGE';
