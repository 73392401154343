import { ReactElement, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { MapModal } from './MapModal/MapModal';
import * as S from './Footer.styles';
import { AbstractButton } from 'components/atoms/AbstractButton/AbstractButton';
import { Path } from 'routes/Path';
import { Symbol, THNK } from 'components/atoms/Icon/Icon';

interface FooterProps {
  footerRedirectPath: Path;
}

export const Footer = ({ footerRedirectPath }: FooterProps): ReactElement => {
  const [modalOpen, setModalOpen] = useState(false);
  const { push } = useHistory();

  const handleModal = (open: boolean) => {
    document.body.style.overflow = open ? 'hidden' : 'auto';
    setModalOpen(open);
  };

  const handleIconClick = () => {
    push(footerRedirectPath);
  };

  return (
    <S.Footer>
      {modalOpen && <MapModal close={() => handleModal(false)} />}
      <S.ContentWrapper>
        <AbstractButton onClick={handleIconClick}>
          <Symbol />
          <THNK />
        </AbstractButton>
        <S.TermsPolicyWrapper>
          <S.TermsConditions
            href="https://www.thnk.org/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              description="Footer - TermsConditions"
              defaultMessage="Terms & Conditions"
            />
          </S.TermsConditions>
          <S.PrivacyPolicy
            href="https://www.thnk.org/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              description="Footer - PrivacyPolicy"
              defaultMessage="Privacy Policy"
            />
          </S.PrivacyPolicy>
        </S.TermsPolicyWrapper>
      </S.ContentWrapper>
    </S.Footer>
  );
};
