import { ApiClient } from '../util/apiClient';
import { createUnitDTO } from './dto/CreateUnitDTO';
import { OrganizationUnit } from '../model/OrganizationUnit';
import { Unit } from './types/UnitTypes';

enum UnitEndpoints {
  OrganizationalUnit = '/organizational-units',
}

const createUnit = async (
  customerId: number,
  unit: OrganizationUnit,
): Promise<OrganizationUnit> => {
  const { data } = await ApiClient.post(
    UnitEndpoints.OrganizationalUnit,
    createUnitDTO(customerId, unit),
  );

  const newUnit = unit;
  newUnit.id = data.id;
  return newUnit;
};

const updateUnit = async (unit: Unit): Promise<Unit> => {
  const { data } = await ApiClient.put(`${UnitEndpoints.OrganizationalUnit}/${unit.id}`, unit);

  return data;
};

const removeUnit = async (unitId: number): Promise<Unit> => {
  const { data } = await ApiClient.delete(`${UnitEndpoints.OrganizationalUnit}/${unitId}`);

  return data;
};

export const UnitService = { createUnit, updateUnit, removeUnit };
