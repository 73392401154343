import { ReactElement, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import ProtectedRoute from './custom/ProtectedRoute';
import { Path } from './Path';
import { Home } from 'components/pages/Home/Home';
import { Login } from 'components/pages/Login/Login';
import { Onboarding } from 'components/pages/Onboarding/Onboarding';
import { LoginFormType } from 'components/pages/Login/Login.types';
import { CreateObjective } from 'components/pages/CreateObjective/CreateObjective';
import { Profile } from 'components/pages/Profile/Profile';
import { Assessment } from 'components/pages/Assessment/Assessment';
import { AssessmentFeedback } from 'components/pages/AssessmentFeedback/AssessmentFeedback';
import { AssessmentCompleted } from 'components/pages/AssessmentCompleted/AssessmentCompleted';
import { TermsAndConditions } from 'components/pages/TermsAndConditions/TermsAndConditions';
import { PrivacyPolicy } from 'components/pages/PrivacyPolicy/PrivacyPolicy';
import { Error } from 'components/pages/Error/Error';
import { SuperAdmin } from 'components/pages/Superadmin/SuperAdmin';
import { useGlobalEventsHandler } from 'hooks/useGlobalEventsHandler';
import { Dashboard } from 'components/pages/Dashboard/Dashboard';
import { userRoles } from 'model/User';

export const Routes = (): ReactElement => {
  useGlobalEventsHandler();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Switch>
      <ProtectedRoute path={Path.Index} exact component={Home} />
      <ProtectedRoute path={Path.Home} exact component={Home} />
      <Route path={Path.Login} exact component={Login} />
      <ProtectedRoute path={`${Path.Onboarding}/:participantId`} exact component={Onboarding} />
      {/* For testing New Password form */}
      <Route path={Path.NewPassword} exact>
        <Login form={LoginFormType.NewPasswordForm} />
      </Route>
      <ProtectedRoute path={Path.Dashboard} component={Dashboard} />
      <ProtectedRoute path={Path.CreateObjective} component={CreateObjective} />
      <ProtectedRoute path={Path.SuperAdmin} component={SuperAdmin} role={userRoles.superAdmin} />
      <ProtectedRoute path={Path.Profile} exact component={Profile} />
      <Route path={Path.AssessmentFeedback} exact component={AssessmentFeedback} />
      <Route path={Path.AssessmentCompleted} exact component={AssessmentCompleted} />
      <Route path={`${Path.Assessment}/:cohortSurveyId`} exact component={Assessment} />
      <Route path={Path.TermsAndConditions} exact component={TermsAndConditions} />
      <Route path={Path.PrivacyPolicy} exact component={PrivacyPolicy} />
      <Route path={Path.PasswordPage} exact>
        <Login form={LoginFormType.CreatePasswordForm} />
      </Route>
      <Route path={Path.NotFound} exact>
        <Error type={404} label="Page not Found" />
      </Route>
    </Switch>
  );
};
