/* eslint-disable import/extensions */
import { useState, ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { ManagementOverview } from './pages/ManagementOverview/ManagementOverview';
import { ElementsManagement } from './pages/ElementsManagement/ElementsManagement';
import { ChallengesManagement } from './pages/ChallengesManagement/ChallengesManagement';
import { AssessmentManagement } from './pages/AssessmentManagement/AssessmentManagement';
import { QuestionsManagement } from './pages/QuestionsManagement/QuestionsManagement';
import { AddMissions } from './pages/AddMissions/AddMissions';
import { ClientOverview } from './pages/ClientOverview/ClientOverview';
import { CreateChallenge } from './pages/CreateChallenge/CreateChallenge';
import { CreateElement } from './pages/CreateElement/CreateElement';
import { AddClient } from './pages/AddClient/AddClient';
import { EditClient } from './pages/EditClient/EditClient';
import { CreateAssessment } from './pages/CreateAssessment/CreateAssessment';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { AssessmentHistory } from './pages/AssessmentHistory/AssessmentHistory';
import { EditAssessment } from './pages/EditAssessment/EditAssessment';
import { SuperAdminManagement } from './pages/SuperAdminManagement/SuperAdminManagement';
import { StyledLogoutButton } from 'components/pages/Superadmin/components/LogoutButton/LogoutButton.styles';
import { PillTabs } from 'components/atoms/PillTabs/PillTabs';
import Header from 'components/molecules/Header/Header';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';
import { HeaderVariants } from 'components/molecules/Header/Header.styles';
import { Path } from 'routes/Path';
import { Layout } from 'components/organisms/Layout/Layout';
import { Footer } from 'components/molecules/Footer/Footer';
import { SessionService } from 'services/SessionService';
import { SET_PROFILE_ID } from 'redux/ducks/authDuck/authTypes';
import { Flex } from 'components/atoms/Flex/FlexContainer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SuperAdminProps {}

// eslint-disable-next-line no-empty-pattern
export const SuperAdmin = ({}: SuperAdminProps): ReactElement => {
  const [headerVariant, setHeaderVariant] = useState<HeaderVariants>(HeaderVariants.Active);
  const { push, location } = useHistory();
  const dispatch = useDispatch();

  const managementToolPaths = [
    Path.ManagementTool,
    Path.ElementsManagement,
    Path.QuestionsManagement,
    Path.AssessmentManagement,
    Path.ChallengesManagement,
    Path.CreateChallenge,
    Path.CreateElement,
    Path.AddMissions,
    Path.CreateAssessment,
    Path.EditAssessment,
    Path.EditChallenge,
    Path.SuperAdminManagement,
  ];

  const getActiveTab = (pathname: Path) => (managementToolPaths.includes(pathname) ? 1 : 0);

  const [active, setActive] = useState(getActiveTab(location.pathname as Path));

  useEffect(() => {
    setActive(getActiveTab(location.pathname as Path));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleSignOut = async () => {
    try {
      await SessionService.logout();
      dispatch({
        type: SET_PROFILE_ID,
        payload: null,
      });
    } catch (error) {
      // Do nothing
    } finally {
      localStorage.removeItem('token');
      push(Path.Login);
    }
  };

  return (
    <Layout padding="small" overflowHidden>
      <Header
        headerVariant={headerVariant}
        setHeaderVariant={setHeaderVariant}
        headerRedirectPath={Path.ClientOverview}
      >
        <StyledLogoutButton type="button" onClick={handleSignOut}>
          Sign Out
        </StyledLogoutButton>
      </Header>
      <TabsContainer
        onActivate={(active) => {
          const mainPages = [Path.ClientOverview, Path.ManagementTool];
          push(mainPages[active]);
        }}
        defaultActive={active}
      >
        <Flex container style={{ marginTop: '140px' }} justifyContent="center">
          <PillTabs active={active} tabs={['Client Overview', 'Management Tool']} />
        </Flex>
        <main>
          <Switch>
            <Route path={Path.ClientOverview} component={ClientOverview} />
            <Route path={`${Path.AdminDashboard}/:clientId`} component={Dashboard} />
            <Route path={Path.AddClient} component={AddClient} />
            <Route path={Path.EditClient} component={EditClient} />
            <Route path={Path.AssessmentHistory} component={AssessmentHistory} />
          </Switch>
          <Switch>
            <Route path={Path.ManagementTool} component={ManagementOverview} />
            <Route path={Path.ElementsManagement} component={ElementsManagement} />
            <Route path={Path.CreateElement} component={CreateElement} />
            <Route path={Path.ChallengesManagement} component={ChallengesManagement} />
            <Route path={Path.AssessmentManagement} component={AssessmentManagement} />
            <Route path={Path.QuestionsManagement} component={QuestionsManagement} />
            <Route path={Path.SuperAdminManagement} component={SuperAdminManagement} />
            <Route path={Path.CreateChallenge} component={CreateChallenge} />
            <Route path={Path.AddMissions} component={AddMissions} />
            <Route path={Path.CreateAssessment} component={CreateAssessment} />
            <Route path={Path.EditAssessment} component={EditAssessment} />
            <Route path={Path.EditChallenge} component={CreateChallenge} />
          </Switch>
        </main>
      </TabsContainer>
      <Footer footerRedirectPath={Path.ClientOverview} />
    </Layout>
  );
};
