import { ApiClient } from 'util/apiClient';
import { Profile, ProfilePutBody } from 'services/types/ProfileTypes';

enum ProfileEndpoints {
  Profile = '/profiles',
  ProfilePicture = '/profiles/update-image',
}

const getProfile = async (): Promise<Profile> => {
  const { data } = await ApiClient.get(ProfileEndpoints.Profile);

  return data;
};

const updateProfile = async (body: ProfilePutBody): Promise<Profile> => {
  const { data } = await ApiClient.put(ProfileEndpoints.Profile, body);
  return data;
};

const updateProfilePicture = async (img: string): Promise<string> => {
  const { data } = await ApiClient.put(ProfileEndpoints.ProfilePicture, { img });
  return data;
};

export const ProfileService = { getProfile, updateProfile, updateProfilePicture };
