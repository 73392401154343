/* eslint-disable react/no-array-index-key */
import { ReactElement } from 'react';
import * as S from './StatusIndicator.styles';

export type StatusValue = {
  type: S.StatusType;
  copy?: string;
};

export interface StatusIndicatorProps {
  status: StatusValue;
  oneColor?: boolean;
  small?: boolean;
}

export const StatusIndicator = ({
  status,
  small,
  ...props
}: StatusIndicatorProps): ReactElement => {
  return (
    <S.StyledIndicator small={small} type={status.type} {...props}>
      {status.copy && status.copy}
    </S.StyledIndicator>
  );
};
