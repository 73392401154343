import { ReactElement, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as S from './ClientAdminDashboard.styles';
import { AssessmentDashboard } from 'components/pages/AssessmentDashboard/AssessmentDashboard';
import { StickyTabs } from 'components/molecules/StickyTabs/StickyTabs';
import { MediaQuery } from 'styles/mediaQuery';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Heading } from 'components/atoms/Heading/Heading';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { PillTabs } from 'components/atoms/PillTabs/PillTabs';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';
import { AdminDashboardWrapper } from 'components/organisms/AdminDashboardWrapper/AdminDashboardWrapper';
import { AdminDashboardTable } from 'components/pages/ClientAdminDashboard/content/AdminDashboardTable';
import { dashboardActions } from 'redux/ducks/dashboardDuck/dashboardReducer';
import { ParticipantDashboardWrapper } from 'components/organisms/ParticipantDashboardWrapper/ParticipantDashboardWrapper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ClientAdminDashboardProps {}

// eslint-disable-next-line no-empty-pattern
export const ClientAdminDashboard = ({}: ClientAdminDashboardProps): ReactElement => {
  const [currentTab, setCurrentTab] = useState(0);
  const intl = useIntl();
  const dispatch = useDispatch();

  const tabsProps = {
    tabs: [
      intl.formatMessage({
        description: 'Admin Dashboard - Tabs - Assessment Tab',
        defaultMessage: 'my dashboard',
      }),
      intl.formatMessage({
        description: 'Admin Dashboard - Tabs - Admin Tab',
        defaultMessage: 'admin screen',
      }),
    ],
    color: 'redA',
  };

  const isAdminTab = useMemo(() => currentTab === 1, [currentTab]);
  const handleChangeTab = (index: number) => {
    dispatch(dashboardActions.setCurrentCohortIndex(0));
    setCurrentTab(index);
  };

  return (
    <NormalLayout padding="normal">
      <BackgroundCircle
        lastBackground
        position="fixed"
        top="70rem"
        left="-20%"
        size="90vw"
        color="blueE"
      />
      <S.StyledDashboardContainer>
        <>
          <S.StyledFlex
            container
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Flex flexBasis={[[MediaQuery.MAX_1023, '100%']]}>
              <Heading type={HeadingType.H2}>
                <FormattedMessage
                  description="Admin Dashboard - Heading"
                  defaultMessage="My dashboard"
                />
              </Heading>
            </Flex>
            <PillTabs active={currentTab} setActive={handleChangeTab} {...tabsProps} />
            <StickyTabs active={currentTab} setActive={handleChangeTab} {...tabsProps} />
          </S.StyledFlex>

          {isAdminTab ? (
            <>
              <AssessmentDashboard>
                <AdminDashboardWrapper />
              </AssessmentDashboard>
              <AdminDashboardTable />
            </>
          ) : (
            <>
              <AssessmentDashboard>
                <ParticipantDashboardWrapper />
              </AssessmentDashboard>
            </>
          )}
        </>
      </S.StyledDashboardContainer>
    </NormalLayout>
  );
};
