import { useIntl } from 'react-intl';
import { Size } from '../model/Customer';

type UseClientSizeTransformer = {
  ClientSizeTransformer: { [s in Size]: string | undefined };
  adaptedClientSizes: Array<{ title: string; value: string }>;
};

export const useClientSizeTransformer = (): UseClientSizeTransformer => {
  const intl = useIntl();

  // TODO: Add all copies to intl
  const ClientSizeTransformer = {
    [Size.LessThan50]: intl.formatMessage({
      defaultMessage: 'Less than 50',
      description: 'Client form - Client Select Size - Less than 50',
    }),
    [Size.From51To250]: '51-250',
    [Size.From251To1000]: '251 - 1000',
    [Size.From1001To10000]: '1001 - 10.000',
    [Size.MoreThan10000]: intl.formatMessage({
      defaultMessage: 'More than 10.000',
      description: 'Client form - Client Select Size - More than 10.000',
    }),
  };

  const adaptedClientSizes = Object.entries(ClientSizeTransformer).map(([value, title]) => ({
    title,
    value,
  }));

  return {
    ClientSizeTransformer,
    adaptedClientSizes,
  };
};
