import styled, { keyframes } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const StyledContainer = styled.div`
  z-index: 1;
  background-color: ${({ theme }) => hexToRgba(theme.color.greyD, 0.25)};
  position: relative;
  height: 100%;
  width: 100%;
`;

export const StyledSpinner = styled.div`
  opacity: 0.9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border: 10px solid ${({ theme }) => theme.color.greyD};
  border-top: 10px solid ${({ theme }) => theme.color.blueA};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: ${spin} 1s linear infinite;
`;
