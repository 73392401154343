import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { typeStyle } from 'styles/typeStyle';

export const StyledIntroItem = styled.div`
  &:not(:first-of-type) {
    padding-top: 3rem;

    @media ${respondTo(MediaQuery.MIN_1024)} {
      padding-top: 4rem;
    }
  }

  @media ${respondTo(MediaQuery.MAX_1023)} {
    svg {
      max-width: 4rem;
    }

    &:nth-of-type(2) {
      svg {
        height: 5rem;
        transform: rotate(270deg) scaleX(-1) translateY(0.5rem);
      }
    }
    &:nth-of-type(3) {
      svg {
        margin-bottom: 1rem;
      }
    }
    &:nth-of-type(4) {
      svg {
        height: 5rem;
        transform: rotate(90deg) translateY(-0.8rem);
      }
    }
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    display: flex;
  }
`;

export const StyledItemContent = styled.div`
  max-width: 50.4rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-left: 5.5rem;
  }
`;

export const StyledListItem = styled.li`
  ${typeStyle.label};

  &:not(:last-of-type) {
    margin-bottom: 0.9rem;
  }

  &::before {
    content: '';
    display: inline-block;
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 50%;
    margin-right: 0.8rem;
    background: ${({ theme }) => theme.color.redB};
    vertical-align: top;
    margin-top: 0.6rem;
  }
`;
