/* eslint-disable babel/no-unused-expressions */
import { useRef, useState } from 'react';

type PromiseResolver = (value: unknown) => void;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useModal = <T>() => {
  const [data, setData] = useState<T | false>(false);
  const resolver = useRef<PromiseResolver>(null);

  const setModal = (data: T) => {
    setData(data);

    return new Promise((resolve) => {
      (resolver.current as PromiseResolver) = resolve;
    });
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);
    setData(false);
  };

  const handleConfirm = () => {
    resolver.current && resolver.current(true);
    setData(false);
  };

  return {
    data,
    resolver,
    setData,
    setModal,
    handleCancel,
    handleConfirm,
  };
};
