import styled from 'styled-components';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledDashboardContainer = styled.div`
  padding: 9rem 0;
  position: relative;
  z-index: 1;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 2.6rem 0;
  }
`;

export const StyledFlex = styled(Flex)`
  margin: 0 0 4rem;
`;
