import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { styledScrollbar } from 'styles/styledScrollbar';
import { ColorKey } from 'styles/theme/default';
import { typeStyle } from 'styles/typeStyle';
import { zIndex } from 'styles/zIndex';

export interface TableStyleProps {
  $color?: ColorKey;
  $thColor?: ColorKey;
}

export const TableWrapper = styled.div<{ maxHeight?: string }>`
  ${styledScrollbar};
  max-height: ${({ maxHeight }) => maxHeight || '44rem'};
  overflow-y: auto;
`;

export const StyledTable = styled.table<TableStyleProps>`
  width: 100%;
  position: relative;
  border-spacing: 0;
  vertical-align: baseline;
  color: ${({ theme, $color }) => ($color ? theme.color[$color] : theme.color.greyA)};
  table-layout: auto;

  ${({ theme, $thColor }) =>
    $thColor &&
    `
    th {
      color: ${theme.color[$thColor]};
    }
    `}
`;

export const THead = styled.thead`
  top: 0;
  position: sticky;
  background: ${({ theme }) => theme.color.greyB};
  z-index: ${zIndex('tableHead')};
`;

export const TableBody = styled.tbody`
  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding-right: 1rem;
  }
`;

export const TableTH = styled.th<{ align?: string; width?: string }>`
  ${typeStyle.label};
  height: 4rem;
  text-align: ${({ align }) => align || 'left'};
  ${({ align }) => align !== 'right' && align !== 'center' && 'padding-right: 2rem;'};
`;
