/* eslint-disable import/no-unresolved */
import { memo, ReactElement, useMemo, useState } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { AnimatePresence } from 'framer-motion';
import { useDispatch } from 'react-redux';
import * as S from './Invite.styles';
import { PeopleListItem } from './PeopleListItem/PeopleListItem';
import { EmailForm } from './EmailForm/EmailForm';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { Hide } from 'components/atoms/Hide/Hide';
import { MediaQuery } from 'styles/mediaQuery';
import { AssessmentService } from 'services/AssessmentService';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { setErrorEvent, setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';

export type InviteVariantType = 'blue' | 'grey';

interface InviteProps {
  assessment_id: number;
  title: string;
  paragraph: string;
  cta: string;
  variant?: InviteVariantType;
  onComplete: () => void;
}

export const Invite = memo(
  ({
    variant = 'blue',
    title,
    paragraph,
    cta,
    assessment_id,
    onComplete,
    ...props
  }: InviteProps): ReactElement => {
    const [invitelist, setInviteList] = useState<Array<{ email: string; name: string }>>([]);
    const secondaryColor = useMemo(() => (variant === 'blue' ? 'cream' : 'blueA'), [variant]);
    const dispatch = useDispatch();

    const handleDeleteGuest = (guestToDelete: { email: string; name: string }) =>
      setInviteList(invitelist.filter((guest) => guest.email !== guestToDelete.email));

    const handleFormSuccess = async (
      formData: FieldValues,
      methods: UseFormReturn<FieldValues>,
    ) => {
      setInviteList([...invitelist, formData.guest]);
      methods.reset();
    };

    const handleSendInvites = async () => {
      try {
        await Promise.all(
          invitelist.map(({ name, email }) =>
            AssessmentService.inviteAssessor({ name, email, assessment_id }),
          ),
        );
        dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
        onComplete();
      } catch (error) {
        dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
      }
    };

    return (
      <S.StyledInvite {...props} variant={variant} className="full-bleed">
        <S.StyledHeading type={HeadingType.H2} color={secondaryColor}>
          {title}
        </S.StyledHeading>
        <S.StyledInviteFormWrapper>
          <EmailForm
            invitelist={invitelist}
            handleFormSuccess={handleFormSuccess}
            secondaryColor={secondaryColor}
          />
          <S.StyledPeopleListContainer>
            <Paragraph color={secondaryColor} margin="0 0 3.2rem">
              {paragraph}
            </Paragraph>
            <ul>
              <AnimatePresence initial={false}>
                {invitelist.map((guest) => (
                  <PeopleListItem
                    key={guest.email}
                    onDelete={() => handleDeleteGuest(guest)}
                    person={guest}
                    variant={variant}
                  />
                ))}
              </AnimatePresence>
            </ul>
          </S.StyledPeopleListContainer>
          <S.StyledSendButtonContainer>
            <PrimaryButton
              disabled={invitelist.length === 0}
              $color={secondaryColor === 'cream' ? 'blueA' : 'cream'}
              $backgroundColor={secondaryColor}
              $disabledBackgroundColor="blueG"
              $disabledColor="cream"
              onClick={handleSendInvites}
            >
              {cta}
            </PrimaryButton>
          </S.StyledSendButtonContainer>
        </S.StyledInviteFormWrapper>
        {variant === 'blue' && (
          <>
            <BackgroundCircle
              lastBackground
              color="redA"
              size="41rem"
              bottom="-20.5rem"
              left="-20.5rem"
            />
            <Hide screen={MediaQuery.MIN_1024}>
              <BackgroundCircle
                lastBackground
                color="redA"
                size="41rem"
                top="-20.5rem"
                right="-20.5rem"
              />
            </Hide>
          </>
        )}
      </S.StyledInvite>
    );
  },
);
