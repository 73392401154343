/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import { RegisteredFormItem } from 'globalTypes';
import { ReactElement, useEffect, useState } from 'react';
import * as S from './QuestionLevel.styles';
import {
  FormItemSizes,
  StyledLabel,
} from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Text } from 'components/atoms/Text/Text';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { Select, SelectItem } from 'components/molecules/Select/Select';
import { AdminHeading } from 'components/pages/Superadmin/components/AdminHeading/AdminHeading';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { assessmentData } from 'data/assessmentData';

export const QuestionLevel = ({
  register,
  formMethods,
  errors,
  value,
}: RegisteredFormItem): ReactElement => {
  const [questionLevel, setQuestionLevel] = useState(0);
  const [defaultQuestionLevel, setDefaultQuestionLevel] = useState(0);

  const handleQuestionLevelSelect = (item: SelectItem) => {
    const questionLevel = parseInt(item.value.toString().split('-')[1], 10);
    setQuestionLevel(questionLevel);

    formMethods.setValue('question-level', questionLevel);
  };

  useEffect(() => {
    if (value) {
      if (value <= 5) setDefaultQuestionLevel(0);
      if (value > 5 && value < 7) setDefaultQuestionLevel(1);
      if (value >= 7 && value <= 10) setDefaultQuestionLevel(2);
    }
  }, [value]);

  return (
    <Flex container flexDirection="column" gap="1.5rem">
      <AdminHeading title="Set Question Characteristics" $size="small" $underline />
      <Flex container gap="2rem">
        <Flex gap="2rem" flexBasis="50%">
          <RegisterFormItem
            register={register}
            formMethods={formMethods}
            errors={errors}
            registerErrors="required"
            name="question-level"
            label="Question Level"
            size={FormItemSizes.Medium}
            alignError="right"
          >
            <Select
              onSelect={handleQuestionLevelSelect}
              items={assessmentData.scores}
              defaultValue={assessmentData.scores[defaultQuestionLevel].value}
              variant="input"
              placeholder="Select an option"
            />
          </RegisterFormItem>
        </Flex>
        <Flex gap="2rem" flexGrow="1">
          <StyledLabel size={FormItemSizes.Large}>Final Question Slider</StyledLabel>
          <S.StyledScoreBar score={3} barDots={questionLevel + 1} variant="blue" />
        </Flex>
      </Flex>
      <Text type="label">Set a number between 5 and 10</Text>
    </Flex>
  );
};
