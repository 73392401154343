import { ReactElement, ReactNode } from 'react';
import * as S from './Text.styles';
import { typeStyle } from 'styles/typeStyle';

interface TextProps extends S.TextStyleProps {
  children: ReactNode;
  as?: string;
  type?: keyof typeof typeStyle;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const Text = ({ type, as = 'p', children, ...props }: TextProps): ReactElement => {
  return (
    <S.StyledText {...props} $type={type} as={as as unknown as undefined}>
      {children}
    </S.StyledText>
  );
};
