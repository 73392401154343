import { motion } from 'framer-motion';
import styled from 'styled-components';
import { AbstractButton } from 'components/atoms/AbstractButton/AbstractButton';

export const StyledLogoutButton = styled(motion(AbstractButton))`
  padding: 12px;
  border-radius: 2px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.blueA};
  background-color: ${({ theme }) => theme.color.greyB};
`;
