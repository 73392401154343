/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactElement, useState } from 'react';
import { TableControlProps } from '../Table.types';
import * as S from './TableControl.styles';
import * as icons from 'components/atoms/Icon/Icon';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { ChevronButton } from 'components/atoms/ChevronButton/ChevronButton';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Text } from 'components/atoms/Text/Text';
import { FontWeight } from 'styles/variables';

export const TableControl = ({
  tableRow,
  type,
  checkboxLabel,
  checkboxDefaultChecked,
  copy,
  mobiledropdown,
  rowIndex,
  action,
  actions,
  onChange,
  align,
  areActionsDisabled,
}: TableControlProps): ReactElement => {
  const [toggle, setToggle] = useState<boolean>(true);

  const handleOnClick = () => {
    if (action === 'AddOrRemove' || action === 'RemoveOrAdd') {
      setToggle(!toggle);
    }

    const isToggled = (type === 'Add' && true) || (type === 'Remove' && false);

    if (action && actions) {
      actions[action as string](
        rowIndex as number,
        tableRow,
        type === 'Add' || type === 'Remove' ? isToggled : (toggle as boolean),
      );
    }
  };

  let Icon;
  switch (type) {
    case 'Checkbox':
      return (
        <Flex container gap="1rem" justifyContent={align === 'center' ? 'center' : 'flex-start'}>
          {checkboxLabel && (
            <Text color="inherit" fontWeight={FontWeight.Normal} type="label">
              {checkboxLabel}
            </Text>
          )}
          <Checkbox
            isDisabled={areActionsDisabled}
            name={`row-checkbox-${rowIndex}`}
            onChange={onChange}
            checked={checkboxDefaultChecked}
          />
        </Flex>
      );

    case 'ChevronButton':
      return <ChevronButton open={mobiledropdown} />;

    case 'Button':
      return (
        <SecondaryButton color="blueA" onClick={handleOnClick}>
          <span>{copy}</span>
        </SecondaryButton>
      );

    case 'AddOrRemove':
      Icon = toggle ? icons.Remove : icons.AddNew;
      break;

    case 'Add':
      Icon = icons.AddNew;
      break;

    case 'Remove':
      Icon = icons.Remove;
      break;

    case 'Link':
      Icon = icons.ArrowExternal;
      break;

    case 'RemoveOrAdd':
      Icon = toggle ? icons.AddNew : icons.Remove;
      break;

    default:
      Icon = icons[type];
  }

  return (
    <S.TableControlButton
      type="button"
      onClick={handleOnClick}
      disabled={areActionsDisabled}
      style={{
        cursor: areActionsDisabled ? 'auto' : 'pointer',
        /*
        Hidding the disabled action temporarily.
        TODO: Remove this when no longer needed.
        */
        display: areActionsDisabled ? 'none' : 'inline-block',
      }}
    >
      <Icon style={{ color: areActionsDisabled ? 'grey' : '' }} />
    </S.TableControlButton>
  );
};
