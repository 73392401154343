import { ReactElement, ReactNode } from 'react';
import { ConfirmModal } from '../ConfirmModal/ConfirmModal';
import { FormModal } from '../FormModal/FormModal';
import { ShareModal } from '../ShareModal/ShareModal';
import * as S from './Layout.styles';

export interface LayoutProps extends S.LayoutStyleProps {
  children: ReactNode;
}

export const Layout = ({ children, ...props }: LayoutProps): ReactElement => {
  return (
    <S.StyledLayout {...props}>
      <ConfirmModal>
        <FormModal>
          <ShareModal>{children}</ShareModal>
        </FormModal>
      </ConfirmModal>
    </S.StyledLayout>
  );
};
