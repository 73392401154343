import type { ReactElement } from 'react';
import Select from 'react-select';
import * as S from './MultiSelect.styles';
import { theme } from 'styles/theme/default';

interface MultiSelectProps {
  options?: Array<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (input: any) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  label?: string;
}

export const MultiSelect = ({
  label,
  defaultValue,
  onChange,
  options,
}: MultiSelectProps): ReactElement => (
  <S.StyledMultiSelect>
    {label && <S.StyledLabel>{label}</S.StyledLabel>}
    <Select
      options={options}
      value={defaultValue}
      onChange={onChange}
      isMulti
      styles={{ control: (provided) => ({ ...provided, backgroundColor: theme.color.greyB }) }}
    />
  </S.StyledMultiSelect>
);
