import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { ParticipantDashboardWrapper } from 'components/organisms/ParticipantDashboardWrapper/ParticipantDashboardWrapper';
import { AssessmentDashboard } from 'components/pages/AssessmentDashboard/AssessmentDashboard';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UserDashboardProps {}

// eslint-disable-next-line no-empty-pattern
export const UserDashboard = ({}: UserDashboardProps): ReactElement => {
  const intl = useIntl();

  return (
    <NormalLayout padding="normal">
      <AssessmentDashboard
        title={intl.formatMessage({
          description: 'User Dashboard - Heading',
          defaultMessage: 'My dashboard',
        })}
      >
        <ParticipantDashboardWrapper />
      </AssessmentDashboard>
    </NormalLayout>
  );
};
