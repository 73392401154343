/* eslint no-param-reassign: ["error", { "props": false }] */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  FunctionsBaseProps,
  FunctionsGetProps,
  FunctionsInitialStateProps,
} from './functionsTypes';
import { ApiPayloadArguments, ApiPayloadOptions, ApiPayloadReturn, ApiResponse } from 'redux/types';
import { ApiClient } from 'util/apiClient';

const functionsApiHandler = createAsyncThunk<
  ApiPayloadReturn<FunctionsGetProps, FunctionsGetProps>,
  ApiPayloadArguments<FunctionsBaseProps>,
  ApiPayloadOptions
>('functions/functionsApiHandler', async (payloadArguments) => {
  const { data, message } = (await ApiClient[payloadArguments.method](
    '/functions',
    payloadArguments.body,
  )) as ApiResponse<FunctionsGetProps, FunctionsGetProps>;

  return { data, message, method: payloadArguments.method };
});

const initialState: FunctionsInitialStateProps = {
  functions: [],
  loading: false,
  showFunctionsError: null,
};

const functionsSlice = createSlice({
  name: 'functions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(functionsApiHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(functionsApiHandler.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.functions = data as Array<FunctionsGetProps>;
        state.loading = false;
      })
      .addCase(functionsApiHandler.rejected, (state, action) => {
        const message = action.payload?.message;
        state.showFunctionsError = message || null;
        state.loading = false;
      });
  },
});

export const functionsActions = {
  ...functionsSlice.actions,
  functionsApiHandler,
};

export default functionsSlice.reducer;
