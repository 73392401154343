import styled from 'styled-components';
import { Heading } from 'components/atoms/Heading/Heading';
import { Hide } from 'components/atoms/Hide/Hide';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { Message } from 'components/atoms/Message/Message';

export const StyledObjectives = styled.section`
  position: relative;
`;

export const StyledObjectivesGap = styled.div`
  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding-bottom: 300vh;
  }
`;

export const StyledObjectivesSticky = styled.div`
  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: 12rem 0;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    position: sticky;
    top: 0;
    height: 100vh;
    min-height: 80rem;
    display: flex;
    align-items: center;
  }
`;

export const StyledObjectivesInner = styled.div`
  display: flex;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
  }
`;

export const StyledObjectivesLeft = styled.div`
  padding-top: 3rem;
  flex-basis: 100%;
  min-width: 100%;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding: 11rem 0 0 ${({ theme }) => theme.sitePaddings.desktop};
    flex-basis: 45%;
    min-width: 45%;
  }
`;

export const StyledTitleHighlight = styled.span`
  display: block;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.color.redA};
`;

export const StyledHide = styled(Hide)`
  margin-bottom: 3rem;
  padding-left: ${({ theme }) => theme.sitePaddings.mobile};
`;

export const StyledHeading = styled(Heading)`
  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-bottom: 8.6rem;
  }
`;

export const StyledObjectivesContainer = styled.div`
  overflow: hidden;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding: 0 0 0 15%;
  }
`;

export const StyledObjectivesCarousel = styled.div`
  display: flex;
  transition: transform 500ms ease;
`;

export const StyledObjectivesRight = styled.div`
  flex-basis: 100%;
  min-width: 100%;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex-basis: 55%;
    min-width: 55%;
  }
`;

export const StyledObjectivesRightInner = styled.div`
  position: relative;
  height: 37.5rem;
  display: flex;
  align-items: center;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    height: 79.6rem;
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.color.redA};
    border-radius: 39.8rem 0px 0px 39.8rem;
  }
`;

export const StyledMessage = styled(Message)`
  position: absolute;
  top: 5rem;
  right: 8%;
`;
