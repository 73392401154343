import { ApiClient } from '../util/apiClient';
import { createCohortDTO } from './dto/CreateCohortDTO';
import { Cohort } from '../model/Cohort';

enum CohortEndpoints {
  Cohort = '/cohorts',
  CohortParticipants = '/cohorts-participants',
}

const createCohort = async (
  unitId: number,
  subunitId: number,
  customerId: number,
  cohort: Cohort,
): Promise<Cohort> => {
  const { data } = await ApiClient.post(
    CohortEndpoints.Cohort,
    createCohortDTO(unitId, subunitId, customerId, cohort),
  );

  const newCohort = cohort;
  newCohort.id = data.id;
  return newCohort;
};

const deleteCohort = async (cohortId: number): Promise<void> => {
  const { data } = await ApiClient.delete(`${CohortEndpoints.Cohort}/${cohortId}`);
  return data;
};

const editCohort = async (
  unitId: number,
  subunitId: number,
  customerId: number,
  cohort: Cohort,
): Promise<void> => {
  const { data } = await ApiClient.put(
    `${CohortEndpoints.Cohort}/${cohort.id}`,
    createCohortDTO(unitId, subunitId, customerId, cohort),
  );
  return data;
};

const addParticipantToCohort = async (
  participant_id: number,
  cohort_id: number,
): Promise<unknown> => {
  const { data } = await ApiClient.post(CohortEndpoints.CohortParticipants, {
    participant_id,
    cohort_id,
  });

  return data;
};

const removeParticipantFromCohort = async (cohort_participant_id: number): Promise<unknown> => {
  const { data } = await ApiClient.delete(
    `${CohortEndpoints.CohortParticipants}/${cohort_participant_id}`,
  );
  return data;
};

export const CohortService = {
  createCohort,
  addParticipantToCohort,
  removeParticipantFromCohort,
  deleteCohort,
  editCohort,
};
