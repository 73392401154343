import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';
import InertiaPlugin from 'gsap/InertiaPlugin';
import { debounce } from 'lodash';

gsap.registerPlugin(Draggable, InertiaPlugin);

interface DraggableElementProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watch?: Array<any>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDraggableElement = (props: DraggableElementProps = {}) => {
  const draggableRef = useRef<HTMLDivElement | null>(null);

  const hasDraggable = () => Draggable.get(draggableRef.current);

  const canDrag = () => {
    const draggable = draggableRef.current;

    return draggable && draggable.parentElement
      ? draggable.offsetWidth > draggable.parentElement.offsetWidth
      : false;
  };

  const createDraggable = () => {
    const draggable = draggableRef.current as HTMLElement;

    Draggable.create(draggable, {
      type: 'x',
      bounds: draggable.parentElement,
      inertia: true,
      edgeResistance: 0.8,
      resistance: 0.75,
    });
  };

  const killDraggable = () => {
    const draggable = draggableRef.current as HTMLDivElement;

    draggable.style.transform = '';

    Draggable.get(draggable).kill();
  };

  useEffect(() => {
    const handleResize = debounce(() => {
      const temporaryHasDraggable = hasDraggable();
      const temporaryCanDrag = canDrag();

      if (temporaryCanDrag && !temporaryHasDraggable) {
        createDraggable();
      } else if (!temporaryCanDrag && temporaryHasDraggable) {
        killDraggable();
      }
    }, 100);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (canDrag() && !hasDraggable()) {
      createDraggable();
    }

    // eslint-disable-next-line consistent-return
    return () => hasDraggable() && killDraggable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(props.watch || [])]);

  return { draggableRef };
};
