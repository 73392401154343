import { Keyframes, keyframes } from 'styled-components';

export const rotate = keyframes`
	from {
		transform: rotate3d(0, 0, 1, 0deg);
	}
  to {
		transform: rotate3d(0, 0, 1, 360deg);
	}
`;

export const bounce = (
  axis: 'x' | 'y',
  offset: number = 10,
  rotate: number = 0,
): Keyframes => keyframes`
  0%{
    transform: ${
      axis === 'x' ? `translateX(0%) rotate(${rotate}deg)` : `translateY(0%) rotate(${rotate}deg)`
    };
  }
  50% {
    transform: ${
      axis === 'x'
        ? `translateX(${offset}%) rotate(${rotate}deg)`
        : `translateY(${offset}%) rotate(${rotate}deg)`
    };
  }
  100% {
    transform: ${
      axis === 'x' ? `translateX(0%) rotate(${rotate}deg)` : `translateY(0%) rotate(${rotate}deg)`
    };
  }
`;
