/* eslint-disable no-console */
import axios from 'axios';

export const getToken = (): string => {
  const tokenQueryParam = new URLSearchParams(window.location.search).get('token');
  if (tokenQueryParam) return `Bearer ${tokenQueryParam}`;

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  return token ? `Bearer ${token}` : '';
};

const API_URL = process.env.REACT_APP_API_URL;

export const ApiClient = axios.create({
  baseURL: API_URL,
  timeout: 20 * 1000,
});

axios.interceptors.request.use((request) => {
  console.log('Starting Request', JSON.stringify(request, null, 2));
  return request;
});

ApiClient.interceptors.request.use((config) => {
  const token = getToken();

  const headerConfig = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...(token && { Authorization: token }),
  };

  Object.assign(config, { headers: headerConfig });
  return config;
});

ApiClient.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error),
);
