import styled, { css } from 'styled-components';
import { Input } from 'components/atoms/Input/Input';
import { styledInput } from 'styles/styledInput';
import { ColorKey } from 'styles/theme/default';
import { FontWeight } from 'styles/variables';
import { Ease } from 'styles/easing';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export interface InputVariants {
  variant?: 'input';
  hasError?: boolean;
  isOpen?: boolean;
  width?: string;
  margin?: string;
}

export interface SelectStyleProps extends InputVariants {
  color?: ColorKey;
}

export const StyledLabel = styled.label`
  color: ${({ theme }) => theme.color.black};
  font-weight: ${FontWeight.Normal};
  display: block;
  margin-bottom: 0.8rem;
  font-size: 1.6rem;
  margin-bottom: 0.9rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    font-size: 2.4rem;
    margin-bottom: 2.4rem;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    max-width: 40rem;
  }

  @media ${respondTo(MediaQuery.MIN_1280)} {
    max-width: none;
  }
`;

export const StyledSelect = styled.div<SelectStyleProps>`
  position: relative;
  max-width: 100%;

  ${({ margin }) => margin && `margin: ${margin};`}

  ${({ color, theme }) => css`
    color: ${color ? theme.color[color] : theme.color.greyA};
  `}
`;

export const StyledSelectedItem = styled.button<InputVariants>`
  font-size: 1.8rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.black};
  background-color: ${({ theme }) => theme.color.white};
  display: inline-flex;
  min-height: 4.2rem;
  align-items: center;
  transition: border-color 0.3s ease;
  max-width: 100%;

  ${({ disabled, theme }) =>
    disabled &&
    `
    background-color: ${theme.color.greyG};
    color: ${theme.color.blackB};
    cursor: default;
  `}

  ${({ variant, theme, hasError, isOpen }) => css`
    ${variant === 'input'
      ? `
  ${styledInput(theme, isOpen, hasError)}
  justify-content: space-between;
  `
      : `font-weight: ${FontWeight.SemiBold};`}
  `}

  ${({ width }) => width && `width: ${width};`}

  /* ${({ isOpen, theme }) => isOpen && `border-color: ${theme.color.blueA}`};
  ${({ isOpen, theme }) => isOpen && `border-bottom-color: ${theme.color.greyC}`}; */

  svg {
    margin-left: 5px;
    fill: currentColor;
  }
`;

export const StyledSelectedItemText = styled.span`
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledSelectList = styled.ul<InputVariants>`
  position: absolute;
  top: 100%;
  left: 50%;
  max-height: 350px;
  overflow-y: auto;
  transform: translateX(-50%) scaleY(0.5);
  background: ${({ theme }) => theme.color.cream};
  border: 1px solid ${({ theme }) => theme.color.greyC};
  border-top: 0;
  border-radius: 0 0 2px 2px;
  padding: 1rem 0;
  z-index: 10;
  opacity: 0;
  transition: transform 0.3s ${Ease.EaseOutExpo};
  transform-origin: top;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen &&
    `
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) scaleY(1);
  `}

  ${({ variant }) => css`
    ${variant === 'input'
      ? `width: 100%;  font-size: 1.6rem;
    `
      : `font-weight: ${FontWeight.SemiBold};`}
  `}
`;

export const StyledSelectListItem = styled.li`
  cursor: pointer;
  padding: 1rem 2rem;
  transition: color 0.3s ${Ease.EaseOutExpo}, background-color 0.3s ${Ease.EaseOutExpo};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.blueE};
      color: ${({ theme }) => theme.color.black};
    }
  }
`;

export const StyledSearchInput = styled(Input)`
  padding: 0 3.5rem 0 1rem;
  border: solid 1px ${({ theme }) => theme.color.greyC};
  color: ${({ theme }) => theme.color.blueA};
  font-size: 1.6rem;
  margin-inline: 2rem;
  margin-block: 1rem;
  width: calc(100% - 4rem);

  &::placeholder {
    color: ${({ theme }) => theme.color.greyB};
  }
`;

export const StyledPlaceholder = styled.span`
  color: ${({ theme }) => theme.color.blackD};
`;

export const StyledNoItemsText = styled.span`
  text-align: center;
  display: block;
  font-size: 1.4rem;
  font-weight: ${FontWeight.SemiBold};
  margin: 2rem auto 1rem;
`;
