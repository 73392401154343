import type { ReactElement } from 'react';
import { Chevron } from '../Icon/Icon';
import * as S from './ChevronButton.styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChevronButtonProps {
  open?: boolean;
}

export const ChevronButton = ({ open, ...props }: ChevronButtonProps): ReactElement => {
  return <S.StyledChevronButton $open={open} icon={Chevron} {...props} />;
};
