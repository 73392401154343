import { useEffect, useState, useRef, useCallback } from 'react';
import { ApiClient } from '../util/apiClient';

const useApiClient = (endpoint: string | null): { data: Array<never> } => {
  const cache = useRef<Record<string, Array<never>>>({});
  const [data, setData] = useState<Array<never>>([]);

  const fetchData = useCallback(async () => {
    if (!endpoint) return;

    if (cache.current[endpoint]) {
      const cachedData = cache.current[endpoint];
      setData(cachedData);
    } else {
      const { data } = await ApiClient.get(endpoint);
      cache.current[endpoint] = data;
      setData(data);
    }
  }, [endpoint]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data };
};

export default useApiClient;
