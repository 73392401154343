import type { ReactElement } from 'react';
import * as S from './AssessmentHistory.styles';
import { AssessmentTable } from './sections/AssessmentTable/AssessmentTable';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import { Path } from 'routes/Path';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { UppercaseText } from 'components/atoms/UppercaseText/UppercaseText';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AssessmentHistoryProps {}

export const AssessmentHistory = ({ ...props }: AssessmentHistoryProps): ReactElement => {
  return (
    <S.StyledAssessmentHistory {...props}>
      <AdminHeading
        title="Siemen’s Assessment History"
        subtitle="A historic view of all assessments of the client"
        backButton={{ href: Path.AdminDashboard, title: 'client overview' }}
      />
      <CardContainer>
        <Flex container justifyContent="flex-end">
          <UppercaseText color="greyA" margin="1.2rem 0 4.4rem">
            total entries: 65
          </UppercaseText>
        </Flex>
        <AssessmentTable />
      </CardContainer>
    </S.StyledAssessmentHistory>
  );
};
