/* eslint no-param-reassign: ["error", { "props": false }] */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CustomerBaseProps,
  CustomerFullProps,
  CustomerGetProps,
  CustomerParticipantProps,
  CustomerParticipantsGetProps,
  CustomerState,
} from './customersTypes';
import {
  ApiPayloadArguments,
  ApiPayloadOptions,
  ApiPayloadReturn,
  ApiResponse,
  EndpointProps,
} from 'redux/types';
import { ApiClient } from 'util/apiClient';

export enum CustomerEndpoints {
  Customers = 'customers',
  AddParticipant = 'add_participant',
}

const customersApiHandler = createAsyncThunk<
  ApiPayloadReturn<CustomerGetProps | CustomerParticipantProps, CustomerFullProps>,
  ApiPayloadArguments<CustomerBaseProps | CustomerParticipantProps>,
  ApiPayloadOptions
>('customers/customersApiHandler', async (payloadArguments) => {
  const endpoint: EndpointProps = {
    get: `/${CustomerEndpoints.Customers}/${payloadArguments.id || ''}`,
    post: `/${CustomerEndpoints.Customers}`,
    put: `/${CustomerEndpoints.Customers}/${payloadArguments.id}`,
    delete: `/${CustomerEndpoints.Customers}/${payloadArguments.id}`,
  };

  const { data, message } = (await ApiClient[payloadArguments.method](
    `${endpoint[payloadArguments.method]}/${payloadArguments.alternateEndpoint || ''}`,
    payloadArguments.body,
  )) as ApiResponse<CustomerGetProps | CustomerParticipantProps, CustomerFullProps>;

  return {
    data: data || payloadArguments.body,
    message,
    method: payloadArguments.method,
    endpoint: payloadArguments.alternateEndpoint || CustomerEndpoints.Customers,
    entity: payloadArguments.entity,
  };
});

const initialState: CustomerState = {
  customers: [],
  currentCustomer: null,
  loading: false,
  showClientError: null,
  customerParticipants: [],
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    resetCurrentCustomer(state) {
      state.currentCustomer = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(customersApiHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(customersApiHandler.fulfilled, (state, action) => {
        const { data, entity } = action.payload;

        if (entity === 'customer') {
          state.currentCustomer = data as unknown as CustomerFullProps;
        } else if (entity === 'customerList') {
          state.customers = data as Array<CustomerGetProps>;
        } else if (entity === 'participant') {
          state.customerParticipants.push(data as unknown as CustomerParticipantsGetProps);
        }

        state.loading = false;
      })
      .addCase(customersApiHandler.rejected, (state, action) => {
        const message = action.payload?.message;
        state.showClientError = message;
        state.loading = false;
      });
  },
});

export const customersActions = {
  ...customersSlice.actions,
  customersApiHandler,
};

export default customersSlice.reducer;
