import { ApiClient } from '../util/apiClient';
import { createSubunitDTO } from './dto/CreateSubunitDTO';
import { OrganizationSubunit } from '../model/OrganizationSubunit';

enum SubunitEndpoints {
  AddSubunit = '/organizational-subunits',
}

const createSubunit = async (
  customerId: number,
  subunit: OrganizationSubunit,
): Promise<OrganizationSubunit> => {
  const { data } = await ApiClient.post(
    SubunitEndpoints.AddSubunit,
    createSubunitDTO(customerId, subunit),
  );

  const newSubUnit = subunit;
  newSubUnit.id = data.id;
  return newSubUnit;
};

const removeSubunit = async (subunitId: number): Promise<OrganizationSubunit> => {
  const { data } = await ApiClient.delete(`${SubunitEndpoints.AddSubunit}/${subunitId}`);

  return data;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SubunitService = { createSubunit, removeSubunit };
