import styled, { css } from 'styled-components';
import { color as themeColors } from '../../../styles/theme/default';
import { AbstractButton } from '../AbstractButton/AbstractButton';

interface SecondaryButtonProps {
  color?: keyof typeof themeColors;
  disabled?: boolean;
}

export const SecondaryButton = styled(AbstractButton)<SecondaryButtonProps>`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.7;
  background-color: transparent;
  color: ${({ theme, color }) => color && theme.color[color]};
  text-transform: uppercase;
  user-select: none;
  transition: color 0.3s ease;
  text-decoration: underline;
  text-underline-offset: 3px;
  gap: 0.6rem;
  transition: text-decoration-color 0.3s ease;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.color.greyA};
      pointer-events: none;
    `};

  @media (hover: hover) {
    &:hover {
      text-decoration-color: ${({ theme, color }) =>
        theme.utils.hexToRgba(theme.color[color || 'white'], 0.5)};
    }
  }
`;
