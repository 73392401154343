import { ReactElement } from 'react';
import * as S from './TableData.styles';
import { TableControl } from '../TableControl/TableControl';
import { TableDataProps } from '../Table.types';
import { StatusIndicator } from 'components/atoms/StatusIndicator/StatusIndicator';

export const TableData = ({
  status,
  copy,
  type,
  mobiledropdown,
  width,
  rowIndex,
  actions,
  action,
  defaultValue,
  checkboxLabel,
  checkboxDefaultChecked,
  onChange,
  align,
  areActionsDisabled,
  tableRow,
  ...props
}: TableDataProps): ReactElement => {
  const isCopy = copy && !status && !type;

  return (
    <S.StyledTableData align={align || (type && 'center')} $width={width} {...props}>
      {status && <StatusIndicator status={status} />}
      {isCopy && `${copy}`.length > 100 ? `${`${copy}`.slice(0, 100)}...` : copy}
      {defaultValue && !copy && defaultValue}
      {type && (
        <TableControl
          actions={actions}
          action={action}
          rowIndex={rowIndex}
          mobiledropdown={mobiledropdown}
          type={type}
          checkboxLabel={checkboxLabel}
          checkboxDefaultChecked={checkboxDefaultChecked}
          onChange={onChange}
          align={align || (type && 'center')}
          areActionsDisabled={areActionsDisabled}
          tableRow={tableRow}
        />
      )}
    </S.StyledTableData>
  );
};
