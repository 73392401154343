/* eslint-disable babel/no-unused-expressions */
/* eslint no-param-reassign: ["error", { "props": false }] */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Types from './questionsTypes';
import { EndpointProps } from 'redux/types';
import { ApiClient } from 'util/apiClient';

const questionsApiHandler = createAsyncThunk<
  Types.QuestionsPayloadCreatorProps['returnType'],
  Types.QuestionsPayloadCreatorProps['arguments'],
  Types.QuestionsPayloadCreatorProps['options']
>('questions/questionsApiHandler', async (payloadArguments) => {
  const endpoint: EndpointProps = {
    get: `/elements?element_type_id=2/${payloadArguments.id || ''}`,
    post: '/elements',
    put: `/elements/${payloadArguments.id}?element_type_id=2`,
    delete: `/elements/${payloadArguments.id}?element_type_id=2`,
  };
  const { data, message } = (await ApiClient[payloadArguments.method](
    endpoint[payloadArguments.method],
    payloadArguments.data,
  )) as Types.QuestionsApiResponseProps;

  if (payloadArguments.method !== 'get') {
    // refresh page
    window.location.reload();
  }

  return { data, message, method: payloadArguments.method };
});

const initialState: Types.InitialStateProps = {
  questions: [],
  currentQuestion: null,
  loading: false,
  loadingCurrentQuestion: false,
  showQuestionsError: null,
};

const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(questionsApiHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(questionsApiHandler.fulfilled, (state, action) => {
        const questionData = action.payload.data;
        const actionMethod = action.payload.method;
        const updatedStateProp = actionMethod === 'post' ? 'currentQuestion' : 'questions';

        state[updatedStateProp] = questionData;
        state.loading = false;
      })
      .addCase(questionsApiHandler.rejected, (state, action) => {
        state.showQuestionsError = action.payload?.message;
        state.loading = false;
      });
  },
});

export const questionsActions = {
  ...questionsSlice.actions,
  questionsApiHandler,
};

export default questionsSlice.reducer;
