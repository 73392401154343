import styled from 'styled-components';
import EmptyBackground from 'assets/img/dashboard/empty-background.svg';
import EmptyBackgroundMobile from 'assets/img/dashboard/empty-background-mobile.svg';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledCardContainer = styled(CardContainer)`
  text-align: center;
  color: ${({ theme }) => theme.color.greyA};
  padding: 18.3rem 0 16.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${EmptyBackgroundMobile}) no-repeat center/cover;

  @media ${respondTo(MediaQuery.MIN_768)} {
    background: url(${EmptyBackground}) no-repeat center/cover;
  }
`;
