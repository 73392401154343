import styled, { css } from 'styled-components';
import type { ImageProps } from './Image';

const fitCss = css`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`;

const aspectRatioCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const StyledImage = styled.div<
  Partial<ImageProps> & {
    $fit?: 'cover' | 'contain';
    $contain?: boolean;
    $aspectRatio?: [number, number];
  }
>`
  width: 100%;
  position: relative;
  height: auto;

  ${({ $fit }) => $fit && fitCss}
`;

export const StyledPicture = styled.picture<{ $aspectRatio?: [number, number] }>`
  ${({ $aspectRatio }) => $aspectRatio && aspectRatioCss}
`;

type StyledImageElementProps = {
  $fit?: 'cover' | 'contain';
  $borderRadius?: string;
  $objectPosition?: string;
};

export const StyledImageElement = styled.img<StyledImageElementProps>`
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  ${({ $borderRadius }) => $borderRadius && `border-radius: ${$borderRadius};`}
  ${({ $fit }) => $fit && `height: 100%; object-fit: ${$fit};`}
  ${({ $objectPosition }) => $objectPosition && `object-position: ${$objectPosition};`}
`;
