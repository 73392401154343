import { ReactElement, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import * as S from './Dashboard.styles';
import { MainContent } from './sections/MainContent/MainContent';
import { Sidebar } from './sections/Sidebar/Sidebar';
import { TopCard } from './sections/TopCard/TopCard';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import { RootState, useAppDispatch } from 'redux/store';
import { customerDashboardActions } from 'redux/ducks/customerDashboardDuck/customerDashboardReducer';
import { Path } from 'routes/Path';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Loader } from 'components/atoms/Loading/Loading.styles';
import { MediaQuery } from 'styles/mediaQuery';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DashboardProps {}

export const Dashboard = ({ ...props }: DashboardProps): ReactElement => {
  const dispatch = useAppDispatch();
  const { loading, customerUnitsSelectItem } = useSelector((state: RootState) => {
    return state.customerDashboard;
  });
  const flexGap = '2rem';

  const { clientId } = useParams<{ clientId: string }>();

  useEffect(() => {
    dispatch(customerDashboardActions.fetchCustomerDashboardData(clientId));
  }, [dispatch, clientId]);

  if (loading) return <Loader />;
  return (
    <S.StyledDashboard {...props}>
      <AdminHeading
        title="Client overview"
        subtitle="Manage existing clients and create new ones"
        backButton={{ href: Path.ClientOverview, title: 'Client overview' }}
      />
      <Flex container gap={flexGap} flexWrap="wrap">
        <Flex flexBasis="100%">
          <TopCard clientId={clientId} />
        </Flex>
        {customerUnitsSelectItem.length > 0 && (
          <>
            <Flex flexBasis={[`calc(33.5% - ${flexGap})`, [MediaQuery.MAX_1023, '100%']]}>
              <Sidebar />
            </Flex>

            <Flex flexBasis={['66.5%', [MediaQuery.MAX_1023, '100%']]}>
              <MainContent />
            </Flex>
          </>
        )}
      </Flex>
    </S.StyledDashboard>
  );
};
