/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { CheckboxVariants } from './Checkbox.styles';
import * as S from './Checkbox.styles';

export interface CheckboxProps {
  variant?: CheckboxVariants;
  name?: string;
  refId?: number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  formMethods?: UseFormReturn;
  hasError?: boolean;
  checked?: boolean;
  isOnTable?: boolean;
  isDisabled?: boolean;
  parentCallback?: (event: boolean, refId?: number) => void;
}

export const Checkbox = ({
  variant,
  formMethods,
  onChange,
  hasError,
  refId,
  checked,
  isOnTable,
  parentCallback,
  isDisabled,
  name = '',
  ...props
}: CheckboxProps): ReactElement => {
  const [isChecked, setIsChecked] = useState(!!checked);

  const handleOnClick = () => {
    const newState = !isChecked;
    setIsChecked(newState);

    if (formMethods) {
      formMethods.setValue(name, newState);

      if (checked || isChecked) {
        formMethods.clearErrors(name);
      }
    }

    // TODO: Check why do we pass a parentCallback
    if (parentCallback) parentCallback(newState, refId);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  useEffect(() => {
    if (formMethods) {
      formMethods.setValue(name, !!checked);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (formMethods) {
        const value = formMethods.getValues(name);
        if (value !== isChecked) setIsChecked(value);
      } else if (checked !== isChecked) {
        setIsChecked(!!checked);
      }
    }, 66);
  }, [checked]);

  return (
    <S.StyledCheckbox
      $variant={variant || CheckboxVariants.Regular}
      disabled={isDisabled}
      hasError={hasError}
      type="checkbox"
      value={isChecked ? 'true' : ''}
      checked={isChecked}
      onClick={handleOnClick}
      onChange={handleOnChange}
      isOnTable={isOnTable}
      isDisabled={isDisabled}
      {...props}
    />
  );
};
