import styled from 'styled-components';

interface ChevronButtonHeaderProps {
  left?: string;
}

export const ChevronButtonHeader = styled.span<ChevronButtonHeaderProps>`
  display: inline-flex;
  position: absolute;

  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -0.15em;
    left: ${({ left }) => left || '2em'};
    height: 0.45em;
    width: 0.45em;
    border-style: solid;
    border-width: 0.2em 0.2em 0 0;
    vertical-align: top;
    transform: rotate(135deg);
    color: ${({ theme }) => theme.color.blueC};
  }
`;
