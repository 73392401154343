import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { FontWeight } from 'styles/variables';

export interface TextAreaStyleProps {
  hasError?: boolean;
}

export const StyledTextAreaContainer = styled.div``;

export const StyledTextArea = styled.textarea<TextAreaStyleProps>`
  appearance: none;
  resize: none;
  outline: none;
  background: ${({ theme }) => theme.color.white};
  border-radius: 2px;
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.color.redA : theme.color.greyC)};
  width: 100%;
  min-height: 19.8rem;
  padding: 1.2rem;
  font-size: 1.6rem;
  ${({ theme, hasError }) => hasError && `color: ${theme.color.redA};`};

  @media ${respondTo(MediaQuery.MIN_768)} {
    min-height: 13.9rem;
  }

  &::placeholder {
    color: ${({ theme }) => theme.color.greyA};
  }

  &:focus {
    border-color: ${({ theme, hasError }) => (hasError ? theme.color.redA : theme.color.blueA)};
  }
`;

export const StyledTextAreaNote = styled.span`
  position: absolute;
  right: 0;
  display: block;
  width: fit-content;
  font-size: 1.2rem;
  margin-top: 1.6rem;
  font-weight: ${FontWeight.Medium};
  margin-left: auto;
  color: ${({ theme }) => theme.color.greyA};
`;
