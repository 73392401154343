/* eslint-disable max-lines */
import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './EditProfileModal.styles';
import { Modal } from 'components/organisms/Modal/Modal';
import { ChangePassword } from 'components/organisms/ChangePassword/ChangePassword';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Select, SelectItem } from 'components/molecules/Select/Select';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { Plus } from 'components/atoms/Icon/Icon';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { UppercaseText } from 'components/atoms/UppercaseText/UppercaseText';
import { Text } from 'components/atoms/Text/Text';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { FontWeight } from 'styles/variables';
import { MediaQuery } from 'styles/mediaQuery';
import { Input } from 'components/atoms/Input/Input';
import { Profile } from 'services/types/ProfileTypes';
//* * Removed for MVP import { SizeAdapter } from 'services/adapters/SizeAdapter';
import { ProfileService } from 'services/ProfileService';
import { setErrorEvent, setSuccessEvent } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { RootState } from 'redux/store';
import { countriesActions } from 'redux/ducks/countriesDuck/countriesReducer';
import { nationalitiesActions } from 'redux/ducks/nationalitiesDuck/nationalitiesReducer';
import { gendersActions } from 'redux/ducks/gendersDuck/gendersReducer';
//* * import { useClientSizeTransformer } from 'hooks/useClientSizeTransformer';

export interface MissionDetailModalProps {
  profile: Profile;
  setProfile: Dispatch<SetStateAction<Profile>>;
  onClose: () => void;
}

export const EditProfileModal = ({
  profile,
  setProfile,
  onClose,
  ...props
}: MissionDetailModalProps): ReactElement => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const { adaptedCountries, adaptedNationalities, adaptedGenders } = useSelector(
    (state: RootState) => ({
      adaptedCountries: state.countries.adaptedCountries,
      adaptedNationalities: state.nationalities.adaptedNationalities,
      adaptedGenders: state.genders.adaptedGenders,
    }),
  );

  //* * const { ClientSizeTransformer } = useClientSizeTransformer();
  const intl = useIntl();
  const dispatch = useDispatch();
  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: {
      name: profile.name,
      email: profile.email,
      company: profile?.company,
      country_id: profile.country?.id,
      gender_id: profile.gender?.id,
      nationality_id: profile.nationality?.id,
      other: profile.other,
      participant_id: profile.participant_id,
      birth_date: profile?.birth_date,
    } as FieldValues,
  });

  useEffect(() => {
    dispatch(countriesActions.fetchCountries());
    dispatch(nationalitiesActions.fetchNationalities());
    dispatch(gendersActions.fetchGenders());
  }, [dispatch]);

  const handleHideChangePassword = () => setShowChangePassword(false);

  const handleSubmit = async () => {
    try {
      if (!(await formMethods.trigger())) throw new Error('Form validation error');

      const {
        company,
        country_id,
        email,
        gender_id,
        name,
        nationality_id,
        other,
        participant_id,
        birth_date,
      } = formMethods.getValues();

      const newProfile = {
        participant_id,
        name,
        email,
        company,
        gender_id,
        other,
        nationality_id,
        country_id,
        birth_date,
      };

      const updatedProfile = await ProfileService.updateProfile(newProfile);

      setProfile(updatedProfile);

      onClose();
      dispatch(setSuccessEvent(Events.FORM_SUCCESS));
    } catch (error) {
      dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
    }
  };

  const setValue = (field: string, item: SelectItem) => {
    formMethods.setValue(field, item.value);
  };

  return (
    <Modal onClose={onClose} {...props} variant="fullscreen">
      <S.ContentWrapper>
        <Heading type={HeadingType.H4} fontWeight={FontWeight.SemiBold}>
          <FormattedMessage
            description="Profile - Edit Profile Modal - Title"
            defaultMessage="Edit Profile"
          />
        </Heading>

        <S.StyledForm customMethods={formMethods}>
          <Heading margin="4rem 0 3rem" type={HeadingType.H4}>
            <FormattedMessage
              description="Profile - Edit Profile Modal - Personal Information title"
              defaultMessage="Personal Information"
            />
          </Heading>

          <S.InputRow>
            <RegisterFormItem
              register
              registerErrors="required"
              name="name"
              color="greyA"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Name Input - Label',
                defaultMessage: 'Name',
              })}
            >
              <Input
                hasRef
                placeholder={intl.formatMessage({
                  description: 'Profile - Edit Profile Modal - Name Input - Placeholder',
                  defaultMessage: 'Enter your name',
                })}
                defaultValue={profile.name}
              />
            </RegisterFormItem>
          </S.InputRow>

          <S.InputRow>
            <RegisterFormItem
              register
              registerErrors="required"
              name="gender_id"
              color="greyA"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Gender Input - Label',
                defaultMessage: 'Gender',
              })}
            >
              <Select
                onSelect={(value) => setValue('gender_id', value)}
                items={adaptedGenders}
                variant="input"
                defaultValue={profile.gender?.description}
                placeholder="Select an option"
                shouldStartEmpty
              />
            </RegisterFormItem>

            <RegisterFormItem
              register
              registerErrors="required"
              name="birth_date"
              color="greyA"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Birth Date Input - Label',
                defaultMessage: 'Date of birth',
              })}
            >
              <Input hasRef type="date" defaultValue={profile.birth_date} />
            </RegisterFormItem>
            <RegisterFormItem
              register
              registerErrors="required"
              name="country_id"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Country Input - Label',
                defaultMessage: 'Country of residence',
              })}
              color="greyA"
            >
              <Select
                onSelect={(value) => setValue('country_id', value)}
                hasRef
                items={adaptedCountries}
                variant="input"
                defaultValue={profile.country?.description}
                placeholder="Select an option"
                shouldStartEmpty
              />
            </RegisterFormItem>
            <RegisterFormItem
              register
              registerErrors="required"
              name="nationality_id"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Nationality Input - Label',
                defaultMessage: 'Nationality',
              })}
              color="greyA"
            >
              <Select
                onSelect={(value) => setValue('nationality_id', value)}
                hasRef
                items={adaptedNationalities}
                variant="input"
                defaultValue={profile.nationality?.description}
                placeholder="Select an option"
                shouldStartEmpty
              />
            </RegisterFormItem>
          </S.InputRow>

          {/* //* Removed for MVP
           <Heading margin="4rem 0 3rem" type={HeadingType.H4}>
            <FormattedMessage
              description="Profile - Edit Profile Modal - Job Information Title"
              defaultMessage="Organization Information"
            />
          </Heading>
          <S.InputRow>
            <RegisterFormItem
              register
              name="industry.description"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Sector Input - Label',
                defaultMessage: 'Industry',
              })}
              color="greyA"
            >
              <Select
                onSelect={(value) => setValue('industry.description', value)}
                items={[]}
                variant="input"
                defaultValue={profile.industry?.description}
                disabled
              />
            </RegisterFormItem>

            <RegisterFormItem
              register
              name="function.description"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Function Input - Label',
                defaultMessage: 'Function',
              })}
              color="greyA"
            >
              <Select
                onSelect={(value) => setValue('function.description', value)}
                items={[]}
                variant="input"
                defaultValue={profile.function?.description}
                disabled
              />
            </RegisterFormItem>

            <RegisterFormItem
              register
              name="company"
              color="greyA"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Organization name Input - Label',
                defaultMessage: 'Organization name',
              })}
              defaultValue={profile.company}
            >
              <Input
                hasRef
                placeholder={intl.formatMessage({
                  description: 'Profile - Edit Profile Modal - Organization name - Placeholder',
                  defaultMessage: 'Enter your organization name',
                })}
                defaultValue={profile.company}
                isDisabled
              />
            </RegisterFormItem>

            <RegisterFormItem
              register
              name="organization_size"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Organization Size Input - Label',
                defaultMessage: 'Organization Size',
              })}
              color="greyA"
              defaultValue={SizeAdapter[profile.organization_size]}
            >
              <Select
                onSelect={(value) => setValue('organization_size', value)}
                items={[]}
                variant="input"
                defaultValue={ClientSizeTransformer[profile.organization_size]}
                disabled
              />
            </RegisterFormItem>

            <RegisterFormItem
              register
              name="organizational_type.description"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Type of organization Input - Label',
                defaultMessage: 'Type of organization',
              })}
              color="greyA"
            >
              <Select
                onSelect={(value) => setValue('organizational_type.description', value)}
                items={[]}
                variant="input"
                defaultValue={profile.organizational_type?.description}
                disabled
              />
            </RegisterFormItem>
          </S.InputRow> */}

          <Heading margin="4rem 0 3rem" type={HeadingType.H4}>
            <FormattedMessage
              description="Profile - Edit Profile Modal - Account Details Title"
              defaultMessage="Account Details"
            />
          </Heading>
          <S.InputRow>
            <RegisterFormItem
              register
              registerErrors="required"
              name="email"
              color="greyA"
              label={intl.formatMessage({
                description: 'Profile - Edit Profile Modal - Email Input - Label',
                defaultMessage: 'Email',
              })}
              defaultValue={profile.email}
            >
              <Input
                hasRef
                autoComplete="off"
                type="email"
                placeholder={intl.formatMessage({
                  description: 'Profile - Edit Profile Modal - Email Input - Placeholder',
                  defaultMessage: 'Email address',
                })}
                defaultValue={profile.email}
              />
            </RegisterFormItem>
          </S.InputRow>

          <Heading margin={['4rem 0 2rem', [MediaQuery.MIN_768, '4rem 0 0']]} type={HeadingType.H4}>
            <FormattedMessage
              description="Profile - Edit Profile Modal - Change Password - Title"
              defaultMessage="Change Password"
            />
          </Heading>
          <Flex
            container
            justifyContent="space-between"
            alignItems="flex-end"
            screen={MediaQuery.MIN_768}
          >
            <Paragraph margin={[[MediaQuery.MAX_767, '0 0 2rem']]}>
              <FormattedMessage
                description="Profile - Edit Profile Modal - Change Password - Description"
                defaultMessage="Click the button below to change your password. It must be at least 8 characters long."
              />
            </Paragraph>
            <Flex
              container
              screen={MediaQuery.MAX_767}
              alignItems="center"
              justifyContent="space-between"
            >
              <UppercaseText color="greyA">
                <FormattedMessage
                  description="Profile - Edit Profile Modal - Change Password - Last Time Modified Label"
                  defaultMessage="LAST MODIFIED"
                />
              </UppercaseText>
              <Text margin={[[MediaQuery.MIN_768, '0.8rem 0 0']]} type="bold">
                {/* //TODO: This data should be fetched from the API, we currently don't have it */}
                06/15/2020
              </Text>
            </Flex>
          </Flex>
        </S.StyledForm>

        <S.StyledSecondaryButton type="button" onClick={() => setShowChangePassword(true)}>
          <span>
            <FormattedMessage
              description="Profile - Edit Profile Modal - Change Password - Cta"
              defaultMessage="Change Password"
            />
          </span>
          <Plus />
        </S.StyledSecondaryButton>
        {showChangePassword && (
          <ChangePassword onHideChangePassword={handleHideChangePassword}></ChangePassword>
        )}
        <S.StyledStickyBar>
          <>
            <SecondaryButton type="button" onClick={onClose} color="greyA">
              <span>
                <FormattedMessage
                  description="Profile - Edit Profile Modal - Cancel Copy"
                  defaultMessage="Cancel"
                />
              </span>
            </SecondaryButton>
            <PrimaryButton type="submit" $size="regular" onClick={handleSubmit}>
              <FormattedMessage
                description="Profile - Edit Profile Modal - Save Copy"
                defaultMessage="Save Changes"
              />
            </PrimaryButton>
          </>
        </S.StyledStickyBar>
      </S.ContentWrapper>
    </Modal>
  );
};
