import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledError = styled.div`
  height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem ${({ theme }) => theme.sitePaddings.mobile} 0;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding: 0 0 0 25vw;
  }
`;

export const StyledErrorContent = styled.div`
  @media ${respondTo(MediaQuery.MIN_1024)} {
    max-width: 56rem;
  }
`;

export const StyledErrorTitle = styled.h1`
  color: ${({ theme }) => theme.color.blueA};
  font-size: 6.5rem;
  margin-bottom: 1.4rem;

  @media ${respondTo(MediaQuery.MIN_768)} {
    font-size: 13rem;
    margin-bottom: 0.3rem;
  }
`;
