import type { ReactElement, ReactNode } from 'react';
import * as S from './ErrorMessage.styles';

interface ErrorMessageProps {
  children?: ReactNode;
}

export const ErrorMessage = ({ children, ...props }: ErrorMessageProps): ReactElement => {
  return <S.StyledErrorMessage {...props}>{children}</S.StyledErrorMessage>;
};
