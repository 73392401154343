/* eslint-disable no-console */
import { useState } from 'react';
import { Element } from 'model/Element';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormElement = () => {
  // eslint-disable-next-line prettier/prettier
  const [formElementValues, setValues] = useState(new Element());

  const reset = () => {
    setValues({});
  };

  const handleInputChange = ({ target }) => {
    setValues((previousValues) => ({ ...previousValues, [target.name]: target.value }));
  };

  const setCheckBoxValue = (value, name) => {
    setValues((previousValues) => ({ ...previousValues, [name]: value }));
  };

  return { formElementValues, handleInputChange, reset, setCheckBoxValue };
};
