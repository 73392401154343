import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AdminFeedback } from 'components/organisms/AdminDashboardWrapper/AdminFeedback/AdminFeedback';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { dashboardActions } from 'redux/ducks/dashboardDuck/dashboardReducer';
import { RootState } from 'redux/store';
import { Loader } from 'components/atoms/Loading/Loading.styles';

export const MainContent = (): ReactElement => {
  const [loading, setLoading] = useState(true);
  const { currentSubunit } = useSelector((state: RootState) => {
    return state.customerDashboard;
  });
  const dispatch = useDispatch();

  const cohorts = useMemo(() => {
    if (!currentSubunit) return undefined;

    return currentSubunit.cohorts;
  }, [currentSubunit]);

  useEffect(() => {
    setLoading(true);
    if (!cohorts) return;

    dispatch(dashboardActions.setCohorts(cohorts));
    dispatch(dashboardActions.setCurrentCohortIndex(0));
    dispatch(dashboardActions.setCurrentParticipantIndex(0));

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cohorts]);

  if (loading) return <Loader />;

  return (
    <Flex container flexDirection="column" gap="2rem">
      <AdminFeedback />

      {/* //* Removed for MVP
      <CardContainer minHeight="550px">
        <UsersTable />
      </CardContainer> */}
    </Flex>
  );
};
