/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */
import { ReactElement, useState, useEffect, useMemo, ChangeEventHandler } from 'react';
import { AccordionItemProps, RegisteredFormItem, RegisterFormItemChild } from 'globalTypes';

import * as S from './ElementSingleQuestion.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Input } from 'components/atoms/Input/Input';
import { TextArea } from 'components/atoms/TextArea/TextArea';
import { Checkbox } from 'components/atoms/Checkbox/Checkbox';
import { Hide } from 'components/atoms/Hide/Hide';
import { MediaQuery } from 'styles/mediaQuery';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { Text } from 'components/atoms/Text/Text';
import { FontWeight } from 'styles/variables';
import { useFormElement } from 'hooks/useFormElement';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Plus } from 'components/atoms/Icon/Icon';

type Question = {
  name?: string;
  description?: string;
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ElementQuestionProps
  extends RegisterFormItemChild,
    AccordionItemProps,
    RegisteredFormItem {
  title: string;
  parentCallback: (argument: any) => void;
}

// eslint-disable-next-line no-empty-pattern
export const ElementSingleQuestion = ({
  name,
  formMethods,
  register,
  errors,
  parentCallback,
}: ElementQuestionProps): ReactElement => {
  const [isOpenQuestion, setIsOpenQuestion] = useState<boolean>(false);
  const question = useMemo(() => formMethods.getValues(name), [name]) as Question;
  const checkboxFieldName = `${name}.isopen`;

  const { formElementValues, handleInputChange, setCheckBoxValue } = useFormElement();

  const [questionName, setQuestionName] = useState(question && question.name);
  const [questionDescription, setQuestionDescription] = useState(question && question.description);

  useEffect(() => {
    formMethods.setValue(checkboxFieldName, isOpenQuestion);
  }, [isOpenQuestion, question]);

  useEffect(() => {
    setCheckBoxValue(isOpenQuestion, `${name}-isopen`);
  }, [isOpenQuestion]);

  const handleAddQuestion = () => {
    setIsOpenQuestion(false);
    setQuestionName('');
    setQuestionDescription('');
    parentCallback(formElementValues);
  };

  const handleCheckboxClick = () => {
    setIsOpenQuestion(!isOpenQuestion);
  };

  return (
    <S.ContentWrapper container flexDirection="column" gap="2rem">
      <Flex container justifyContent="space-between" gap="9rem">
        <RegisterFormItem
          errors={errors}
          register={register}
          formMethods={formMethods}
          name="elem.questions.name"
          hideMessage
        >
          <Input
            hasRef
            defaultValue={questionName}
            placeholder="Question Name"
            handleInputChange={handleInputChange as unknown as ChangeEventHandler<HTMLInputElement>}
          />
        </RegisterFormItem>

        <div style={{ display: 'flex', alignItems: 'center' }} onClick={handleCheckboxClick}>
          <Text
            color="blueA"
            fontWeight={FontWeight.Normal}
            type={'uppercase' && 'fileButton'}
            style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}
          >
            Open Question
          </Text>
          <Checkbox
            formMethods={formMethods}
            name="elem.questions.isopen"
            checked={isOpenQuestion}
          />
        </div>

        <Hide screen={MediaQuery.MIN_480}>
          <Checkbox
            formMethods={formMethods}
            name="elem.questions.isopen"
            checked={isOpenQuestion}
          />
        </Hide>
      </Flex>
      <RegisterFormItem
        errors={errors}
        register={register}
        formMethods={formMethods}
        name="elem.questions.description"
        hideMessage
      >
        <TextArea
          hasRef
          placeholder="Brief Description"
          defaultValue={questionDescription}
          handleInputChange={handleInputChange}
        />
      </RegisterFormItem>
      <PrimaryButton
        onClick={handleAddQuestion}
        type="button"
        $size="regular"
        $outline
        style={{ marginBlock: '2rem', width: '200px' }}
      >
        Add Questions <Plus />
      </PrimaryButton>
    </S.ContentWrapper>
  );
};
