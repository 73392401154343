/* eslint-disable no-console */
import {
  ChangeEventHandler,
  DOMAttributes,
  ForwardedRef,
  forwardRef,
  FunctionComponent,
  ReactElement,
  ChangeEvent,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
// eslint-disable-next-line import/no-unresolved
import { InputDefaultProps, RegisterFormItemChild } from 'globalTypes';
// eslint-disable-next-line import/no-unresolved
import * as S from './Input.styles';
import * as icons from '../Icon/Icon';
import { Eye, EyeOff } from 'components/atoms/Icon/Icon';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputProps
  extends InputDefaultProps,
    S.InputStyleProps,
    RegisterFormItemChild,
    DOMAttributes<HTMLInputElement> {
  input?: UseFormRegisterReturn;
  autoComplete?: string;
  defaultValue?: string;
  minValue?: number;
  handleInputChange?: ChangeEventHandler<HTMLInputElement>;
  handleSelectedFile?: ChangeEvent<HTMLInputElement>;
  isDisabled?: boolean;
  isSelect?: boolean;
  toggleVisible?: boolean;
}

export const Input = forwardRef(
  (
    {
      icon,
      type,
      minValue,
      hasError,
      input,
      defaultValue,
      formMethods,
      name,
      onChange,
      handleInputChange,
      handleSelectedFile,
      isDisabled,
      isSelect,
      ...props
    }: InputProps,
    ref,
  ): ReactElement => {
    const [hidePassword, setHidePassword] = useState(true);

    const handlePasswordClick = () => setHidePassword((hidePassword) => !hidePassword);

    const Icon = (
      type === 'date' ? icons.Calendar : icon && icons[icon as keyof typeof icons]
    ) as FunctionComponent<React.SVGProps<SVGSVGElement>>;

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (handleInputChange) {
        handleInputChange(event);
      }
      if (input && input.onChange) {
        input.onChange(event);
      }
    };

    const inputType = useMemo(() => {
      if (type === 'password') {
        return hidePassword ? 'password' : 'text';
      }

      return type;
    }, [hidePassword, type]);

    useEffect(() => {
      if (formMethods) {
        formMethods.setValue(name, defaultValue);
      }
    }, [formMethods, defaultValue, name]);

    return (
      <S.InputWrapper>
        {Icon && (
          <S.StyledIcon hasError={hasError} isSelect={isSelect}>
            <Icon />
          </S.StyledIcon>
        )}

        {type === 'password' && props.toggleVisible && (
          <S.StyledPasswordButton
            icon={hidePassword ? EyeOff : Eye}
            onClick={handlePasswordClick}
          />
        )}

        {type === 'file' ? (
          <S.StyledFileInput
            onChange={handleSelectedFile as unknown as ChangeEventHandler<HTMLInputElement>}
            {...props}
          />
        ) : (
          <S.StyledInput
            hasError={hasError}
            type={inputType || 'text'}
            min={minValue}
            name={name}
            ref={ref as ForwardedRef<HTMLInputElement>}
            disabled={isDisabled}
            onChange={handleOnChange}
            {...props}
          />
        )}
      </S.InputWrapper>
    );
  },
);
