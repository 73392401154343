import { ReactElement, ReactNode } from 'react';
import * as S from './AdminHeading.styles';
import { Chevron } from 'components/atoms/Icon/Icon';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Text } from 'components/atoms/Text/Text';
import { Breadcrumbs, Breadcrumb } from 'components/molecules/Breadcrumbs/Breadcrumbs';

interface AdminHeadingProps extends S.AdminHeadingProps {
  title: string;
  subtitle?: string;
  children?: ReactNode;
  breadcrumbs?: Array<Breadcrumb>;
  backButton?: Breadcrumb;
  headingType?: HeadingType;
}

export const AdminHeading = ({
  title,
  subtitle,
  children,
  breadcrumbs,
  backButton,
  headingType,
  ...props
}: AdminHeadingProps): ReactElement => {
  return (
    <S.StyledAdminHeading {...props}>
      {breadcrumbs && <Breadcrumbs crumbs={breadcrumbs} />}
      {backButton && backButton.href && (
        <S.StyledBackButton
          to={{ pathname: backButton.href, ...(backButton.state && { state: backButton.state }) }}
        >
          <Chevron />
          <span>{backButton.title}</span>
        </S.StyledBackButton>
      )}
      <Heading margin="1.7rem 0 2rem" type={headingType || HeadingType.H2} style={HeadingType.H2}>
        {title}
      </Heading>
      {(subtitle || children) && (
        <Flex
          container
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
          gap="1rem"
        >
          {subtitle && <Text color="greyA">{subtitle}</Text>}
          {children && (
            <Flex container gap="2rem">
              {children}
            </Flex>
          )}
        </Flex>
      )}
    </S.StyledAdminHeading>
  );
};
