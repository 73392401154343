import { ReactElement } from 'react';
import { Circles } from '../Circles/Circles';
import { CircleVariants } from './Graphic.styles';
import * as S from './Graphic.styles';
import { CheckList } from 'components/atoms/Icon/Icon';

interface GraphicProps {
  percentage: number;
  getItemScrollPosition: (index: number) => number;
  onCircleClick: (index: number) => void;
}

export const Graphic = ({
  getItemScrollPosition,
  onCircleClick,
  percentage,
  ...props
}: GraphicProps): ReactElement => {
  return (
    <S.StyledGraphic {...props}>
      {[...new Array(4).keys()].map((element) => (
        <S.StyledCircle
          key={`circle-${element}`}
          onClick={() => onCircleClick(element)}
          variant={percentage >= element * 25 && CircleVariants.Active}
        >
          0{element + 1}
        </S.StyledCircle>
      ))}
      <S.StyledCirclePositonContainer>
        <S.StyledCirclePositon style={{ transform: `rotate(${(360 * percentage) / 100}deg` }} />
      </S.StyledCirclePositonContainer>
      <S.StyledIconContainer>
        <CheckList />
      </S.StyledIconContainer>
      <Circles percentage={percentage} />
    </S.StyledGraphic>
  );
};
