import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { ColorKey } from 'styles/theme/default';

export interface CardContainerStyleProps {
  overflow?: 'visible';
  variant?: 'blue' | 'grey' | 'red';
  minHeight?: string;
  margin?: string;
  isCreateQuestion?: boolean;
}

const cardContainerPadding = '3.2rem';
export const cardContainerPaddingMobile = '2.5rem';

const backgroundVariants = {
  blue: 'blueA',
  grey: 'greyB',
  red: 'redF',
};

const colorVariants = {
  blue: 'cream',
  grey: 'blueA',
  red: 'white',
};

export const CardContainerDefaultStyles = css`
  padding: ${cardContainerPadding};
  border-radius: 0.8rem;
`;

export const CardContainer = styled(motion.div)<CardContainerStyleProps>`
  ${CardContainerDefaultStyles};
  overflow: ${({ overflow }) => overflow || 'hidden'};
  position: relative;

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}; height: 100%;`}
  ${({ margin }) => margin && `margin: ${margin};`};

  ${({ variant, theme }) => css`
    background: ${theme.color[backgroundVariants[variant || 'grey'] as ColorKey]};
    color: ${theme.color[colorVariants[variant || 'grey'] as ColorKey]};
  `}
  ${({ isCreateQuestion }) =>
    isCreateQuestion &&
    `background: #FFFDF9;
  `}

  @media ${respondTo(MediaQuery.MAX_1023)} {
    padding: ${cardContainerPaddingMobile};
  }

  .card-container-full-bleed {
    margin: 0 -${cardContainerPadding} 0 -${cardContainerPadding};
    padding: 0 ${cardContainerPadding} 0 ${cardContainerPadding};
  }
`;

export const StyledCardButtons = styled.div`
  display: flex;
  gap: 1.6rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex-direction: column;
  }
`;

export const StyledCardButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: auto;
  margin-top: 3rem;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  gap: 3rem;
  z-index: 1;
  position: absolute;
  bottom: ${cardContainerPadding};
  right: ${cardContainerPadding};

  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex-direction: column;
    margin-top: 0;
    width: 65%;
    top: ${cardContainerPadding};
    bottom: auto;
  }
`;
