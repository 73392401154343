import { DOMAttributes, forwardRef, ReactElement, ReactNode } from 'react';
import { StyledHeadingProps } from './Heading.styles';
import { HeadingType } from './Heading.data';
import * as S from './Heading.styles';
import { TypeStyleType } from 'styles/typeStyle';

interface HeadingProps extends StyledHeadingProps, DOMAttributes<HTMLElement> {
  children?: ReactNode;
  type?: HeadingType;
  style?: keyof TypeStyleType;
}

export const Heading = forwardRef<HTMLHeadingElement, HeadingProps>(
  (
    { type = HeadingType.H1, style = type, children, ...props }: HeadingProps,
    ref,
  ): ReactElement => {
    return (
      <S.StyledHeading {...props} $type={style} as={type} ref={ref}>
        {children}
      </S.StyledHeading>
    );
  },
);
