import * as Yup from 'yup';

const passwordValidationRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z 0-9])[\S ]{8,}$/;

const basePasswordFormSchema = Yup.object({
  new_password: Yup.string()
    .required('New password is a required field')
    .min(8, 'New password must be at least 8 characters')
    .matches(
      passwordValidationRegex,
      'Must Contain at least One Uppercase, One Lowercase, One Number and one special case Character',
    ),
  confirm_password: Yup.string()
    .required('Confirm password is a required field.')
    .oneOf([Yup.ref('new_password')], 'Passwords does not match'),
});

export const changePasswordFormSchema = basePasswordFormSchema.shape({
  old_password: Yup.string().required('Current password is a required field.'),
});

export const newPasswordFormSchema = basePasswordFormSchema.shape({
  enter_email: Yup.string()
    .required('Email is a required field.')
    .email('Please enter a valid email.'),
});

export const elementCreationSchema = Yup.object({
  name: Yup.string()
    .required('Name is a required field.')
    .max(50, 'Name cannot be longer than 50 characters.'),
  description: Yup.string()
    .required('Description is a required field.')
    .max(400, 'Description cannot be longer than 50 characters.'),
});
