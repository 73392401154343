import { SET_SUCCESS_EVENT, Events, SET_ERROR_EVENT } from './eventsTypes';
import { Action, ReduxActionCreator } from 'redux/types';
import { Path } from 'routes/Path';

type EventsActionCreatorType = ReduxActionCreator<Events | undefined, Path | undefined>;
type EventsActionType = (event?: Events) => Action;

export const setSuccessEventAction: EventsActionType = (event) => ({
  type: SET_SUCCESS_EVENT,
  payload: { event },
});

export const setErrorEventAction: EventsActionType = (event) => ({
  type: SET_ERROR_EVENT,
  payload: { event },
});

export const setSuccessEvent: EventsActionCreatorType = (event) => (dispatch) => {
  dispatch(setSuccessEventAction(event));
};

export const setErrorEvent: EventsActionCreatorType = (event) => (dispatch) => {
  dispatch(setErrorEventAction(event));
};
