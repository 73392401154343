/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
// eslint-disable-next-line import/no-unresolved
import { LocationState } from 'globalTypes';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import * as S from './AddMissions.styles';
import { AddMissionForm } from './sections/AddMissionForm/AddMissionForm';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { MediaQuery } from 'styles/mediaQuery';
import { Tabs } from 'components/atoms/Tabs/Tabs';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';
import { Text } from 'components/atoms/Text/Text';
import { Path } from 'routes/Path';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { StickyBar } from 'components/atoms/StickyBar/StickyBar';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { StorageItem } from 'data/enum/StorageItem';
import { setErrorEventAction, setSuccessEvent } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { RootState } from 'redux/store';
import { challengesActions } from 'redux/ducks/challengesDuck/challengesReducer';
import { ActionMethod } from 'data/enum/ActionMethod';
import { ChallengeGetProps } from 'redux/ducks/challengesDuck/challengesTypes';
import { MissionGetProps } from 'redux/ducks/missionsDuck/missionsTypes';
import { missionsActions } from 'redux/ducks/missionsDuck/missionsReducer';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AddMissionsProps {}

const defaultChallenge = {
  challenge: {
    name: '',
    description: '',
    duration: 1,
    duration_type_id: 1,
    mission_amount: '1',
    mission_duration_type_id: 1,
  },
};

// eslint-disable-next-line no-empty-pattern
export const AddMissions = ({}: AddMissionsProps): ReactElement => {
  const formsContainer = useRef<HTMLDivElement>(null);
  const shouldTriggerValidations = useRef<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory<LocationState>();
  const temporaryData = JSON.parse(
    localStorage.getItem(StorageItem.TEMPORARY_CHALLENGE) || JSON.stringify(defaultChallenge),
  );
  const { location, push } = history;
  const challengeID = history.location.state && history.location.state.id;

  const getInitialMissions = (): Array<MissionGetProps> => {
    const missionAmount = +temporaryData?.challenge?.mission_amount || 1;
    return Array.from({ length: missionAmount }, () => ({
      id: 0,
      name: '',
      description: '',
      created_at: '',
      updated_at: '',
      deleted_at: '',
      img: '',
      imgName: '',
      challenge_id: challengeID,
    }));
  };

  const formMethods = useForm({
    mode: 'onSubmit',
    defaultValues: { missions: getInitialMissions() },
  });

  const { challenge: temporaryChallenge }: { challenge: ChallengeGetProps } = temporaryData;
  const initialActiveMission = 0;

  const temporaryMissions = temporaryData.missions || getInitialMissions();

  // Get the current mission if Editing a challenge/mission
  const { challengeMissions, loading } = useSelector((state: RootState) => {
    return state.challenges;
  });

  const [missions, setMissions] = useState<Array<MissionGetProps>>(temporaryMissions);
  const [activeMission, setActiveMission] = useState<number>(initialActiveMission);
  const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false);

  const variableBreadCrumbs =
    challengeID === undefined
      ? [{ title: 'create challenge', href: Path.CreateChallenge }, { title: 'Add Missions' }]
      : [
          { title: 'edit challenge', href: Path.EditChallenge, state: { id: challengeID } },
          { title: 'Edit Missions' },
        ];

  /**
   * Find and set as active the first form (from left to right) that has not been
   * filled out if there is one left.
   */
  const nextMission = (missions: Array<MissionGetProps>) => {
    if (!missions[activeMission].name || !missions[activeMission].description) {
      return;
    }

    const index = missions.findIndex(({ name, description }) => !name || !description);

    if (index !== -1) {
      if (index < activeMission) {
        shouldTriggerValidations.current = true;
      }
      setActiveMission(index);
    } else {
      setIsReadyToSubmit(true);
    }
  };

  const saveChallenge = async (challengeData: ChallengeGetProps) => {
    const method = challengeID ? ActionMethod.PUT : ActionMethod.POST;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = dispatch(
      challengesActions.challengesApiHandler({
        method,
        body: challengeData,
      }),
    );
    const { data } = await response.unwrap();
    return data;
  };

  const saveMission = async (mission: MissionGetProps) => {
    const method = mission.id ? ActionMethod.PUT : ActionMethod.POST;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = dispatch(
      missionsActions.missionsApiHandler({
        method,
        body: mission,
        ...(mission.id && { id: mission.id }),
      }),
    );

    const { data } = await response.unwrap();
    return data;
  };

  const submitForm = async () => {
    try {
      // 1. Post/put challenge data and wait for response to receive challenge ID
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const challengeData: any = { ...temporaryChallenge };
      challengeData.mission_duration_type_id = challengeData.mission_duration_id;
      delete challengeData.mission_duration_id;

      const challengeDataResponse = (await saveChallenge(challengeData)) as ChallengeGetProps;

      // 2. Loop and post/put each mission with the challenge id
      for (const item of missions) {
        const { imgName, ...adaptedItem } = item;

        const missionData: MissionGetProps = {
          ...(adaptedItem as MissionGetProps),
          challenge_id: challengeDataResponse.id || challengeID,
        };
        await saveMission(missionData);
      }

      // TODO: catch any potential errors and dispatch an erorr message instead
      dispatch(setSuccessEvent(Events.FORM_MISSION_SAVED));

      // clear the temporary challenge and missions data from localstorage and redux state
      localStorage.removeItem(StorageItem.TEMPORARY_CHALLENGE);
      dispatch(challengesActions.resetCurrentChallenge());
      dispatch(missionsActions.resetCurrentMissions());

      push(Path.ChallengesManagement);
    } catch (error) {
      dispatch(setErrorEventAction(Events.FORM_INVALID_ERROR));
    }
  };

  const handleSaveFinish = async () => {
    const { missions } = formMethods.getValues();

    // Dispatch native submit event for current active mission form when save button is clicked,
    // this is very helpful for validation checks.
    if (formsContainer.current) {
      const form = formsContainer.current.querySelector('form') as HTMLFormElement;
      form.dispatchEvent(new Event('submit', { bubbles: true }));
    }

    nextMission(missions);
  };

  const handleMissionFormChange = (index: number, value: string, title: string) => {
    const hasValues = !!Object.values(value).filter((item) => item)[0];

    if (value && hasValues) {
      const updateMissions = [...missions];
      const currentUpdateMission = { ...missions[index], [title]: value };

      updateMissions[index] = currentUpdateMission;

      setMissions(updateMissions);

      const localStorageData = {
        ...temporaryData,
        missions: updateMissions,
      };

      localStorage.setItem(StorageItem.TEMPORARY_CHALLENGE, JSON.stringify(localStorageData));
    }
  };

  const handleCancel = () => {
    localStorage.removeItem(StorageItem.TEMPORARY_CHALLENGE);
    dispatch(challengesActions.resetCurrentChallenge());
    dispatch(missionsActions.resetCurrentMissions());

    push(Path.ChallengesManagement);
  };

  useEffect(() => {
    if (shouldTriggerValidations.current) {
      formMethods.trigger();
      shouldTriggerValidations.current = false;
    } else {
      formMethods.clearErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMission]);

  useEffect(() => {
    if (challengeID) {
      dispatch(
        challengesActions.challengesApiHandler({
          method: ActionMethod.GET,
          id: challengeID,
          alternateEndpoint: 'challenge-missions',
        }),
      );
    }
  }, [dispatch, challengeID]);

  useEffect(() => {
    if (challengeMissions.length > 0) {
      setMissions(challengeMissions);
    }
  }, [challengeMissions]);

  useEffect(() => {
    if (isReadyToSubmit) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyToSubmit]);

  if (!location.state && !temporaryChallenge) {
    push(Path.ChallengesManagement);
    return <></>;
  }

  return (
    <S.StyledAddMissions className="formLeftPaddingDifference">
      <BackgroundCircle
        lastBackground
        top={['40%', [MediaQuery.MIN_768, '34.6rem']]}
        left={['-40.5rem', [MediaQuery.MIN_768, '-58.5rem']]}
        size={['78.6rem', [MediaQuery.MIN_768, '113.8rem']]}
      />

      <AdminHeading
        title="Add missions to your challenge"
        $color="blueA"
        breadcrumbs={[
          {
            title: 'Challenges Management',
            href: Path.ChallengesManagement,
          },
          ...variableBreadCrumbs,
        ]}
      >
        <Flex container gap="6rem">
          <Text type="label">Challenge: {temporaryChallenge.name}</Text>
          <Text type="label">Challenge description: {temporaryChallenge.description}</Text>
        </Flex>
      </AdminHeading>

      <TabsContainer
        defaultActive={initialActiveMission}
        activeTab={activeMission}
        setActiveTab={setActiveMission}
      >
        <Tabs
          tabs={missions.map((mission, index) => mission.name || `Mission ${index + 1}`)}
          isNumerated
        />
        <div ref={formsContainer}>
          {missions.map(
            (mission, index) =>
              !loading && (
                <AddMissionForm
                  formMethods={formMethods}
                  key={`add-mission-${index}`}
                  index={index}
                  mission={mission}
                  onChange={handleMissionFormChange}
                />
              ),
          )}
        </div>
      </TabsContainer>

      <StickyBar>
        <Flex container gap="3rem" alignItems="center" justifyContent="space-between">
          <Text type="uppercase" color="blueA">
            2/2
          </Text>
          <Flex container gap="3rem">
            <SecondaryButton onClick={handleCancel} type="button" color="greyA">
              <span>Leave without saving</span>
            </SecondaryButton>
            <PrimaryButton type="button" onClick={handleSaveFinish} $size="regular">
              Save & Finish
            </PrimaryButton>
          </Flex>
        </Flex>
      </StickyBar>
    </S.StyledAddMissions>
  );
};
