import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import * as S from './CustomMouse.styles';

interface CustomMouseProps {
  children: ReactNode;
  tip?: string;
  active?: boolean;
}

export const CustomMouse = ({ active, tip, children }: CustomMouseProps): ReactElement => {
  const mouseRef = useRef(null);

  const setMouseScale = (scale: number) => {
    const mouseNode = mouseRef.current as unknown as HTMLElement;
    const mouseInnerNode = mouseNode.firstChild as HTMLElement;
    mouseInnerNode.style.transform = `scale(${scale})`;
  };

  useEffect(() => {
    const mouseNode = mouseRef.current as unknown as HTMLElement;
    const mouseParentNode = mouseNode.parentElement as HTMLElement;

    const handleMouseMove = (event: MouseEvent) => {
      mouseNode.style.transform = `translate3D(${event.clientX}px, ${event.clientY}px, 0)`;
    };

    const handleMouseDown = () => setMouseScale(0.8);
    const handleMouseUp = () => setMouseScale(1);

    mouseParentNode.addEventListener('mousemove', handleMouseMove, true);
    mouseParentNode.addEventListener('mousedown', handleMouseDown, true);
    mouseParentNode.addEventListener('mouseup', handleMouseUp, true);

    return () => {
      mouseParentNode.removeEventListener('mousemove', handleMouseMove, true);
      mouseParentNode.removeEventListener('mousedown', handleMouseDown, true);
      mouseParentNode.removeEventListener('mouseup', handleMouseUp, true);
    };
  }, []);

  useEffect(() => {
    setMouseScale(active ? 1 : 0);
  }, [active]);

  return (
    <S.StyledCustomMouse ref={mouseRef}>
      <S.StyledCustomMouseInner>
        {children}
        {tip && <S.StyledTip>{tip}</S.StyledTip>}
      </S.StyledCustomMouseInner>
    </S.StyledCustomMouse>
  );
};
