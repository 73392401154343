import { ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as S from './ProfileMenu.styles';
import { Profile } from 'components/atoms/Icon/Icon';
import { IconButton } from 'components/molecules/IconButton/IconButton';
import { ChevronButtonHeader } from 'components/atoms/ChevronButtonHeader/ChevronButtonHeader';
import { Path } from 'routes/Path';
import { SessionService } from 'services/SessionService';
import { SET_PROFILE_ID } from 'redux/ducks/authDuck/authTypes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileMenuProps {}

// eslint-disable-next-line no-empty-pattern
export const ProfileMenu = ({}: ProfileMenuProps): ReactElement => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => setShowDropdown((previousState) => !previousState);
  // const handleGoToProfile = () => push(Path.Profile);
  const handleSignOut = async () => {
    try {
      await SessionService.logout();
      dispatch({
        type: SET_PROFILE_ID,
        payload: null,
      });
    } catch (error) {
      // Do nothing
    } finally {
      localStorage.removeItem('token');
      push(Path.Login);
    }
  };

  return (
    <S.StyledProfileMenu>
      <S.StyledProfileButton onClick={toggleDropdown}>
        <IconButton size="nav-icon" icon={Profile} />
        <ChevronButtonHeader left="1.75em" />
      </S.StyledProfileButton>

      {showDropdown && (
        <S.StyledDropdown>
          {/* <S.StyledItem onClick={handleGoToProfile}>Go to Profile</S.StyledItem> */}
          <S.StyledItem onClick={handleSignOut}>Sign Out</S.StyledItem>
        </S.StyledDropdown>
      )}
    </S.StyledProfileMenu>
  );
};
