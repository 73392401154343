import styled from 'styled-components';
import { size } from 'styles/helpers/size';
import { ColorKey } from 'styles/theme/default';

export type StatusType = 'finished' | 'in_progress' | 'not_started' | 'completed';

const statusColors = {
  finished: 'blueA',
  completed: 'greenA',
  in_progress: 'greyA',
  not_started: 'redA',
};

export const StyledIndicator = styled.span<{
  type: StatusType;
  small?: boolean;
  oneColor?: boolean;
}>`
  position: relative;
  padding-left: 1.6rem;
  ${({ oneColor, theme, type }) =>
    oneColor && `color: ${theme.color[statusColors[type] as ColorKey]};`}

  &:before {
    ${({ small }) => (small === true ? size('0.6rem') : size('0.8rem'))};
    content: '';
    display: inline-block;
    background-color: ${({ theme, type }) => theme.color[statusColors[type] as ColorKey]};
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;
