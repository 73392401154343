/* eslint-disable babel/no-unused-expressions */
import { createContext, ReactElement, ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';
import * as S from './ConfirmModal.styles';
import { Modal } from '../Modal/Modal';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { useModal } from 'hooks/useModal';
import { ModalHeading } from 'components/molecules/ModalHeading/ModalHeading';
import { FormSticky } from 'components/molecules/FormSticky/FormSticky';

interface ConfirmModalProps {
  children?: ReactNode;
}

export interface ModalData {
  title: string;
  subtitle?: string;
  description?: string;
  cancelCopy?: string;
  confirmCopy?: string;
}

interface ConfirmModalContextProps {
  useConfirmModal: (data: ModalData) => Promise<unknown>;
}

export const ConfirmModalContext = createContext({} as ConfirmModalContextProps);

export const ConfirmModal = ({ children }: ConfirmModalProps): ReactElement => {
  const { data, handleCancel, handleConfirm, setModal } = useModal<ModalData>();

  return (
    <>
      <AnimatePresence>
        {data && (
          <Modal $size="small" onClose={handleCancel}>
            <ModalHeading title={data.title} />
            <S.StyledModalContent>
              {data.subtitle && <Heading type={HeadingType.H4}>{data.subtitle}</Heading>}
              {data.description && <Paragraph margin="1.8rem 0 0">{data.description}</Paragraph>}
            </S.StyledModalContent>
            <FormSticky
              handleCancel={handleCancel}
              handleConfirm={handleConfirm}
              confirmCopy={data.confirmCopy}
              cancelCopy={data.cancelCopy}
              inModal
            />
          </Modal>
        )}
      </AnimatePresence>
      <ConfirmModalContext.Provider value={{ useConfirmModal: setModal }}>
        {children}
      </ConfirmModalContext.Provider>
    </>
  );
};
