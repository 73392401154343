import type { ReactElement, MouseEvent, ReactNode } from 'react';
import * as S from './Modal.styles';
import { Backdrop } from '../../atoms/Backdrop/Backdrop';

export interface ModalProps extends S.ModalStyleProps {
  onClose: (event: MouseEvent) => void;
  children?: ReactNode;
  $noscroll?: boolean;
  $size?: 'small';
}

export const Modal = ({ onClose, children, variant, ...props }: ModalProps): ReactElement => {
  return (
    <Backdrop onBackdropClick={onClose}>
      <S.StyledModal
        initial={{ scale: 0.9, x: '-50%', y: '-50%' }}
        exit={{ scale: 0.9, x: '-50%', y: '-50%' }}
        animate={{ scale: 1, x: '-50%', y: '-50%' }}
        transition={{ duration: 0.6, ease: [0.19, 1, 0.22, 1] }}
        variant={variant}
      >
        <S.StyledCloseButton aria-label="Close button" onClick={onClose} />
        <S.StyledContent {...props}>{children}</S.StyledContent>
      </S.StyledModal>
    </Backdrop>
  );
};
