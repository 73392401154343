import type { ReactElement } from 'react';
import * as S from './Error.styles';
import errorMobileBackground from '../../../assets/img/error/error-bg-mb.jpg';
import errorDesktopBackground from '../../../assets/img/error/error-bg-dsk.jpg';
import { MediaQuery } from 'styles/mediaQuery';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';
import { HeaderTypes } from 'components/molecules/Header/Header.styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ErrorProps {
  type: number;
  label: string;
}

export const Error = ({ type, label, ...props }: ErrorProps): ReactElement => {
  return (
    <NormalLayout
      background={[
        `url(${errorMobileBackground}) no-repeat center/cover`,
        [MediaQuery.MIN_768, `url(${errorDesktopBackground}) no-repeat center/cover`],
      ]}
      headerType={HeaderTypes.NoBackground}
    >
      <S.StyledError {...props}>
        <S.StyledErrorContent>
          <S.StyledErrorTitle>Error {type}</S.StyledErrorTitle>
          <Heading type={HeadingType.H6} color="blueA">
            {label}
          </Heading>
        </S.StyledErrorContent>
      </S.StyledError>
    </NormalLayout>
  );
};
