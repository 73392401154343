import styled from 'styled-components';

export const StyledCircles = styled.div`
  height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.color.white};
  svg {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .dotted-circle {
    path {
      stroke: currentColor;
      stroke-width: 3;
      stroke-linecap: round;
      stroke-dasharray: 0.1 10;
    }
  }

  .complete-circle {
    circle {
      stroke: currentColor;
      stroke-width: 3;
      stroke-dasharray: 200, 20000;
    }
  }
`;
