import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled from 'styled-components';
import { theme } from '../../../styles/theme/default';
import { zIndex } from '../../../styles/zIndex';

export const StyledBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  background-color: ${rgba(theme.color.black, 0.5)};
  overflow: hidden;
  overflow-y: auto;
  z-index: ${zIndex('overlay')};
`;
