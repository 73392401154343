import styled from 'styled-components';
import { Flex } from '../Flex/FlexContainer';
import { typeStyle } from 'styles/typeStyle';

export const StyledSliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0.4rem;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.newColor.primary90};
  touch-action: none;
`;

export const StyledCustomSlider = styled.div`
  width: 100%;
  height: 100%;
  appearance: none;
  background: transparent;
  outline: none;
  z-index: 2;
`;
export const StyledSliderThumb = styled.button`
  position: absolute;
  width: 0;
  height: 0;
`;

export const StyledSlider = styled.input`
  width: 100%;
  height: 100%;
  appearance: none;
  background: transparent;
  outline: none;
  z-index: 3;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 1.2rem;
    height: 1.2rem;
    background: ${({ theme }) => theme.color.blueA};
    cursor: grabbing;
    border-radius: 50%;
  }
`;

export const StyledProgress = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: ${({ theme }) => theme.newColor.primary100};
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: left;
  z-index: 1;
`;

export const StyledProgressInner = styled.div`
  position: relative;
`;

export const StyledProgressNumber = styled.div`
  ${typeStyle.label};
  position: absolute;
  top: -2.6rem;
  color: ${({ theme }) => theme.color.blueA};
  user-select: none;
`;

export const StyledBottomFlex = styled(Flex)`
  margin-top: 1.8rem;
  color: ${({ theme }) => theme.color.blueA};
  width: 108%;
  transform: translateX(-4%);
  user-select: none;
`;
