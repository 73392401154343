/* eslint no-param-reassign: ["error", { "props": false }] */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import * as Types from './surveysDuckTypes';
import { ApiClient } from 'util/apiClient';

const surveysApiHandler = createAsyncThunk<
  Types.SurveyPayloadReturnType,
  Types.SurveyPayloadArguments,
  Types.SurveyPayloadOptions
>('surveys/surveysApiHandler', async (payloadArguments) => {
  const { data, message } = (await ApiClient[payloadArguments.method](
    `/surveys/${payloadArguments.id || ''}`,
    payloadArguments.data,
  )) as Types.SurveyApiResponseProps;
  return { data, message, method: payloadArguments.method };
});

// constants
const initialState: Types.SurveyInitialStateProps = {
  surveys: [],
  currentSurvey: null,
  loading: false,
  loadingSurveys: false,
  showSurveysError: null,
};

const surveysSlice = createSlice({
  name: 'surveys',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(surveysApiHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(surveysApiHandler.fulfilled, (state, action) => {
        const surveyData = action.payload.data;

        if (Array.isArray(surveyData)) {
          state.surveys = surveyData;
        } else {
          state.currentSurvey = surveyData;
        }
        if (action.payload.method === 'delete') toast.success(action.payload.message);

        state.loading = false;
      })
      .addCase(surveysApiHandler.rejected, (state, action) => {
        state.showSurveysError = action.payload?.message;
        state.loading = false;
      });
  },
});

export const surveysActions = {
  ...surveysSlice.actions,
  surveysApiHandler,
};

export default surveysSlice.reducer;
