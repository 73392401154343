/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
import { ReduxReducer } from 'redux/types';
import * as types from './selectedCohortsTypes';

const initialState = {
  selectedCohorts: [],
};

export const selectedCohortsReducer: ReduxReducer<typeof initialState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.GET_SELECTED_COHORTS_SUCCESS:
      return { ...state, selectedCohorts: action.payload };
    default:
      return state;
  }
};
