import { Participant } from './Participant';
import { Survey } from './Survey';
import { Cohort as DashboardCohort } from 'services/types/DashboardTypes';

export class Cohort {
  public id?: number;
  public name?: string;
  public participants: Array<Participant> = [];
  public cohort_survey: {
    survey: Survey;
  } = { survey: new Survey() };

  public static fromDashboardCohort({ id, name, cohort_survey }: DashboardCohort): Cohort {
    const newCohort = new Cohort();
    newCohort.id = id;
    newCohort.name = name;
    newCohort.participants = cohort_survey?.participants || [];
    newCohort.cohort_survey = cohort_survey;
    return newCohort;
  }
}
