// eslint-disable-next-line import/no-unresolved
import { LocationState } from 'globalTypes';
import type { ReactElement } from 'react';
import * as S from './Breadcrumbs.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { UppercaseText } from 'components/atoms/UppercaseText/UppercaseText';

export type Breadcrumb = {
  title: string;
  href?: string;
  state?: LocationState;
};

interface BreadcrumbsProps {
  crumbs: Array<Breadcrumb>;
}

export const Breadcrumbs = ({ crumbs, ...props }: BreadcrumbsProps): ReactElement => {
  return (
    <Flex container alignItems="center" {...props}>
      {crumbs.map((crumb, index) => (
        <Flex container key={crumb.title} alignItems="center">
          {crumb.href ? (
            <S.StyledCrumbLink
              to={{ pathname: crumb.href, ...(crumb.state && { state: crumb.state }) }}
            >
              {crumb.title}
            </S.StyledCrumbLink>
          ) : (
            <UppercaseText color="blueA">{crumb.title}</UppercaseText>
          )}
          {crumbs[index + 1] && <S.StyledChevron />}
        </Flex>
      ))}
    </Flex>
  );
};
