import styled, { css } from 'styled-components';
import { responsive, Responsive } from 'styles/helpers/responsive';
import { ColorKey } from 'styles/theme/default';
import { typeStyle, TypeStyleType } from 'styles/typeStyle';
import { FontWeight } from 'styles/variables';

export interface TextStyleProps {
  color?: ColorKey | 'inherit';
  $type?: keyof TypeStyleType;
  margin?: Responsive;
  fontWeight?: FontWeight;
}

export const StyledText = styled.p<TextStyleProps>`
  ${(props) => css`
    ${props.$type && `${typeStyle[props.$type]};`}
    ${`color: ${
      props.color === 'inherit' ? props.color : props.theme.color[props.color || 'greyA']
    };`}
    ${props.margin && responsive('margin', props.margin)}
    ${props.fontWeight && `font-weight: ${props.fontWeight};`}
  `}
`;
