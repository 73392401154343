/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ChallengeGetProps, ChallengesInitialStateProps } from './challengesTypes';
import { MissionGetProps } from '../missionsDuck/missionsTypes';
import { ApiPayloadArguments, ApiPayloadOptions, ApiPayloadReturn, ApiResponse } from 'redux/types';
import { ApiClient } from 'util/apiClient';

const challengesApiHandler = createAsyncThunk<
  ApiPayloadReturn<ChallengeGetProps | MissionGetProps, ChallengeGetProps>,
  ApiPayloadArguments<ChallengeGetProps>,
  ApiPayloadOptions
>('challenges/challengesApiHandler', async (payloadArguments) => {
  const { data, message } = (await ApiClient[payloadArguments.method](
    `${payloadArguments.alternateEndpoint || 'challenges'}/${payloadArguments.id || ''}`,
    payloadArguments.body,
  )) as ApiResponse<ChallengeGetProps, ChallengeGetProps>;
  const returnObject = {
    data,
    message,
    method: payloadArguments.method,
  };

  return returnObject;
});

const initialState: ChallengesInitialStateProps = {
  challenges: [],
  currentChallenge: null,
  challengeMissions: [],
  loading: false,
  showChallengesError: null,
  temporayChallenge: null,
};

const challengesSlice = createSlice({
  name: 'challenges',
  initialState,
  reducers: {
    resetCurrentChallenge(state) {
      state.currentChallenge = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(challengesApiHandler.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(challengesApiHandler.fulfilled, (state, action) => {
      const { data } = action.payload;
      const { meta } = action;

      if (action.payload.method === 'delete') {
        state.challenges = state.challenges.filter((challenge) => challenge.id !== meta.arg.id);
      }

      if (Array.isArray(data) && data.length > 0) {
        if ((data[0] as MissionGetProps).challenge_id) {
          state.challengeMissions = data as Array<MissionGetProps>;
        } else {
          state.challenges = data as Array<ChallengeGetProps>;
        }
      } else {
        state.currentChallenge = data as ChallengeGetProps;
      }

      state.loading = false;
    });
    builder.addCase(challengesApiHandler.rejected, (state, action) => {
      state.showChallengesError = action.payload ? action.payload.message : null;
    });
  },
});

export const challengesActions = {
  ...challengesSlice.actions,
  challengesApiHandler,
};

export default challengesSlice.reducer;
