/* eslint no-param-reassign: ["error", { "props": false }] */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ParticipantAssessmentsProps,
  ParticipantBaseProps,
  ParticipantFullProps,
  ParticipantObjectivesGetProps,
  ParticipantsInitialStateProps,
} from './participantsTypes';
import { ApiPayloadArguments, ApiPayloadOptions, ApiPayloadReturn, ApiResponse } from 'redux/types';
import { ApiClient } from 'util/apiClient';

const participantsApiHandler = createAsyncThunk<
  ApiPayloadReturn<
    ParticipantAssessmentsProps | ParticipantBaseProps | ParticipantObjectivesGetProps,
    ParticipantFullProps
  >,
  ApiPayloadArguments<ParticipantBaseProps>,
  ApiPayloadOptions
>('participants/participantsApiHandler', async (payloadArguments) => {
  const participantEndpoint = payloadArguments.alternateEndpoint
    ? payloadArguments.alternateEndpoint
    : 'participants';
  const { data, message } = (await ApiClient[payloadArguments.method](
    `/${participantEndpoint}/${payloadArguments.id || ''}`,
    payloadArguments.body,
  )) as ApiResponse<
    ParticipantAssessmentsProps | ParticipantBaseProps | ParticipantObjectivesGetProps,
    ParticipantFullProps
  >;
  const returnObject = {
    data,
    message,
    method: payloadArguments.method,
  };
  return returnObject;
});

// constants
const initialState: ParticipantsInitialStateProps = {
  participants: [],
  participantAssessments: [],
  participantObjectives: [],
  currentParticipant: null,
  loading: false,
  loadingParticipants: false,
  showParticipantsError: null,
};

// reducers

const participantsSlice = createSlice({
  name: 'participants',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(participantsApiHandler.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(participantsApiHandler.fulfilled, (state, action) => {
      const { data } = action.payload;
      if (Array.isArray(data) && data.length > 0) {
        if ((data as Array<ParticipantAssessmentsProps>)[0].assessor_name) {
          state.participantAssessments = data as Array<ParticipantAssessmentsProps>;
        } else if ((data as Array<ParticipantObjectivesGetProps>)[0].challenge_id) {
          state.participantObjectives = data as Array<ParticipantObjectivesGetProps>;
        } else {
          state.participants = data as Array<ParticipantBaseProps>;
        }
      } else {
        state.currentParticipant = data as ParticipantFullProps;
      }
      state.loading = false;
    });
    builder.addCase(participantsApiHandler.rejected, (state, action) => {
      state.showParticipantsError = action.payload ? action.payload.message : null;
    });
  },
});

export const participantsActions = {
  ...participantsSlice.actions,
  participantsApiHandler,
};

export default participantsSlice.reducer;
