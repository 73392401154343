import styled from 'styled-components';
import EmptyFeedbackBackground from 'assets/img/dashboard/empty-feedback-card.svg';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';

export const StyledEmptyFeedbackCard = styled(CardContainer)`
  background: url(${EmptyFeedbackBackground}) no-repeat center/cover;
  height: 100%;
  padding: 16rem 0;
  text-align: center;

  ${PrimaryButton} {
    margin-top: 40px;
  }
`;

export const StyledContent = styled.div`
  max-width: 50rem;
  margin: 0 auto;
  padding: 0 2rem;
`;
