import { Element } from '../model/Element';
import { ApiClient } from '../util/apiClient';
import { createQuestionDTO } from './dto/CreateQuestionDTO';

enum QuestionEndpoints {
  CreateQuestion = '/elements',
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const createQuestion = (body: Element): Promise<void> => {
  return ApiClient.post(QuestionEndpoints.CreateQuestion, createQuestionDTO(body));
};

export const QuestionService = { createQuestion };
