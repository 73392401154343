import { ReactElement } from 'react';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import * as S from './ClientCard.styles';
import { Text } from 'components/atoms/Text/Text';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { AbstractButton } from 'components/atoms/AbstractButton/AbstractButton';
import { Path } from 'routes/Path';

interface ClientCardProps {
  name: string;
  logo: string;
  date: string;
  id: number;
}

export const ClientCard = ({ name, logo, date, id }: ClientCardProps): ReactElement => {
  const formattedDate = format(new Date(date), 'MM/dd/yyyy');

  const { push } = useHistory();

  const redirect = () => {
    push(`${Path.AdminDashboard}/${id}`);
  };

  return (
    <AbstractButton type="button" key={name} onClick={redirect}>
      <S.StyledClientCard>
        {logo && <S.ClientLogo src={logo} alt={`${name}-logo`} />}
        <S.HeadingClient margin="0 0 0.5rem" type={HeadingType.H5}>
          {name}
        </S.HeadingClient>
        <Text as="span">
          <Text type="label">Starting Date:</Text>
          <Text type="label">{formattedDate}</Text>
        </Text>
      </S.StyledClientCard>
    </AbstractButton>
  );
};
