import { ValidationRule } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultErrors = {
  required: 'This field is required.',
  emailPattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'Enter a valid e-mail address',
  },
  alphabeticPattern: {
    value: /^[a-zA-Z]+$/i,
    message: 'This field can only contain alphabetic characters (a-z).',
  },
  customMaxLength: (value: number): ValidationRule => ({
    value,
    message: `This field length cannot be more than ${value}.`,
  }),
  customMax: (value: number): ValidationRule => ({
    value,
    message: `This field value cannot exceed ${value}.`,
  }),
};

export type DefaultErrorsKey = keyof typeof defaultErrors;
