/* eslint-disable import/no-unresolved */
import React, { ReactElement, ReactNode, useCallback } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { setErrorEvent, setSuccessEvent } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';

interface FormProps {
  children?: ReactNode;
  onSuccess?: (formData: FieldValues, methods: UseFormReturn<FieldValues>) => Promise<void>;
  autoComplete?: string;
  showSuccessMessage?: boolean;
  showErrorMessage?: boolean;
  schema?: AnyObjectSchema;
  customMethods?: UseFormReturn<FieldValues>;
}

export const Form = ({
  children,
  onSuccess,
  showSuccessMessage,
  showErrorMessage,
  schema,
  customMethods,
  ...props
}: FormProps): ReactElement => {
  const dispatch = useDispatch();
  const form = useForm({ mode: 'onSubmit', resolver: schema && yupResolver(schema) });
  const methods = customMethods || form;
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const onError = useCallback(() => {
    dispatch(setErrorEvent(Events.FORM_INVALID_ERROR));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (formData: FieldValues) => {
      if (onSuccess) {
        try {
          await onSuccess(formData, methods);
          if (showSuccessMessage) dispatch(setSuccessEvent(Events.FORM_SUCCESS));
        } catch {
          if (showErrorMessage) dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
        }
      }
    },
    [onSuccess, methods, dispatch, showSuccessMessage, showErrorMessage],
  );

  const recursiveMap = useCallback(
    (children: ReactNode): ReactNode => {
      return React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if (child.props.register) {
            return React.cloneElement(child, {
              ...child.props,
              register: methods.register,
              formMethods: methods,
              errors,
              key: child.key || child.props.name,
            });
          }

          if (child.props.type === 'submit' && child.props.onClick) {
            return React.cloneElement(child, {
              ...child.props,
              onClick: handleSubmit(child.props.onClick),
            });
          }

          if (child.props.type === 'button' && child.props.onClick) {
            return React.cloneElement(child, {
              ...child.props,
              onClick: () => child.props.onClick(methods),
            });
          }

          let elementChild: React.ReactElement = child;
          if (child.props.children) {
            elementChild = React.cloneElement(elementChild, {
              children: recursiveMap(elementChild.props.children),
            });
          }
          return elementChild;
        }
        return child;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errors],
  );

  return (
    <form {...props} onSubmit={handleSubmit(onSubmit, onError)}>
      {recursiveMap(children)}
    </form>
  );
};
