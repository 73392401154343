import { ReactElement, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { ClientForm } from '../../components/ClientForm/ClientForm';
import { CustomerService } from 'services/CustomerService';
import { setSuccessEventAction, setErrorEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { useAppDispatch } from 'redux/store';
import { Customer } from 'model/Customer';
import { Participant } from 'model/Participant';

export const AddClient = (): ReactElement => {
  const [currentCustomer, setCurrentCustomer] = useState<Customer>(() => new Customer());
  const [participants, setParticipants] = useState<Array<Participant>>([]);
  const dispatch = useAppDispatch();

  const handleCreateClient = async (formData: FieldValues) => {
    try {
      const customer: Customer = Object.assign(new Customer(), formData.customer);

      const customerData = await CustomerService.createCustomer(customer);
      setCurrentCustomer(customerData);

      dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
    } catch (error) {
      dispatch(setErrorEventAction(Events.FORM_INVALID_ERROR));
    }
  };

  return (
    <ClientForm
      handleCreateClient={handleCreateClient}
      currentCustomer={currentCustomer}
      setCurrentCustomer={setCurrentCustomer}
      participants={participants}
      setParticipants={setParticipants}
    />
  );
};
