/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-unresolved
import { LocationState } from 'globalTypes';
import { memo, ReactElement, useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import * as S from './EditAssessment.styles';
import { ElementFormItem } from './sections/ElementFormItem/ElementFormItem';
import { QuestionLevel } from './sections/QuestionLevel/QuestionLevel';
import { ApiElementProps } from './EditAssessmentTypes';
import { CustomerSelect } from '../CustomerSelect/CustomerSelect';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { MediaQuery } from 'styles/mediaQuery';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Input } from 'components/atoms/Input/Input';
import { FormSticky } from 'components/molecules/FormSticky/FormSticky';
import { RootState } from 'redux/store';
import { surveysActions } from 'redux/ducks/surveysDuck/surveysDuckReducer';
import { ActionMethod } from 'data/enum/ActionMethod';
import { SurveyDataProps, SurveyProps } from 'redux/ducks/surveysDuck/surveysDuckTypes';

export const EditAssessment = memo((): ReactElement => {
  const history = useHistory<LocationState>();
  const dispatch = useDispatch();

  const [survey, setSurvey] = useState<null | SurveyProps | SurveyDataProps>(null);

  // const { selectedChallenges, selectedQuestions, selectedNewQuestions } = useSelector(
  //   (state: RootState) => {
  //     return state.selectedChallengesOrQuestions;
  //   },
  // );

  // const { elements } = useSelector((state: RootState) => {
  //   return state.elements;
  // });

  // const { temporaryQuestions } = useSelector((state: RootState) => {
  //   return state.temporaryQuestions;
  // });

  const currentSurvey = useSelector((state: RootState) => {
    return state.surveys.currentSurvey as SurveyProps;
  });

  const { temporaryNewQuestions } = useSelector((state: RootState) => {
    return state.temporaryQuestions;
  });

  const handleSuccess = async (values: FieldValues) => {
    // eslint-disable-next-line no-console
    if (!survey) return;

    const {
      'assessment-description': description,
      'assessment-name': name,
      'question-level': question_level,
    } = values;

    const { id } = history.location.state;

    const elementsCollection = values.elements.map((item: any) => {
      const { challenges } = item;
      const questions: Array<any> = item.questions.map((item: any) => ({
        question_id: item.question_id,
        name: item.name,
        description: item.description,
        allow_no_answer: item.allow_no_answer,
        set_as_open: item.set_as_open,
        element_type_id: item.element_type_id,
        order: item.order,
      }));

      return {
        element_id: item.element_id,
        order: 0,
        challenges,
        questions,
      };
    });

    const newQuestions = temporaryNewQuestions.filter((item) => item);

    const responseBody: SurveyDataProps = {
      survey: {
        version_id: id,
        name,
        description,
        question_level,
      },
      elements: elementsCollection,
      ...(newQuestions.length > 0 ? { questions: newQuestions } : {}),
    };
    dispatch(
      surveysActions.surveysApiHandler({ method: ActionMethod.PUT, data: responseBody, id }),
    );
  };

  const handleCancel = () => {
    history.goBack();
  };

  useEffect(() => {
    const { id } = history.location.state;
    dispatch(surveysActions.surveysApiHandler({ method: ActionMethod.GET, id }));
  }, [history, dispatch]);

  useEffect(() => {
    setSurvey(currentSurvey as SurveyProps);

    return () => {
      setSurvey(null);
    };
  }, [currentSurvey]);

  if (!currentSurvey) return <S.StyledLoading color="blueA" />;

  return (
    <S.StyledCreateAssessment>
      <BackgroundCircle
        lastBackground
        top={['40%', [MediaQuery.MIN_768, '34.6rem']]}
        left={['-40.5rem', [MediaQuery.MIN_768, '-58.5rem']]}
        size={['78.6rem', [MediaQuery.MIN_768, '113.8rem']]}
      />

      <AdminHeading
        title="Edit the assessment"
        $color="blueA"
        $underline
        breadcrumbs={[
          { title: 'Assessment Management', href: '/admin/assessment-management' },
          { title: 'edit assessment' },
        ]}
      />

      <S.StyledForm onSuccess={handleSuccess}>
        <Flex container gap="2rem">
          <Flex flexBasis="50%">
            <RegisterFormItem
              register
              registerErrors="required"
              name="assessment-name"
              label="Assessment Name"
              size={FormItemSizes.Medium}
              alignError="left"
            >
              <Input
                hasRef
                placeholder="Enter assessment name"
                defaultValue={survey ? (survey as SurveyProps)?.name : ''}
              />
            </RegisterFormItem>
          </Flex>
          <Flex flexBasis="50%">
            <RegisterFormItem
              register
              registerErrors="required"
              name="assessment-description"
              label="Assessment Description"
              size={FormItemSizes.Medium}
              alignError="left"
            >
              <Input
                hasRef
                placeholder="Enter Clients Description"
                defaultValue={survey ? (survey as SurveyProps)?.description : ''}
              />
            </RegisterFormItem>
          </Flex>
        </Flex>

        <CustomerSelect register defaultValue={0} />

        {/* <AssignToCohort register /> */}
        <QuestionLevel
          register
          value={(survey as SurveyProps)?.question_level}
          name="question_level"
          defaultValue={(survey as SurveyProps)?.question_level}
        />
        <ElementFormItem
          register
          apiElements={(survey as SurveyDataProps)?.elements as Array<ApiElementProps>}
        />

        <FormSticky handleCancel={handleCancel} confirmCopy="save &amp; finish" />
      </S.StyledForm>
    </S.StyledCreateAssessment>
  );
});
