import styled from 'styled-components';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';

export const StyledClientLogo = styled.div`
  width: 4.5rem;
  height: 4.5rem;
  background: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 3.6rem;
  }
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 2rem;
`;

export const StyledEditButton = styled(SecondaryButton)`
  grid-column-start: 2;
`;
