/* eslint no-param-reassign: ["error", { "props": false }] */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ObjectiveGetProps,
  ObjectiveInitialStateProps,
  ObjectivePostProps,
} from './objectivesDuckTypes';
import { ApiPayloadArguments, ApiPayloadOptions, ApiPayloadReturn, ApiResponse } from 'redux/types';
import { ApiClient } from 'util/apiClient';

const objectivesApiHandler = createAsyncThunk<
  ApiPayloadReturn<ObjectiveGetProps, ObjectiveGetProps>,
  ApiPayloadArguments<ObjectivePostProps>,
  ApiPayloadOptions
>('objectives/objectivesApiHandler', async (payloadArguments) => {
  const { data, message } = (await ApiClient[payloadArguments.method](
    `objetives/${payloadArguments.id || ''}`,
    payloadArguments.body,
  )) as ApiResponse<ObjectiveGetProps, ObjectiveGetProps>;

  const returnObject = {
    data,
    message,
    method: payloadArguments.method,
  };

  return returnObject;
});

const initialState: ObjectiveInitialStateProps = {
  objectives: [],
  currentObjective: null,
  loading: false,
  loadingObjectives: false,
  showObjectivesError: null,
};

const objectivesSlice = createSlice({
  name: 'objectives',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(objectivesApiHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(objectivesApiHandler.fulfilled, (state, action) => {
        const { data } = action.payload;
        if (action.payload.method === 'delete') {
          state.objectives = state.objectives.filter(
            (objective, index) => objective.id !== (data as Array<ObjectiveGetProps>)[index].id,
          );
        }

        if (Array.isArray(data)) {
          state.objectives = data;
        } else {
          state.currentObjective = data;
        }
        state.loading = false;
      })
      .addCase(objectivesApiHandler.rejected, (state, action) => {
        state.showObjectivesError = action.payload ? action.payload.message : null;
      });
  },
});

export const objectivesActions = {
  ...objectivesSlice.actions,
  objectivesApiHandler,
};
export default objectivesSlice.reducer;
