import styled from 'styled-components';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Heading } from 'components/atoms/Heading/Heading';

export const StyledClientCard = styled(CardContainer)`
  width: 19rem;
  height: 24.5rem;
  text-align: center;
  padding: 3.5rem 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.color.blueE};
    }
  }
`;

export const ClientLogo = styled.img`
  border-radius: 50%;
  aspect-ratio: 1/1;
  max-width: 65%;
  width: 100%;
  object-fit: cover;
`;

export const HeadingClient = styled(Heading)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
