/* eslint-disable import/no-unresolved */
import { RegisteredFormItem } from 'globalTypes';
import { ReactElement, useEffect, useState, forwardRef, ForwardedRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './CustomerSelect.styles';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Select, SelectItem } from 'components/molecules/Select/Select';
import { RootState } from 'redux/store';
import { customersActions } from 'redux/ducks/customersDuck/customersReducer';
import { ActionMethod } from 'data/enum/ActionMethod';

// eslint-disable-next-line no-empty-pattern
export const CustomerSelect = forwardRef(
  (
    { register, formMethods, errors, defaultValue, onChange }: RegisteredFormItem,
    ref,
  ): ReactElement => {
    const [formCustomers, setFormCustomers] = useState<Array<SelectItem>>();
    const [defaultItem, setDefaultItem] = useState<SelectItem>();
    const { customers } = useSelector((state: RootState) => {
      return state.customers;
    });
    const dispatch = useDispatch();

    const getCustomers = useCallback(() => {
      if (!customers) return;

      const testClients = customers.map((customer) => {
        return {
          title: customer.name,
          value: customer.id,
        };
      });

      if (defaultValue) {
        const defaultCustomer = testClients.filter((client) => client.value === defaultValue);
        setDefaultItem(defaultCustomer[0]);
      }
      setFormCustomers(testClients);
    }, [customers, defaultValue]);

    const handleSelectItem = (item: SelectItem) => {
      if (onChange) {
        onChange(item.value);
      }
      formMethods.setValue('customer-select', item);
      formMethods.trigger('customer-select');
    };

    useEffect(() => {
      dispatch(
        customersActions.customersApiHandler({ method: ActionMethod.GET, entity: 'customerList' }),
      );
    }, [dispatch]);

    useEffect(() => {
      getCustomers();
    }, [getCustomers]);

    return (
      <S.StyledCustomerSelect>
        <RegisterFormItem
          errors={errors}
          register={register}
          formMethods={formMethods}
          registerErrors="required"
          name="customer-select"
          label="Customer Select"
          size={FormItemSizes.Medium}
          alignError="right"
        >
          <Select
            // {...props}
            ref={ref as ForwardedRef<HTMLDivElement>}
            items={formCustomers || []}
            variant="input"
            onSelect={handleSelectItem}
            defaultValue={defaultItem?.title}
            placeholder="Select an option"
            isElementComponent
          />
        </RegisterFormItem>
      </S.StyledCustomerSelect>
    );
  },
);
