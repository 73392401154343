import { ReactElement, useEffect } from 'react';
import * as S from './Onboarding.styles';
import { Elements } from './sections/Elements/Elements';
import { Hero } from './sections/Hero/Hero';
import { Intro } from './sections/Intro/Intro';
import { Objectives } from './sections/Objectives/Objectives';
// hiding this component until client gives us the final assets
// import { VideoGallery } from './sections/VideoGallery/VideoGallery';
import { StartAssessment } from './sections/StartAssessment/StartAssessment';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';

export const Onboarding = (): ReactElement => {
  useEffect(() => {
    localStorage.setItem('onboardingAlreadyShowed', 'true');
  }, []);

  return (
    <>
      <NormalLayout>
        <S.OnboardingPage>
          <Hero />
          <Intro />
          <Elements />
          {/* <VideoGallery /> */}
          <Objectives />
          <StartAssessment />
        </S.OnboardingPage>
      </NormalLayout>
    </>
  );
};
