import styled, { css } from 'styled-components';
import { Check } from '../Icon/Icon';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export interface RadioStyleProps {
  mobileType?: 'box' | 'row';
}

export const StyledRadioContainer = styled.div<RadioStyleProps>`
  display: flex;
  align-items: center;
  gap: 16px;

  ${({ mobileType, theme }) =>
    mobileType &&
    css`
      @media ${respondTo(MediaQuery.MAX_767)} {
        ${mobileType === 'box' &&
        `
          justify-content: center;
          width: 100%;
          height: 14.8rem;
          border-radius: 0.8rem;
          background: ${theme.color.greyB};
        `}

        ${mobileType === 'row' &&
        `
          flex-direction: row;
        `}
      }
    `}
`;

export const StyledRadio = styled.input<RadioStyleProps>`
  appearance: none;
  display: block;
  width: 3.2rem;
  height: 3.2rem;
  color: ${({ theme }) => theme.color.blueA};
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid ${({ theme }) => theme.newColor.surface200};
  border-radius: 2px;
  position: relative;
  cursor: pointer;

  &[checked] {
    background-color: ${({ theme }) => theme.newColor.primary100};
    border-color: ${({ theme }) => theme.newColor.primary100};
  }
`;

export const StyledContainer = styled.div`
  position: relative;
  z-index: 0;
`;

export const StyledCheck = styled(Check)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2rem;
  height: 2rem;
  color: ${({ theme }) => theme.color.white};
  z-index: 1;
  user-select: none;
  pointer-events: none;
`;
