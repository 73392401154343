/* eslint-disable import/no-cycle */
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import assessmentsReducer from './ducks/assessmentsDuck/assessmentsReducer';
import participanReducer from './ducks/participantsDuck/participantsReducer';
import industriesReducer from './ducks/industriesDuck/industriesReducer';
import functionsReducers from './ducks/functionsDuck/functionsReducers';
import { authReducer } from './ducks/authDuck/authReducer';
import { eventsReducer } from './ducks/eventsDuck/eventsReducer';
import challengesReducer from './ducks/challengesDuck/challengesReducer';
import { elementsReducer } from './ducks/elementsDuck/elementsReducer';
import questionsReducer from './ducks/questionsDuck/questionsReducer';
import surveysReducer from './ducks/surveysDuck/surveysDuckReducer';
import objectivesReducer from './ducks/objectivesDuck/objectivesDuckReducer';
import missionsReducer from './ducks/missionsDuck/missionsReducer';
import { temporaryQuestionsReducer } from './ducks/temporaryQuestionsDuck/temporaryQuestionsReducers';
import { selectedChallengesOrQuestionsReducer } from './ducks/selectedChallengesOrQuestionsDuck/selectedChallengesOrQuestionsReducers';
import cohortsReducer from './ducks/cohortDuck/cohortsReducers';
import { selectedCohortsReducer } from './ducks/selectedCohortsDuck/selectedCohortsReducers';
import customersReducer from './ducks/customersDuck/customersReducer';
import organizationalTypesReducer from './ducks/organizationalTypes/organizationalTypesReducer';
import customerDashboardSlice from './ducks/customerDashboardDuck/customerDashboardReducer';
import dashboardSlice from './ducks/dashboardDuck/dashboardReducer';
import countriesSlice from './ducks/countriesDuck/countriesReducer';
import nationalitiesSlice from './ducks/nationalitiesDuck/nationalitiesReducer';
import gendersSlice from './ducks/gendersDuck/gendersReducer';

/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const store = configureStore({
  reducer: {
    assessments: assessmentsReducer,
    auth: authReducer,
    events: eventsReducer,
    elements: elementsReducer,
    challenges: challengesReducer,
    cohorts: cohortsReducer,
    customers: customersReducer,
    customerDashboard: customerDashboardSlice,
    selectedCohorts: selectedCohortsReducer,
    questions: questionsReducer,
    surveys: surveysReducer,
    objectives: objectivesReducer,
    missions: missionsReducer,
    temporaryQuestions: temporaryQuestionsReducer,
    selectedChallengesOrQuestions: selectedChallengesOrQuestionsReducer,
    participants: participanReducer,
    industries: industriesReducer,
    functions: functionsReducers,
    organizationalTypes: organizationalTypesReducer,
    countries: countriesSlice,
    nationalities: nationalitiesSlice,
    genders: gendersSlice,
    dashboard: dashboardSlice,
  },
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers.concat(Sentry.createReduxEnhancer({}));
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
