import { ReactElement } from 'react';
import { UseEmblaCarouselType } from 'embla-carousel/react';
import { FormattedMessage } from 'react-intl';
import { AnimatePresence, MotionProps } from 'framer-motion';
import * as S from './CardQuestions.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { Question } from 'services/types/DashboardTypes';

interface CardQuestionsProps {
  questions: Array<Question>;
  active: number;
  paginationMotionProps: MotionProps;
  carouselRef: UseEmblaCarouselType[0];
}

interface SelfWrittenAssessmentProps {
  questions: Array<Question>;
  active: number;
  paginationMotionProps: MotionProps;
}

const SelfWrittenAssessment = ({
  paginationMotionProps,
  questions,
  active,
}: SelfWrittenAssessmentProps) => (
  <S.StyledSelfAssessment>
    <AnimatePresence exitBeforeEnter>
      <S.StyledTitle>My comments</S.StyledTitle>
      <S.PersonalCommentsWrapper {...paginationMotionProps} key={questions[active].self_answer}>
        <Paragraph color="cream">{questions[active].self_answer}</Paragraph>
      </S.PersonalCommentsWrapper>
    </AnimatePresence>
  </S.StyledSelfAssessment>
);

export const CardQuestions = ({
  active,
  questions,
  paginationMotionProps,
  carouselRef,
}: CardQuestionsProps): ReactElement => {
  return (
    <S.StyledCardQuestionsContainer>
      <S.StyledCarouselViewport className="embla__viewport" ref={carouselRef}>
        <Flex container className="embla__container">
          {questions.map((question, index) => (
            <S.StyledQuestion key={question.title}>
              <S.StyledQuestionEyebrow>
                <FormattedMessage
                  description="Dashboard - Feedback Card - Question - Eyebrow"
                  defaultMessage="Question {index}"
                  values={{ index: index + 1 }}
                />
              </S.StyledQuestionEyebrow>
              <S.StyledTitle>{question.title}</S.StyledTitle>
              <Paragraph color="cream">{question.content}</Paragraph>
            </S.StyledQuestion>
          ))}
        </Flex>
      </S.StyledCarouselViewport>

      {typeof questions[active]?.self_answer === 'string' ? (
        <SelfWrittenAssessment
          questions={questions}
          active={active}
          paginationMotionProps={paginationMotionProps}
        />
      ) : (
        typeof questions[active]?.self_answer !== 'number' && (
          <S.StyledPersonalWarning color="cream">
            The participant hasn’t completed the assessment. You can always send them a friendly
            reminder.
          </S.StyledPersonalWarning>
        )
      )}
    </S.StyledCardQuestionsContainer>
  );
};
