/* eslint-disable unicorn/prevent-abbreviations */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable no-case-declarations */
import * as types from './selectedChallengesOrQuestionsTypes';
import { ReduxReducer } from 'redux/types';

const initialState = {
  selectedChallenges: [],
  selectedQuestions: [],
  selectedNewQuestions: [],
} as initialStateTypes;

export interface initialStateTypes {
  selectedChallenges: Array<any>;
  selectedQuestions: Array<any>;
  selectedNewQuestions: Array<any>;
}

export const selectedChallengesOrQuestionsReducer: ReduxReducer<typeof initialState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.GET_SELECTED_CHALLENGES:
      let selectedChallenges;
      const indexOfUpdate = state?.selectedChallenges.findIndex((el) => {
        return Object.values(el)[0] === Object.values(action.payload)[0];
      });
      if (indexOfUpdate >= 0) {
        selectedChallenges = [...state?.selectedChallenges];
        selectedChallenges[indexOfUpdate] = action.payload;
      } else {
        selectedChallenges = [...state?.selectedChallenges, action.payload];
      }
      return {
        ...state,
        selectedChallenges,
      };

    case types.REMOVE_SELECTED_CHALLENGE:
      return {
        ...state,
        selectedChallenges: [
          ...state?.selectedChallenges.filter((_, index) => {
            return index !== action.payload;
          }),
        ],
      };

    case types.CLEAR_SELECTED_CHALLENGES:
      return {
        ...state,
        selectedChallenges: [],
      };

    case types.GET_SELECTED_QUESTIONS:
      return {
        ...state,
        selectedQuestions: [
          ...state?.selectedQuestions.filter((value) => {
            return Object.values(value)[0] !== Object.values(action.payload)[0];
          }),
          action.payload,
        ],
      };

    case types.GET_SELECTED_NEW_QUESTIONS:
      return {
        ...state,
        selectedNewQuestions: [
          ...state?.selectedNewQuestions.filter((value) => {
            return Object.values(value)[0] !== Object.values(action.payload)[0];
          }),
          action.payload,
        ],
      };

    case types.GET_SELECTED_NAME:
      return { ...state, selectedChallenges: action.payload };

    default:
      return state;
  }
};
