import { ReactElement, useCallback, useMemo } from 'react';
import * as S from './Card.styles';
import { FontWeight } from 'styles/variables';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { Text } from 'components/atoms/Text/Text';
import { MediaQuery } from 'styles/mediaQuery';
import { SurveElementChallenge } from 'services/types/SurveyTypes';

interface CardProps {
  challenge: SurveElementChallenge;
  active: boolean;
}

export const Card = ({ challenge, active }: CardProps): ReactElement => {
  const setLengthType = useCallback((type: number) => {
    let lengthType: string;
    switch (type) {
      case 1:
        lengthType = 'day';
        break;
      case 2:
        lengthType = 'weeks';
        break;
      case 3:
        lengthType = 'months';
        break;
      default:
        lengthType = 'years';
    }

    return lengthType;
  }, []);

  const setTitle = useMemo(() => {
    if (challenge) {
      const challengeLengthType = setLengthType(challenge.duration_type.id);
      return `${challenge.duration} - ${challengeLengthType} challenge`;
    }
    return '';
  }, [challenge, setLengthType]);

  const setSubtitle = useMemo(() => {
    if (challenge) {
      const missionLengthTime = setLengthType(challenge.mission_duration.id);
      return `This package is a set of ${challenge.mission_amount} missions per ${missionLengthTime}`;
    }
    return '';
  }, [challenge, setLengthType]);

  return (
    <S.StyledCardContainer variant={active ? 'blue' : 'grey'}>
      <Flex container flexDirection="column" justifyContent="space-between" minHeight="100%">
        <div>
          <Paragraph
            color="currentColor"
            margin={['0 0 1rem', [MediaQuery.MIN_768, '0 0 .4rem']]}
            fontWeight={FontWeight.SemiBold}
          >
            {setTitle}
          </Paragraph>
          <Text margin="0 0 1.5rem" color={active ? 'whiteB' : 'blackB'} type="labelB">
            {setSubtitle}
          </Text>
        </div>
        <Text color={active ? 'cream' : 'greyA'} type="labelB">
          {challenge.description}
        </Text>
      </Flex>
    </S.StyledCardContainer>
  );
};
