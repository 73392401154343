/* eslint-disable import/no-cycle */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/no-unresolved */
import { RegisteredFormItem } from 'globalTypes';
import { ReactElement, useEffect, useState } from 'react';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { Plus } from 'components/atoms/Icon/Icon';
import { Input, InputProps } from 'components/atoms/Input/Input';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import {
  RegisterFormItem,
  RegisterFormItemProps,
} from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { MediaQuery } from 'styles/mediaQuery';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';

interface AddUnitProps extends RegisteredFormItem {
  handleAddUnit: (unitDescription: string) => void;
  name: string;
  registerFormItemProps?: Omit<RegisterFormItemProps, 'children' | 'name'>;
  inputProps?: InputProps;
  ctaCopy?: string;
  defaultValue?: string;
}

export const AddUnit = ({
  formMethods,
  register,
  errors,
  handleAddUnit,
  ctaCopy,
  registerFormItemProps,
  inputProps,
  name,
  defaultValue,
}: AddUnitProps): ReactElement => {
  const [isopen, setIsOpen] = useState(false);

  useEffect(() => {
    if (formMethods && !isopen) {
      formMethods.clearErrors(name);
    }
  }, [isopen]);

  const handleAddClick = async () => {
    const unitName = formMethods.getValues(name) || 'No Program Selected';
    handleAddUnit(unitName);
    setIsOpen(false);

    // Reset input field
    formMethods.setValue(name, '');
  };

  return (
    <Flex container flexDirection="column" gap="3rem" width="100%">
      <Flex
        container
        gap="3rem"
        alignItems={['center', [MediaQuery.MAX_767, 'stretch']]}
        flexDirection={['row', [MediaQuery.MAX_767, 'column']]}
        justifyContent="space-between"
      >
        <Flex flexBasis={['50%', [MediaQuery.MIN_1024, '70%']]} flexGrow="1">
          <RegisterFormItem
            errors={errors}
            register={register}
            formMethods={formMethods}
            size={FormItemSizes.Medium}
            alignError="left"
            name={name}
            label="Program Name"
            {...registerFormItemProps}
          >
            <Input
              hasRef
              defaultValue={defaultValue}
              placeholder="Enter program's name"
              {...inputProps}
            />
          </RegisterFormItem>
          <Paragraph margin="8px 0 0">
            * In case the program is not specified leave it blank and click Add Program
          </Paragraph>
        </Flex>
        <Flex
          container
          flexDirection="column"
          alignItems={['flex-end', [MediaQuery.MAX_767, 'stretch']]}
          gap="1.5rem"
        >
          <PrimaryButton type="button" $size="regular" onClick={handleAddClick}>
            {ctaCopy || 'Add Program'}
            <Plus />
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  );
};
