import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './Sidebar.styles';
import { slideFade } from 'util/motionTransitions';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { ArrowGrotesk } from 'components/atoms/Icon/Icon';
import { Separator } from 'components/atoms/Separator/Separator';
import { UppercaseText } from 'components/atoms/UppercaseText/UppercaseText';
import { RootState } from 'redux/store';
import { dashboardActions } from 'redux/ducks/dashboardDuck/dashboardReducer';

export const Sidebar = (): ReactElement => {
  const dispatch = useDispatch();
  const { currentSubunit } = useSelector((state: RootState) => {
    return state.customerDashboard;
  });

  const { currentCohortIndex } = useSelector((state: RootState) => state.dashboard);

  const setActiveAssessment = (index: number) => {
    dispatch(dashboardActions.setCurrentParticipantIndex(0));
    dispatch(dashboardActions.setCurrentCohortIndex(index));
  };

  return (
    <CardContainer variant="blue" {...slideFade()}>
      <Heading>Performance Review</Heading>
      <Separator margin="1.6rem 0 4rem" color="greyE" />
      <Flex container justifyContent="space-between" alignItems="center">
        <UppercaseText color="blueG">Assessments</UppercaseText>
        {/* //* Removed for MVP
        <SecondaryButton color="blueG" href={Path.AssessmentHistory}>
          <span>View all</span>
        </SecondaryButton> */}
      </Flex>
      <S.StyledAssessmentsFlex>
        {currentSubunit?.cohorts.map((cohort, index) => (
          <S.StyledAssessment
            key={cohort.cohort_survey?.id}
            active={currentCohortIndex === index}
            className="card-container-full-bleed"
          >
            <S.StyledAssessmentButton onClick={() => setActiveAssessment(index)}>
              {cohort.cohort_survey?.survey.name}
            </S.StyledAssessmentButton>
            {currentCohortIndex === index && (
              <S.StyledCircleArrow>
                <S.StyledCircleArrowInner>
                  <ArrowGrotesk />
                </S.StyledCircleArrowInner>
              </S.StyledCircleArrow>
            )}
          </S.StyledAssessment>
        ))}
      </S.StyledAssessmentsFlex>
    </CardContainer>
  );
};
