import styled from 'styled-components';
import { Flex } from '../Flex/FlexContainer';
import { typeStyle } from 'styles/typeStyle';
import { zIndex } from 'styles/zIndex';

export const StyledMessage = styled.div`
  ${typeStyle.body}
  border-radius: 3rem;
  background: ${({ theme }) => theme.color.redH};
  color: ${({ theme }) => theme.color.white};
  z-index: ${zIndex('overlay')};
`;

export const StyledFlex = styled(Flex)`
  position: relative;
  padding: 1.4rem 2.1rem 1.4rem 2.4rem;
`;

export const StyledTriangle = styled.div`
  position: absolute;
  right: -0.5rem;
  bottom: -0rem;
  border-color: transparent transparent ${({ theme }) => theme.color.redH} transparent;
  border-style: solid;
  border-width: 0 1.6rem 1.6rem 1.6rem;
  height: 0px;
  width: 0px;
  transform: rotate(25deg);
  z-index: -1;
`;
