import { FormEvent, ReactElement } from 'react';
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild } from 'globalTypes';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Input } from 'components/atoms/Input/Input';
import { Radio } from 'components/atoms/Radio/Radio';
import { MediaQuery } from 'styles/mediaQuery';
import { useMemoryState } from 'hooks/useMemoryState';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TimeProps extends RegisterFormItemChild {}

export const Time = ({ formMethods, name }: TimeProps): ReactElement => {
  const FIRST_RADIO = 'Today';
  const SECOND_RADIO = 'Other';
  const radios = [FIRST_RADIO, SECOND_RADIO];

  const [activeradio, setActiveRadio] = useMemoryState<undefined | typeof radios[number]>(
    `${name as string}-active-radio`,
    undefined,
  );
  const [otherdate, setOtherDate] = useMemoryState<undefined | string>(
    `${name as string}-other-date`,
    undefined,
  );

  const handleOtherDateChage = (event: FormEvent<HTMLInputElement>) => {
    setTimeout(() => {
      const {
        target: { value },
      } = event as unknown as { target: { value: string | null } };

      setOtherDate(value || undefined);
      formMethods.setValue(name, value);
    }, 50);
  };

  const handleRadioChage = (radio: typeof radios[number]) => {
    setActiveRadio(radio);
    if (radio === FIRST_RADIO) {
      const dateObject = new Date();
      const month = dateObject.getUTCMonth() + 1;
      const day = dateObject.getUTCDate();
      const year = dateObject.getUTCFullYear();
      const addLeadingCero = (date: number) => (date < 10 ? 0 : '');

      const todaysDate = `${year}-${addLeadingCero(month)}${month}-${addLeadingCero(day)}${day}`;

      formMethods.setValue(name, todaysDate);
    } else {
      formMethods.setValue(name, otherdate);
    }
  };

  return (
    <Flex
      container
      gap={['1.9rem', [MediaQuery.MIN_768, '7.1rem']]}
      flexWrap={[[MediaQuery.MAX_767, 'wrap']]}
    >
      {radios.map((radio) => (
        <Flex flexBasis={[[MediaQuery.MAX_767, 'calc(50% - 1.9rem)']]} key={radio}>
          <Radio
            label={radio}
            defaultChecked={activeradio === radio}
            onClick={() => handleRadioChage(radio)}
            mobileType="box"
            name="create-objective-form"
          />
        </Flex>
      ))}
      <Flex flexBasis={[[MediaQuery.MAX_767, 'calc(100% - 1.9rem)']]}>
        <Input
          onClick={() => setActiveRadio(SECOND_RADIO)}
          handleInputChange={handleOtherDateChage}
          type="date"
          name="date"
          defaultValue={otherdate}
        />
      </Flex>
    </Flex>
  );
};
