import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Heading } from 'components/atoms/Heading/Heading';

export const StyledCardContainer = styled(CardContainer)`
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 400px;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    min-height: 600px;
  }
`;

export const StyledUncompletedDashboardHeading = styled(Heading)`
  text-align: center;
  font-size: 2.4rem;
`;
