import styled from 'styled-components';
import { Form } from 'components/molecules/Form/Form';
import { StickyBar } from 'components/atoms/StickyBar/StickyBar';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const ContentWrapper = styled.div`
  width: 100%;
  height: auto;
  padding-bottom: 15rem;
`;

export const StyledForm = styled(Form)`
  margin-top: 2rem;
  border-top: solid 1px ${({ theme }) => theme.color.greyD};
  width: 100%;

  @media ${respondTo(MediaQuery.MIN_768)} {
    margin-top: 3rem;
  }
`;

export const InputRow = styled.div`
  @media ${respondTo(MediaQuery.MAX_767)} {
    & > *:not(:last-of-type) {
      margin-bottom: 3rem;
    }
  }

  @media ${respondTo(MediaQuery.MIN_768)} {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem;
    margin-bottom: 2rem;

    & > * {
      flex-basis: calc(50% - 2rem);
      min-width: calc(50% - 2rem);
    }
  }
`;

export const StyledStickyBar = styled(StickyBar)`
  display: flex;
  gap: 2rem;
  justify-content: flex-end;

  @media ${respondTo(MediaQuery.MIN_768)} {
    gap: 4rem;
  }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin-top: 2rem;
`;
