import styled from 'styled-components';

export const StyledUpload = styled.div`
  cursor: pointer;
`;

export const StyledUploadInner = styled.div`
  position: relative;
  width: fit-content;
`;

export const StyledLabel = styled.label`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
`;

export const StyledInput = styled.input`
  display: none;
`;

export const StyledFileContainer = styled.li`
  margin-top: 0.8rem;
`;
