import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useEmblaCarousel } from 'embla-carousel/react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as S from './ElementsRightSide.styles';
import { ElementSectionRightItem } from '../ElementSectionRightItem/ElementSectionRightItem';
import { useDeviceState } from 'hooks/useDeviceState';
import { CustomMouse } from 'components/atoms/CustomMouse/CustomMouse';
import { ElementsCursor } from 'components/atoms/Icon/Icon';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Heading } from 'components/atoms/Heading/Heading';
import { Hide } from 'components/atoms/Hide/Hide';
import { MediaQuery } from 'styles/mediaQuery';
import { RootState } from 'redux/store';

export const ElementsRightSide = (): ReactElement => {
  const intl = useIntl();
  const { isMobile } = useDeviceState();
  const [mouse, setMouse] = useState(false);
  const [carouselRef, embla] = useEmblaCarousel({ align: 'start' });
  const items = useMemo(() => embla?.slideNodes(), [embla]) as Array<HTMLElement>;
  const scrollSnapList = useMemo(() => embla?.scrollSnapList(), [embla]) as Array<number>;

  const { currentSurvey } = useSelector((state: RootState) => {
    return state.surveys;
  });

  const currentSurveyElements = useMemo(() => {
    if (!currentSurvey) return [];
    const elementsLength = currentSurvey.elements.length - 1;
    const carouselData = currentSurvey.elements.map((element, index) => {
      return {
        id: element.id,
        title: {
          description: `Onboarding - Elements - Element ${index + 1} - Title`,
          defaultMessage: `
          <title>
            ${index + 1} / ${elementsLength} ${element.name}
          </title>
          `,
        },
        subtitle: {
          description: `Onboarding - Elements - Element ${index + 1} - Subtitle`,
          defaultMessage: `
          <subtitle>To discover new opportunities</subtitle>
        `,
        },
        paragraph: {
          description: `Onboarding - Elements - Element ${index + 1} - Paragraph`,
          defaultMessage: `
          <paragraph>
            Teasing out new insights from weak signals, chance encounters and diverse fields, to
            unearth opportunity. <br />
            <br /> Working through contradicting signals, lack of clarity and multiple perspectives.
            <br />
            <br /> Purposefully challenging existing norms and conventional boundaries to provide an
            original point of view.
          </paragraph>
          `,
        },
      };
    });
    return carouselData;
  }, [currentSurvey]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (embla) {
      embla.on('scroll', () => {
        const scrollProgress = embla.scrollProgress();

        embla.slidesInView().forEach((slideIndex) => {
          const slide = items[slideIndex];
          const slideSnapValue = scrollSnapList[slideIndex];
          const titleContainer = slide.firstChild as HTMLElement;
          const textContainter = slide.lastChild as HTMLElement;
          const scrollProgressToSlide =
            (scrollProgress - scrollSnapList[1] * (slideIndex - 1)) / scrollSnapList[1];

          if (scrollProgress > slideSnapValue) {
            const opacity = (1 - (scrollProgressToSlide - 1)) as unknown as string;
            textContainter.style.opacity = opacity;
            titleContainer.style.opacity = opacity;
          } else {
            textContainter.style.opacity = scrollProgressToSlide as unknown as string;
            titleContainer.style.opacity =
              scrollProgressToSlide < 0.2
                ? (0.2 as unknown as string)
                : (scrollProgressToSlide as unknown as string);
          }
        });
      });

      return () => {
        embla.destroy();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [embla]);

  return (
    <S.StyledRightSide>
      <Hide screen={MediaQuery.MIN_1024}>
        <Heading type={HeadingType.H4} style="label"></Heading>
      </Hide>

      <S.StyledHeading type={HeadingType.H2}>
        <FormattedMessage
          description="Onboarding - Elements - Title"
          defaultMessage="The <bold>key areas</bold> of this assessment"
          values={{
            bold: (chunks: string) => <S.StyledTitleHighlight>{chunks}</S.StyledTitleHighlight>,
          }}
        />
      </S.StyledHeading>

      {/* <S.StyledPaginationButtons
        onLeftClick={() => embla?.scrollPrev()}
        onRightClick={() => embla?.scrollNext()}
      /> */}

      <S.StyledLine />

      <S.StyledCarouselViewport
        className="embla__viewport"
        ref={carouselRef}
        onMouseEnter={() => setMouse(true)}
        onMouseLeave={() => setMouse(false)}
      >
        <S.StyledCarousel className="embla__container">
          {currentSurveyElements.map((element, index) => (
            <ElementSectionRightItem
              title={element.title}
              subtitle={element.subtitle}
              paragraph={element.paragraph}
              key={element.id}
              defaultActive={index === 0}
            />
          ))}
        </S.StyledCarousel>
        {!isMobile && (
          <CustomMouse
            active={mouse}
            tip={intl.formatMessage({
              description: 'Onboarding - Elements - Drag Cursor Tip',
              defaultMessage: 'drag',
            })}
          >
            <ElementsCursor />
          </CustomMouse>
        )}
      </S.StyledCarouselViewport>
    </S.StyledRightSide>
  );
};
