/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-empty-pattern */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable import/no-unresolved */
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { RegisteredFormItem } from 'globalTypes';
import { CohortUsersForm } from '../CohortUsersForm/CohortUsersForm';
import { CohortUsersTable } from '../CohortUsersTable/CohortUsersTable';
import { StyledCardContainerToggle } from '../../ClientForm.styles';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Separator } from 'components/atoms/Separator/Separator';
import { Customer } from 'model/Customer';
import { Participant } from 'model/Participant';
import { ParticipantService } from 'services/ParticipantService';
import { useAppDispatch } from 'redux/store';
import { setErrorEventAction, setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';

interface CreateParticipantTableProps extends RegisteredFormItem {
  customer: Customer;
  participants: Array<Participant>;
  onParticipantDeleted: () => void;
  setParticipants: Dispatch<SetStateAction<Array<Participant>>>;
  isEditMode?: boolean;
}

export const CreateParticipantTable = ({
  customer,
  participants,
  onParticipantDeleted,
  setParticipants,
  isEditMode,
  register,
  formMethods,
  errors,
}: CreateParticipantTableProps): ReactElement => {
  const dispatch = useAppDispatch();

  const deleteParticipant = async (id: number) => {
    ParticipantService.deleteParticipant(id)
      .then(() => {
        dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
        onParticipantDeleted();
      })
      .catch(() => {
        dispatch(setErrorEventAction(Events.FORM_INVALID_ERROR));
      });
  };

  const editParticipant = (newParticipant: Participant, newParticipantIndex: number) => {
    const newParticipants = participants.map((participant, participantIndex) =>
      participantIndex === newParticipantIndex ? newParticipant : participant,
    );

    setParticipants(newParticipants);
  };
  return (
    <>
      <Heading type={HeadingType.H6} margin="5rem 0 4rem">
        Create Participants
      </Heading>
      <CohortUsersForm
        register={register}
        formMethods={formMethods}
        errors={errors}
        customerId={customer.id}
        participants={participants}
        setParticipants={setParticipants}
      />
      <StyledCardContainerToggle $isActive={participants.length > 0}>
        <CohortUsersTable
          participants={participants}
          deleteUser={deleteParticipant}
          editUser={editParticipant}
          setParticipants={setParticipants}
          allowEdit={!isEditMode}
        />
        <Separator color="greyE" margin="2rem 0 3rem" />
      </StyledCardContainerToggle>
    </>
  );
};
