import type { TableOptions } from 'components/atoms/Table/Table.types';

const head = ['No.', 'Name', 'Description', 'Creation Date', 'Open Question', ' '];
const elementQuestionsHead = [
  'Name',
  'Description',
  'Creation Date',
  'Open Question',
  'Add/Remove',
];

export const tableData: TableOptions = {
  head,
  controls: [{ type: 'RemoveOrAdd', action: 'RemoveOrAdd' }],
  columnOptions: {
    0: { width: '6%' },
    1: { width: '16%' },
    2: { width: '26%' },
    3: { width: '24%' },
    4: { width: '10%' },
    5: { width: '4rem' },
  },
  rows: [],
};

export const questionsTableData: TableOptions = {
  head: elementQuestionsHead,
  controls: [{ type: 'RemoveOrAdd', action: 'RemoveOrAdd' }],
  columnOptions: {
    1: { width: '16%' },
    2: { width: '26%' },
    3: { width: '24%' },
    4: { width: '10%' },
    5: { width: '4rem', align: 'center' },
  },
  rows: [],
};

export const createQuestiontableData: TableOptions = {
  head,
  controls: [{ type: 'Delete', action: 'Delete' }],
  columnOptions: {
    0: { width: '6%' },
    1: { width: '14%' },
    2: { width: '30%' },
    3: { width: '21%' },
    4: { width: '21%' },
    5: { width: '4rem' },
    6: { width: '4rem' },
  },
  rows: [],
};
