import styled from 'styled-components';
import { FormItemContainer } from 'components/atoms/FormItemContainer/FormItemContainer';

export const StyledFormItemContainer = styled(FormItemContainer)`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.05em;
  font-weight: 500;
  color: ${({ theme }) => theme.newColor.textDark};

  input {
    margin-left: 16px;
  }
`;
