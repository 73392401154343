import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledPillTabsContainer = styled.div`
  position: relative;
  right: 0;
  margin-top: 5px;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    position: absolute;
    right: 110px;
  }
`;

export const AddedQuestionsWrapper = styled.div`
  margin-inline: -5%;
  border-top: 1px solid rgba(217, 217, 217, 0.5);
`;
