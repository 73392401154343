/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
// eslint-disable-next-line import/no-unresolved
import { InputDefaultProps, RegisterFormItemChild } from 'globalTypes';
import {
  useEffect,
  ForwardedRef,
  forwardRef,
  ReactElement,
  useState,
  DOMAttributes,
  useMemo,
  ChangeEvent,
} from 'react';
import * as S from './TimeLength.styles';
import { PillRadio } from 'components/atoms/PillRadio/PillRadio.styles';
import { Text } from 'components/atoms/Text/Text';

interface TimeLengthProps
  extends InputDefaultProps,
    RegisterFormItemChild,
    DOMAttributes<HTMLInputElement> {
  value?: {
    value?: number | string;
    unit?: number;
  };
  defaultRadio?: number;
  max?: number;
  min?: number;
  labelTitle?: string;
  labelFrequency?: string;
}

export const TimeLength = forwardRef(
  (
    {
      min,
      max,
      name,
      formMethods,
      value,
      defaultRadio,
      labelTitle,
      labelFrequency,
    }: TimeLengthProps,
    ref,
  ): ReactElement => {
    const [activeradio, setActiveRadio] = useState<string | null>();
    const [typeId, setTypeId] = useState<string>();

    const radios = useMemo(() => {
      return ['Daily', 'Weekly', 'Monthly', 'Yearly'];
    }, [setActiveRadio]);

    useEffect(() => {
      if (formMethods && value) {
        if (value.value) {
          setTypeId(value.value as string);
        }
        if (value.unit) {
          setActiveRadio(radios[value.unit - 1]);
        }
      }
    }, [value]);

    useEffect(() => {
      if (defaultRadio) {
        setActiveRadio(radios[defaultRadio]);
      }
    }, [defaultRadio]);

    useEffect(() => {
      // TODO refactor the conditonal to allow for 0 values
      if (activeradio && typeId) {
        const radioTypeId = radios.indexOf(activeradio) + 1;
        formMethods.setValue(`${name}.type`, radioTypeId, { shouldValidate: true });
        formMethods.setValue(`${name}.value`, typeId, { shouldValidate: true });
        formMethods.clearErrors(name);
      }
    }, [activeradio, typeId]);

    const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setTypeId(value);
    };

    const handleRadioChange = (radio: typeof radios[number]) => {
      setActiveRadio(radio);
    };

    return (
      <S.StyledTimeLength container gap="1rem" alignItems="center">
        <S.InputContainer flexStart={!!labelTitle}>
          {labelTitle && <Text>{labelTitle}</Text>}
          <S.StyledInputTimeLength
            ref={ref as ForwardedRef<HTMLInputElement>}
            name={`${name}.value`}
            type="number"
            placeholder=""
            onChange={handleValueChange}
            defaultValue={(value && value.value) || ''}
            min={min}
            max={max}
          />
        </S.InputContainer>

        <S.InputContainer>
          {labelFrequency && <Text>{labelFrequency}</Text>}
          <S.PillContainer>
            {radios.map((radio) => (
              <PillRadio
                key={`${name}-${radio}`}
                id={`${name}-${radio}`}
                label={radio}
                defaultChecked={activeradio === radio}
                onClick={() => handleRadioChange(radio)}
                name={`${name}.unit`}
              />
            ))}
          </S.PillContainer>
        </S.InputContainer>
      </S.StyledTimeLength>
    );
  },
);
