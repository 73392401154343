// eslint-disable-next-line no-shadow
export enum TransitionDuration {
  Fast = '100ms',
  Normal = '200ms',
  Slow = '300ms',
}

// eslint-disable-next-line no-shadow
export enum FontWeight {
  Light = 100,
  Normal = 400,
  Medium = 500,
  SemiBold = 600,
  Bold = 700,
  Black = 900,
}

// eslint-disable-next-line no-shadow
export enum FontFamily {
  Lato = 'Lato, sans-serif',
  Oswald = 'Oswald, sans-serif',
}
