import type { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import * as S from './TopCard.styles';
import { slideFade } from 'util/motionTransitions';
import { CardContainer } from 'components/atoms/CardContainer/CardContainer';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { ArrowExternal } from 'components/atoms/Icon/Icon';
import { Separator } from 'components/atoms/Separator/Separator';
import { Select, SelectItem } from 'components/molecules/Select/Select';
import { Path } from 'routes/Path';
import { RootState, useAppDispatch } from 'redux/store';
import { customerDashboardActions } from 'redux/ducks/customerDashboardDuck/customerDashboardReducer';

type TopCardProps = {
  clientId: string;
};

export const TopCard = ({ clientId }: TopCardProps): ReactElement => {
  const { push } = useHistory();
  const dispatch = useAppDispatch();

  const { customerUnitsSelectItem, customerSubunitsSelectItem, customerData } = useSelector(
    (state: RootState) => {
      return state.customerDashboard;
    },
  );

  const handleEditClient = () => {
    push(Path.EditClient, {
      id: clientId,
    });
  };

  const handleUnitSelect = ({ value }: SelectItem) => {
    dispatch(customerDashboardActions.setUnitsFilter(value));
    dispatch(customerDashboardActions.setCurrentSubunit(customerSubunitsSelectItem[0]));
  };

  return (
    <CardContainer {...slideFade()}>
      <Flex container justifyContent="space-between" flexWrap="wrap" gap="1rem">
        <S.StyledGrid>
          <S.StyledClientLogo>
            <img src={customerData?.logo} alt={customerData?.name} />
          </S.StyledClientLogo>
          <Heading type={HeadingType.H6} color="black">
            {customerData?.name}
          </Heading>
          <S.StyledEditButton color="blueA" onClick={handleEditClient}>
            <span>
              <FormattedMessage
                description="Admin - Client Overview - Edit Client Cta"
                defaultMessage="Edit client"
              />
            </span>
            <ArrowExternal />
          </S.StyledEditButton>
        </S.StyledGrid>

        {customerUnitsSelectItem.length > 0 && (
          <Select
            width="30rem"
            variant="input"
            items={customerUnitsSelectItem}
            defaultValue={customerUnitsSelectItem[0].title}
            onSelect={handleUnitSelect}
            placeholder="Select an option"
          />
        )}
        {/* //* Removed for MVP
        <Flex container gap="1.5rem">
          <Text type="labelC" margin="1rem 0 0">
            and
          </Text>

          <Select
            width="30rem"
            variant="input"
            items={customerSubunitsSelectItem}
            onSelect={handleSubunitSelect}
            defaultValue={customerSubunitsSelectItem[0].title}
            placeholder="Select an option"
          />
        </Flex> */}
      </Flex>
      <Separator margin="3rem 0 2rem" color="greyE" />
      {/* //* Removed for MVP
      <Flex container justifyContent="space-between" alignItems="center">
        <DataItem title="Starting date:" content="10/22/2015" variant="box" />
        <Flex container gap="3.6rem">
          <SecondaryButton color="blueA">
            <span>download Overview PDF</span>
            <Download />
          </SecondaryButton>
          <SecondaryButton color="blueA">
            <span>download Overview csv</span>
            <Download />
          </SecondaryButton>
        </Flex>
      </Flex> */}
    </CardContainer>
  );
};
