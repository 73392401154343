import styled from 'styled-components';
import { motion } from 'framer-motion';
import { typeStyle } from 'styles/typeStyle';
import { FontWeight } from 'styles/variables';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';

export const StyledCardQuestionsContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex: 1;
  gap: 3.2rem;
`;

export const StyledQuestionEyebrow = styled.div`
  ${typeStyle.label};
  margin-bottom: 0.8rem;
  color: ${({ theme }) => theme.color.blueC};
`;

export const StyledTitle = styled.h5`
  font-weight: ${FontWeight.Medium};
  font-size: 2rem;
  margin-bottom: 1.8rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    font-size: 2.4rem;
  }
`;

export const StyledButtonsContainer = styled.div`
  @media ${respondTo(MediaQuery.MAX_1023)} {
    position: absolute;
    bottom: 3.3rem;
  }
`;

export const StyledCarouselViewport = styled.div``;

export const StyledQuestion = styled.div`
  min-width: 100%;
  padding-left: 1px;
`;

export const StyledSelfAssessment = styled.div`
  overflow: hidden;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-bottom: 3.2rem;
  }
`;

export const PersonalCommentsWrapper = styled(motion.div)`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 4px;
  word-wrap: break-word;

  ::-webkit-scrollbar {
    width: 8px;
    &-track {
      background-color: transparent;
    }
    &-thumb {
      background-color: ${({ theme }) => theme.color.cream};
      border-radius: 100px;
    }
  }
`;

export const StyledPersonalWarning = styled(Paragraph)`
  margin-bottom: 2.5rem;
`;
