import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { typeStyle } from 'styles/typeStyle';
import { Expand } from 'components/atoms/Icon/Icon';

export const StyledCrumbLink = styled(Link)`
  ${typeStyle.uppercase};
  color: ${({ theme }) => theme.color.greyA};
`;

export const StyledChevron = styled(Expand)`
  margin: 0 1rem;
  transform: rotate(-90deg);
  fill: ${({ theme }) => theme.color.greyA};
`;
