import { ThemeType } from './theme/default';
import { FontWeight } from './variables';

export const styledInput = (theme: ThemeType, isOpen?: boolean, hasError?: boolean): string => `
  border: 1px solid ${hasError ? theme.color.redA : theme.color.greyC};
  width: 100%;
  border-radius: ${isOpen ? '2px 2px 0 0' : '2px'};
  padding: 1.4rem 1.1rem;
  font-weight: ${FontWeight.Normal};
`;
