import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export interface HideStyleProps {
  screen: MediaQuery;
}

export const StyledHide = styled.div<HideStyleProps>`
  @media ${({ screen }) => respondTo(screen)} {
    display: none;
  }
`;
