import { UseFormReturn, FieldValues } from 'react-hook-form';
import type { ModalData } from 'components/organisms/FormModal/FormModal';
import { Superadmin } from 'services/types/SuperadminTypes';

export const createSuperadminModal = (adminUsers: Array<Superadmin>): ModalData => ({
  title: 'Edit',
  confirmCopy: 'Submit',
  items: [
    {
      type: 'input',
      registerFormItemProps: {
        name: 'name',
        label: 'Name',
        color: 'greyA',
        registerErrors: 'required',
      },
      itemProps: {
        hasRef: true,
      },
    },
    {
      type: 'input',

      registerFormItemProps: {
        name: 'email',
        label: 'Email',
        color: 'greyA',
        registerErrors: [
          'required',
          { type: 'pattern', key: 'emailPattern' },
          {
            type: 'validate',
            value: (emailValue: string) => {
              if (adminUsers && adminUsers.some((adminUser) => adminUser.email === emailValue)) {
                return 'Email already in list.';
              }

              return true;
            },
          },
        ],
      },
      itemProps: {
        autoComplete: 'off',
        hasRef: true,
      },
    },
    {
      type: 'input',
      registerFormItemProps: {
        name: 'email_confirmation',
        label: 'Confirm email',
        color: 'greyA',

        registerErrors: [
          {
            type: 'validate',
            value: (confirmEmailValue: string, methods: UseFormReturn<FieldValues>) => {
              const emailValue = methods.getValues('email');

              if (emailValue !== confirmEmailValue) {
                return 'The Emails doesn’t match.';
              }

              return true;
            },
          },
        ],
      },
      itemProps: {
        hasRef: true,
      },
    },
  ],
});
