// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const APP_NAME = process.env.REACT_APP_NAME!;

if (APP_NAME == null) {
  throw new Error('process.env.REACT_APP_NAME is empty');
}

export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

export const GTM_ID = process.env.REACT_APP_GTM_ID;

export const THEO_KEY = process.env.REACT_APP_THEO_LICENSE_KEY;
export const THEO_LICENSE = process.env.REACT_APP_THEO_LICENSE;
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const EVENT_START_TIME = new Date(
  process.env.REACT_APP_EVENT_START_TIME || Date.now(),
).getTime();
export const EVENT_END_TIME = new Date(
  process.env.REACT_APP_EVENT_END_TIME || Date.now(),
).getTime();
