import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Image } from 'components/atoms/Image/Image';
import { respondTo } from 'styles/helpers/respondTo';
import { size } from 'styles/helpers/size';
import { MediaQuery } from 'styles/mediaQuery';
import { typeStyle } from 'styles/typeStyle';
import { fluidType } from 'styles/helpers/fluidType';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { Chevron, LargeArrow } from 'components/atoms/Icon/Icon';

export const OnboardingOutro = styled.section`
  height: 100vh;
  position: relative;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    height: 75vh;
    padding: 0 ${({ theme }) => theme.sitePaddings.mobile};
  }
`;

export const Background = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;

export const ContentWrapper = styled.div`
  ${size('100%')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1;
`;

export const Headline = styled.h2`
  ${typeStyle.h2};
  font-size: 6.5rem;
  color: ${({ theme }) => theme.color.white};

  @media ${respondTo(MediaQuery.MIN_1024)} {
    ${fluidType(15, 180)}
    line-height: 1.075;
  }
`;

export const HeadlineSpan = styled(motion.span)`
  position: relative;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    display: block;
  }
`;

export const Underline = styled.span`
  @media ${respondTo(MediaQuery.MIN_1024)} {
    text-decoration: none;
    border-bottom: 0.05em solid currentColor;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 3em;
  display: flex;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    flex-direction: column;
  }
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  color: ${({ theme }) => theme.color.white};
  margin-top: 3rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-top: 0;
    margin-left: 4rem;
  }
`;

export const StyledChevron = styled(Chevron)`
  transform: rotate(180deg);

  && {
    margin-left: 1.6rem;
  }
`;

export const StyledInineArrow = styled.span`
  position: relative;
  display: inline-block;
  width: 1.2em;
  height: 0.6em;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: 1em;
    margin-left: 0.2em;
  }

  &:before {
    content: '';
    position: absolute;
    ${size('1em')};
    background-color: ${({ theme }) => theme.color.redE};
    border-radius: 50%;
    top: 50%;
    transform: translate(20%, -50%);

    @media ${respondTo(MediaQuery.MIN_1024)} {
      transform: translate(25%, -50%);
      ${size('0.8em')};
    }
  }
`;

export const StyledLargeArrow = styled(LargeArrow)`
  width: 0.8em;
  color: ${({ theme }) => theme.color.white};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const QuarterCircles = styled.span`
  --size: 0.7em;
  display: inline-block;
  width: calc(var(--size) * 2);
  height: var(--size);
  position: relative;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    position: absolute;
    right: 0;
    margin-top: 0.2em;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    margin-left: -0.5em;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--size);
    height: var(--size);
    background: ${({ theme }) => theme.color.redE};
    border-radius: 0 var(--size) 0 0;
  }
  &:after {
    left: var(--size);
  }
`;
