import { ForwardedRef, forwardRef, ReactElement } from 'react';
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild } from 'globalTypes';
import { UseFormReturn } from 'react-hook-form';
import { Card } from './Card/Card';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { MediaQuery } from 'styles/mediaQuery';
import { useMemoryState } from 'hooks/useMemoryState';
import { SurveElementChallenge } from 'services/types/SurveyTypes';

interface PackageProps extends RegisterFormItemChild {
  surveyElementChallenges: Array<SurveElementChallenge>;
}

export const Package = forwardRef(
  ({ formMethods, name, surveyElementChallenges }: PackageProps, ref): ReactElement => {
    const [active, setActive] = useMemoryState<string | null>(name as string, null);

    const renderChallenges = () => {
      if (surveyElementChallenges.length <= 0)
        return <p>Current Growth Area has no objective, please select another one.</p>;

      return surveyElementChallenges.map((challenge) => (
        <Flex
          onClick={() => {
            (formMethods as UseFormReturn).setValue(name as string, challenge);
            setActive(JSON.stringify(challenge.id));
          }}
          key={challenge.name}
          flexBasis={[[MediaQuery.MIN_768, 'calc(33.33% - 2.2rem)']]}
        >
          <Card active={active === JSON.stringify(challenge.id)} challenge={challenge} />
        </Flex>
      ));
    };

    return (
      <Flex
        container
        gap={['2rem', [MediaQuery.MIN_768, '2.2rem']]}
        flexWrap={[[MediaQuery.MAX_767, 'wrap']]}
        ref={ref as ForwardedRef<HTMLDivElement>}
      >
        {renderChallenges()}
      </Flex>
    );
  },
);
