import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import EmptyBackground from 'assets/img/dashboard/empty-background.svg';
import EmptyBackgroundMobile from 'assets/img/dashboard/empty-background-mobile.svg';

export const NoResults = styled.div<{ $hasMinHeight?: boolean }>`
  display: flex;
  flex: 4;
  text-align: center;
  background: url(${EmptyBackgroundMobile}) no-repeat center/cover;
  min-height: ${({ $hasMinHeight }) => ($hasMinHeight ? '65rem' : 'auto')};
  color: ${({ theme }) => theme.color.greyA};
  position: relative;

  @media ${respondTo(MediaQuery.MIN_768)} {
    background: url(${EmptyBackground}) no-repeat center/cover;
  }
`;

export const StyledTitleNoResult = styled.p`
  margin: auto;
  font-size: 4rem;
`;

export const StyledSubtitleNoResult = styled.span`
  display: block;
  font-size: 1.6rem;
  margin-top: 1.6rem;
`;
