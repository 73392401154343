/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Dispatch } from 'redux';
import * as types from './temporaryQuestionsTypes';
import { Action } from 'redux/types';
import { QuestionProps } from 'redux/ducks/questionsDuck/questionsTypes';

export type QuestionsArrayType = {
  question_id: number;
  name: string;
  description: string;
  allow_no_answer: boolean;
  set_as_open: boolean;
  element_type_id: number;
  order: number;
  created_at: string;
};

// actions
export const getTemporaryQuestions =
  (questions: Array<QuestionProps>) => async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: types.GET_TEMP_QUESTIONS_SUCCESS,
        payload: questions,
      });
      return null;
    } catch (error) {
      return null;
    }
  };

export const getTemporaryAddQuestions =
  (questions: Array<QuestionProps>) => async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: types.GET_TEMP_NEW_QUESTIONS_SUCCESS,
        payload: questions,
      });
      return null;
    } catch (error) {
      return null;
    }
  };
