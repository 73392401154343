import styled, { css } from 'styled-components';
import { Image } from 'components/atoms/Image/Image';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledOnboardingElementsSection = styled.section`
  display: flex;
  user-select: none;
  overflow: hidden;
  touch-action: none;
  align-items: center;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
    margin-top: 12rem;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    max-height: 100vh;
  }
`;

export const StyledLeftSide = styled.div`
  position: relative;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    display: flex;
    width: 100vw;
    position: relative;
  }

  @media ${respondTo(MediaQuery.MIN_1024)} {
    width: 38%;
    min-width: 48rem;
    max-width: 80rem;
    margin-right: 8%;
    margin-left: -5%;
  }
`;

const CircleBasicStyle = css`
  width: 13%;
  padding-top: 74%;
  height: 0;
  position: relative;
  overflow: hidden;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding-top: 100%;
    width: 100%;
  }
`;

export const StyledRedCircle = styled.div`
  ${CircleBasicStyle}

  @media ${respondTo(MediaQuery.MIN_1024)} {
    padding-top: 50%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: ${({ theme }) => theme.color.redC};
    border-radius: 50%;
    width: 570%;
    height: 100%;

    @media ${respondTo(MediaQuery.MIN_1024)} {
      width: 100%;
      height: 200%;
    }
  }

  &:first-of-type {
    &:before {
      bottom: 0;
      top: auto;

      @media ${respondTo(MediaQuery.MAX_1023)} {
        right: 0;
        left: auto;
      }
    }
  }
`;

export const StyledImage = styled(Image)`
  position: absolute;
  top: 0;
`;

export const StyledImageCircle = styled.div`
  ${CircleBasicStyle};
  border-radius: 50%;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    width: 74%;
    padding-top: 74%;
  }
`;
