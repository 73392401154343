import { css } from 'styled-components';

export const styledScrollbar = css`
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.color.greyA} ${({ theme }) => theme.color.cream};

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.color.greyD};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.color.greyA};
  }
`;
