import type { MouseEventHandler, ReactElement } from 'react';
import * as S from './PeopleListItem.styles';
import type { InviteVariantType } from '../Invite';
import { Trash } from 'components/atoms/Icon/Icon';
import { IconButton } from 'components/molecules/IconButton/IconButton';
import { slideFade } from 'util/motionTransitions';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PeopleListItemProps {
  person: { email: string; name: string };
  onDelete?: MouseEventHandler<HTMLElement> | undefined;
  variant?: InviteVariantType;
}

export const PeopleListItem = ({
  person,
  onDelete,
  variant,
  ...props
}: PeopleListItemProps): ReactElement => {
  return (
    <S.StyledItemContainer {...slideFade('x', 175, 1, 1)} layout {...props}>
      <S.StyledItemCircle>{person.email.substring(0, 2).toUpperCase()}</S.StyledItemCircle>
      <S.StyledItemInner variant={variant}>
        {`${person.name} - ${person.email}`}
        <IconButton icon={Trash} onClick={onDelete} />
      </S.StyledItemInner>
    </S.StyledItemContainer>
  );
};
