import { ForwardedRef, ReactElement, useState, forwardRef, useEffect, useCallback } from 'react';
// eslint-disable-next-line import/no-unresolved
import { RegisterFormItemChild } from 'globalTypes';
import { SelectItem } from '../Select/Select';
import { PillRadio } from 'components/atoms/PillRadio/PillRadio.styles';
import { Flex, FlexContainerProps } from 'components/atoms/Flex/FlexContainer';
import { Radio } from 'components/atoms/Radio/Radio';

export interface RadioGroupProps extends RegisterFormItemChild, FlexContainerProps {
  items: Array<SelectItem>;
  radioType?: 'pill' | 'normal';
  defaultValue?: number;
}

// eslint-disable-next-line no-empty-pattern
export const RadioGroup = forwardRef(
  (
    { items, name, formMethods, radioType, defaultValue, ...props }: RadioGroupProps,
    ref,
  ): ReactElement => {
    const [activeradio, setActiveRadio] = useState<null | number | string>(null);
    const CurrentRadio = {
      pill: PillRadio,
      normal: Radio,
    }[radioType || 'normal'];

    const handleRadioChange = useCallback(
      (value: number | string) => {
        setActiveRadio(value);
        formMethods.setValue(name, value);
      },
      [formMethods, name],
    );

    useEffect(() => {
      if (defaultValue !== undefined) {
        handleRadioChange(defaultValue);
      }
    }, [defaultValue, handleRadioChange]);

    return (
      <Flex container ref={ref as ForwardedRef<HTMLInputElement>} {...props}>
        {items.map((item) => (
          <CurrentRadio
            key={item.value}
            name={name}
            id={`${name}-${item.value}`}
            label={item.title}
            defaultChecked={activeradio === item.value}
            onClick={() => handleRadioChange(item.value)}
          />
        ))}
      </Flex>
    );
  },
);
