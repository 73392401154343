import { motion } from 'framer-motion';
import styled from 'styled-components';
import { typeStyle } from 'styles/typeStyle';

export const StyledErrorMessage = styled(motion.span)`
  ${typeStyle.h5}
  color: ${({ theme }) => theme.color.redA};
  padding-top: 0.8rem;
  display: block;
`;
