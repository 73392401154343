import { ReactElement } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useMount } from 'react-use';
import { theme } from 'styles/theme/default';

export const Toast = (): ReactElement => {
  useMount(() => {
    // css variables reference: https://fkhadra.github.io/react-toastify/how-to-style
    document.documentElement.style.setProperty('--toastify-text-color-light', theme.color.black);
    document.documentElement.style.setProperty('--toastify-color-error', theme.color.redA);
    document.documentElement.style.setProperty('--toastify-color-success', theme.color.blueA);
  });

  return (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      newestOnTop={false}
      closeOnClick={false}
      limit={2}
      rtl={false}
      draggable={false}
      style={{ width: 'fit-content', maxWidth: '60%' }}
    />
  );
};
