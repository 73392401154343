import { ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FieldValues } from 'react-hook-form';
import { useHistory } from 'react-router';
import moment from 'moment';
import * as S from './CreateObjective.styles';
import { ObjectiveFormPagination } from './sections/ObjectiveFormPagination/ObjectiveFormPagination';
import { MediaQuery } from 'styles/mediaQuery';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Breadcrumbs } from 'components/molecules/Breadcrumbs/Breadcrumbs';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { ScrollDownArrow } from 'components/atoms/ScrollDownArrow/ScrollDownArrow';
import { Hide } from 'components/atoms/Hide/Hide';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';
import { Form } from 'components/molecules/Form/Form';
import { Path } from 'routes/Path';
import { setErrorEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { ObjectiveService } from 'services/ObjectiveService';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateObjectiveProps {}

export const CreateObjective = ({ ...props }: CreateObjectiveProps): ReactElement => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory<{ cohort_survey_id: number; survey_id: number }>();
  const { push } = useHistory();
  // TODO : add dynamic {participantId} and {cohort_survey_id}
  if (!history.location.state) push(`${Path.Home}`);
  const { state } = history.location;

  const formatFormBody = (objective: FieldValues) => ({
    title: objective.title,
    description: objective.description,
    starting_time: moment(objective.startTime).format('YYYY-MM-DD HH:mm:ss'),
    challenge_id: objective.package.id,
    element_id: objective.elements.value,
    cohort_survey_id: state.cohort_survey_id,
    survey_id: state.survey_id,
  });

  const handleSubmit = async (data: FieldValues) => {
    const objectiveBody = formatFormBody(data.objective);
    try {
      await ObjectiveService.createObjective(objectiveBody);
      push(Path.Dashboard);
    } catch (error) {
      dispatch(setErrorEventAction(Events.FORM_REQUEST_ERROR));
    }
  };

  return (
    <NormalLayout padding="form" overflowHidden>
      <BackgroundCircle
        lastBackground
        top={['67.8rem', [MediaQuery.MIN_768, '35.5rem']]}
        left="-58.5rem"
        size="113.8rem"
      />
      <ScrollDownArrow />
      <S.StyledCreateObjective {...props}>
        <Hide screen={MediaQuery.MAX_767}>
          <Breadcrumbs
            crumbs={[
              {
                title: intl.formatMessage({
                  description: 'Create Objective - Breadcrumbs - Dashboard',
                  defaultMessage: 'dashboard',
                }),
                href: '/dashboard',
              },
              {
                title: intl.formatMessage({
                  description: 'Create Objective - Breadcrumbs - Create Objectives',
                  defaultMessage: 'create Objectives',
                }),
              },
            ]}
          />
        </Hide>
        <Heading
          type={HeadingType.H2}
          color="blueA"
          margin={['2rem 0 3rem', [MediaQuery.MIN_768, '2rem 0 4rem']]}
          maxWidth={[[MediaQuery.MAX_767, '25rem']]}
        >
          <FormattedMessage
            description="Create Objective - Title"
            defaultMessage="Create an Objective"
          />
        </Heading>
        <Form onSuccess={handleSubmit}>
          <ObjectiveFormPagination register />
        </Form>
      </S.StyledCreateObjective>
    </NormalLayout>
  );
};
