import { AnimatePresence } from 'framer-motion';
// eslint-disable-next-line import/no-unresolved
import { FormItemContainerBasicProps } from 'globalTypes';
import { ReactElement, ReactNode } from 'react';
import * as S from './FormItemContainer.styles';
import { AlignErrorOptions } from './FormItemContainer.styles';
import { slideFade } from 'util/motionTransitions';

export interface FormItemContainerProps extends FormItemContainerBasicProps {
  children: ReactNode;
  alignError?: AlignErrorOptions;
  marginBottom?: string;
}

export const FormItemContainer = ({
  label,
  description,
  children,
  errorMessage,
  size,
  color,
  alignError,
  marginBottom,
  ...props
}: FormItemContainerProps): ReactElement => {
  return (
    <S.StyledFormItemContainer {...props}>
      {label !== 'hide' ? (
        <S.StyledLabel size={size} color={color} marginBottom={marginBottom}>
          {label}
          {description && <S.StyledLabelDescription>{description}</S.StyledLabelDescription>}
        </S.StyledLabel>
      ) : (
        <S.TransparentLabel />
      )}
      {children}
      <AnimatePresence exitBeforeEnter>
        {errorMessage && (
          <S.StyledErrorMessage
            alignError={alignError}
            color={color}
            key={errorMessage}
            {...slideFade('x', 10, 0.5, -1)}
          >
            {errorMessage}
          </S.StyledErrorMessage>
        )}
      </AnimatePresence>
    </S.StyledFormItemContainer>
  );
};
