import styled from 'styled-components';
import { IconButton } from 'components/molecules/IconButton/IconButton';
import { ColorKey } from 'styles/theme/default';

export interface InputStyleProps {
  hasError?: boolean;
  backgroundColor?: ColorKey;
  color?: ColorKey;
  name?: string;
}

export const StyledInput = styled.input<InputStyleProps>`
  font-size: 1.8rem;
  line-height: 1.5;
  padding: 1.4rem 1.1rem;
  display: block;
  border-radius: 2px;
  border: 1px solid ${({ theme, hasError }) => (hasError ? theme.color.redA : theme.color.greyC)};
  color: ${({ theme, hasError }) => hasError && theme.color.redA};
  width: 100%;
  outline: none;
  transition: border-color 0.2s ease;
  ${({ theme, backgroundColor }) =>
    `background: ${backgroundColor ? theme.color[backgroundColor] : theme.color.white};`}

  ${({ disabled, theme }) =>
    disabled &&
    `
  background-color: ${theme.color.greyG};
  color: ${theme.color.blackB};
  `}



  &::placeholder {
    color: ${({ theme }) => theme.color.greyA};
  }

  &:focus {
    border-color: ${({ theme, hasError }) => (hasError ? theme.color.redA : theme.color.blueA)};
  }

  &::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

  &[type='date'] {
    color: ${({ theme }) => theme.color.greyA};
  }
  &[name='unique-id-short'] {
    max-width: 10rem;
    text-align: center;
    margin-right: 3rem;
  }
  &[name='unique-id-long'] {
    width: 30rem;
    &::before {
      content: '-';
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: #7d7d7d;
      top: 15px;
      left: -50%;
    }
  }
`;

export const StyledIcon = styled.div<{ hasError?: boolean; isSelect?: boolean }>`
  position: absolute;
  top: 50%;
  right: ${({ isSelect }) => (isSelect ? '4rem' : '1rem')};
  color: ${({ theme, hasError }) => (hasError ? theme.color.redA : 'currentColor')};
  transform: translateY(-50%);
  pointer-events: none;
  opacity: ${({ hasError }) => (hasError ? '1' : '0.5')};
  transition: color 0.2s ease, opacity 0.2s ease;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  flex: 1;

  &:focus-within {
    ${StyledIcon} {
      opacity: 1;
      color: currentColor;
    }
  }
`;

export const StyledFileInput = styled.input.attrs({ type: 'file' })`
  opacity: 0;
  position: absolute;
  top: -15px;
  height: 30px;
  cursor: pointer;
`;

export const StyledPasswordButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  border-radius: 100%;
  padding: 2px;
  box-sizing: content-box;
  opacity: 0.5;
  transition: all 0.2s ease;
  &:hover {
    opacity: 1;
    background-color: ${({ theme }) => theme.color.greyD};
  }
`;
