/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/order */
import { ReduxReducer } from 'redux/types';
import * as types from './temporaryQuestionsTypes';

const initialState = {
  temporaryQuestions: [],
  temporaryNewQuestions: [],
};

export const temporaryQuestionsReducer: ReduxReducer<typeof initialState> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case types.GET_TEMP_QUESTIONS_SUCCESS:
      return { ...state, temporaryQuestions: action.payload };

    case types.GET_TEMP_NEW_QUESTIONS_SUCCESS:
      return { ...state, temporaryNewQuestions: action.payload };
    default:
      return state;
  }
};
