import { ReactElement, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as S from './AdminFeedback.styles';
import { Heading } from 'components/atoms/Heading/Heading';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';
import { Separator } from 'components/atoms/Separator/Separator';
import { CardTabs } from 'components/atoms/CardTabs/CardTabs';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { TabsContainer } from 'components/molecules/TabsContainer/TabsContainer';
import { slideFade } from 'util/motionTransitions';
import {
  DropdownItemProps,
  SearchSelect,
  SearchSelectItemsType,
} from 'components/organisms/SearchSelect/SearchSelect';
import {
  selectCurrentParticipant,
  dashboardActions,
  selectCurrentCohort,
} from 'redux/ducks/dashboardDuck/dashboardReducer';
import { RootState } from 'redux/store';
import { ActiveFeedbackContent } from 'components/organisms/ActiveFeedbackContent/ActiveFeedbackContent';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AdminFeedbackProps {
  additionalCopy?: string;
}

export const AdminFeedback = ({ additionalCopy }: AdminFeedbackProps): ReactElement | null => {
  const currentParticipant = useSelector((state: RootState) =>
    selectCurrentParticipant(state.dashboard),
  );

  const cohort = useSelector((state: RootState) => selectCurrentCohort(state.dashboard));
  const [activeElement, setActiveElement] = useState(0);
  const dispatch = useDispatch();

  const selfAssessmentIsCompleted =
    currentParticipant?.assessment &&
    currentParticipant.assessment.elements.every((element) =>
      element.questions.every((question) => !!question.self_answer || question.self_answer === 0),
    );

  const adaptedParticipants: SearchSelectItemsType = useMemo(() => {
    if (!cohort) return [];

    return cohort.cohort_survey.participants.map((participant) => ({
      id: participant.id,
      type: 'placeholder',
      copy: participant.name,
    }));
  }, [cohort]);

  const tabs = useMemo(
    () => currentParticipant?.assessment?.elements?.map(({ name }) => name || '') || [],
    [currentParticipant],
  );

  const handleParticipantChange = (item: DropdownItemProps) => {
    if (!item.id) return;

    dispatch(dashboardActions.setParticipant(item.id));
  };

  useEffect(() => {
    setActiveElement(0);
  }, [currentParticipant, cohort]);

  return (
    <S.StyledCardContainer minHeight="100%" {...slideFade()} variant="blue">
      <Flex container gap="3.2rem" flexWrap="wrap">
        <SearchSelect
          onChange={handleParticipantChange}
          placeholder="User..."
          items={adaptedParticipants}
          defaultValue={adaptedParticipants[0]}
          readOnly
        />
        <Heading type={HeadingType.H6}>
          <FormattedMessage
            defaultMessage="Feedback Results"
            description="Dashboard - Feedback Card - Title"
          />
        </Heading>
      </Flex>
      {!selfAssessmentIsCompleted ? (
        <S.StyledUncompletedDashboardHeading type={HeadingType.H5} margin="auto 8rem">
          The participant hasn’t completed the assessment{' '}
          {additionalCopy && (
            <>
              <br /> {additionalCopy}{' '}
            </>
          )}
        </S.StyledUncompletedDashboardHeading>
      ) : (
        <>
          <Paragraph color="blueE" margin="1rem 0 3.2rem">
            <FormattedMessage
              defaultMessage="Select a participant from the list to learn more"
              description="Dashboard - Admin - Feedback Card - Subtitle"
            />
          </Paragraph>
          <TabsContainer activeTab={activeElement} setActiveTab={setActiveElement}>
            <>
              <CardTabs gap="3.9rem" tabs={tabs} />
              <Separator color="greyE" margin="3.2rem 0 1.7rem" />
            </>

            {(activetab: number) => (
              <ActiveFeedbackContent
                questions={
                  currentParticipant?.assessment?.elements
                    ? currentParticipant?.assessment.elements[activetab]?.questions || []
                    : []
                }
                selfNumericTitle="How the participant scored itself - average"
                assessorsNumericTitle="How assessors scored the participant - average"
                activeElement={currentParticipant?.assessment?.elements[activetab]}
              />
            )}
          </TabsContainer>
        </>
      )}
    </S.StyledCardContainer>
  );
};
