import styled from 'styled-components';
import { Form } from 'components/molecules/Form/Form';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';

export const StyledForm = styled(Form)`
  margin-top: 2rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

export const StyledButton = styled(PrimaryButton)`
  width: fit-content;
`;
