import styled from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledAnswersContainer = styled.div`
  gap: 3rem;
  display: flex;
  overflow: hidden;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 2rem;

  @media ${respondTo(MediaQuery.MIN_1024)} {
    flex-direction: row;
    gap: 6rem;
  }
`;

export const StyledElementAnswersContainer = styled.div`
  margin: 2rem 0;
  padding-bottom: 4rem;
  border-bottom: 1px solid ${({ theme }) => theme.color.greyE};
`;
