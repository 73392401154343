import styled from 'styled-components';
import { Flex } from 'components/atoms/Flex/FlexContainer';

export const StyledOrganizationUnit = styled.div`
  margin-top: 4rem;
`;

export const StyledSubunitsWrapper = styled(Flex)`
  margin-top: 4rem;
`;
