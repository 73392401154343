/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactElement, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useHistory } from 'react-router';
// eslint-disable-next-line import/no-unresolved
import { LocationState } from 'globalTypes';
import { ClientForm } from '../../components/ClientForm/ClientForm';
import { CustomerService } from 'services/CustomerService';
import { setSuccessEventAction, setErrorEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { useAppDispatch } from 'redux/store';
import { Customer } from 'model/Customer';
import { Participant } from 'model/Participant';

export const EditClient = (): ReactElement => {
  const [currentCustomer, setCurrentCustomer] = useState<Customer>(() => new Customer());
  const [participants, setParticipants] = useState<Array<Participant>>([]);
  const dispatch = useAppDispatch();
  const history = useHistory<LocationState>();

  const handleCreateClient = async (formData: FieldValues) => {
    try {
      const customer: Customer = Object.assign(new Customer(), formData.customer);
      const customerId = history.location.state && history.location.state.id;

      if (currentCustomer.logo === formData.customer.logo) {
        customer.logo = undefined;
      }

      await CustomerService.editCustomer(customer, customerId);

      dispatch(setSuccessEventAction(Events.FORM_SUCCESS));
    } catch (error) {
      dispatch(setErrorEventAction(Events.FORM_INVALID_ERROR));
    }
  };

  return (
    <ClientForm
      handleCreateClient={handleCreateClient}
      currentCustomer={currentCustomer}
      setCurrentCustomer={setCurrentCustomer}
      participants={participants}
      setParticipants={setParticipants}
      isEditMode
    />
  );
};
