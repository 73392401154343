/* eslint-disable max-lines */
import { ReactElement } from 'react';
import * as S from './TermsAndConditions.styles';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';

export const TermsAndConditions = (): ReactElement => {
  return (
    <NormalLayout padding="normal">
      <S.StyledTitle>General Terms and Conditions</S.StyledTitle>
      <S.StyledParagraph>Last updated: 6/9/2022</S.StyledParagraph>
      <S.StyledParagraph>
        These general terms and conditions govern the relationship between THNK and the User (as
        defined below) with respect to the services, features, content and use of 360 Assessment
        Tool Mirror offered by THNK School of Creative Leadership (hereinafter THNK).
      </S.StyledParagraph>
      <S.StyledOrderedList>
        <S.StyledTitleItem>Definitions</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            General Terms and Conditions: these general terms and conditions;
          </S.StyledParagraphItem>
          <S.StyledParagraphItem>
            360 Assessment Tool Mirror: an online tool for leaders to assess their own leadership
            skills and ask for others to give them feedback on the same skills (hereinafter 360
            Mirror)
          </S.StyledParagraphItem>
          <S.StyledParagraphItem>
            Agreement: these general terms and conditions as well as any other agreements, for
            example if the User is also a Participant in any THNK programs
          </S.StyledParagraphItem>
          <S.StyledParagraphItem>
            User: a natural person registering to use 360 Mirror
          </S.StyledParagraphItem>
          <S.StyledParagraphItem>
            Participant: a natural person registering for a program through the Website, through a
            Registration Form directly received by THNK employee or representative or enrolled in
            THNK program through their organization; 360 Mirror User can be a Participant in THNK
            program but that’s not required
          </S.StyledParagraphItem>
          <S.StyledParagraphItem>
            Program: the sessions making up the Program, as well as the modules and trainings
            offered and organized by THNK;
          </S.StyledParagraphItem>
          <S.StyledParagraphItem>
            THNK: THNK Innovation B.V., having its registered office at Plantage Middenlaan 45 in
            (1018DC) Amsterdam, The Netherlands and registered in the trade register under number:
            62499394;
          </S.StyledParagraphItem>
          <S.StyledParagraphItem>
            Fee: the tool fees as stated on the THNK Website or the tool website, unless the use of
            the tool is part of a program fee
          </S.StyledParagraphItem>
          <S.StyledParagraphItem>
            Intellectual Property Rights: means all, copyright, trademark rights, copyright in the
            assessment methodology, (intellectual property) rights resulting from or arising out of
            360 Mirror and/or other (intellectual property) rights, also including rights pertaining
            to data bases, information provided by THNK, content of the Website, 360 Mirror or other
            products of commercial knowhow owned by or licensed by any third party to THNK or any of
            its group companies and relating to or embodied in the products, programs or any other
            materials provided by THNK.
          </S.StyledParagraphItem>
          <S.StyledParagraphItem>
            Services: All services provided by THNK for the User, more specifically all services
            regarding – amongst other things –the assessment.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK Website: the website with domain name{' '}
            <S.StyledLink href="https://www.thnk.org" target="_blank" rel="noopener">
              http://www.thnk.org
            </S.StyledLink>
          </S.StyledParagraphItem>

          {/* TODO: Add mirror website url */}
          {/* <S.StyledParagraphItem>Mirror website:</S.StyledParagraphItem> */}
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Applicability</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            These General Terms and Conditions govern the legal relationship between THNK and the
            User. These General Terms and Conditions apply to the relationship between THNK and
            User, also if the Agreement is no longer in force.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            Upon Registration the User indicates to have read, understood and agreed to be bound by
            these General Terms and Conditions and all other rights and obligations, for example but
            not excluding the privacy policy and cookies, as stated on 360 Mirror Website. THNK will
            make these General Terms and Conditions available to the User in such a way that allows
            them to be stored and inspected in the future.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK reserves the right to amend, add or remove any provision of these General Terms and
            Conditions at all times. By using the 360 Mirror website the User agrees to be bound by
            the then current version of these General Terms and Conditions.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            The applicability of any general terms and conditions of the User is expressly rejected.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            Deviation and/or additions to any provision of these terms and conditions shall solely
            be applicable when explicitly agreed in writing and shall have no general effect. All
            other remaining provisions shall remain unimpaired, in full force and effect.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            All rights and claims stipulated by THNK in these General Terms and Conditions and in
            any further agreements are also stipulated for the benefit of its employees and any
            third parties whose services are engaged by THNK.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            If any one or more of the provisions contained in the General Terms and Conditions shall
            for any reason be held to be invalid, illegal or unenforceable in any respect, such
            invalidity, illegality or unenforceability shall not affect any other provision of these
            General Terms and Conditions, and these Terms and Conditions shall be construed as if
            such invalid, illegal or unenforceable provision had never been contained in it.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>
          Formation of an Agreement with regard to the Registration for 360 Mirror
        </S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            An Agreement comes into effect in the moment the User creates a profile on 360 Mirror
            website.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            The User warrants that the information he or she has provided upon registration is
            complete, accurate, correct and up-to-date. The Participant is fully responsible for the
            consequences of any misstated facts.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            In the event of a breach of contract on the part of the User THNK is entitled to
            terminate the Agreement with the User. In such cases the User may also be denied access
            to the tool.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            By accepting the Registration, THNK takes on a best efforts obligation to provide the
            User with its services as described in the 360 Mirror information.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            For quality improvement purposes and reprogramming purposes (mandatory and otherwise)
            THNK reserves the right to make interim changes to the tool.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            Obvious mistakes or errors on the 360 Mirror website do not bind THNK. In the event of a
            mistake and/or error THNK shall inform the User as soon as possible.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Cancellation</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            Cancellation of use of 360 Mirror takes effect as per requested end date and the right
            of access and use of 360 Mirror will end.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>The fee for 360 Mirror, if non-refundable.</S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Payment</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            The current fee for using 360 Mirror will be specified on the 360 Mirror Website. The
            most recent fees always apply and are always available on the 360 Mirror Website. Fees
            may change from time to time, but changes will not affect any already formalized
            Registration.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            Access to the 360 Mirror tool is provided only to Users who have paid the fee in full,
            if applicable.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            Payment is due immediately in order to complete the Registration.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Intellectual property rights</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            All Intellectual Property rights remain the property of THNK.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            No part of any information provided by THNK via their Website or the 360 Mirror website
            may be reproduced, stored in an automated database or disclosed to a third party in any
            form or in any way whatsoever, be it electronically, mechanically, by means of
            photocopies, recordings or in any other manner, without the prior written permission of
            THNK.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            User shall use such Intellectual Property only within the scope and for the purpose of
            the assessment and shall not make any reproductions, changes, additions, improvements,
            alterations, analysis, reverse engineering or modifications or disclose such
            Intellectual Property to any third parties.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Confidentiality</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            Confidential Information (as defined in article 8.2) may not be shared with third
            parties with the exception of the receiving party’s personnel, including employees,
            agents, and subcontractors, on a “need-to-know” basis in connection with the Agreement,
            so long as such personnel have agreed in writing to treat such Confidential Information
            with the same level of confidentiality as provided for in these General Terms and
            Conditions.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            Confidential Information shall mean: Invention description(s), technical and business
            information relating to proprietary ideas and inventions, ideas, patentable ideas, trade
            secrets, drawings and/or illustrations, patent searches, existing and/or contemplated
            products and services, research and development, production, costs, profit and margin
            information, finances and financial projections, customers, clients, marketing, and
            current or future business plans and models, regardless of whether such information is
            designated as “Confidential Information” at the time of its disclosure.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Liability</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            THNK shall have a best efforts obligation in the performance of the Agreement.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK shall not be in default until after the User has sent written notice of default to
            THNK by registered letter.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK shall not be liable for any type of losses or damages, such as personal injury,
            immaterial damage, consequential damage, lost profits, loss of income, business
            interruption or loss of data, unless such losses and/or damages are the result of fault
            or negligence on the part of THNK.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            Notwithstanding any provisions in this article 9, liability on the part of THNK shall
            always be limited to the amount paid out by its professional liability insurance in the
            relevant instance. To the extent that THNK’s liability insurance does not provide cover,
            it may be held liable only for the direct, purely financial loss suffered by the User
            and/or any third parties, maximized to the fee amount. The only exception to this
            maximum liability is in case of intent (opzet) and/or gross negligence (bewuste
            roekeloosheid) of THNK.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK shall not be liable for any damage caused by third parties whose services are
            engaged by THNK.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK shall not be liable for any damage caused by the use of the Website and 360 Mirror
            website and the information and products available through websites of third parties
            referred to by links on the THNK Website and 360 Mirror website except and if there is
            intent or gross negligence on the part of THNK.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            Any claims against THNK must be addressed to THNK by the User in writing within one year
            after the cause of the damage has occurred or the User has learned of the damage.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            The User indemnifies THNK and holds THNK harmless from and against any claims from third
            parties against THNK on whatever grounds, resulting from any acts and/or omissions on
            the part of the User.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Website</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            THNK has taken all measures reasonably possible to ensure that the data transmitted and
            obtained through the 360 Mirror Website are protected against unauthorized use.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK complies strictly with GDPR guidelines related to processing of personal data.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Privacy</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            The User is aware that by using the 360 Mirror Website he/she provides certain data to
            THNK, for example name, email, gender, Country of residence, Nationality, Date of birth,
            Organization name (and sometimes Organizational unit, Organizational subunit),
            Organization size, Industry, Job function, Type of organization, individual evaluation
            data.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK shall treat all data from the User in conformity with the privacy legislation.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK will record the data of the User for the acceptance and performance of the
            Agreement as well as for customer relationship management purposes. THNK may also use
            these data for the purpose of informing the User about offers and other Services
            provided by THNK. If the User does not wish to receive such information, he or she may
            inform THNK to that effect by means of the Website.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            The User has the right to inspect the data and to correct these.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            THNK has a separate Privacy policy, which provides more details and is integral part of
            the agreement. By agreeing to these General Terms and Conditions, the User agrees to the
            privacy policy applied by THNK, as shown on the 360 Mirror Website.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Force Majeur</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            The term force majeure means any circumstance on the basis of which any (further)
            fulfilment of the Agreement by THNK cannot reasonably be required. This includes in any
            event - but not exclusively - data loss as a result of computer breakdown, virus
            infection or hacking by third parties, strike or lock-out; illness of personnel; non- or
            untimely performance by THNK’s suppliers; nature-/nuclear disasters; war, danger of war;
            negligence of THNK except for willfulness or gross negligence, and other circumstances
            which are beyond THNK’s reasonable control.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            In the event that THNK is prevented by force majeure from fully or partially performing
            their Services, THNK will be entitled to suspend the performance of the Services or to
            consider the Agreement as wholly or partially dissolved without any judicial
            intervention being required, all this at its discretion, without THNK being obliged to
            compensate any loss suffered by the Participant.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>General provisions</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            If THNK at any given moment does not demand performance of one or more provisions in the
            Agreement, this does not mean that, by doing so, it has waived their applicability, nor
            does it indicate, by so doing, that the User is entitled not to perform one or more
            provisions in this Agreement.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            The voidness, voidability or other non-enforceability of any of the provisions of the
            Agreement shall not affect the legal effect of the remaining provisions of the
            Agreement. If a provision of the Agreement should prove to be void, voidable or
            otherwise non-enforceable, THNK will within a reasonable term provide for a new
            provision which legal effect will approach THNK’s original intention as closely as
            possible.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            E-mail messages transmitted by THNK are regarded as having been received within 24 hours
            from having been sent to the e-mail address stated by the User.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Governing law, competent court</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            The Agreement including these General Terms and Conditions and the legal relationship
            between the User and THNK are governed by Dutch law.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>
            Any disputes which cannot be resolved amicable shall be submitted to the district court
            of Amsterdam.
          </S.StyledParagraphItem>
        </S.StyledUnorderedList>

        <S.StyledTitleItem>Liability</S.StyledTitleItem>

        <S.StyledUnorderedList>
          <S.StyledParagraphItem>
            Cancellation of use of 360 Mirror takes effect as per requested end date and the right
            of access and use of 360 Mirror will end.
          </S.StyledParagraphItem>

          <S.StyledParagraphItem>The fee for 360 Mirror, if non-refundable.</S.StyledParagraphItem>
        </S.StyledUnorderedList>
      </S.StyledOrderedList>
    </NormalLayout>
  );
};
