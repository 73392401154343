import { AssessmentType } from './types/AssessmentTypes';
import { ApiClient } from '../util/apiClient';
import { Assessor } from 'services/types/DashboardTypes';
import { AssessmentPostProps } from 'redux/ducks/assessmentsDuck/assessmentsTypes';

enum AssessmentEndpoints {
  Assessments = '/assessments',
  Assessors = '/assessors',
  DownloadCsv = 'download-csv',
  DownloadPdf = 'download-pdf',
}

const createAssessment = async (body: AssessmentPostProps): Promise<Array<AssessmentType>> => {
  const { data } = await ApiClient.post(AssessmentEndpoints.Assessments, body);
  return data;
};

const inviteAssessor = async (body: {
  email: string;
  name: string;
  assessment_id: number;
}): Promise<Assessor> => {
  const { data } = await ApiClient.post(AssessmentEndpoints.Assessors, body);
  return data;
};

const removeAssessor = async (assessorId: string): Promise<void> => {
  await ApiClient.delete(`${AssessmentEndpoints.Assessors}/${assessorId}`);
};

const getCsvDownloadUrl = async (id: number): Promise<string> => {
  const { data } = await ApiClient.get(
    `${AssessmentEndpoints.Assessments}/${id}/${AssessmentEndpoints.DownloadCsv}`,
  );

  return data.download;
};

const getPdfDownloadUrl = async (id: number): Promise<string> => {
  const { data } = await ApiClient.get(
    `${AssessmentEndpoints.Assessments}/${id}/${AssessmentEndpoints.DownloadPdf}`,
  );

  return data.download;
};

export const AssessmentService = {
  createAssessment,
  inviteAssessor,
  removeAssessor,
  getCsvDownloadUrl,
  getPdfDownloadUrl,
};
