import styled from 'styled-components';
import { Slider } from 'components/atoms/Slider/Slider';
import { Radio } from 'components/atoms/Radio/Radio';
import { typeStyle } from 'styles/typeStyle';

export const StyledRate = styled.div``;

export const StyledSlider = styled(Slider)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.2rem;
  margin-bottom: 32px;
`;

export const StyledRadio = styled(Radio)`
  margin-left: auto;
`;

export const StyledValue = styled.div`
  ${typeStyle.bodyRegular}
  padding: 8px;
  background-color: ${({ theme }) => theme.newColor.surface200};
`;

export const StyledDesc = styled.p`
  ${typeStyle.bodyRegular};
  margin: 8px 0 0;
`;
