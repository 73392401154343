/* eslint-disable import/extensions */
/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

import { Dispatch } from 'redux';
import * as types from './selectedChallengesOrQuestionsTypes';
import { Action } from 'redux/types';

interface SelectedChallenges {
  challenges?: Array<number | null> | undefined;
}

interface SelectedQuestions {
  questions?: Array<any | null> | undefined;
  element_name?: string;
}
// actions
export const updateSelectedChallenges =
  (selectedChallenges: SelectedChallenges) => async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: types.GET_SELECTED_CHALLENGES,
        payload: selectedChallenges,
      });
      return null;
    } catch (error) {
      return null;
    }
  };

export const removeSelectedChallenge = (index: number) => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: types.REMOVE_SELECTED_CHALLENGE,
      payload: index,
    });
    return null;
  } catch (error) {
    return null;
  }
};

export const clearSelectedChallenges = () => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: types.CLEAR_SELECTED_CHALLENGES,
    });
    return null;
  } catch (error) {
    return null;
  }
};

export const updateSelectedQuestions =
  (selectedQuestions: SelectedQuestions) => async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: types.GET_SELECTED_QUESTIONS,
        payload: selectedQuestions,
      });
      return null;
    } catch (error) {
      return null;
    }
  };

export const updateSelectedNewQuestions =
  (selectedQuestions: SelectedQuestions) => async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: types.GET_SELECTED_NEW_QUESTIONS,
        payload: selectedQuestions,
      });
      return null;
    } catch (error) {
      return null;
    }
  };

// actions
export const updateSelectedName = (selectedName: string) => async (dispatch: Dispatch<Action>) => {
  try {
    dispatch({
      type: types.GET_SELECTED_NAME,
      payload: selectedName,
    });
    return null;
  } catch (error) {
    return null;
  }
};
