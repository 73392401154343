import { SelectItem } from 'components/molecules/Select/Select';
import { ApiBaseProps } from 'redux/types';

type ItemsBaseProps = ApiBaseProps & {
  id: number;
  description: string;
};

export const getSelectItems = (apiData: Array<ItemsBaseProps>): Array<SelectItem> => {
  return apiData.map((item) => {
    return {
      value: item.id,
      title: item.description,
    };
  });
};
