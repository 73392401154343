import { useEffect, useRef } from 'react';
import { useDeviceState } from './useDeviceState';

interface TabsIndicatorProps {
  active: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useTabsIndicator = ({ active }: TabsIndicatorProps) => {
  const { deviceState } = useDeviceState();
  const tabsContainerRef = useRef<HTMLDivElement | null>(null);
  const tabsIndicatorRef = useRef<HTMLDivElement | null>(null);

  const calculateIndicatorPosition = () => {
    const tabsIndicatorNode = tabsIndicatorRef.current as HTMLElement;
    const tabsContainerNode = tabsContainerRef.current as HTMLElement;

    if (tabsContainerNode) {
      const activeTabNode = (tabsContainerNode.children[active] ||
        tabsContainerNode.children[0]) as HTMLElement;

      if (activeTabNode) {
        const activeTabWidth = activeTabNode.getBoundingClientRect().width;

        tabsIndicatorNode.style.width = `${activeTabWidth}px`;
        tabsIndicatorNode.style.transform = `translateX(${activeTabNode.offsetLeft}px)`;
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((document as any).fonts) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (document as any).fonts.ready.then(() => {
        setTimeout(() => {
          calculateIndicatorPosition();
        }, 10);
      });
    } else {
      calculateIndicatorPosition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateIndicatorPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, deviceState]);

  return {
    tabsContainerRef,
    tabsIndicatorRef,
    calculateIndicatorPosition,
  };
};
