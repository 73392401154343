import { toast, TypeOptions } from 'react-toastify';
import { Events, SET_ERROR_EVENT, SET_SUCCESS_EVENT } from './eventsTypes';
import { ReduxReducer } from 'redux/types';

export interface EventsState {
  event?: Events;
  eventType?: TypeOptions;
}

const initialState = {
  event: undefined,
  eventType: undefined,
} as EventsState;

export const eventsReducer: ReduxReducer<EventsState> = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUCCESS_EVENT:
      return {
        event: action.payload.event,
        eventType: toast.TYPE.SUCCESS,
      };
    case SET_ERROR_EVENT:
      return {
        event: action.payload.event,
        eventType: toast.TYPE.ERROR,
      };
    default:
      return state;
  }
};
