import { TableOptions, TableDataItems } from 'components/atoms/Table/Table.types';
import { Superadmin } from 'services/types/SuperadminTypes';

export const getSuperadminDetails = (
  tableData: TableOptions,
  rowIndex: number,
): { id: number; name: string; email: string } => {
  const rowData = tableData.rows[rowIndex];
  const name = rowData[1].copy as string;
  const email = rowData[2].copy as string;
  const id = rowData[4].data as number;

  return { id, name, email };
};

const getSuperadminRow = (adminUser: Superadmin, index: number): TableDataItems => {
  return [
    {
      copy: `${index + 1}. `,
    },
    { copy: adminUser.name },
    { copy: adminUser.email },
    { copy: '' },
    { data: adminUser.id },
  ];
};

export const getFormatedSuperadminTableData = (adminUsers: Array<Superadmin>): TableOptions => {
  return {
    head: ['No.', 'Name', 'Email', ' '],
    controls: [
      { type: 'Edit', action: 'Edit' },
      { type: 'Delete', action: 'Delete' },
    ],
    columnOptions: {
      0: { width: '4rem', mobileRow: true },
      1: { width: '16%', defaultValue: '-', mobileRow: true },
      2: { width: '40%', mobileRow: true },
      4: { width: '3.5rem', mobileRow: true },
      5: { width: '3.5rem', mobileRow: true },
    },
    rows: adminUsers.map((adminUser, index) => getSuperadminRow(adminUser, index)),
  };
};
