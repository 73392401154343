/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable import/order */
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Plus } from 'components/atoms/Icon/Icon';
import { Path } from 'routes/Path';
import { ConfirmModalContext } from 'components/organisms/ConfirmModal/ConfirmModal';
import { RootState } from 'redux/store';
import { TableOptions } from 'components/atoms/Table/Table.types';
import { tableData } from './AssessmentManagement.data';

import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import { ManagementTable } from '../../components/ManagementTable/ManagementTable';
import { surveysActions } from 'redux/ducks/surveysDuck/surveysDuckReducer';
import { ActionMethod } from 'data/enum/ActionMethod';
import { SurveyProps } from 'redux/ducks/surveysDuck/surveysDuckTypes';
import { escapeRegExp, values } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AssessmentManagementProps {}

// eslint-disable-next-line no-empty-pattern
export const AssessmentManagement = ({}: AssessmentManagementProps): ReactElement => {
  const { useConfirmModal } = useContext(ConfirmModalContext);
  const { surveys } = useSelector((state: RootState) => state.surveys);
  const dispatch = useDispatch();

  const [filterQuery, setFilterQuery] = useState<string>();
  const [formattedTableData, setFormattedTableData] = useState<TableOptions>();

  /**
   * Create and update the data used to populate the table.
   */
  const createFormattedTableData = (data: Array<SurveyProps>) => {
    const rows = data.map((item, index) => {
      let date = '';
      if (item.created_at === undefined) {
        date = '-';
      } else {
        date = item.created_at.substring(0, 19);
      }

      return [
        { hidden: true, copy: item.id },
        { copy: index + 1 },
        { copy: item.name },
        { copy: item.description },
        { copy: date },
        {
          type: 'Checkbox',
          checkboxDefaultChecked: item.elements ? item.elements[0]?.set_as_open : false,
          areActionsDisabled: true,
        },
      ];
    });

    setFormattedTableData({ ...tableData, rows } as TableOptions);
  };

  /**
   * Filter and update table data elements by their name and description based on a filter string.
   * */
  const filterTableData = (data: Array<SurveyProps>) => {
    const regex = new RegExp(`(${escapeRegExp(filterQuery?.toLowerCase())})`);

    const filtered = values(data).filter(
      (item) =>
        regex.test(item.name?.toLowerCase() || '') ||
        regex.test(item.description?.toLowerCase() || ''),
    );

    createFormattedTableData(filtered);
  };

  const Delete = async (rowIndex: number) => {
    const rowData = formattedTableData?.rows[rowIndex];

    if (!rowData) return;

    const id = Number(rowData[0].copy);
    const confirmed = await useConfirmModal({
      title: 'Delete',
      subtitle: rowData[2].copy,
      description: 'Are you sure you want to delete this assessment?',
    });

    if (!confirmed) return;

    await dispatch(surveysActions.surveysApiHandler({ method: ActionMethod.DELETE, id }));
    dispatch(surveysActions.surveysApiHandler({ method: ActionMethod.GET }));
  };

  useEffect(() => {
    dispatch(surveysActions.surveysApiHandler({ method: ActionMethod.GET }));
  }, [dispatch]);

  useEffect(() => {
    if (filterQuery === undefined) {
      createFormattedTableData(surveys || []);
    } else {
      filterTableData(surveys || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveys, filterQuery]);

  return (
    <>
      <AdminHeading
        title="Assessment Management"
        subtitle="Create and manage assessments"
        backButton={{
          title: 'Back to Management Tool',
          href: Path.ManagementTool,
        }}
      >
        <PrimaryButton href={Path.CreateAssessment} $size="regular">
          Create New Assessment <Plus />
        </PrimaryButton>
      </AdminHeading>

      {formattedTableData && (
        <ManagementTable
          data={formattedTableData as TableOptions}
          actions={{ Delete }}
          setFilterQuery={setFilterQuery}
          /*
          This will disable action buttons temporarily while some
          survey issues related to this actions are being fixed.
          */
          // @NOTE(gb) excuse me?
          // shouldDisableItem={() => true}
        />
      )}
    </>
  );
};
