import styled from 'styled-components';
import { PrimaryButton } from '../PrimaryButton/PrimaryButton';
import { Ease } from 'styles/easing';
import { ColorKey } from 'styles/theme/default';
import { typeStyle } from 'styles/typeStyle';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';

export const StyledTabs = styled.div<{ $empty: boolean }>`
  border-bottom: solid 1px ${({ theme, $empty }) => ($empty ? 'transparent' : theme.color.greyD)};
  max-width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
  gap: 3rem;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
  }
`;

export const StyledTabButton = styled.button<{ isActive: boolean }>`
  ${typeStyle.h4};
  color: ${({ theme, isActive }) => (isActive ? theme.color.black : theme.color.greyA)};
  font-weight: 500;
  white-space: nowrap;
  padding: 0 0 2rem;
  transition: color 0.3s ease;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    font-size: 2rem;
  }

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.color.black};
    }
  }
`;

export const StyledDraggableWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

export const StyledTabsContainer = styled.div`
  display: inline-block;
`;

export const TabsList = styled.div`
  display: inline-flex;
  gap: 3rem;
`;

export const StyledTabIndicator = styled.div<{ color?: ColorKey; $empty: boolean }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.3rem;
  background-color: ${({ theme, color, $empty }) => color && !$empty && theme.color[color]};
  border-radius: 0.3rem 0.3rem 0 0;
  transition: width 0.4s ${Ease.EaseInOutQuart}, transform 0.4s ${Ease.EaseInOutQuart};
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  margin: 0 0 2rem 1rem;

  @media ${respondTo(MediaQuery.MAX_1023)} {
    margin: 0 0 2rem;
  }
`;
