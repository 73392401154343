import type {
  ModalData,
  ModalItemProps,
  ModalRegisterFormItemProps,
} from 'components/organisms/FormModal/FormModal';

interface ItemExtraProps {
  registerFormItemProps?: ModalRegisterFormItemProps;
  itemProps?: ModalItemProps;
}

export const editModalNameEmailProps = (
  name: string,
  email: string,
  extraOptionsOne?: ItemExtraProps,
  extraOptionsTwo?: ItemExtraProps,
): ModalData => ({
  title: 'Edit',
  confirmCopy: 'Submit',
  items: [
    {
      type: 'input',
      registerFormItemProps: {
        name: 'name',
        label: 'Name',
        color: 'greyA',
        ...extraOptionsOne?.registerFormItemProps,
      },
      itemProps: {
        defaultValue: name,
        hasRef: true,
        ...extraOptionsOne?.itemProps,
      },
    },
    {
      type: 'input',
      registerFormItemProps: {
        name: 'email',
        label: 'Email',
        color: 'greyA',
        ...extraOptionsTwo?.registerFormItemProps,
      },
      itemProps: {
        defaultValue: email,
        hasRef: true,
        ...extraOptionsTwo?.itemProps,
      },
    },
  ],
});
