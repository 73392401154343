import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import * as S from './ManagementOverview.styles';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Text } from 'components/atoms/Text/Text';
import { Path } from 'routes/Path';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ManagementOverviewProps {}

// eslint-disable-next-line no-empty-pattern
export const ManagementOverview = ({}: ManagementOverviewProps): ReactElement => {
  const data = [
    {
      title: 'Elements management',
      description: 'Click to add, edit and view elements',
      link: Path.ElementsManagement,
    },
    // {
    //   title: 'Challenges management',
    //   description: 'Click to add and view challenges',
    //   link: Path.ChallengesManagement,
    // },
    {
      title: 'Questions management',
      description: 'Click to add, edit and view questions',
      link: Path.QuestionsManagement,
    },
    {
      title: 'Assessment management',
      description: 'Click to add and view assessments',
      link: Path.AssessmentManagement,
    },
    {
      title: 'SuperAdmin management',
      description: 'Click to add, edit and view admins',
      link: Path.SuperAdminManagement,
    },
  ];
  return (
    <>
      <Flex container flexDirection="column">
        <S.StyledHeading margin="2rem 0" type={HeadingType.H2}>
          Survey Management Tool
        </S.StyledHeading>
      </Flex>
      <S.CardsWrapper
        container
        justifyContent="center"
        flexWrap="wrap"
        alignItems="center"
        gap="2rem"
      >
        {data.map((item) => {
          return (
            <Link to={item.link} key={item.title}>
              <S.ManagementCard container flexDirection="column" gap="2rem">
                <S.CardTitle type={HeadingType.H4}>{item.title}</S.CardTitle>
                <Text type="body">{item.description}</Text>
              </S.ManagementCard>
            </Link>
          );
        })}
      </S.CardsWrapper>
    </>
  );
};
