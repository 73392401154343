import styled from 'styled-components';
import { Form } from 'components/molecules/Form/Form';
import { ErrorMessage } from 'components/atoms/ErrorMessage/ErrorMessage';

export const StyledAddMissions = styled.div`
  padding-bottom: 10rem;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  padding-bottom: 7rem;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 2rem;
`;
