import {
  cloneElement,
  ReactElement,
  ReactNode,
  useState,
  Children,
  isValidElement,
  useEffect,
} from 'react';

interface TabsContainerProps {
  children: Array<ReactNode>;
  defaultActive?: number;
  onActivate?: (active: number) => void;

  // Alternative to handle current tab from parent component if required
  // TODO: Figure out re-render issues if removing setActiveTab to rely on onActive
  setActiveTab?: (index: number) => void;
  activeTab?: number;
}

export const TabsContainer = ({
  defaultActive,
  onActivate,
  setActiveTab,
  activeTab,
  children,
}: TabsContainerProps): ReactElement => {
  const [activeTabState, setActiveTabState] = useState(defaultActive || 0);
  const active = activeTab !== undefined ? activeTab : activeTabState;
  const setActive = setActiveTab || setActiveTabState;

  const handleTabs = (container: ReactElement) => {
    const cloneTabs = (child: ReactElement) =>
      cloneElement(child, {
        ...child.props,
        active,
        setActive,
      });

    if (container.props.tabs) {
      return cloneTabs(container);
    }

    const children = Children.map(container.props.children, (child: ReactElement) => {
      if (child.props.tabs) {
        return cloneTabs(child);
      }
      return child;
    });

    return cloneElement(container, {
      ...container.props,
      children,
    });
  };

  const handleContent = (
    container:
      | ReactElement
      | ((active: number, setActive: (index: number) => void) => ReactElement),
  ) => {
    if (isValidElement(container)) {
      if (Array.isArray(container.props.children)) {
        const children = container.props.children[active];

        return cloneElement(container, {
          ...container.props,
          children,
        });
      }

      return children.slice(1)[active];
    }

    return container(active, setActive);
  };

  useEffect(() => {
    if (defaultActive !== undefined) {
      setActive(defaultActive);
    }
  }, [defaultActive, setActive]);

  useEffect(() => {
    if (onActivate && defaultActive !== active) {
      onActivate(active);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <>
      {handleTabs(children[0] as ReactElement)}
      {handleContent(children[1] as ReactElement)}
    </>
  );
};
