import styled from 'styled-components';
import { typeStyle } from 'styles/typeStyle';

export const StyledSidebar = styled.div``;

export const StyledAssessmentsFlex = styled.ul`
  margin-top: 1.1rem;
`;

export const StyledAssessment = styled.li<{ active?: boolean }>`
  width: calc(100% + 1rem);
  position: relative;
  padding-right: 0 !important;

  &:not(:last-of-type) {
    margin-bottom: 1.1rem;
  }

  ${({ theme, active }) =>
    active
      ? `
      background: ${theme.color.greyB};
      color: ${theme.color.blueA};

      &::after {
        content: '';
        position: absolute;
        right: -2rem;
        top: 0;
        border-radius: 50%;
        height: 4rem;
        width: 4rem;
        background: ${theme.color.greyB};
        pointer-events: none;
      }
  `
      : `color: ${theme.color.cream};`}
`;

export const StyledAssessmentButton = styled.button`
  ${typeStyle.h5};
  padding: 0.9rem 0;
  height: 4rem;
  width: calc(100% + 2rem);
  text-align: left;
  color: inherit;
`;

export const StyledCircleArrow = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  border-radius: 50%;
  background: ${({ theme }) => theme.color.blueD};
  width: 2.6rem;
  height: 2.6rem;
  z-index: 1;
  transform: translate(30%, -50%);
`;

export const StyledCircleArrowInner = styled.div`
  position: relative;
  height: 100%;

  svg {
    position: absolute;
    width: 3.1rem;
    height: 1.1rem;
    top: 50%;
    left: -40%;
    transform: translateY(-50%);
  }
`;
