import { defineMessages } from 'react-intl';

export const carouselItems = defineMessages({
  objectiveOneTitle: {
    description: 'Onboarding - Objectives - Objective 1 - Title',
    defaultMessage: 'Assess yourself',
  },
  objectiveOneContent: {
    description: 'Onboarding - Objectives - Objective 1 - Content',
    defaultMessage: `
    <p>Learn about your strengths and areas for growth by answering questions about your leadership skills.</p>
     <h6>You will explore:</h6>
      <ul>
        <li>What are your gifts</li>
        <li>What are your growth opportunities</li>
        <li>Where do you want to develop</li>
      </ul>
    `,
  },
  objectiveTwoTitle: {
    description: 'Onboarding - Objectives - Objective 2 - Title',
    defaultMessage: 'Ask for feedback',
  },
  objectiveTwoContent: {
    description: 'Onboarding - Objectives - Objective 2 - Content',
    defaultMessage: `
    <p>Identify people who can give you meaningful feedback.</p>
      <h6>The tool will help you to:</h6>
        <ul>
          <li>Invite others to give you feedback</li>
          <li>Check the status of their feedback</li>
          <li>Send reminders to provide feedback as needed</li>
        </ul>
      `,
  },
  objectiveThreeTitle: {
    description: 'Onboarding - Objectives - Objective 3 - Title',
    defaultMessage: 'Reflect',
  },
  objectiveThreeContent: {
    description: 'Onboarding - Objectives - Objective 3 - Content',
    defaultMessage: `
    <p>Based on your 360º assessment, decide which areas you want to develop.</p>
    <h6>Using your feedback report, you will:</h6>
      <ul>
        <li>Reflect on your strengths</li>
        <li>Reflect on your areas for improvement</li>
      </ul>
      `,
  },
  objectiveFourTitle: {
    description: 'Onboarding - Objectives - Objective 4 - Title',
    defaultMessage: 'Set objectives',
  },
  objectiveFourContent: {
    description: 'Onboarding - Objectives - Objective 4 - Content',
    defaultMessage: `
    <p>You will set objectives to help practice the leadership skills you want to increase.</p>
      <h6>After reflecting on your feedback, you will:</h6>
        <ul>
          <li>Set growth objectives for yourself moving forward</li>
          <li>Determine how you will hold yourself accountable</li>
        </ul>
      `,
  },
});
