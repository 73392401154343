import { createCustomerDTO } from './dto/CreateCustomerDTO';
import { Customer } from '../model/Customer';
import { ApiClient } from '../util/apiClient';
import { Cohort } from 'model/Cohort';

enum CustomerEndpoints {
  Customers = 'customers',
  Industries = '/industries',
}

const createCustomer = async (customer: Customer): Promise<Customer> => {
  const { data } = await ApiClient.post(
    `/${CustomerEndpoints.Customers}`,
    createCustomerDTO(customer),
  );

  const newCustomer = customer;
  newCustomer.id = data.id;

  return newCustomer;
};

const editCustomer = async (customer: Customer, id: number): Promise<Customer> => {
  const { data } = await ApiClient.put(`/${CustomerEndpoints.Customers}/${id}`, customer);

  return data;
};

const getCustomerCohorts = async (id: number): Promise<Array<Cohort>> => {
  const { data } = await ApiClient.get(`/${CustomerEndpoints.Customers}/${id}/cohorts`);
  return data as Array<Cohort>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getCustomer = async (id: string): Promise<any> => {
  const { data } = await ApiClient.get(`/${CustomerEndpoints.Customers}/${id}`);
  return data;
};

const getIndustries = async (): Promise<Array<{ id: number; description: string }>> => {
  const { data } = await ApiClient.get(CustomerEndpoints.Industries);
  return data;
};

export const CustomerService = {
  createCustomer,
  getCustomerCohorts,
  getCustomer,
  editCustomer,
  getIndustries,
};
