import { useEffect, useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import debounce from 'lodash/debounce';
import { toast } from 'react-toastify';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { RootState } from 'redux/store';
import constants from 'data/constants';

export const useGlobalEventsHandler = (): void => {
  const intl = useIntl();
  const history = useHistory();
  const state = useSelector((state: RootState) => state.events);

  const messages = useMemo(
    () => ({
      [Events.COHORT_USER_DELETED]: intl.formatMessage({
        description: 'Global - Forms - Popup - Success - Cohort User Deleted Correctly',
        defaultMessage: 'User Deleted Correctly',
      }),
      [Events.FORM_INVALID_ERROR]: intl.formatMessage({
        description: 'Global - Forms - Popup - Error - Invalid Form',
        defaultMessage: 'Please make sure every field was filled correctly.',
      }),
      [Events.PICTURE_INVALID_EXTENSION]: 'The only image formats allowed are .png, .jpg & .jpeg',
      [Events.FORM_SUCCESS]: intl.formatMessage({
        description: 'Global - Forms - Popup - Success - Form Sent Correctly',
        defaultMessage: 'Information sent correctly.',
      }),
      [Events.FORM_DELETE_SUCCESS]: intl.formatMessage({
        description: 'Global - Forms - Popup - Success - Element Deleted Correctly',
        defaultMessage: 'Element Deleted Correctly.',
      }),
      [Events.FORM_REQUEST_ERROR]: intl.formatMessage({
        description: 'Global - Forms - Popup - Error - Request Error',
        defaultMessage: 'An error occurred while sending the request, please try again later.',
      }),
      [Events.FORM_MANDATORY_QUESTIONS_ERROR]: intl.formatMessage({
        description: 'Global - Forms - Popup - Error - Invalid Form',
        defaultMessage: 'All the elements must have at least one question added.',
      }),
      [Events.LOGIN_SUCCESS]: intl.formatMessage({
        description: 'Global - Popup - Login - Login Success',
        defaultMessage: 'Login Successful.',
      }),
      [Events.LOGIN_ERROR]: intl.formatMessage({
        description: 'Global - Popup - Login - Login Error',
        defaultMessage: 'Incorrect email or password.',
      }),
      [Events.FORM_MISSION_SAVED]: intl.formatMessage({
        description: 'Global - Forms - Popup - Success - Mission Saved Correctly',
        defaultMessage: 'Mission Saved Correctly',
      }),
      [Events.FORM_ASSESSMENT_SAVED]: intl.formatMessage({
        description: 'Global - Forms - Popup - Success - Assessment Saved Correctly',
        defaultMessage: 'Assessment Saved Correctly',
      }),
      [Events.COPY_CLIPBOARD_SUCCESS]: 'Element copied succesfully to the clipboard',
      [Events.ADD_MISSION_COMPLETED_SUCCESS]: 'Mission Completed Successfully',
      [Events.MAXIMUM_NUMBER_OF_ELEMENTS_EXCEEDED]: `You can set up to ${constants.CREATE_ASSESSMENT_MAX_ELEMENTS} elements`,
      [Events.MAXIMUM_NUMBER_OF_QUESTIONS_EXCEEDED]: `You can set up to ${constants.CREATE_ASSESSMENT_MAX_QUESTIONS} questions`,
      [Events.MAXIMUM_NUMBER_OF_CHALLENGES_EXCEEDED]: `You can set up to ${constants.CREATE_ASSESSMENT_MAX_CHALLENGES} challenges`,
      [Events.INVITATIONS_SENT]: intl.formatMessage({
        description: 'Assessors - Forms - Popup - Success - Invitations sent',
        defaultMessage: 'Invitations sent',
      }),
    }),
    [intl],
  ) as Record<Events, string>;

  const toastId = 0;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEvents = useCallback(
    debounce(() => {
      if (!state.event) return;

      if (messages[state.event] && state.eventType) {
        toast(messages[state.event], { type: state.eventType, toastId });
      }
    }, 150),
    [state.event, history],
  );

  useEffect(() => {
    handleEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, handleEvents]);
};
