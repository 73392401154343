/* eslint-disable @typescript-eslint/naming-convention */
import { OrganizationSubunit } from './OrganizationSubunit';

export class OrganizationUnit {
  public id: number = 0;
  public description: string = '';
  public organizational_sub_units: Array<OrganizationSubunit> = [];

  public addSubunit(subunit: OrganizationSubunit): void {
    if (subunit.id !== undefined) {
      this.organizational_sub_units.push(subunit);
    } else {
      const newSubunit = subunit;
      newSubunit.id = this.organizational_sub_units.length;
      this.organizational_sub_units.push(newSubunit);
    }
  }

  public getSubunitById(subunitId: number): OrganizationSubunit {
    const subunit = this.organizational_sub_units.find((subunit) => subunit.id === subunitId);
    if (!subunit) throw new Error('Cohort given id not found');

    return subunit;
  }

  public removeSubunitById(unitId: number): void {
    this.organizational_sub_units = this.organizational_sub_units.filter(
      (subunit) => subunit.id !== unitId,
    );
  }

  public getSubunitByIndex(index: number): OrganizationSubunit {
    return this.organizational_sub_units[index];
  }
}
