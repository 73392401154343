/* eslint-disable import/no-unresolved */
import { ReactElement, useMemo } from 'react';
import { RegisteredFormItem } from 'globalTypes';
import { StyledCardContainer } from '../../ClientForm.styles';
import { CreateCohort } from '../CreateCohort/CreateCohort';
import { Participant } from 'model/Participant';
import { Cohort } from 'model/Cohort';
import { OrganizationSubunit } from 'model/OrganizationSubunit';
import { OrganizationUnit } from 'model/OrganizationUnit';
import { Survey } from 'model/Survey';

interface SubunitProps extends RegisteredFormItem {
  surveys: Array<{ id: number; name: string }>;
  unitIndex: number;
  subunit: OrganizationSubunit;
  subunitIndex: number;
  unit: OrganizationUnit;
  cohorts: Array<Cohort>;
  isEditMode?: boolean;
  initialEditCohorts: Array<number>;
  handleAddCohort: (unitId?: number, subunitId?: number) => void;
  handleDeleteCohort: (unitId: number, subunitId: number, cohortId: number) => void;
  handleCohortChange: (unitId: number, subunitId: number, cohortId: number, name: string) => void;
  handleSurveyChange: (unitId: number, subunitId: number, cohortId: number, survey: Survey) => void;
  participants: Array<Participant>;
  handleParticipantChange: (
    unitId: number,
    subunitId: number,
    cohortId: number,
    newParticipantsList: Array<Participant>,
  ) => void;
}

export const Subunit = ({
  unitIndex,
  subunitIndex,
  register,
  formMethods,
  errors,
  subunit,
  isEditMode,
  initialEditCohorts,
  handleAddCohort,
  handleDeleteCohort,
  handleParticipantChange,
  handleSurveyChange,
  cohorts,
  unit,
  participants,
  surveys,
  handleCohortChange,
}: SubunitProps): ReactElement => {
  const isSubunit = !!subunit.description;

  const orderedCohorts = useMemo(() => {
    return cohorts.sort();
  }, [cohorts]);

  return (
    <StyledCardContainer>
      <CreateCohort
        name={subunit.description}
        unitIndex={unitIndex}
        subunitIndex={subunitIndex}
        register={register}
        formMethods={formMethods}
        errors={errors}
        isSubunit={isSubunit}
        unit={unit}
        participants={participants}
        handleParticipantChange={handleParticipantChange}
        cohorts={orderedCohorts}
        handleCohortChange={handleCohortChange}
        subunit={subunit}
        handleAddCohort={handleAddCohort}
        handleDeleteCohort={handleDeleteCohort}
        surveys={surveys}
        handleSurveyChange={handleSurveyChange}
        isEditMode={isEditMode}
        initialEditCohorts={initialEditCohorts}
      />
    </StyledCardContainer>
  );
};
