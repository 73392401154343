import styled from 'styled-components';
import { StickyBar } from 'components/atoms/StickyBar/StickyBar';

export const StyledStickyBar = styled(StickyBar)`
  padding-right: 3.2rem;
`;

export const StyledModalContent = styled.div`
  min-height: 15rem;
`;
