/* eslint-disable no-console */
import { ReactElement } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { messages } from '../../Login.locale';
import * as S from './LoginForm.styles';
import { LoginFormType } from '../../Login.types';
import { Form } from 'components/molecules/Form/Form';
import { Input } from 'components/atoms/Input/Input';
import { SessionService } from 'services/SessionService';
import { userRoles } from 'model/User';
import { setSuccessEventAction, setErrorEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { Path } from 'routes/Path';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Heading } from 'components/atoms/Heading/Heading';

interface LoginFormProps {
  setActiveForm: React.Dispatch<React.SetStateAction<LoginFormType>>;
}

export const LoginForm = ({ setActiveForm }: LoginFormProps): ReactElement => {
  const { replace, push } = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();

  const onFormSuccess = async (formData: FieldValues) => {
    try {
      const { email, password, remember_me } = formData;
      console.log(formData);
      const { user, token } = await SessionService.login({ email, password });

      if (!token) throw new Error('Missing token');

      if (remember_me) {
        localStorage.setItem('token', token);
        sessionStorage.removeItem('token');
      } else {
        sessionStorage.setItem('token', token);
        localStorage.removeItem('token');
      }

      const isSuperAdmin = user.roles[0].name === userRoles.superAdmin;

      replace(isSuperAdmin ? Path.ClientOverview : Path.Dashboard);

      if (!isSuperAdmin && localStorage.getItem('onboardingAlreadyShowed') !== 'true') {
        push(`${Path.Onboarding}/${user.participant_id}`);
      }

      dispatch(setSuccessEventAction(Events.LOGIN_SUCCESS));
    } catch (error) {
      dispatch(setErrorEventAction(Events.LOGIN_ERROR));
    }
  };

  return (
    <Form onSuccess={onFormSuccess} showSuccessMessage={false} showErrorMessage={false}>
      <Heading type={HeadingType.H3} margin="0 0 24px">
        Log in
      </Heading>
      <S.StyledRegisterFormItem
        register
        registerErrors="required"
        label={intl.formatMessage(messages.emailLabel)}
        name="email"
        size="small"
      >
        <Input
          autoComplete="off"
          hasRef
          type="email"
          placeholder={intl.formatMessage(messages.enterEmail)}
          tabIndex={1}
        />
      </S.StyledRegisterFormItem>
      <S.StyledRegisterFormItem
        register
        registerErrors="required"
        label={intl.formatMessage(messages.passwordLabel)}
        name="password"
        size="small"
      >
        <Input
          hasRef
          type="password"
          placeholder={intl.formatMessage(messages.enterPassword)}
          tabIndex={2}
        />
      </S.StyledRegisterFormItem>
      <S.CheckboxWrapper>
        <S.StyledCheckBoxInput register name="remember_me">
          <FormattedMessage description="Login Remember Checkbox" defaultMessage="Remember me" />
        </S.StyledCheckBoxInput>
        <S.ForgotPasswordbutton
          type="button"
          onClick={() => setActiveForm(LoginFormType.ResetForm)}
        >
          <FormattedMessage
            description="Login Forgot Password Button"
            defaultMessage="Forgot my password"
          />
        </S.ForgotPasswordbutton>
      </S.CheckboxWrapper>
      <S.StyledPrimaryButton $mobileSize="regular" type="submit" $backgroundColor="redA">
        <FormattedMessage description="Login" defaultMessage="Login" />
      </S.StyledPrimaryButton>
    </Form>
  );
};
