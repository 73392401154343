/* eslint-disable unicorn/error-message */
import { Participant } from '../../model/Participant';

type AddParticipantDTO = {
  customer_id: number;
  email: string;
  is_admin: boolean;
  name: string;
};

export const addParticipantDTO = (
  customerId: number,
  participant: Participant,
): AddParticipantDTO => {
  if (!participant.name) throw new Error('Missing name field');
  if (!participant.email) throw new Error('Missing email field');
  if (participant.is_admin === undefined) throw new Error('Missing isAdmin field');

  const addParticipantDto: AddParticipantDTO = {
    customer_id: customerId,
    name: participant.name,
    email: participant.email,
    is_admin: participant.is_admin,
  };

  return addParticipantDto;
};
