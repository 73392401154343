import styled, { css } from 'styled-components';
import CheckedIcon from '../Icon/svg/checkbox-checked.svg';

// eslint-disable-next-line no-shadow
export enum CheckboxVariants {
  Regular = 'Regular',
  Circular = 'Circular',
}

export interface CheckBoxProps {
  $variant: CheckboxVariants;
  hasError?: boolean;
  isOnTable?: boolean;
  isDisabled?: boolean;
}

export const StyledCheckbox = styled.input<CheckBoxProps>`
  position: relative;
  appearance: none;
  align-self: flex-start;
  border: 1px solid
    ${({ theme, hasError }) => (hasError ? theme.color.redA : theme.newColor.surface200)};
  ${({ isDisabled }) => !isDisabled && 'cursor: pointer;'};
  ${({ isOnTable }) => isOnTable && 'margin-inline: 3rem;'}

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 150ms ease-in-out;
  }

  &:checked {
    border: 1px solid
      ${({ theme, hasError }) => (hasError ? theme.color.redA : theme.newColor.primary100)};

    &::after {
      opacity: 1;
    }
  }

  ${({ $variant }) =>
    $variant === CheckboxVariants.Regular &&
    css`
      min-width: 32px;
      min-height: 32px;
      border-radius: 2px;

      &::after {
        background-image: url(${CheckedIcon});
        background-repeat: no-repeat;
        background-size: contain;
        width: 13px;
        height: 10px;
      }
    `}

  ${({ $variant }) =>
    $variant === CheckboxVariants.Circular &&
    css`
      min-width: 12px;
      min-height: 12px;
      border-radius: 50%;

      &::after {
        width: 4px;
        height: 4px;
        background: ${({ theme }) => theme.color.blueA};
        border-radius: 50%;
      }
    `}
`;
