import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-use';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Heading } from 'components/atoms/Heading/Heading';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';
import { HeaderTypes } from 'components/molecules/Header/Header.styles';
import { Path } from 'routes/Path';
import { IS_PRODUCTION } from 'util/environment';

interface HomeProps {
  userRole?: string;
}

export const Home = ({ userRole }: HomeProps): ReactElement => {
  const { pathname } = useLocation();
  const { push } = useHistory();

  const isIndexPath = pathname?.includes(Path.Index);
  const redirectByRole = () => {
    if (userRole === 'super-admin') {
      push(Path.ClientOverview);
    } else if (userRole === 'participant' || userRole === 'client-admin') {
      push(Path.Dashboard);
    } else {
      push(Path.Login);
    }
  };

  useEffect(() => {
    if (isIndexPath) {
      if (IS_PRODUCTION) {
        push(Path.Home);
      }
    } else {
      redirectByRole();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NormalLayout headerType={HeaderTypes.Logo} padding="normal">
      <Flex container gap="2rem" flexWrap="wrap">
        <Flex flexBasis="100%">
          <Heading margin="5rem 0 2rem">User Pages:</Heading>
        </Flex>
        <PrimaryButton href="/login">Login</PrimaryButton>
        <PrimaryButton href="/onboarding">Onboarding</PrimaryButton>
        <PrimaryButton href="/dashboard">Dashboard</PrimaryButton>
        <PrimaryButton href="/dashboard/empty">Empty Dashboard</PrimaryButton>
        <PrimaryButton href="/profile">Profile</PrimaryButton>
        <PrimaryButton href="/assessment">Assessment</PrimaryButton>
        <PrimaryButton href="/assessment/feedback">Assessment Feedback</PrimaryButton>
        <PrimaryButton href="/assessment/completed">Assessment Completed</PrimaryButton>
        <PrimaryButton href="/create/objective">Create Objective</PrimaryButton>
        <PrimaryButton href="/not-found">Not found</PrimaryButton>
      </Flex>
      <Flex container gap="2rem" flexWrap="wrap">
        <Flex flexBasis="100%">
          <Heading margin="10rem 0 2rem">Admin Pages:</Heading>
        </Flex>
        <PrimaryButton href="/dashboard/admin">Dashboard</PrimaryButton>
      </Flex>
      <Flex container gap="2rem" flexWrap="wrap">
        <Flex flexBasis="100%">
          <Heading margin="10rem 0 2rem">Superadmin Pages:</Heading>
        </Flex>
        <PrimaryButton href={Path.ClientOverview}>Client Overview</PrimaryButton>
        <PrimaryButton href={Path.AdminDashboard}>Client Dashboard</PrimaryButton>
        <PrimaryButton href={Path.ElementsManagement}>Elements Management</PrimaryButton>
        {/* <PrimaryButton href={Path.ChallengesManagement}>Challenges Management</PrimaryButton> */}
        <PrimaryButton href={Path.QuestionsManagement}>Questions Management</PrimaryButton>
        <PrimaryButton href={Path.AssessmentManagement}>Assessment Management</PrimaryButton>
        <PrimaryButton href={Path.AssessmentHistory}>Assessment History</PrimaryButton>
      </Flex>
    </NormalLayout>
  );
};
