enum ElementType {
  Question = 2,
  Element = 1,
}

export class Element {
  public idElement?: number;
  public name?: string;
  public description?: string;
  public allowNoAnswer?: boolean;
  public elementType?: ElementType;
  public isOpenAnswer?: boolean;
}
