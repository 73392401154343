/* eslint-disable unicorn/error-message */
import { OrganizationUnit } from '../../model/OrganizationUnit';

type CreateUnitDTO = {
  description: string;
  customer_id?: number;
  organizational_unit_id?: number;
};

export const createUnitDTO = (customerId: number, unit: OrganizationUnit): CreateUnitDTO => {
  if (!unit.description) throw new Error('Missing description field');

  const createUnitDto: CreateUnitDTO = {
    customer_id: customerId,
    description: unit.description,
    organizational_unit_id: unit.id,
  };

  return createUnitDto;
};
