import styled from 'styled-components';

export const StyledMobileMenu = styled.div`
  position: fixed;
  top: var(--header-height);
  background: ${({ theme }) => theme.color.cream};
  padding: 20vh ${({ theme }) => theme.sitePaddings.mobile} 6rem;
  width: 100vw;
  left: 0;
  height: calc(100vh - var(--header-height));
`;
