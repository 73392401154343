/* eslint-disable no-lonely-if */
import { ChangeEvent, ReactElement, useCallback, useState, useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { RegisteredFormItem } from 'globalTypes';
import * as S from './UploadImage.styles';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { Cross } from 'components/atoms/Icon/Icon';
import { Text } from 'components/atoms/Text/Text';
import { IconButton } from 'components/molecules/IconButton/IconButton';

interface UploadProps extends RegisteredFormItem {
  children: ReactElement;
  name?: string;
  displayFileName?: boolean;
  maxFiles?: number;
  defaultImage?: { name: string | undefined; logo: string | undefined };
  onUploadSuccess?: (base64image: string) => void;
  onUploadError?: () => void;
  withFilename?: boolean;
}

type FormImage = { image: string; name: string };

export const UploadImage = ({
  children,
  name,
  displayFileName = true,
  maxFiles = 1,
  onUploadSuccess,
  onUploadError,
  formMethods,
  defaultImage,
  withFilename,
  ...props
}: UploadProps): ReactElement => {
  const inputName = name || 'upload';
  const fileTypes = ['image/jpeg', 'image/pjpeg', 'image/png', 'image/webp'];
  const [files, setFiles] = useState<Array<string>>([]);

  const updateForm = useCallback(
    (name: string, image?: string) => {
      if (formMethods) {
        let images = formMethods.getValues(inputName);
        const newImage = { image, name };

        if (images && images.length > 0 && image) {
          images = [...images, newImage];
        } else if (image) {
          images = [newImage];
        } else if (!image && images) {
          images = images.filter((image: FormImage) => image.name !== name);
        } else {
          images = [];
        }

        if (withFilename) {
          formMethods.setValue(inputName, images[0].image);
          formMethods.setValue(`${inputName}Name`, images[0].name);
        } else {
          let rawImages = images.map((img: { name: string; image?: string }) => img.image);
          rawImages = rawImages.filter((element: string) => element !== undefined);
          const final = rawImages.length === 1 ? rawImages[0] : rawImages;

          formMethods.setValue(inputName, final);
        }
      }
    },
    [formMethods, inputName, withFilename],
  );

  const handleDeleteFile = (fileName: string) => {
    const newFiles = files.filter((file) => file !== fileName);
    setFiles(newFiles);
    formMethods.setValue(inputName, undefined);
  };

  const handleAddFile = useCallback(
    (fileName: string) => {
      setFiles((previousFiles) => (maxFiles === 1 ? [fileName] : [...previousFiles, fileName]));
    },
    [maxFiles],
  );

  const saveCanvasResizeImg = useCallback(
    (fileName: string, dataUrl: string) => {
      updateForm(fileName, dataUrl);
      if (onUploadSuccess) onUploadSuccess(dataUrl);
      if (displayFileName) handleAddFile(fileName);
    },
    [displayFileName, handleAddFile, onUploadSuccess, updateForm],
  );

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target || !event.target.files || !event.target.files[0]) return;
    const file = (event.target.files as FileList)[0];
    const fileName = file.name;
    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      const img = document.createElement('img');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');
        if (!context) return;
        context.drawImage(img, 0, 0);

        const MAX_WIDTH = 300;
        const MAX_HEIGHT = 300;
        let { width, height } = img;
        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        context = canvas.getContext('2d');
        context?.drawImage(img, 0, 0, width, height);
        const dataUrl = canvas.toDataURL('image/png');
        saveCanvasResizeImg(fileName, dataUrl);
      };

      if (loadEvent.target !== null) {
        img.src = loadEvent.target.result as string;
      }
    };

    reader.onerror = () => onUploadError && onUploadError();

    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (!defaultImage) return;

    formMethods.setValue(inputName, defaultImage.logo);
    formMethods.setValue(`${inputName}Name`, defaultImage.name);
    if (defaultImage.name) setFiles([defaultImage.name]);
  }, [defaultImage, formMethods, inputName]);

  return (
    <S.StyledUpload {...props}>
      <S.StyledUploadInner>
        <S.StyledLabel htmlFor={inputName} />
        {children}
        <S.StyledInput
          onChange={handleOnChange}
          type="file"
          id={inputName}
          name={inputName}
          accept={fileTypes.join(', ')}
        />
      </S.StyledUploadInner>
      {files && displayFileName && (
        <ul>
          {files.map((file) => (
            <S.StyledFileContainer key={file}>
              <Flex container gap=".8rem" alignItems="center">
                <Text type="label" color="black">
                  {file}
                </Text>
                <IconButton icon={Cross} onClick={() => handleDeleteFile(file)} />
              </Flex>
            </S.StyledFileContainer>
          ))}
        </ul>
      )}
    </S.StyledUpload>
  );
};
