import styled, { css } from 'styled-components';
import { ColorKey } from 'styles/theme/default';

export interface TabStyleProps {
  isActive?: boolean;
  variant?: 'blue' | 'grey';
}

const inactiveColorVariants = {
  blue: 'blueG',
  grey: 'greyA',
};

const activeColorVariants = {
  blue: 'cream',
  grey: 'blueA',
};

export const StyledTab = styled.button<TabStyleProps>`
  font-size: 1.4rem;
  transition: color 300ms ease;
  position: relative;
  white-space: nowrap;

  &:hover {
    color: ${({ theme, variant }) =>
      theme.color[activeColorVariants[variant || 'blue'] as ColorKey]};
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    transition: background 300ms ease;
    background: currentColor;
  }

  ${({ variant, isActive, theme }) => css`
    color: ${isActive
      ? theme.color[activeColorVariants[variant || 'blue'] as ColorKey]
      : theme.color[inactiveColorVariants[variant || 'blue'] as ColorKey]};
  `}
`;

export const SliderWrapper = styled.div`
  max-width: 100%;
  overflow: hidden;
  padding: 2px 0;
`;

export const StyledTabsContainer = styled.div`
  display: inline-block;
`;
