/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable babel/no-unused-expressions */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-console */
/* eslint-disable import/order */
import { Plus } from 'components/atoms/Icon/Icon';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { TableOptions } from 'components/atoms/Table/Table.types';
import { ConfirmModalContext } from 'components/organisms/ConfirmModal/ConfirmModal';
import { FormModalContext } from 'components/organisms/FormModal/FormModal';
import { ActionResponse } from 'data/enum/ActionResponse';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteElementWithId,
  getElements,
  updateElements,
} from 'redux/ducks/elementsDuck/elementsActions';
import { ElementProps } from 'redux/ducks/elementsDuck/elementsReducer';
import { setErrorEvent } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { RootState } from 'redux/store';
import { Path } from 'routes/Path';
import { editModalNameEmailProps } from 'util/editModalNameEmailProps';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import { ManagementTable } from '../../components/ManagementTable/ManagementTable';
import { tableData } from './ElementsManagement.data';
import { escapeRegExp, values } from 'lodash';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ElementsManagementProps {}
// eslint-disable-next-line no-empty-pattern
export const ElementsManagement = ({}: ElementsManagementProps): ReactElement => {
  const { useConfirmModal } = useContext(ConfirmModalContext);
  const { useFormModal } = useContext(FormModalContext);
  const { elements } = useSelector((state: RootState) => state.elements);
  const dispatch = useDispatch();

  const [filterQuery, setFilterQuery] = useState<string>();
  const [formattedTableData, setFormattedTableData] = useState<TableOptions>();

  /**
   * Create and update the data used to populate the table.
   */
  const createFormattedTableData = (data: Array<ElementProps>) => {
    const rows = data.map((item, index) => {
      const date = item.created_at && item.created_at.substring(0, 10);

      return [
        { copy: index + 1 },
        { copy: item.name },
        { copy: item.description },
        { copy: date },
        { data: item.id },
      ];
    });

    setFormattedTableData({ ...tableData, rows } as TableOptions);
  };

  /**
   * Filter and update table data elements by their name and description based on a filter string.
   * */
  const filterTableData = (data: Array<ElementProps>) => {
    const regex = new RegExp(`(${escapeRegExp(filterQuery?.toLowerCase())})`);

    const filtered = values(data).filter(
      (item) => regex.test(item.name.toLowerCase()) || regex.test(item.description.toLowerCase()),
    );

    createFormattedTableData(filtered);
  };

  const Delete = async (rowIndex: number) => {
    const rowData = formattedTableData && formattedTableData.rows[rowIndex];

    if (rowData) {
      const id = Number(rowData[rowData.length - 1].data);
      const confirmed = await useConfirmModal({
        title: 'Delete',
        subtitle: rowData[2].copy,
        description: 'Are you sure you want to delete this element?',
      });

      if (!confirmed) return;
      dispatch(deleteElementWithId(id));
    }
  };

  const Edit = async (rowIndex: number) => {
    const rowData = formattedTableData && formattedTableData.rows[rowIndex];

    if (rowData) {
      const name = rowData[1].copy as string;
      const email = rowData[2].copy as string;
      const formModalProps = editModalNameEmailProps(name, email, undefined, {
        registerFormItemProps: {
          label: 'Description',
          name: 'description',
        },
        itemProps: {
          maxLength: 400,
        },
      });

      const response = (await useFormModal(formModalProps)) as {
        name: string;
        description: string;
      };

      if (!response) return;

      const id = Number(rowData[rowData.length - 1].data);
      const copyData = {
        ...response,
        allow_no_answer: false,
        set_as_open: true,
        element_type_id: 1,
      };

      const test = await dispatch(updateElements(id, copyData));
      if (test.toString() === ActionResponse.ERROR) {
        dispatch(setErrorEvent(Events.FORM_REQUEST_ERROR));
      }

      dispatch(getElements());
    }
  };

  useEffect(() => {
    dispatch(getElements());
  }, [dispatch]);

  useEffect(() => {
    if (filterQuery === undefined) {
      createFormattedTableData(elements || []);
    } else {
      filterTableData(elements || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements, filterQuery]);

  return (
    <>
      <AdminHeading
        title="Elements Management"
        subtitle="Create and manage elements"
        backButton={{
          title: 'Back to Management Tool',
          href: Path.ManagementTool,
        }}
      >
        <PrimaryButton href={Path.CreateElement} $size="regular">
          Create New Element <Plus />
        </PrimaryButton>
      </AdminHeading>

      {formattedTableData && (
        <ManagementTable
          data={formattedTableData as TableOptions}
          actions={{ Delete, Edit }}
          setFilterQuery={setFilterQuery}
          /*
          This will disable action buttons temporarily while some
          survey issues related to this actions are being fixed.
          */
          // @NOTE(gb) excuse me?
          // shouldDisableItem={() => true}
        />
      )}
    </>
  );
};
