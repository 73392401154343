import { ReactElement } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import * as S from './CreateElement.styles';
import { TextArea } from 'components/atoms/TextArea/TextArea';
import {
  RegisterErrorProp,
  RegisterFormItem,
} from 'components/molecules/RegisterFormItem/RegisterFormItem';
import { FormItemSizes } from 'components/atoms/FormItemContainer/FormItemContainer.styles';
import { StickyBar } from 'components/atoms/StickyBar/StickyBar';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { SecondaryButton } from 'components/atoms/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { BackgroundCircle } from 'components/atoms/BackgroundCircle/BackgroundCircle';
import { MediaQuery } from 'styles/mediaQuery';
import { Input } from 'components/atoms/Input/Input';
import { postElements } from 'redux/ducks/elementsDuck/elementsActions';
import { Path } from 'routes/Path';
import { elementCreationSchema } from 'components/molecules/Form/FormSchemas';
import { setSuccessEvent } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CreateElementProps {}

// eslint-disable-next-line no-empty-pattern
export const CreateElement = ({}: CreateElementProps): ReactElement => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const registerErrors: Record<string, RegisterErrorProp> = {
    name: [
      'required',
      {
        type: 'maxLength',
        key: 'customMaxLength',
        customValue: 50,
      },
    ],
    description: [
      'required',
      {
        type: 'maxLength',
        key: 'customMaxLength',
        customValue: 400,
      },
    ],
  };

  const onPostSuccess = () => {
    dispatch(setSuccessEvent(Events.FORM_SUCCESS));
    push(Path.ElementsManagement);
  };

  const setElementBody = (values: FieldValues) => {
    const { name, description } = values;
    const elementBody = {
      name,
      description,
      allow_no_answer: false,
      set_as_open: true,
      element_type_id: 1,
    };

    dispatch(postElements(elementBody, onPostSuccess));
  };

  const handleSubmit = async (values: FieldValues, methods: UseFormReturn<FieldValues>) => {
    methods.reset();
    setElementBody(values);
  };

  const handleCancel = () => {
    push(Path.ElementsManagement);
  };

  return (
    <S.StyledCreateElement className="formLeftPaddingDifference">
      <BackgroundCircle
        lastBackground
        top={['40%', [MediaQuery.MIN_768, '34.6rem']]}
        left={['-40.5rem', [MediaQuery.MIN_768, '-58.5rem']]}
        size={['78.6rem', [MediaQuery.MIN_768, '113.8rem']]}
      />

      <AdminHeading
        title="Create a new element"
        $color="blueA"
        $underline
        breadcrumbs={[
          { title: 'Elements Management', href: '/admin/elements-management' },
          { title: 'create element' },
        ]}
      />

      <S.StyledForm onSuccess={handleSubmit} schema={elementCreationSchema}>
        <RegisterFormItem
          register
          registerErrors={registerErrors.name}
          name="name"
          label="Element name"
          size={FormItemSizes.Medium}
          alignError="left"
        >
          <Input hasRef placeholder="Input a name for the element" />
        </RegisterFormItem>
        <div>
          <RegisterFormItem
            alignError="left"
            register
            registerErrors={registerErrors.description}
            name="description"
            label="Description"
            size={FormItemSizes.Medium}
          >
            <TextArea hasRef maxLength={400} />
          </RegisterFormItem>
        </div>

        <StickyBar>
          <Flex container gap="3rem" alignItems="center" justifyContent="flex-end">
            <SecondaryButton type="button" color="greyA" onClick={handleCancel}>
              <span>Cancel</span>
            </SecondaryButton>
            <PrimaryButton type="submit" $size="regular">
              Create Element
            </PrimaryButton>
          </Flex>
        </StickyBar>
      </S.StyledForm>
    </S.StyledCreateElement>
  );
};
