import { ApiClient } from '../util/apiClient';
import { Superadmin } from 'services/types/SuperadminTypes';

enum SuperadminEndpoints {
  SuperAdmins = '/super-admins',
}

const getSuperadmins = async (): Promise<Array<Superadmin>> => {
  const { data } = await ApiClient.get(SuperadminEndpoints.SuperAdmins);

  return data;
};

const deleteSuperadmin = async (adminId: number): Promise<Array<Superadmin>> => {
  const { data } = await ApiClient.delete(`${SuperadminEndpoints.SuperAdmins}/${adminId}`);

  return data;
};

const editSuperadmin = async (adminUser: Superadmin): Promise<Superadmin> => {
  const { id, name, email } = adminUser;
  const body = { name, email };
  const { data } = await ApiClient.put(`${SuperadminEndpoints.SuperAdmins}/${id}`, body);

  return data;
};

const createSuperadmin = async (adminUserData: {
  name: string;
  email: string;
}): Promise<Superadmin> => {
  const { data } = await ApiClient.post(SuperadminEndpoints.SuperAdmins, adminUserData);
  return data;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const SuperadminService = {
  createSuperadmin,
  getSuperadmins,
  editSuperadmin,
  deleteSuperadmin,
};
