/* eslint-disable no-nested-ternary */
import type { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router';
import * as S from './AssessmentCompleted.styles';
import { HeadingType } from 'components/atoms/Heading/Heading.data';
import { Text } from 'components/atoms/Text/Text';
import { HeaderColors, HeaderTypes } from 'components/molecules/Header/Header.styles';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AssessmentCompletedProps {}

export const AssessmentCompleted = ({ ...props }: AssessmentCompletedProps): ReactElement => {
  const { search, state }: { search: string; state: { name: string } } = useLocation();
  const name: string | undefined = state?.name;

  const isAssessor = new URLSearchParams(search).get('assessor');

  return (
    <S.StyledLayout headerType={HeaderTypes.Logo} headerColorVariant={HeaderColors.White}>
      <S.Background />
      <S.StyledFlex container justifyContent="center" alignItems="center" flexBasis="1" {...props}>
        <S.ContentWrapper>
          <S.StyledHeading color="white" type={HeadingType.H2} margin="0 0 3.4rem">
            {isAssessor ? (
              'Thank you for providing feedback!'
            ) : (
              <FormattedMessage
                description="Assessment Completed - Title"
                defaultMessage="You have completed your assessment!"
              />
            )}
          </S.StyledHeading>

          <S.TextWrapper>
            <S.BoldText color="white" type="bold">
              {isAssessor ? (
                <FormattedMessage
                  description="Assessment Completed - Subtitle"
                  defaultMessage="The 360° Mirror is done."
                />
              ) : (
                <FormattedMessage
                  description="Assessment Completed - Subtitle"
                  defaultMessage="You are done with the first part of your 360° assesment."
                />
              )}
            </S.BoldText>
            <Text type="body" color="white">
              {isAssessor ? (
                name ? (
                  <FormattedMessage
                    description="Assessment Completed - Paragraph"
                    defaultMessage="Your feedback will help {name} better understand their strengths and areas for growth."
                    values={{ name }}
                  />
                ) : (
                  <FormattedMessage
                    description="Assessment Completed - Paragraph"
                    defaultMessage="Your feedback will help me to determine my strengths and learning areas as I reflect on my leadership skills. I am grateful for your input!"
                  />
                )
              ) : (
                <FormattedMessage
                  description="Assessment Completed - Paragraph"
                  defaultMessage="Next step is to wait for feedback from your assessors before setting objectives and completing missions."
                />
              )}
            </Text>
          </S.TextWrapper>
        </S.ContentWrapper>
      </S.StyledFlex>
    </S.StyledLayout>
  );
};
