import styled from 'styled-components';
import { Ease } from 'styles/easing';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { TransitionDuration } from 'styles/variables';
import { StickyBar } from 'components/atoms/StickyBar/StickyBar';

export const StyledStickyBar = styled(StickyBar)<{
  isHeaderActive?: boolean;
  isStickyBarActive?: boolean;
}>`
  display: flex;
  transition: transform ${TransitionDuration.Slow} ${Ease.EaseOutQuad};
  transform: translateY(-100%);

  @media ${respondTo(MediaQuery.MIN_1024)} {
    justify-content: flex-end;
  }

  ${({ isStickyBarActive }) =>
    isStickyBarActive &&
    `
    transform: translateY(0);
  `}

  ${({ isHeaderActive, isStickyBarActive }) =>
    isHeaderActive &&
    isStickyBarActive &&
    `
    transform: translateY(var(--header-height));
  `}
`;
