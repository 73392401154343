import type { ReactElement } from 'react';
import * as S from './Image.styles';
import { MediaQuery } from '../../../styles/mediaQuery';

type AspectRatio = [number, number];

interface ImageSource {
  media: MediaQuery;
  srcset: string;
  aspectRatio?: AspectRatio;
}

const FALLBACK_SOURCE: ReadonlyArray<ImageSource> = [];

export interface ImageProps {
  alt?: string;
  src: string;
  className?: string;
  preload?: boolean;
  fit?: 'cover' | 'contain';
  objectPosition?: string;
  borderRadius?: string;
  source?: ReadonlyArray<ImageSource>;
  aspectRatio?: AspectRatio;
}

export const Image = ({
  alt,
  src,
  aspectRatio,
  source = FALLBACK_SOURCE,
  fit,
  objectPosition,
  preload = false,
  borderRadius,
  className,
}: ImageProps): ReactElement => {
  return (
    <S.StyledImage className={className} source={source} $aspectRatio={aspectRatio} $fit={fit}>
      <S.StyledPicture $aspectRatio={aspectRatio}>
        {source.map(({ media, srcset }) => (
          <source key={srcset} media={media} data-srcset={srcset} />
        ))}

        <S.StyledImageElement
          $fit={fit}
          $objectPosition={objectPosition}
          $borderRadius={borderRadius}
          data-srcset={src}
          className={preload ? 'lazyload lazypreload' : 'lazyload'}
          alt={alt}
        />
      </S.StyledPicture>
    </S.StyledImage>
  );
};
