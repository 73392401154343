import styled from 'styled-components';
import { size } from 'styles/helpers/size';
import { Ease } from 'styles/easing';

export const EditButton = styled.button`
  ${size('5.3rem')};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.redD};
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  transition: transform 0.3s ${Ease.EaseBackExpo};
  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const EditInput = styled.input`
  visibility: hidden;
  width: 0;
`;
