import { OrganizationUnit } from 'model/OrganizationUnit';
import { OrganizationSubunit } from 'model/OrganizationSubunit';
import { Size } from 'model/Customer';

type baseType = {
  id: number;
  description: string;
};

export type Profile = {
  img: string;
  participant_id: number;
  company: string;
  country: baseType;
  email: string;
  function: baseType;
  gender: baseType;
  industry: baseType;
  name: string;
  birth_date: string;
  nationality: baseType;
  organization_size: Size;
  organizational_unit: OrganizationUnit;
  organizational_subunit: OrganizationSubunit;
  organizational_type: baseType;
  other: string;
};

export type ProfilePutBody = {
  participant_id: number;
  name: string;
  email: string;
  company: string;
  gender_id: number;
  other: string;
  nationality_id: number;
  country_id: number;
};

export const initialProfile: Profile = {
  img: '',
  participant_id: 0,
  company: '',
  country: { id: 0, description: '' },
  email: '',
  function: { id: 0, description: '' },
  gender: { id: 0, description: '' },
  industry: { id: 0, description: '' },
  name: '',
  birth_date: '',
  nationality: { id: 0, description: '' },
  organization_size: Size.From1001To10000,
  organizational_unit: new OrganizationUnit(),
  organizational_subunit: new OrganizationSubunit(),
  organizational_type: { id: 0, description: '' },
  other: '',
};
