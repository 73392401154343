import { ReactElement, useEffect, useState } from 'react';
import { ProfileHero } from './sections/ProfileHero/ProfileHero';
import { NormalLayout } from 'components/organisms/NormalLayout/NormalLayout';
import { Loader } from 'components/atoms/Loading/Loading.styles';
import { ProfileService } from 'services/ProfileService';
import { Profile as ProfileType, initialProfile } from 'services/types/ProfileTypes';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ProfileProps {}

export const Profile = ({ ...props }: ProfileProps): ReactElement => {
  const [profile, setProfile] = useState<ProfileType>(initialProfile);
  // const [objectives, setObjectives] = useState<Array<ObjectiveInfoType>>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      // const [profile, objectives] = await Promise.all([
      //   ProfileService.getProfile(),
      //   ObjectiveService.getObjectives(),
      // ]);
      const profile = await ProfileService.getProfile();

      setProfile(profile);
      // setObjectives(objectives);

      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) return <Loader />;

  return (
    <NormalLayout padding="normal" {...props}>
      <ProfileHero profile={profile} setProfile={setProfile} />
      {/* TODO: Hidden for MVP */}
      {/* <Flex container gap="2rem">
        <Cards objectives={objectives} setObjectives={setObjectives} />
        {objectives.length === 0 && <NoResults title="No Mission Started yet" />}
      </Flex> */}
      {/*
      <Achievements />
      */}
    </NormalLayout>
  );
};
