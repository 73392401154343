import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { respondTo } from 'styles/helpers/respondTo';
import { MediaQuery } from 'styles/mediaQuery';
import { ColorKey } from 'styles/theme/default';

export type AdminHeadingProps = {
  $color?: ColorKey;
  $underline?: boolean;
  $size?: 'small' | 'regular';
  $marginBottom?: string;
};

export const StyledAdminHeading = styled.div<AdminHeadingProps>`
  padding-top: 4.4rem;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || '5.4rem'};
  ${({ $color, theme }) => $color && `color:${theme.color[$color]};`};
  ${({ $underline, theme }) => $underline && `border-bottom: solid 1px ${theme.color.greyE};`};

  & > * {
    ${({ $size }) =>
      $size === 'small' &&
      css`
        @media ${respondTo(MediaQuery.MIN_768)} {
          font-size: 4rem;
        }
      `}
  }
`;

export const StyledBackButton = styled(Link)`
  text-transform: uppercase;
  display: flex;
  gap: 1rem;
  font-size: 1.4rem;
  line-height: 1.7;
  align-items: center;
  color: ${({ theme }) => theme.color.greyA};
  position: relative;
`;
