/* eslint-disable import/no-extraneous-dependencies */
import type { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { MessageDescriptor } from '@formatjs/intl';
import * as S from './ElementSectionRightItem.styles';
import { ElementTitleItem } from '../ElementTitleItem/ElementTitleItem';
import { Paragraph } from 'components/atoms/Paragraph/Paragraph';

interface ElementSectionRightItemProps {
  defaultActive: boolean;
  title: MessageDescriptor;
  subtitle: MessageDescriptor;
  paragraph: MessageDescriptor;
  className?: string;
}

export const ElementSectionRightItem = ({
  defaultActive,
  title,
  subtitle,
  paragraph,
  ...props
}: ElementSectionRightItemProps): ReactElement => {
  const intl = useIntl();

  return (
    <S.StyledElementSectionRightItem {...props}>
      {intl.formatMessage(title, {
        title: (data) => (
          <ElementTitleItem style={{ opacity: defaultActive ? 1 : 0.2 }}>{data}</ElementTitleItem>
        ),
      })}
      <S.StyledElementsContent style={{ opacity: defaultActive ? 1 : 0 }}>
        {intl.formatMessage(paragraph, {
          paragraph: (data) => (
            <Paragraph
              color="black"
              dangerouslySetInnerHTML={{ __html: data as unknown as string }}
            />
          ),
        })}
      </S.StyledElementsContent>
    </S.StyledElementSectionRightItem>
  );
};
