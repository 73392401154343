/* eslint-disable @typescript-eslint/naming-convention */
import { OrganizationUnit } from './OrganizationUnit';
import { Participant } from './Participant';

type baseType = {
  id: number;
  description: string;
};

export enum BusinessType {
  B2B = 'B2B',
  B2C = 'B2C',
}

export type ClientFunction = {
  id: number;
  title: string;
};

export type ClientIndustry = {
  id: number;
  title: string;
};

export type OrganizationalType = {
  id: number;
  title: string;
};

export enum Size {
  LessThan50 = 0,
  From51To250 = 1,
  From251To1000 = 2,
  From1001To10000 = 3,
  MoreThan10000 = 4,
}

export class Customer {
  public id?: number;
  public B2B_B2C?: number;
  public name?: string;
  public organization_size?: Size;
  public logo?: string;
  public industry?: baseType;
  public industry_id?: number;
  public function?: baseType;
  public function_id?: number;
  public organizational_type?: baseType;
  public organizational_type_id?: number;
  public organizationUnits: Array<OrganizationUnit> = [];
  public organizational_units: Array<OrganizationUnit> = [];
  public participants: Array<Participant> = [];
  public createdAt?: Date;

  public addUnit(unit: OrganizationUnit): void {
    if (unit.id !== undefined) {
      this.organizationUnits.push(unit);
    } else {
      const newUnit = unit;
      newUnit.id = this.organizationUnits.length;
      this.organizationUnits.push(newUnit);
    }
  }

  public removeUnit(unitToDelete: OrganizationUnit): void {
    this.organizationUnits = this.organizationUnits.filter((unit) => unit !== unitToDelete);
  }

  public getUnitById(unitId: number): OrganizationUnit {
    const unit = this.organizationUnits.find((unit) => unit.id === unitId);
    if (!unit) throw new Error('Unit given id not found');

    return unit;
  }

  public getUnitIndex(unitId: number): OrganizationUnit {
    const unit = this.organizationUnits.find((unit, index) => {
      if (unit.id === unitId) return index;
      return null;
    });
    if (!unit) throw new Error('Unit given id not found');

    return unit;
  }

  public getUnitByIndex(index: number): OrganizationUnit {
    return this.organizationUnits[index];
  }

  public removeUnitById(unitId: number): void {
    this.organizationUnits = this.organizationUnits.filter((unit) => unit.id !== unitId);
  }

  public clone(): Customer {
    return Object.assign(new Customer(), this);
  }
}
