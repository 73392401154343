// eslint-disable-next-line import/no-unresolved
import { TabsProps } from 'globalTypes';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { PillTabs } from 'components/atoms/PillTabs/PillTabs';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { AssessmentService } from 'services/AssessmentService';
import { DownloadButton } from 'components/molecules/DownloadButton/DownloadButtons';
import { RootState } from 'redux/store';
import {
  selectAreEnoughAssessors,
  selectCurrentParticipant,
} from 'redux/ducks/dashboardDuck/dashboardReducer';

interface StatsHeaderProps extends Omit<TabsProps, 'tabs'> {
  isUserDashboard?: boolean;
  tabs?: Array<string>;
}

export const StatsHeader = ({
  tabs,
  isUserDashboard,
  active = 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActive = () => {},
}: StatsHeaderProps): ReactElement => {
  const { participant, areEnoughAssessors } = useSelector((state: RootState) => ({
    participant: selectCurrentParticipant(state.dashboard),
    areEnoughAssessors: selectAreEnoughAssessors(state.dashboard),
  }));

  const handleDownloadCsv = async () => {
    const downloadUrl = await AssessmentService.getCsvDownloadUrl(participant?.assessment?.id || 0);
    window.open(downloadUrl)?.focus();
  };

  const handleDownloadPdf = async () => {
    const downloadUrl = await AssessmentService.getPdfDownloadUrl(participant?.assessment?.id || 0);
    window.open(downloadUrl)?.focus();
  };

  return (
    <>
      {isUserDashboard && tabs && tabs.length > 0 && (
        <PillTabs tabs={tabs} active={active} setActive={setActive} />
      )}

      {areEnoughAssessors && isUserDashboard && (
        <Flex container flexWrap="wrap" gap="1rem 3rem">
          <DownloadButton onClick={handleDownloadPdf}>
            <FormattedMessage
              defaultMessage="Download PDF"
              description="User Dashboard - Assessment - Download PDF"
            />
          </DownloadButton>

          <DownloadButton onClick={handleDownloadCsv}>Download CSV</DownloadButton>
        </Flex>
      )}
    </>
  );
};
