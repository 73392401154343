import { memo, ReactElement } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import * as S from './EmailForm.styles';
import { ColorKey } from 'styles/theme/default';
import { Input } from 'components/atoms/Input/Input';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { RegisterFormItem } from 'components/molecules/RegisterFormItem/RegisterFormItem';

interface EmailFormProps {
  secondaryColor: ColorKey;
  invitelist: Array<{ email: string; name: string }>;
  handleFormSuccess?: (formData: FieldValues, methods: UseFormReturn<FieldValues>) => Promise<void>;
}

export const EmailForm = memo(
  ({ handleFormSuccess, secondaryColor, invitelist, ...props }: EmailFormProps): ReactElement => {
    return (
      <S.StyledFormContainer onSuccess={handleFormSuccess} autoComplete="off" {...props}>
        <RegisterFormItem
          register
          registerErrors="required"
          alignError="left"
          name="guest.name"
          label="Full Name"
          color={secondaryColor}
          size="Small"
        >
          <Input
            hasRef
            name="guest.name"
            placeholder="Enter name"
            backgroundColor="cream"
            autoComplete="off"
          />
        </RegisterFormItem>
        <RegisterFormItem
          register
          registerErrors={['required', { type: 'pattern', key: 'emailPattern' }]}
          alignError="left"
          name="guest.email"
          label="Email address"
          color={secondaryColor}
          size="Small"
        >
          <Input
            hasRef
            name="guest.email"
            type="email"
            placeholder="Enter email"
            backgroundColor="cream"
            autoComplete="off"
          />
        </RegisterFormItem>
        <RegisterFormItem
          register
          registerErrors={[
            {
              type: 'validate',
              value: (confirmEmailValue: string, methods: UseFormReturn<FieldValues>) => {
                const emailValue = methods.getValues('guest.email');

                if (emailValue !== confirmEmailValue) {
                  return 'The Emails doesn’t match.';
                }

                if (invitelist.some((guest) => guest.email === confirmEmailValue)) {
                  return 'Email already in list.';
                }

                return true;
              },
            },
          ]}
          alignError="left"
          name="guest.confirm-email"
          label="Confirm email address"
          color={secondaryColor}
          size="Small"
        >
          <Input
            hasRef
            name="guest.confirm-email"
            type="email"
            placeholder="Confirm email"
            backgroundColor="cream"
            autoComplete="off"
          />
        </RegisterFormItem>
        <PrimaryButton $outline $color={secondaryColor} $size="regular" type="submit">
          Add
        </PrimaryButton>
      </S.StyledFormContainer>
    );
  },
);
