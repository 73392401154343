import { ApiClient } from '../util/apiClient';

import { Cohort } from 'services/types/DashboardTypes';

enum DashboardEndpoints {
  CohortParticipant = 'cohorts-participants',
}

const getDashboard = async (): Promise<Array<Cohort>> => {
  const { data } = await ApiClient.get(`${DashboardEndpoints.CohortParticipant}`);
  return data as Array<Cohort>;
};

export const DashboardService = { getDashboard };
