import { ForwardedRef, forwardRef, FunctionComponent, SVGProps } from 'react';
import type { ReactElement } from 'react';
import { IconButtonStyleProps } from './IconButton.styles';
import * as S from './IconButton.styles';
import { AbstractButtonProps } from 'components/atoms/AbstractButton/AbstractButton';

export interface IconButtonProps extends IconButtonStyleProps, AbstractButtonProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  center?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
}

export const IconButton = forwardRef(
  ({ icon: Icon, center, ...props }: IconButtonProps, ref): ReactElement => {
    return (
      <S.StyledIconButton
        {...props}
        $center={center}
        type="button"
        ref={ref as ForwardedRef<HTMLButtonElement>}
      >
        <S.StyledIcon>
          <Icon />
        </S.StyledIcon>
      </S.StyledIconButton>
    );
  },
);
