import { createContext, ReactElement, ReactNode, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../Modal/Modal';
import * as S from './ShareModal.styles';
import { useModal } from 'hooks/useModal';
import { ModalHeading } from 'components/molecules/ModalHeading/ModalHeading';
import { FormSticky } from 'components/molecules/FormSticky/FormSticky';

interface ShareModalProps {
  children: ReactNode;
}

export interface ModalData {
  title: string;
  content: string;
}

interface ShareModalContextProps {
  useShareModal: (data: ModalData) => Promise<unknown>;
}

export const ShareModalContext = createContext({} as ShareModalContextProps);

export const ShareModal = ({ children }: ShareModalProps): ReactElement => {
  const { data, setData, handleCancel, resolver, setModal } = useModal<ModalData>();

  const handleConfirm = () => {
    // eslint-disable-next-line babel/no-unused-expressions
    resolver.current && resolver.current(true);
    setData(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const modalContext = useMemo(() => ({ useShareModal: setModal }), []);

  return (
    <>
      <AnimatePresence>
        {data && (
          <Modal $size="small" onClose={handleCancel}>
            <ModalHeading title={data.title} />
            <S.StyledShareContainer>{data.content}</S.StyledShareContainer>
            <S.StyledText>
              <FormattedMessage
                description="Modal - Share Modal - Copy"
                defaultMessage="The link above is a unique link for this assessor to give you feedback. While the system cannot send another automated email to this person, you can make a copy of this link and share it with them via email, text message, or any other method you choose."
              />
            </S.StyledText>
            <FormSticky
              inModal
              noCancelButton
              handleConfirm={handleConfirm}
              confirmCopy="Copy invite link"
            />
          </Modal>
        )}
      </AnimatePresence>
      <ShareModalContext.Provider value={modalContext}>{children}</ShareModalContext.Provider>
    </>
  );
};
