// eslint-disable-next-line no-shadow
export enum Ease {
  EaseLinear = 'linear',
  Ease = 'ease',
  EaseIn = 'ease-in',
  EaseOut = 'ease-out',
  EaseInOut = 'ease-in-out',
  EaseInQuad = 'cubic-bezier(0.550, 0.085, 0.680, 0.530)',
  EaseOutQuad = 'cubic-bezier(0.250, 0.460, 0.450, 0.940)',
  EaseInCubic = 'cubic-bezier(0.550, 0.055, 0.675, 0.190)',
  EaseOutCubic = 'cubic-bezier(0.215, 0.610, 0.355, 1.000)',
  EaseInQuart = 'cubic-bezier(0.895, 0.030, 0.685, 0.220)',
  EaseOutQuart = 'cubic-bezier(0.165, 0.840, 0.440, 1.000)',
  EaseInQuint = 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
  EaseOutQuint = 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
  EaseInSine = 'cubic-bezier(0.470, 0.000, 0.745, 0.715)',
  EaseOutSine = 'cubic-bezier(0.390, 0.575, 0.565, 1.000)',
  EaseInExpo = 'cubic-bezier(0.950, 0.050, 0.795, 0.035)',
  EaseOutExpo = 'cubic-bezier(0.190, 1.000, 0.220, 1.000)',
  EaseBackExpo = 'cubic-bezier(0.190, 1.000, 0.220, 1.375)',
  EaseInCirc = 'cubic-bezier(0.600, 0.040, 0.980, 0.335)',
  EaseOutCirc = 'cubic-bezier(0.075, 0.820, 0.165, 1.000)',
  EaseInBack = 'cubic-bezier(0.600, -0.280, 0.735, 0.045)',
  EaseOutBack = 'cubic-bezier(0.175, 0.885, 0.320, 1.275)',
  EaseInOutQuad = 'cubic-bezier(0.455, 0.030, 0.515, 0.955)',
  EaseInOutCubic = 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
  EaseInOutQuart = 'cubic-bezier(0.770, 0.000, 0.175, 1.000)',
  EaseInOutQuint = 'cubic-bezier(0.860, 0.000, 0.070, 1.000)',
  EaseInOutSine = 'cubic-bezier(0.445, 0.050, 0.550, 0.950)',
  EaseInOutExpo = 'cubic-bezier(1.000, 0.000, 0.000, 1.000)',
  EaseInOutCirc = 'cubic-bezier(0.785, 0.135, 0.150, 0.860)',
  EaseInOutBack = 'cubic-bezier(0.680, -0.550, 0.265, 1.550)',
}
