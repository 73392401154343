import { useEffect, useRef, useState } from 'react';
import { useDeviceState } from './useDeviceState';
import { getScrollPosition } from 'util/getScrollPosition';

interface UseSectionScrollProps {
  disableMobile?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSectionScroll = ({ disableMobile }: UseSectionScrollProps) => {
  const { isMobile } = useDeviceState();
  const sectionRef = useRef<HTMLElement | null>(null);
  const [percentage, setPercentage] = useState(0);

  const getItemScrollPosition = (index: number): number => {
    const sectionNode = sectionRef.current as HTMLElement;
    const { height } = sectionNode.getBoundingClientRect();
    const scrollPosition = getScrollPosition(sectionNode);
    const maxScroll = scrollPosition + height - window.innerHeight - scrollPosition;
    return scrollPosition + (maxScroll / 4) * index + 10;
  };

  useEffect(() => {
    const sectionNode = sectionRef.current as HTMLElement;
    let { height } = sectionNode.getBoundingClientRect();
    let scrollPosition = getScrollPosition(sectionNode);

    const handleScroll = () => {
      if (!isMobile && disableMobile) {
        const windowScroll = window.pageYOffset;
        const endScroll = scrollPosition + height - window.innerHeight;

        if (windowScroll < endScroll && windowScroll > scrollPosition) {
          const scroll = windowScroll - scrollPosition;
          const maxScroll = endScroll - scrollPosition;

          setPercentage((100 * scroll) / maxScroll);
        }
      }
    };

    const handleResize = () => {
      if (disableMobile && isMobile) {
        setPercentage(Math.floor(percentage / 25) * 25);
      } else {
        height = sectionNode.getBoundingClientRect().height;
        scrollPosition = getScrollPosition(sectionNode);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return {
    setPercentage,
    sectionRef,
    percentage,
    getItemScrollPosition,
  };
};
