import { ReactElement } from 'react';
import { AnimatePresence, MotionProps } from 'framer-motion';
import * as S from './WrittenScore.styles';
import {
  PaginationButtons,
  PaginationButtonsProps,
} from 'components/molecules/PaginationButtons/PaginationButtons';

interface WrittenScoreProps {
  questionContent: string;
  paginationButtonProps: PaginationButtonsProps;
  paginationMotionProps: MotionProps;
  amountOfQuestions: number;
}

export const WrittenScore = ({
  questionContent,
  paginationButtonProps,
  paginationMotionProps,
  amountOfQuestions,
}: WrittenScoreProps): ReactElement => {
  if (amountOfQuestions === 0)
    return (
      <S.StyledWrittenScore>
        <S.StyledParagraph color="cream">
          There are still some assessors that haven’t completed the assessment. You can always send
          them a friendly reminder.
        </S.StyledParagraph>
      </S.StyledWrittenScore>
    );

  return (
    <S.StyledWrittenScore>
      <S.StyledHeader>
        <S.StyledTitle>Assessor&#39;s feedback</S.StyledTitle>
        <S.StyledCommentsContainer>
          <S.StyledSubTitle>COMMENTS</S.StyledSubTitle>
          <S.StyledLabel>{amountOfQuestions || 1} comments</S.StyledLabel>
        </S.StyledCommentsContainer>
      </S.StyledHeader>
      <S.StyledBody>
        <AnimatePresence exitBeforeEnter>
          <S.StyledTextWrapper {...paginationMotionProps} key={questionContent}>
            <S.StyledParagraph color="cream">{questionContent}</S.StyledParagraph>
          </S.StyledTextWrapper>
        </AnimatePresence>
        <PaginationButtons {...(paginationButtonProps as PaginationButtonsProps)} color="white" />
      </S.StyledBody>
    </S.StyledWrittenScore>
  );
};
