import type { TableOptions } from 'components/atoms/Table/Table.types';

export const tableData: TableOptions = {
  head: ['', 'No.', 'Names', 'Description', 'Creation Date', 'Open', ' '],
  controls: [{ type: 'Delete', action: 'Delete' }],
  columnOptions: {
    0: { width: '0%' },
    1: { width: '6%' },
    2: { width: '20%' },
    3: { width: '30%' },
    4: { width: '25%' },
    5: { width: '4rem' },
  },
  rows: [],
};
