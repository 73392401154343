import styled from 'styled-components';

export const StyledTitle = styled.label`
  display: block;
  font-weight: 400;
  font-size: 3.4rem;
`;

export const StyledSubTitle = styled.label`
  display: block;
  font-weight: 400;
  font-size: 3.2rem;
  color: ${({ theme }) => theme.color.blackB};
  margin: 0.8rem 0 1.8rem;
`;

export const StyledHighlight = styled.label`
  display: block;
  margin: 1.8rem 0;
  font-weight: 400;
  font-size: 2.4rem;
`;

export const StyledParagraph = styled.p`
  margin-bottom: 2.4rem;
  color: ${({ theme }) => theme.color.blackB};
`;

export const StyledLink = styled.a`
  display: inline;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.blueA};

  &:hover {
    color: ${({ theme }) => theme.color.blueB};
  }
`;

export const StyledUnorderedList = styled.ul`
  list-style-type: disc;
  list-style-position: outside;
  margin-top: -0.8rem;
  padding-left: 4rem;
  padding-bottom: 1.2rem;
`;

export const StyledParagraphItem = styled.li`
  margin: 1rem 0;
  color: ${({ theme }) => theme.color.blackB};
`;
