import styled, { css } from 'styled-components';
import { Responsive, responsive } from 'styles/helpers/responsive';
import { ColorKey } from 'styles/theme/default';

interface SeparatorStyleProps {
  color?: ColorKey;
  margin?: Responsive;
  isCreateQuestion?: boolean;
}

export const Separator = styled.div<SeparatorStyleProps>`
  width: 100%;
  ${({ isCreateQuestion }) => (isCreateQuestion ? ` height: 0; ` : `height: 1px;`)}

  ${({ color, theme }) =>
    color
      ? css`
          background: ${theme.color[color]};
        `
      : css`
          background: ${theme.color.greyD};
        `}

  ${({ margin }) => margin && responsive('margin', margin)}
`;
