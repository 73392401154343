import type { ModalData } from 'components/organisms/FormModal/FormModal';

export const editModalQuestionProps = (name: string, description: string): ModalData => ({
  title: 'Edit',
  confirmCopy: 'Submit',
  items: [
    {
      type: 'input',
      registerFormItemProps: {
        name: 'name',
        label: 'Question Name',
        color: 'greyA',
        registerErrors: [
          'required',
          {
            type: 'maxLength',
            key: 'customMaxLength',
            customValue: 500,
          },
        ],
      },
      itemProps: {
        defaultValue: name,
        hasRef: true,
        placeholder: 'Add your name',
      },
    },
    {
      type: 'textarea',
      registerFormItemProps: {
        name: 'description',
        label: 'Question Description',
        color: 'greyA',
        registerErrors: [
          'required',
          {
            type: 'maxLength',
            key: 'customMaxLength',
            customValue: 500,
          },
        ],
      },
      itemProps: {
        defaultValue: description,
        hasRef: true,
        placeholder: 'Input question description',
      },
    },
  ],
});
