import { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

export type ErrorHandlerProps = {
  error: Error;
};

type ErrorBoundaryProps = RouteComponentProps & {
  handler: (props: ErrorHandlerProps) => ReactNode;
};

type ErrorBoundaryState = {
  error?: Error;
};

class UnwrappedErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private readonly disposeListen: () => void;

  public constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      error: undefined,
    };

    this.disposeListen = props.history.listen(this.handleLocationChange.bind(this));
  }

  public componentWillUnmount() {
    this.disposeListen();
  }

  private handleLocationChange() {
    const { error } = this.state;

    if (error == null) {
      return;
    }

    this.setState({ error: undefined });
  }

  public static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public render() {
    const { error } = this.state;
    const { children, handler } = this.props;

    if (error) {
      return handler({ error });
    }

    return children;
  }
}

export const ErrorBoundary = withRouter(UnwrappedErrorBoundary);
