import { ReactElement } from 'react';
import * as S from './Elements.styles';
import { ElementsRightSide } from './ElementsRightSide/ElementsRightSide';
import elementsImage from 'assets/img/onboarding/es-1.jpg';

export const Elements = (): ReactElement => {
  return (
    <S.StyledOnboardingElementsSection>
      <S.StyledLeftSide>
        <S.StyledRedCircle />
        <S.StyledImageCircle>
          <S.StyledImage src={elementsImage} />
        </S.StyledImageCircle>
        <S.StyledRedCircle />
      </S.StyledLeftSide>
      <ElementsRightSide />
    </S.StyledOnboardingElementsSection>
  );
};
