import styled from 'styled-components';
import { responsive, Responsive } from 'styles/helpers/responsive';
import { ColorKey } from 'styles/theme/default';
import { typeStyle } from 'styles/typeStyle';

interface UppercaseTextProps {
  color?: ColorKey;
  margin?: Responsive;
}

export const UppercaseText = styled.span<UppercaseTextProps>`
  ${typeStyle.uppercase};
  ${(props) => `
  ${props.color && `color: ${props.theme.color[props.color]};`}
  ${props.margin && `display: block;${responsive('margin', props.margin)}`}
`}
`;
