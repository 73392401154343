// This component works along the TabsContainer component which holds the state of the tabs.
// the active and setActive states are inherited from it, so passing them as props directly to this component wont
// do anything. TabsContainer should be the parent of this component... hope that helps. :)

// eslint-disable-next-line import/no-unresolved
import { TabsProps as GeneralTabsProps } from 'globalTypes';
import { ReactElement, useEffect } from 'react';
import * as S from './Tabs.styles';
import { Plus } from '../Icon/Icon';
import { ColorKey } from 'styles/theme/default';
import { useTabsIndicator } from 'hooks/useTabsIndicator';
import { useDraggableElement } from 'hooks/useDragglableElement';

interface TabsProps extends GeneralTabsProps {
  color?: ColorKey;
  cta?: {
    label: string;
    action?: () => void;
  };
  isNumerated?: boolean;
}

export const Tabs = ({
  tabs,
  cta,
  color = 'blueA',
  active = 0,
  isNumerated,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActive = () => {},
  ...props
}: TabsProps): ReactElement => {
  const { tabsContainerRef, tabsIndicatorRef, calculateIndicatorPosition } = useTabsIndicator({
    active,
  });
  const { draggableRef } = useDraggableElement({ watch: [tabs] });
  const tabsAreEmpty = tabs.length === 0;

  useEffect(() => {
    setActive(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    calculateIndicatorPosition();
    if (active >= tabs.length) {
      setActive(tabs.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabs]);

  return (
    <S.StyledTabs {...props} $empty={tabsAreEmpty}>
      <S.StyledDraggableWrapper>
        <S.StyledTabsContainer ref={draggableRef}>
          <S.StyledTabIndicator color={color} ref={tabsIndicatorRef} $empty={tabsAreEmpty} />
          <S.TabsList ref={tabsContainerRef}>
            {tabs.map((tab, index) => (
              <S.StyledTabButton
                onClick={() => setActive(index)}
                isActive={index === active}
                // eslint-disable-next-line react/no-array-index-key
                key={`${tab}-${index}`}
                type="button"
              >
                {isNumerated ? `${tab}º` : tab}
              </S.StyledTabButton>
            ))}
          </S.TabsList>
        </S.StyledTabsContainer>
      </S.StyledDraggableWrapper>
      {cta && (
        <S.StyledPrimaryButton
          type="button"
          $outline
          onClick={cta.action}
          $size="regular"
          $color={color}
        >
          {cta.label}
          <Plus />
        </S.StyledPrimaryButton>
      )}
    </S.StyledTabs>
  );
};
