import styled from 'styled-components';
import { Form } from 'components/molecules/Form/Form';
import { Flex } from 'components/atoms/Flex/FlexContainer';
import { IconButton } from 'components/molecules/IconButton/IconButton';
import { size } from 'styles/helpers/size';
import { Loader } from 'components/atoms/Loading/Loading.styles';

export const StyledCreateAssessment = styled.div`
  padding-bottom: 7rem;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: 4.5rem;
  padding-bottom: 7rem;
`;

export const ButtonWrapper = styled(Flex)`
  margin-top: 5.1rem;
  height: 4.2rem;
`;

export const StyledDeleteButton = styled(IconButton)`
  ${size('3.4rem')};
  position: absolute;
  top: 6.7rem;
  right: 0;
`;

export const StyledLoading = styled(Loader)`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
`;
