/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/rules-of-hooks */
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { escapeRegExp, isArray, values } from 'lodash';
import { createQuestionModalOptions } from './CreateQuestionModalOptions';
import { AdminHeading } from '../../components/AdminHeading/AdminHeading';
import { ManagementTable } from '../../components/ManagementTable/ManagementTable';
import { tableData } from './QuestionsManagement.data';
import { editModalQuestionProps } from 'util/editModalQuestionProps';
import { PrimaryButton } from 'components/atoms/PrimaryButton/PrimaryButton';
import { Plus } from 'components/atoms/Icon/Icon';
import { Path } from 'routes/Path';
import { FormModalContext, ModalData } from 'components/organisms/FormModal/FormModal';
import { ConfirmModalContext } from 'components/organisms/ConfirmModal/ConfirmModal';
import { RootState } from 'redux/store';
import { questionsActions } from 'redux/ducks/questionsDuck/questionsReducer';
import { QuestionProps } from 'redux/ducks/questionsDuck/questionsTypes';
import { TableOptions } from 'components/atoms/Table/Table.types';
import { setSuccessEventAction } from 'redux/ducks/eventsDuck/eventsActions';
import { Events } from 'redux/ducks/eventsDuck/eventsTypes';
import { ActionMethod } from 'data/enum/ActionMethod';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface QuestionsManagementProps {}

// eslint-disable-next-line no-empty-pattern
export const QuestionsManagement = ({}: QuestionsManagementProps): ReactElement => {
  const { useFormModal } = useContext(FormModalContext);
  const { useConfirmModal } = useContext(ConfirmModalContext);
  const { questions } = useSelector((state: RootState) => state.questions);
  const dispatch = useDispatch();

  const [filterQuery, setFilterQuery] = useState<string>();
  const [formattedTableData, setFormattedTableData] = useState<TableOptions>();

  /**
   * Create and update the data used to populate the table.
   */
  const createFormattedTableData = (data: Array<QuestionProps>) => {
    const rows = data.map((item, index) => {
      const date = item.created_at && item.created_at.substring(0, 10);

      return [
        { copy: index + 1 },
        { copy: item.name },
        { copy: item.description },
        { copy: date },
        { type: 'Checkbox', checkboxDefaultChecked: item.set_as_open, areActionsDisabled: true },
        { data: item, hidden: true },
      ];
    });

    setFormattedTableData({ ...tableData, rows } as TableOptions);
  };

  /**
   * Filter and update table data elements by their name and description based on a filter string.
   * */
  const filterTableData = (data: Array<QuestionProps>) => {
    const regex = new RegExp(`(${escapeRegExp(filterQuery?.toLowerCase())})`);

    const filtered = values(data).filter(
      (item) => regex.test(item.name.toLowerCase()) || regex.test(item.description.toLowerCase()),
    );

    createFormattedTableData(filtered);
  };

  const createNewQuestion = async () => {
    const response = (await useFormModal(createQuestionModalOptions as ModalData)) as {
      name: string;
      description: string;
      set_as_open: boolean;
    };
    const createNewQuestionBody = {
      ...response,
      allow_no_answer: false,
      element_type_id: 2,
    };
    dispatch(
      questionsActions.questionsApiHandler({
        method: ActionMethod.POST,
        data: createNewQuestionBody,
      }),
    );
  };

  const Delete = async (rowIndex: number) => {
    const selectedRow = formattedTableData && formattedTableData.rows[rowIndex];

    if (!selectedRow) return;

    const rowData = (selectedRow.filter((item) => item.data)[0] || {}).data;

    const confirmed = await useConfirmModal({
      title: 'Delete',
      subtitle: selectedRow[1].copy,
      description: 'Are you sure you want to delete this question?',
    });

    if (!confirmed) return;

    const { id } = rowData;
    dispatch(questionsActions.questionsApiHandler({ id, method: ActionMethod.DELETE }));
    dispatch(setSuccessEventAction(Events.FORM_DELETE_SUCCESS));
  };

  const Edit = async (rowIndex: number) => {
    const selectedRow = formattedTableData && formattedTableData.rows[rowIndex];

    if (!selectedRow) return;

    const rowData = (selectedRow.filter((item) => item.data)[0] || {}).data;
    const { id, name, description } = rowData;
    const formModalProps = editModalQuestionProps(name, description);

    const updatedValues = (await useFormModal(formModalProps)) as QuestionProps;
    if (!updatedValues) return;

    const editQuestionBody = {
      ...rowData,
      description: updatedValues.description,
      name: updatedValues.name,
    };

    dispatch(
      questionsActions.questionsApiHandler({
        id,
        method: ActionMethod.PUT,
        data: editQuestionBody,
      }),
    );
    dispatch(questionsActions.questionsApiHandler({ method: ActionMethod.GET }));
  };

  useEffect(() => {
    dispatch(questionsActions.questionsApiHandler({ method: ActionMethod.GET }));
  }, [dispatch]);

  useEffect(() => {
    if (filterQuery === undefined) {
      createFormattedTableData(isArray(questions) ? questions : []);
    } else {
      filterTableData(isArray(questions) ? questions : []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions, filterQuery]);

  return (
    <>
      <AdminHeading
        title="Questions Management"
        subtitle="Create and manage questions"
        backButton={{
          title: 'Back to Management Tool',
          href: Path.ManagementTool,
        }}
      >
        <PrimaryButton $size="regular" onClick={createNewQuestion}>
          Create New Question <Plus />
        </PrimaryButton>
      </AdminHeading>

      {formattedTableData && (
        <ManagementTable
          data={formattedTableData as TableOptions}
          actions={{ Delete, Edit }}
          setFilterQuery={setFilterQuery}
          /*
          This will disable action buttons temporarily while some
          survey issues related to this actions are being fixed.
          */
          // @NOTE(gb) excuse me?
          // shouldDisableItem={() => true}
        />
      )}
    </>
  );
};
