/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeEvent, useState } from 'react';

interface initialStateProps {
  [key: string]: number | string;
}

export const useAssesssmentForm = (initialState: initialStateProps) => {
  const [values, setValues] = useState<initialStateProps>(initialState);

  const reset = () => {
    setValues(initialState);
  };

  const handleInputChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    if (target) {
      const { name, value } = target as HTMLTextAreaElement;
      if (name === 'duration' || name === 'mission_amount') {
        setValues({
          ...values,
          [name]: Number(value),
        });
      } else {
        setValues({
          ...values,
          [name]: value,
        });
      }
    }
  };

  const setRateValue = (value: number, title: string) => {
    // I removed the settimeout for now because it was causing issues,
    // the values were not being set in the state properly

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValues((previousValues: initialStateProps) => ({ ...previousValues, [title]: value }));
  };

  return { values, handleInputChange, reset, setRateValue };
};
