/* eslint no-param-reassign: ["error", { "props": false }] */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as Types from './cohortsTypes';
import { ApiClient } from 'util/apiClient';
import { EndpointProps } from 'redux/types';

const cohortsApiHandler = createAsyncThunk<
  Types.CohortPayloadCreatorProps['returnType'],
  Types.CohortPayloadCreatorProps['arguments'],
  Types.CohortPayloadCreatorProps['options']
>('cohorts/cohortsApiHandler', async (payloadArguments) => {
  const endpoint: EndpointProps = {
    get: `/cohorts/${payloadArguments.id || ''}`,
    post: '/cohorts',
    put: `/cohorts/${payloadArguments.id}`,
    delete: `/cohorts/${payloadArguments.id}`,
  };
  const { data, message } = (await ApiClient[payloadArguments.method](
    `${endpoint[payloadArguments.method]}/${payloadArguments.alternateEndpoint || ''}`,
    payloadArguments.body,
  )) as Types.CohortApiResponseProps;

  return { data, message, method: payloadArguments.method };
});

const initialState: Types.InitialStateProps = {
  cohorts: [],
  currentCohort: null,
  loading: false,
  showCohortsError: null,
  postButtonState: false,
};

const cohortsSlice = createSlice({
  name: 'cohorts',
  initialState,
  reducers: {
    disablePostButton(state) {
      state.postButtonState = !state.postButtonState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cohortsApiHandler.pending, (state) => {
        state.loading = true;
      })
      .addCase(cohortsApiHandler.fulfilled, (state, action) => {
        const cohortData = action.payload.data;
        const actionMethod = action.payload.method;
        const updatedStateProp = actionMethod === 'post' ? 'currentCohort' : 'cohorts';

        state[updatedStateProp] = cohortData;

        state.loading = false;
      })
      .addCase(cohortsApiHandler.rejected, (state, action) => {
        state.showCohortsError = action.payload?.message;
        state.loading = false;
      });
  },
});

export const cohortsActions = {
  ...cohortsSlice.actions,
  cohortsApiHandler,
};

export default cohortsSlice.reducer;
